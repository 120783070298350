
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    currentCompetition: undefined
  },
  mutations: {
    update: (state, payload) => _.assign(state, payload)
  }
};
