<template>
  <div>
    <el-form ref="form" :model="additionalQuestion" :rules="rules" label-position="top">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="additionalQuestion.type" placeholder="E.g: Input" filterable>
              <el-option
                v-for="e in additionalQTypes"
                :label="e.name"
                :key="e.type"
                :value="e.type"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="fieldName" label="Report Column Name">
            <el-input
              v-model="additionalQuestion.fieldName"
              placeholder="E.g: Application Reason"
            />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item prop="question" label="Question">
            <el-input
              type="textarea"
              v-model="additionalQuestion.question"
              maxlength="40"
              placeholder="E.g: Country / Area of Birth"
              :rows="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="13" v-if="[2, 4].includes(additionalQuestion.type)">
          <el-form-item prop="meta.options" label="Options">
            <div v-for="(option, idx) in additionalQuestion.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="additionalQuestion.meta.options.splice(idx, 1)"
                ></el-button>
              </div>
            </div>
            <div>
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(additionalQuestion.meta.options)"
                >Add Option</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="5">
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="additionalQuestion.gender" placeholder="E.g: Female">
              <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="ageFrom" label="Age From">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageFrom"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="ageTo" label="Age To">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageTo"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="required" label="Required">
            <el-select v-model="additionalQuestion.required" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="additionalQuestion.isActive" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="add">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      :data="program.additionalQuestions"
      :table-props="{ border: true }"
      layout="table"
      class="data-table mb-2"
    >
      <el-table-column label="Type" width="180">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option
              v-for="e in additionalQTypes"
              :label="e.name"
              :key="e.type"
              :value="e.type"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Report Column Name" width="200">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.fieldName"
            placeholder="E.g: Application Reason"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Question" width="500">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.question"
            maxlength="40"
            placeholder="E.g: Country / Area of Birth"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Options" width="300">
        <template v-slot="scope">
          <div v-if="scope.row.type === 2">
            <div v-for="(option, idx) in scope.row.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                  :disabled="!editingRows[scope.$index]"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="scope.row.meta.options.splice(idx, 1)"
                  v-if="editingRows[scope.$index]"
                />
              </div>
            </div>
            <div v-if="editingRows[scope.$index]">
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(scope.row.meta.options)"
                >Add Option</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.gender"
            placeholder="Gender"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageFrom"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageTo"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column label="Required" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.required"
            placeholder="Required"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Active" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.isActive"
            placeholder="Active"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template v-slot="scope">
          <el-button class="px-1" @click="edit(scope.$index)">
            {{ editingRows[scope.$index] ? "Save" : "Edit" }}
          </el-button>
          <el-button class="px-2" @click="remove(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, genders, additionalQTypes } from "@/utils/constants";

const initAdditionalQuestion = () =>
  _.cloneDeep({
    type: undefined,
    question: undefined,
    fieldName: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    gender: undefined,
    required: undefined,
    isActive: true,
    meta: {
      options: undefined
    }
  });

const addNewOption = options => {
  options.push({ type: "string", value: "" });
};

const updateProgram = (self, additionalQuestions, onSuccess) => {
  const update = { additionalQuestions };
  self.$store.commit("root/loading", true);
  const url = `/nrl/api/v1/admin/programs/${self.program._id}`;
  self.$http
    .put(url, update)
    .then(response => {
      const program = response.data.data;
      const parsedItems = program.additionalQuestions;
      onSuccess(parsedItems);
      self.$store.commit("root/loading", false);
      self.$customSuccess();
    })
    .catch(() => {
      self.$store.commit("root/loading", false);
      self.$customError();
    });
};

export default {
  props: ["program"],
  data() {
    const defaultRequiredRule = { required: true, trigger: "blur", message: errormsg.input_option };
    const checkOptions = (rule, value, callback) => {
      if (!value || value.length === 0) return callback(new Error("Options is required"));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === "") return callback(new Error("Option value is required."));
      }
      return callback();
    };
    return {
      additionalQuestion: initAdditionalQuestion(),
      additionalQTypes,
      genders,
      rules: {
        type: defaultRequiredRule,
        fieldName: [
          defaultRequiredRule,
          {
            min: 0,
            max: 25,
            message: "Length must be less than 25 chars",
            trigger: "blur"
          }
        ],
        question: defaultRequiredRule,
        gender: { required: true, trigger: "blur", message: errormsg.input_option },
        ageFrom: defaultRequiredRule,
        ageTo: defaultRequiredRule,
        required: defaultRequiredRule,
        isActive: defaultRequiredRule,
        "meta.options": { trigger: "blur", validator: checkOptions }
      },
      editingRows: []
    };
  },
  mounted() {},
  computed: {},
  methods: {
    isAgeValid(object) {
      if (object.ageTo < object.ageFrom) {
        this.$customError(errormsg.gte_age);
        return false;
      }
      return true;
    },
    isColumnNameValid(questions, question) {
      if (_.find(questions, q => q.fieldName === question.fieldName)) {
        this.$customError("Report column name already exists");
        return false;
      }
      return true;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (!valid) return;

        // Check if age is valid
        if (!this.isAgeValid(this.additionalQuestion)) return;

        // Check if report column name exists
        if (!this.isColumnNameValid(this.program.additionalQuestions, this.additionalQuestion))
          return;

        // Trim
        this.additionalQuestion.fieldName = _.trim(this.additionalQuestion.fieldName);

        const additionalQuestions = _.cloneDeep(this.program.additionalQuestions);
        additionalQuestions.push(this.additionalQuestion);

        updateProgram(this, additionalQuestions, items => {
          this.program.additionalQuestions = items;
          this.additionalQuestion = initAdditionalQuestion();
        });
      });
    },
    edit(rowIndex) {
      if (this.editingRows[rowIndex]) {
        // Save row
        const additionalQuestion = this.program.additionalQuestions[rowIndex];
        additionalQuestion.fieldName = _.trim(additionalQuestion.fieldName);

        // Check if age is valid
        if (!this.isAgeValid(additionalQuestion)) return false;

        // Check if report column name exists
        const checkQs = _.cloneDeep(this.program.additionalQuestions);
        _.pullAt(checkQs, [rowIndex]);
        if (!this.isColumnNameValid(checkQs, additionalQuestion)) return false;

        updateProgram(this, _.cloneDeep(this.program.additionalQuestions), parsedItems => {
          this.program.additionalQuestions = parsedItems;
          this.$set(this.editingRows, rowIndex, !this.editingRows[rowIndex]);
        });
      } else this.$set(this.editingRows, rowIndex, true);
      return true;
    },
    remove(index) {
      this.$confirm("Are you sure you want to remove this additional question?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        const additionalQuestions = _.cloneDeep(this.program.additionalQuestions);
        additionalQuestions.splice(index, 1);
        updateProgram(this, additionalQuestions, () => {
          this.program.additionalQuestions.splice(index, 1);
          this.editingRows.splice(index, 1);
        });
      });
    },
    addOption(options) {
      addNewOption(options);
    }
  },
  watch: {
    // eslint-disable-next-line
    "additionalQuestion.type": function(val) {
      if (val === 2 || val === 4) {
        this.additionalQuestion.meta.options = [];
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
      } else this.additionalQuestion.meta.options = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
</style>
