<template>
  <div>
    <el-form>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="12">
          <el-form-item label="Competition">
            <el-select
              class="select-competition"
              v-model="competitionId"
              placeholder="Select a competition"
              filterable
              clearable
            >
              <el-option
                v-for="item in availableCompetitions"
                :label="item.name"
                :key="item._id"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="8">
          <el-form-item label="Date Range">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              value-format="timestamp"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
              clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Round Type">
            <el-select v-model="roundType" placeholder="Select Type" filterable clearable>
              <el-option v-for="type in roundTypes" :label="type" :key="type" :value="type">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Round Number">
            <el-input
              type="number"
              v-model="roundNumber"
              min="1"
              @keydown.native="inputNumberOnly"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Status">
            <el-select v-model="status" placeholder="Select a status" filterable clearable>
              <el-option
                v-for="item in matchStatus"
                :label="item.name"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button @click="search" type="success"> Search </el-button>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-button
          v-if="tableData.length > 0 && bulkEditing === false"
          @click="bulkEdit"
          type="success"
          icon="el-icon-setting"
          class="bulk-edit"
        >
          Bulk Edit
        </el-button>
        <el-button
          v-if="bulkEditing === true"
          @click="saveBulkEdit"
          type="success"
          icon="el-icon-setting"
          class="bulk-edit"
        >
          Save Edits
        </el-button>
        <el-button v-if="bulkEditing === true" @click="cancelBulkEdit" class="bulk-edit">
          Cancel
        </el-button>
      </div>
    </el-form>
    <data-tables
      v-loading="loading"
      :data="bulkEditing === true ? editData : tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="edit"
      class="data-table"
    >
      <el-table-column fixed align="left" prop="competition.name" label="Competition" width="230">
      </el-table-column>
      <el-table-column fixed align="left" prop="round.type" label="Round Type" width="98">
      </el-table-column>
      <el-table-column fixed align="left" prop="round.number" label="Round" width="65">
      </el-table-column>
      <el-table-column align="left" prop="homeTeam.name" label="Home Team" width="250">
      </el-table-column>
      <el-table-column align="left" prop="awayTeam.name" label="Away Team" width="250">
      </el-table-column>
      <el-table-column align="left" label="Home Score" width="100">
        <template slot-scope="scope">
          <el-input
            type="number"
            autocomplete="off"
            min="0"
            v-model="scope.row.scores.homeTeam"
            @change="markAsEdited(scope)"
            @keydown.native="inputNumberOnly"
            v-if="bulkEditing && !scope.row.meta.isBye"
          >
          </el-input>
          <span v-else> {{ scope.row.scores.homeTeam }} </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Away Score" width="100">
        <template slot-scope="scope">
          <el-input
            type="number"
            autocomplete="off"
            min="0"
            v-model="scope.row.scores.awayTeam"
            @change="markAsEdited(scope)"
            @keydown.native="inputNumberOnly"
            v-if="bulkEditing && !scope.row.meta.isBye"
          >
          </el-input>
          <span v-else> {{ scope.row.scores.awayTeam }} </span>
        </template>
      </el-table-column>
      <el-table-column label="Match Status" width="150">
        <template slot-scope="scope">
          <el-select
            v-if="bulkEditing"
            v-model="scope.row.status"
            @change="markAsEdited(scope)"
            placeholder="Status"
          >
            <el-option
              v-for="item in matchStatusBulk"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <span v-else> {{ matchStatusFormatter(scope.row) }} </span>
        </template>
      </el-table-column>
      <el-table-column
        :formatter="dateTimeFormatter"
        align="left"
        prop="dateTime"
        label="Date & Time"
        width="200"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { matchStatus, matchStatusBulk } from "../../utils/constants";

const _ = require("lodash");

export default {
  name: "MatchScoreList",
  props: {},
  data() {
    return {
      loading: false,
      bulkEditing: false,
      availableCompetitions: [],
      roundTypes: ["Regular", "Final"],

      competitionId: undefined,
      dateRange: undefined,
      roundType: undefined,
      roundNumber: undefined,
      status: undefined,

      tableData: [],
      editData: [],
      matchStatus,
      matchStatusBulk,
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [25, 50, 75],
      },
    };
  },
  mounted() {
    // remove forfeit from matchStatus constants and add forfeit home and away
    // let forfeit = [
    //   { name: "Forfeit Home", type: "Forfeit Home" },
    //   { name: "Forfeit Away", type: "Forfeit Away" }
    // ];
    // this.matchStatusBulk = this.matchStatusBulk.filter(item => item.type != "forfeit");
    // forfeit.forEach(item => {
    //   var exits = this.matchStatusBulk.find(i => i.name === item.name);
    //   if (!exits) {
    //     this.matchStatusBulk.push(item);
    //   }
    // });

    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.availableCompetitions =
          response.data && response.data.data ? response.data.data.filter((c) => c.isActive) : [];

        // fetch matchscores store if any
        _.assign(this, this.$store.state.matchscores.search);
        if (this.competitionId || this.dateRange) {
          this.search();
        }

        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    search() {
      const params = {
        competitionId: this.competitionId || undefined,
        dateRange: this.dateRange || undefined,
        roundType: this.roundType || undefined,
        roundNumber: parseInt(this.roundNumber) || undefined,
        status: undefined,
      };
      if (this.status) params.status = this.status;

      if (params.competitionId === undefined && params.dateRange === undefined) {
        this.$customError("Competition or date range is required for searching.");
        return;
      }

      // store valid search state
      this.$store.commit("matchscores/update", params);

      this.bulkEditing = false;
      this.loading = true;
      this.$http
        .post("/nrl/api/v1/admin/matche-scores/search", params)
        .then((matchesRes) => {
          this.tableData = matchesRes.data.data.map((match) => {
            match.homeTeam = match.homeTeam || { name: match.meta.isBye ? "BYE" : "NA" };
            match.awayTeam = match.awayTeam || { name: match.meta.isBye ? "BYE" : "NA" };
            return match;
          });
          this.loading = false;
        })
        .catch(() => {
          this.$customError();
          this.loading = false;
        });
    },
    edit(val) {
      if (this.bulkEditing) return;
      this.$router.push({
        name: "matches.update",
        params: { id: val._id },
      });
    },
    bulkEdit() {
      this.editData = _.cloneDeep(this.tableData);
      this.bulkEditing = true;
    },
    saveBulkEdit() {
      this.$store.commit("root/loading", true);
      const bulkPrommises = [];
      const updateTableDate = _.cloneDeep(this.tableData);

      this.editData.forEach((match) => {
        if (match.edited) {
          const homeScore = parseInt(match.scores.homeTeam);
          const awayScore = parseInt(match.scores.awayTeam);
          const updateMatch = {
            scores: {
              homeTeam: _.isNaN(homeScore) ? undefined : homeScore,
              awayTeam: _.isNaN(awayScore) ? undefined : awayScore,
            },
            status: match.status || undefined,
            meta: match.meta,
          };

          // Update local table data
          const tableMatch = _.find(updateTableDate, (m) => m._id === match._id);
          if (updateMatch.scores.homeTeam !== undefined)
            tableMatch.scores.homeTeam = updateMatch.scores.homeTeam;
          if (updateMatch.scores.awayTeam !== undefined)
            tableMatch.scores.awayTeam = updateMatch.scores.awayTeam;
          if (updateMatch.status !== undefined) tableMatch.status = updateMatch.status;

          if (match.status === "Forfeit Home") {
            updateMatch.status = "forfeit";
            updateMatch.meta["forfeitingTeam"] = match.homeTeam;
            tableMatch.meta["forfeitingTeam"] = match.homeTeam;
          } else if (match.status === "Forfeit Away") {
            updateMatch.status = "forfeit";
            updateMatch.meta["forfeitingTeam"] = match.awayTeam;
            tableMatch.meta["forfeitingTeam"] = match.awayTeam;
          }

          bulkPrommises.push(this.$http.put(`/nrl/api/v1/admin/matches/${match._id}`, updateMatch));
        }
      });
      Promise.all(bulkPrommises)
        .then(() => {
          this.tableData = updateTableDate;
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.$customError();
        });
      return true;
    },
    cancelBulkEdit() {
      this.editData = [];
      this.bulkEditing = false;
    },
    dateTimeFormatter(row) {
      if (row && row.dateTime) {
        return this.moment(row.dateTime).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    matchStatusFormatter(row) {
      if (row.status) {
        if (
          row.status == "forfeit" ||
          row.status == "Forfeit Home" ||
          row.status == "Forfeit Away"
        ) {
          return (row.status =
            row.meta.forfeitingTeam._id === row.homeTeam._id ? "Forfeit Home" : "Forfeit Away");
        } else {
          return matchStatus.find((s) => s.type === row.status).name;
        }
      }
      return "NA";
    },
    markAsEdited(scope) {
      scope.row.edited = true;
    },
    inputNumberOnly(event) {
      if ([69, 189, 190].includes(event.keyCode)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}

.data-table-cell {
  text-align: center;
}

.el-pagination {
  margin-top: 1rem !important;
}

.select-competition {
  width: 100%;
}
</style>
