<template>
  <div>
    <el-form
      :rules="rules"
      :model="code"
      @submit.native.prevent
      ref="discountCode"
      label-position="top"
    >
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="discountAmount" label="Discount Amount">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="code.discountAmount"
              placeholder="Discount amount"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountEmail" label="Email Address">
            <el-input
              type="email"
              autocomplete="off"
              v-model="code.discountEmail"
              placeholder="Email address"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="expiry" label="Expire At">
            <el-date-picker
              v-model="code.expiry"
              type="date"
              placeholder="Select a Date"
              format="yyyy/MM/dd"
              value-format="timestamp"
              :picker-options="adminEditDates"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitDiscount('discountCode')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>

    <data-tables
      v-if="discountCodes.length > 0"
      :data="discountCodes"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="ID" prop="_id" width="90"> </el-table-column>
      <el-table-column label="Discount Email" prop="discountEmail" width="auto"> </el-table-column>
      <el-table-column label="Discount Code" prop="code" width="auto"> </el-table-column>

      <el-table-column
        :formatter="
          row => (row.discountAmount ? `$${(row.discountAmount / 100).toFixed(2)}` : false)
        "
        label="Discount Amount"
        prop="discountAmount"
        width="150"
      >
      </el-table-column>

      <el-table-column
        label="Status"
        prop="redeemed"
        width="auto"
        :formatter="row => (row.redeemed ? 'Redeemed' : row.expiry > new Date() ? 'Valid': 'Expired')"
      >
      </el-table-column>
      <el-table-column
        :formatter="expiryFormatter"
        prop="expiry"
        align="left"
        label="Expires"
        width="auto"
      >
      </el-table-column>
      <el-table-column label="Edit/Remove" width="300">
        <template slot-scope="scope">
          <el-button type="success" @click="handleRemoveRowDiscount(scope.$index)"
            >Remove</el-button
          >
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg } from "../../utils/constants";

export default {
  name: "DiscountsCode",
  props: ["program"],
  data() {
    const discountAmountValidator = (obj, value, callback) => {
      if (!value || !parseFloat(value) || parseFloat(value) <= 0) {
        return callback(new Error(errormsg.discount_amount));
      }
      return callback();
    };

    return {
      tableProps: {
        border: true
      },
      discountCodes: [],
      code: {
        entityId: undefined,
        entityType: "program-settings",
        discountAmount: undefined,
        discountEmail: undefined,
        expiry: new Date().setDate(new Date().getDate() + 7) // 7 days from now
      },
      rules: {
        discountAmount: {
          required: true,
          validator: discountAmountValidator
        },
        discountEmail: {
          type: "email",
          required: true,
          message: errormsg.email,
          trigger: "blur"
        }
      }
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get(`/nrl/api/v1/admin/registration-products/discount-codes/programs-settings/${this.$route.params.id}`)
      .then(response => {
        const codes = response.data.data;
        this.discountCodes = codes || [];
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    handleRemoveRowDiscount(index) {
      const code = this.discountCodes[index];
      const { _id } = code;
      this.$confirm(
        "This will remove the discount code and the user will no longer be able to redeem it. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.$http
            .delete("/nrl/api/v1/admin/registration-products/discount-codes", { data: { _id } })
            .then(() => {
              this.discountCodes.splice(index, 1);
              this.$customSuccess();
            })
            .catch(() => {
              this.$customError();
            });
        })
        .catch(() => {});
    },
    expiryFormatter(row) {
      if (row.expiry) {
        return this.moment(row.expiry).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    submitDiscount(formName) {
      this.$refs[formName].validate(validated => {
        if (validated) {
          // eslint-disable-next-line prefer-const
          let { discountAmount, discountEmail, entityType, expiry } = this.code;
          const entityId =  this.program._id;
          this.$confirm(
            `This will generate a discount code that gives ${discountEmail} a discount amount of $${discountAmount}. Continue?`,
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning"
            }
          )
            .then(() => {
              this.$store.commit("root/loading", true);
              discountAmount = parseInt(parseFloat(discountAmount).toFixed(2) * 100);
              this.$http
                .post("/nrl/api/v1/admin/registration-products/discount-codes", {
                  discountAmount,
                  discountEmail,
                  entityType,
                  entityId,
                  expiry
                })
                .then(response => {
                  const { code } = response.data;
                  this.discountCodes.push(code);
                  this.$customSuccess();
                  this.$store.commit("root/loading", false);
                })
                .catch(() => {
                  this.$customError();
                  this.$store.commit("root/loading", false);
                });
            })
            .catch(() => {});
        }
      });
    }
  },
  computed: {
    activeRole() {
      return this.$store.getters["user/activeRole"];
    }
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
