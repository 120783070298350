<template>
  <div>
    <transition
      name="custom"
      enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutUp"
    >
      <div
        v-if="showModal"
        style="max-height: max-content"
        class="tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-flex tw-flex-col tw-self-center tw-w-11/12 tw-max-w-xl tw-m-auto tw-bg-white tw-rounded-md tw-shadow-2xl lg:tw-w-full"
      >
        <div
          class="tw-p-6 tw-text-lg tw-font-bold tw-text-indigo-400 tw-border-b-4 tw-border-gray-200"
        >
          Player Availability
        </div>
        <div class="tw-p-6">
          <p>
            You are marking this participant as available, please confirm you have sighted the
            medical clearance form
          </p>
        </div>
        <div class="tw-flex tw-p-6 tw-border-t-4 tw-border-gray-200">
          <el-row class="tw-w-full" :gutter="4">
            <el-col :span="12">
              <el-button type="success" @click="updateCompcase()">Save</el-button>
            </el-col>
            <el-col :span="12" class="align-text-right">
              <button
                @click="showModal = false"
                class="tw-px-4 tw-py-2 tw-text-white tw-transition tw-duration-150 tw-ease-in-out tw-bg-green-400 tw-rounded-md tw-hover:bg-green-500 tw-focus:outline-none"
              >
                Cancel
              </button>
            </el-col>
          </el-row>
        </div>
      </div>
    </transition>
    <transition
      name="custom"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="showModal"
        @click="showModal = false"
        class="tw-fixed tw-bottom-0 tw-left-0 tw-z-40 tw-w-full tw-h-full tw-transition tw-duration-150 tw-ease-in-out tw-bg-gray-700 tw-bg-opacity-50 tw-transfom"
      ></div>
    </transition>
    <el-form
      label-position="top"
      :model="compcase"
      :rules="rules"
      ref="compcase"
      class="compcase-form"
    >
      <el-row>
        <el-col :span="12">
          <h4 class="tw-pt-6 tw-mt-6 tw-font-bold">Case Details</h4>
        </el-col>
        <el-col class="comp-action" v-if="clearance === roles.superAdmin" :span="12">
          <el-button type="danger" @click="removeDialog = true" class="remove-btn"
            >Remove Case</el-button
          >
        </el-col>
      </el-row>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="compcase.type" disabled>
              <el-option
                v-for="{ type, name } in compCasesTypes"
                :label="name"
                :key="type"
                :value="type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="scene" label="Scene">
            <el-select v-model="compcase.scene" disabled>
              <el-option
                v-for="{ type, name } in sceneType"
                :label="name"
                :key="type"
                :value="type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item label="Competition">
            <el-input :value="compcase.meta.competition.name || 'N/A'" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="round" label="Round">
            <el-input :value="formatRoundName()" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="meta.matchId" label="Match">
            <el-input :value="formatMatchName()" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="meta.teamId" label="Team">
            <el-input :value="(compcase.team && compcase.team.name) || 'N/A'" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="memberId" label="Member">
            <el-input :value="formatMemberName()" disabled></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col v-if="compcase.type !== 2" :span="8">
          <el-form-item prop="meta.dateTimeIncident" label="Date and Time Of Incident">
            <el-date-picker
              v-model="compcase.meta.dateTimeIncident"
              type="datetime"
              placeholder="Select"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col v-if="compcase.type === 2" :span="8">
          <el-form-item prop="dateOfInjury">
            <el-input
              class="input"
              v-model="compcase.dateOfInjuryStr"
              type="date"
              @change="changeDateForBackend"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="compcase.type === 2" :span="8">
          <el-form-item prop="timeOfInjury">
            <el-input
              class="input"
              v-model="compcase.timeOfInjuryStr"
              type="time"
              @change="changeTimeForBackend"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="venue" label="Venue">
            <el-input v-model="compcase.venue.name" disabled> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="compcase.type !== 2">
          <el-form-item prop="meta.onFieldAction" label="On-field Action">
            <el-select v-model="compcase.meta.onFieldAction" placeholder="Select" clearable>
              <el-option
                v-for="item in onFieldActions"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="8"
          v-if="compcase.scene === 1 && compcase.meta.timeOfIncident"
          class="tw-flex tw-flex-wrap"
        >
          <label class="tw-w-full el-form-item__label">Game Time</label>
          <el-form-item class="tw-w-1/2" prop="meta.timeOfIncident.mins">
            <el-input
              class="input"
              type="number"
              max="199"
              min="0"
              @change="(e) => gameTimeChecks(e, 'mins')"
              v-model="compcase.meta.timeOfIncident.mins"
              placeholder="mins"
            />
          </el-form-item>
          <el-form-item class="tw-w-1/2" prop="meta.timeOfIncident.secs">
            <el-input
              class="input"
              type="number"
              max="59"
              min="0"
              @change="(e) => gameTimeChecks(e, 'secs')"
              v-model="compcase.meta.timeOfIncident.secs"
              placeholder="secs"
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <!-- STATUS -->
        <el-col :span="8" v-if="compcase.type !== 2">
          <el-form-item prop="status" label="status">
            <el-select v-model="compcase.status" filterable clearable>
              <el-option
                v-for="item in tribunalStatus"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- HEARING REQUIRED -->
        <el-col :span="8" v-if="compcase.type !== 2">
          <el-form-item prop="hearing.required" label="hearing required">
            <el-select v-model="compcase.hearing.required" filterable clearable>
              <el-option label="Yes" key="Yes" :value="true"></el-option>
              <el-option label="No" key="No" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- HEARING DATE TIME -->
        <el-col :span="8" v-if="compcase.type !== 2">
          <el-form-item prop="hearing.dateTime" label="hearing date/time">
            <el-date-picker
              value-format="timestamp"
              v-model="compcase.hearing.dateTime"
              type="datetime"
              placeholder="Date and time"
              format="yyyy/MM/dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </div>

      <!-- CHARGES AREA -->
      <template v-if="compcase.type !== 2">
        <h4 class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400">Charges</h4>

        <el-row :gutter="10" v-for="(charge, index) in compcase.charges" :key="index">
          <el-col :span="8">
            <el-form-item
              :prop="`charges[${index}].id`"
              :rules="{ required: true, message: 'Field is required', trigger: 'blur' }"
              label="Offence"
            >
              <el-select
                v-model="compcase.charges[index].id"
                :disabled="compcase.charges[index].id ? true : false"
              >
                <el-option
                  v-for="{ type, name } in caseCharges"
                  :label="name"
                  :key="type"
                  :value="type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :prop="`charges[${index}].description`"
              :rules="{ required: true, message: 'Field is required', trigger: 'blur' }"
              label="Action Identified"
            >
              <el-input autocomplete="off" v-model="compcase.charges[index].description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :prop="`charges[${index}].recommendedGrading`"
              :rules="{ required: true, message: 'Field is required', trigger: 'blur' }"
              label="Recommended Grading"
            >
              <el-select v-model="compcase.charges[index].recommendedGrading">
                <el-option
                  v-for="item in chargeGradings"
                  :label="item.name"
                  :key="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="grading">
              <el-select v-model="compcase.charges[index].grading">
                <el-option
                  v-for="item in chargeGradings"
                  :label="item.name"
                  :key="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="outcome">
              <el-select v-model="compcase.charges[index].outcome">
                <el-option
                  v-for="item in chargeOutcomes"
                  :label="item"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="btn-control" v-if="compcase.charges[index].new">
            <el-button type="danger" @click="removeCharge(index)">Remove Charge</el-button>
          </el-col>
        </el-row>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="24">
            <el-button type="success" @click="addCharge">Add Charge</el-button>
          </el-col>
        </div>
      </template>

      <h4
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
        v-if="compcase.type !== 2"
      >
        Penalties
      </h4>
      <!-- PENALTY AREA -->
      <div
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        v-if="compcase.type !== 2"
      >
        <el-col :span="6">
          <el-form-item label="penalty type">
            <el-select v-model="compcase.meta.penalty.type" filterable clearable>
              <el-option
                v-for="item in penaltyTypes"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="penalty units">
            <el-input-number
              :controls="false"
              :min="0"
              v-model="compcase.meta.penalty.units"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="penalty start date">
            <el-date-picker
              type="date"
              v-model="compcase.meta.penalty.startDate"
              format="yyyy/MM/dd"
              value-format="timestamp"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="penalty expiry date">
            <el-date-picker
              type="date"
              v-model="compcase.meta.penalty.expiryDate"
              format="yyyy/MM/dd"
              value-format="timestamp"
            />
          </el-form-item>
        </el-col>
      </div>
      <!-- SUSPENDED AREA -->
      <div
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        v-if="compcase.type !== 2"
      >
        <el-col :span="6">
          <el-form-item label="suspended penalty type">
            <el-select v-model="compcase.meta.suspendedPenalty.type" filterable clearable>
              <el-option
                v-for="item in penaltyTypes"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="suspended penalty units">
            <el-input-number
              :controls="false"
              :min="0"
              v-model="compcase.meta.suspendedPenalty.units"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="suspended penalty expiry date">
            <el-date-picker
              type="date"
              v-model="compcase.meta.suspendedPenalty.expiryDate"
              format="yyyy/MM/dd"
              value-format="timestamp"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="carry over points">
            <el-input-number
              :controls="false"
              :min="0"
              v-model="compcase.meta.carryOverPts"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </div>

      <h4
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
        v-if="compcase.type !== 2"
      >
        Appeal
      </h4>
      <div
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        v-if="compcase.type !== 2"
      >
        <el-col :span="8">
          <el-form-item prop="meta.appeal.lodged" label="appealed">
            <el-select v-model="compcase.meta.appeal.lodged" filterable clearable>
              <el-option label="Yes" key="Yes" :value="true"></el-option>
              <el-option label="No" key="No" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="meta.appeal.dateTime" label="appeal date">
            <el-date-picker
              type="date"
              v-model="compcase.meta.appeal.dateTime"
              format="yyyy/MM/dd"
              value-format="timestamp"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="meta.appeal.outcome" label="outcome">
            <el-select v-model="compcase.meta.appeal.outcome" filterable clearable>
              <el-option
                v-for="item in chargeOutcomes"
                :label="item"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>

      <h4
        v-if="compcase.type === 2"
        class="tw-pt-6 tw-pb-2 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
      >
        Injuries
      </h4>
      <div v-if="compcase.type === 2" class="tw-w-full">
        <el-tabs type="card" v-model="tabPosition" class="front-btns">
          <el-tab-pane label="FRONT">
            <svg
              id="af086e76-5151-45da-a2e8-f4977f3f2710"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="220 0 560 1330"
              style="max-height: 600px"
              class="tw-mx-auto"
            >
              <path
                d="M411.33,269.88c-29.28,6.37-63.48,5.56-73.34,40-14.48,50.59-5.85,115.47-5.85,115.47s26.37-9.75,37.82.43,23.76,26.94,23.76,26.94,22.9-6.22,50.7-53.35c21.95-37.22,24.42-66.89,18.37-99.51-3.22-17.39-18.37-42.71-18.37-42.71S440.6,263.52,411.33,269.88Z"
                @click="chooseBodyParts('FRONT', 'Right Shoulder')"
                :fill="isItActive('FRONT', 'Right Shoulder')"
              />
              <path
                d="M588,269.88c29.27,6.37,63.48,5.56,73.34,40,14.47,50.59,5.85,115.47,5.85,115.47s-26.38-9.75-37.83.43-23.75,26.94-23.75,26.94-22.91-6.22-50.7-53.35c-22-37.22-24.42-66.89-18.38-99.51,3.23-17.39,18.38-42.71,18.38-42.71S558.73,263.52,588,269.88Z"
                @click="chooseBodyParts('FRONT', 'Left Shoulder')"
                :fill="isItActive('FRONT', 'Left Shoulder')"
              />
              <path
                d="M329.87,433.43s21.29-11.38,33-3.5,25.06,25.77,25.06,25.77-7.88,11.69-9.07,32.69-2.54,45-11.1,69.71c-11.57,33.38-27.8,45.79-42.83,83.73,0,0-7.32-7.56-16.23-11.21-7.95-3.26-19.8-3.11-19.8-3.11,7.39-46.77,6.68-77.31,13.06-98.33,5.64-18.56,25.35-49.86,26.31-64.41C329.29,448.82,329.87,433.43,329.87,433.43Z"
                @click="chooseBodyParts('FRONT', 'Right Arm/Elbow')"
                :fill="isItActive('FRONT', 'Right Arm/Elbow')"
              />
              <path
                d="M669.38,433.43s-21.28-11.38-33-3.5-25,25.77-25,25.77,7.87,11.69,9.06,32.69,2.55,45,11.1,69.71c11.57,33.38,27.8,45.79,42.84,83.73,0,0,7.31-7.56,16.22-11.21,7.95-3.26,19.81-3.11,19.81-3.11-7.4-46.77-6.68-77.31-13.07-98.33C691.68,510.62,672,479.32,671,464.77,670,448.82,669.38,433.43,669.38,433.43Z"
                @click="chooseBodyParts('FRONT', 'Left Arm/Elbow')"
                :fill="isItActive('FRONT', 'Left Arm/Elbow')"
              />
              <path
                d="M396.2,587.39s43.56,37.74,34.19,85.43c-10.22,52-51.74,88.78-51.74,88.78s-10.13-56.93-7.48-83.05C376.74,623.67,396.2,587.39,396.2,587.39Z"
                @click="chooseBodyParts('FRONT', 'Right Hip')"
                :fill="isItActive('FRONT', 'Right Hip')"
              />
              <path
                d="M602.88,587.39s-43.57,37.74-34.2,85.43c10.22,52,51.74,88.78,51.74,88.78s10.13-56.93,7.48-83.05C622.33,623.67,602.88,587.39,602.88,587.39Z"
                @click="chooseBodyParts('FRONT', 'Left Hip')"
                :fill="isItActive('FRONT', 'Left Hip')"
              />
              <path
                d="M378.15,766.87l17.5,101.5,4.6,56.64A47.1,47.1,0,0,1,435,910.16c20.81,0,35.4,17.08,35.4,17.08L491.8,763.16s-22.65-28.74-57.09-28.74C405.83,734.42,378.15,766.87,378.15,766.87Z"
                @click="chooseBodyParts('FRONT', 'Right Upper Leg')"
                :fill="isItActive('FRONT', 'Right Upper Leg')"
              />
              <path
                d="M621.18,766.87l-17.5,101.5L599.09,925a47.1,47.1,0,0,0-34.73-14.85c-20.81,0-35.41,17.08-35.41,17.08L507.53,763.16s22.65-28.74,57.09-28.74C593.5,734.42,621.18,766.87,621.18,766.87Z"
                @click="chooseBodyParts('FRONT', 'Left Upper Leg')"
                :fill="isItActive('FRONT', 'Left Upper Leg')"
              />
              <path
                d="M402.69,931.05s13.52-14.31,33.44-14.31c16.83,0,32.42,17.18,32.42,17.18l-4.29,30.54s-10.84,12.06-30.55,11.14c-13.62-.64-32.31-18.3-32.31-18.3L403.65,943Z"
                @click="chooseBodyParts('FRONT', 'Right Knee')"
                :fill="isItActive('FRONT', 'Right Knee')"
              />
              <path
                d="M596.64,931.05s-13.52-14.31-33.43-14.31c-16.84,0-32.43,17.18-32.43,17.18l4.3,30.54s10.81,11.14,30.54,11.14c12.89,0,32.32-18.3,32.32-18.3L595.69,943Z"
                @click="chooseBodyParts('FRONT', 'Left Knee')"
                :fill="isItActive('FRONT', 'Left Knee')"
              />
              <path
                d="M398.42,963.51s14.17,18,33.9,19.15c16.68.94,31.1-7.64,31.1-7.64s8.22,37.17,4.78,68.19-5.66,42.16,0,87.34c0,0-9,3.5-24.43,2-8.86-.86-20-9.49-21.32-13.62-9.35-30.39-28.32-86-28.32-114.39C394.13,975.44,398.42,963.51,398.42,963.51Z"
                @click="chooseBodyParts('FRONT', 'Right Lower Leg')"
                :fill="isItActive('FRONT', 'Right Lower Leg')"
              />
              <path
                d="M600.91,963.51s-14.17,18-33.89,19.15c-16.68.94-31.11-7.64-31.11-7.64s-8.22,37.17-4.78,68.19,5.67,42.16,0,87.34c0,0,9,3.5,24.43,2,8.87-.86,20.06-9.49,21.33-13.62,9.35-30.39,28.32-86,28.32-114.39C605.21,975.44,600.91,963.51,600.91,963.51Z"
                @click="chooseBodyParts('FRONT', 'Left Lower Leg')"
                :fill="isItActive('FRONT', 'Left Lower Leg')"
              />
              <path
                d="M469.36,1137.39s8.43,49,10.34,54.73,11.73,30.54,10.4,34.84-6.41,7.16-13.57,6.68-20.54,1.27-20.54,1.27l-26.11-5.57s-13.66,1-13.66-9.54,11.46-37.23,11.46-37.23,3.66-22.75,2.2-34.84a135.25,135.25,0,0,0-2.68-14.8s12.25,7.64,22.42,7.85A53.48,53.48,0,0,0,469.36,1137.39Z"
                @click="chooseBodyParts('FRONT', 'Right Ankle/Foot')"
                :fill="isItActive('FRONT', 'Right Ankle/Foot')"
              />
              <path
                d="M530,1137.39s-8.44,49-10.34,54.73-11.74,30.54-10.4,34.84,6.4,7.16,13.56,6.68,20.54,1.27,20.54,1.27l26.11-5.57s13.66,1,13.66-9.54-11.45-37.23-11.45-37.23-3.66-22.75-2.21-34.84a135.25,135.25,0,0,1,2.68-14.8s-12.25,7.64-22.42,7.85A53.46,53.46,0,0,1,530,1137.39Z"
                @click="chooseBodyParts('FRONT', 'Left Ankle/Foot')"
                :fill="isItActive('FRONT', 'Left Ankle/Foot')"
              />
              <path
                d="M287.16,633.71a39.16,39.16,0,0,1,19.95,3.35A38.24,38.24,0,0,1,322.23,650l-3.34,50.58s3.26,45.28-1.44,45.82c-6.2.72-11.69-32-11.69-32s-.95,46.77-6.92,46.77c-5.25,0-9.78-48.68-9.78-48.68s-6.45,50.11-11.54,48.68c-5.78-1.63-2.3-49.64-2.3-49.64s-17.66,37.23-22.67,34.6c-4.73-2.47,13.6-55.84,13.6-55.84S248,711,241.89,705.47s14.41-37.38,14.41-37.38Z"
                @click="chooseBodyParts('FRONT', 'Right Wrist/Hand')"
                :fill="isItActive('FRONT', 'Right Wrist/Hand')"
              />
              <path
                d="M712.17,633.71a39.16,39.16,0,0,0-19.95,3.35A38.25,38.25,0,0,0,677.11,650l3.34,50.58s-3.27,45.28,1.43,45.82c6.2.72,11.69-32,11.69-32s1,46.77,6.92,46.77c5.25,0,9.79-48.68,9.79-48.68s6.44,50.11,11.53,48.68c5.78-1.63,2.31-49.64,2.31-49.64s17.66,37.23,22.67,34.6c4.72-2.47-13.61-55.84-13.61-55.84s18.14,20.76,24.27,15.2S743,668.09,743,668.09Z"
                @click="chooseBodyParts('FRONT', 'Left Wrist/Hand')"
                :fill="isItActive('FRONT', 'Left Wrist/Hand')"
              />
              <path
                d="M598.86,583l6.27-123.69c-24.57-10.72-56.78-42.48-71.15-96.81-18-68.21,16-105.31,16-105.31s-35.72,20.36-50.35,20.36-50.35-20.36-50.35-20.36,34.07,37.1,16,105.31C451,416.8,418.77,448.56,394.2,459.28L400.48,583s32.8,18.32,38,67.16c2.58,23.94-6.24,45.31-10.69,54.22s-18.14,30.07-18.14,30.07,13.13-6.68,38.42-3.82c23.05,2.61,44.74,22.27,44.74,22.27V742.06l6.81.24,6.8-.24v10.81s21.7-19.66,44.74-22.27c25.3-2.86,38.42,3.82,38.42,3.82S576,713.26,571.49,704.35s-13.26-30.28-10.69-54.22C566.05,601.29,598.86,583,598.86,583Z"
                @click="chooseBodyParts('FRONT', 'Chest/Abdomin')"
                :fill="isItActive('FRONT', 'Chest/Abdomin')"
              />
              <path
                d="M447.09,250.79s4.13-13-2.55-17.81c-5.4-3.87-19.72,0-19.72,0s-15.91,2.54-21.32-3.19l-5.6-17.5L394.2,204V185.89s-14.57-2.55-13.93-5.1,13.93-22.9,13.93-22.9,1.84-7,0-12.73-7-16.4-6.29-22.27c6.29-55.37,53.52-55.12,79.86-56.32,49-2.23,87.82,26.09,81.45,86.54-1.51,14.41-18.49,29.7-16.86,39.78,3.5,21.63,19,57.9,19,57.9s-29.49,20.37-51.66,20.37S447.09,250.79,447.09,250.79Z"
                @click="chooseBodyParts('FRONT', 'Head/Face/Neck')"
                :fill="isItActive('FRONT', 'Head/Face/Neck')"
              />
            </svg>
          </el-tab-pane>
          <el-tab-pane label="BACK">
            <svg
              id="b893dbae-14b8-486b-ac29-384470045217"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="190 0 540 1330"
              style="max-height: 600px"
              class="tw-mx-auto"
            >
              <path
                id="head"
                d="M290.06,665.66A33.31,33.31,0,0,0,273,652.78c-10.61-3.51-16.72.19-16.72.19l-19.4,64s-19,41.23-14.8,43.39c5.55,2.85,22.21-25.81,22.21-25.81s-15.59,44.11-10,46.21c4.91,1.85,26.3-42.11,26.3-42.11s-11.62,49.17-6.35,49.62c6,.52,19.64-45.64,19.64-45.64s3.42,41.06,9,40.37c5.3-.65,6.94-57,6.94-57s9.66,25.82,17.36,22.76-.33-40-.33-40Z"
                @click="chooseBodyParts('BACK', 'Left Wrist/Hand')"
                :fill="isItActive('BACK', 'Left Wrist/Hand')"
              />
              <path
                d="M638.05,665.66a33.31,33.31,0,0,1,17.11-12.88c10.6-3.51,16.72.19,16.72.19l19.4,64s19,41.23,14.8,43.39c-5.56,2.85-22.21-25.81-22.21-25.81s15.58,44.11,10,46.21c-4.91,1.85-26.31-42.11-26.31-42.11s11.63,49.17,6.36,49.62c-6,.52-19.65-45.64-19.65-45.64s-3.41,41.06-9,40.37c-5.3-.65-6.94-57-6.94-57s-9.66,25.82-17.36,22.76.32-40,.32-40Z"
                @click="chooseBodyParts('BACK', 'Right Wrist/Hand')"
                :fill="isItActive('BACK', 'Right Wrist/Hand')"
              />
              <path
                d="M516.57,247.79s-4.13-10,2.55-14.81c5.4-3.87,19.72,0,19.72,0s15.91,2.54,21.32-3.19l5.6-17.5,3.7-8.27V185.89s14.57-2.55,13.93-5.1-13.93-22.9-13.93-22.9-1.84-7,0-12.73,7-16.4,6.29-22.27c-6.29-55.37-53.52-55.12-79.86-56.32-49-2.23-87.82,26.09-81.45,86.54,1.51,14.41,18.49,29.7,16.86,39.78-3.5,21.63-19,54.9-19,54.9s29.49,20.37,51.66,20.37S516.57,247.79,516.57,247.79Z"
                @click="chooseBodyParts('BACK', 'Head/Face/Neck')"
                :fill="isItActive('BACK', 'Head/Face/Neck')"
              />
              <path
                d="M592.08,566.67c11.29,32.58,27.84,55.37,42.51,92.39,0,0,7.14-7.38,15.83-10.95,7.76-3.18,19.33-3,19.33-3-7.22-45.64-5.83-70.26-12.06-90.76a54.54,54.54,0,0,1-29.62,13C611.46,569,592.08,566.67,592.08,566.67Z"
                @click="chooseBodyParts('BACK', 'Right Lower Arm')"
                :fill="isItActive('BACK', 'Right Lower Arm')"
              />
              <path
                d="M639.71,503.19c-11.29-32.57-14.84-49-14.37-66.24,0,0,11.91-91.15-6.27-130.73-21.27-46.3-52-29.8-52-29.8,36.2,90.8,4.66,183.93,4.66,183.93s.46,19.09,11,53.42a54.48,54.48,0,0,1,29.62-13C629,499.09,639.71,503.19,639.71,503.19Z"
                @click="chooseBodyParts('BACK', 'Right Upper Arm/Shoulder')"
                :fill="isItActive('BACK', 'Right Upper Arm/Shoulder')"
              />
              <path
                d="M335.38,566.67c-11.29,32.58-27.84,55.37-42.51,92.39,0,0-7.14-7.38-15.83-10.95-7.76-3.18-19.32-3-19.32-3,7.21-45.64,5.83-70.26,12-90.76a54.59,54.59,0,0,0,29.62,13C316,569,335.38,566.67,335.38,566.67Z"
                @click="chooseBodyParts('BACK', 'Left Lower Arm')"
                :fill="isItActive('BACK', 'Left Lower Arm')"
              />
              <path
                d="M611.28,507.5c22.38-3.53,35.26,6.21,35.26,6.21l9.16,33.41s-8.53,9.22-28.87,13.35c-17.84,3.61-39.1-6.14-39.1-6.14l-5.29-34.53S592.09,510.53,611.28,507.5Z"
                @click="chooseBodyParts('BACK', 'Right Elbow')"
                :fill="isItActive('BACK', 'Right Elbow')"
              />
              <path
                d="M287.84,503.19c11.29-32.57,14.84-49,14.37-66.24,0,0-11.91-91.15,6.27-130.73,21.27-46.3,52-29.8,52-29.8-36.2,90.8-4.66,183.93-4.66,183.93s-.46,19.09-11,53.42a54.53,54.53,0,0,0-29.62-13C298.6,499.09,287.84,503.19,287.84,503.19Z"
                @click="chooseBodyParts('BACK', 'Left Upper Arm/Shoulder')"
                :fill="isItActive('BACK', 'Left Upper Arm/Shoulder')"
              />
              <path
                d="M316.26,507.5C293.89,504,281,513.71,281,513.71l-9.15,33.41s8.53,9.22,28.87,13.35c17.84,3.61,39.1-6.14,39.1-6.14l5.29-34.53S335.46,510.53,316.26,507.5Z"
                @click="chooseBodyParts('BACK', 'Left Elbow')"
                :fill="isItActive('BACK', 'Left Elbow')"
              />
              <path
                d="M589.11,740.35l-20.18,137-11.64,78.94s-4.26-3.43-26.15-2.33c-22.13,1.12-31.23,10.29-31.23,10.29L473.94,841l-4.71-81.07c7.92,4.19,35,16.35,56.86,14.2C554.13,771.4,589.11,740.35,589.11,740.35Z"
                @click="chooseBodyParts('BACK', 'Right Upper Leg')"
                :fill="isItActive('BACK', 'Right Upper Leg')"
              />
              <path
                d="M338.81,740.35l20.18,137,11.64,78.94s4.26-3.43,26.15-2.33c22.13,1.12,31.23,10.29,31.23,10.29L454,841l4.71-81.07c-7.92,4.19-35,16.35-56.86,14.2C373.79,771.4,338.81,740.35,338.81,740.35Z"
                @click="chooseBodyParts('BACK', 'Left Upper Leg')"
                :fill="isItActive('BACK', 'Left Upper Leg')"
              />
              <path
                d="M501.1,969.76s12.63-8,32-9.52c18-1.43,25.15,2.72,25.15,2.72l10.31,41c-5.26,4.52-21.94,8.21-41.19,8.21-12.57,0-26.73-10.94-26.73-10.94L502,979.4Z"
                @click="chooseBodyParts('BACK', 'Right Knee')"
                :fill="isItActive('BACK', 'Right Knee')"
              />
              <path
                d="M427,969.76s-12.63-8-32-9.52c-18-1.43-25.15,2.72-25.15,2.72l-10.31,41c5.26,4.52,21.94,8.21,41.19,8.21,12.57,0,26.73-10.94,26.73-10.94l-1.4-21.88Z"
                @click="chooseBodyParts('BACK', 'Left Knee')"
                :fill="isItActive('BACK', 'Left Knee')"
              />
              <path
                d="M569.56,1011.8s-20.32,7.31-39.56,8.39c-16.28.92-32.15-12.32-32.15-12.32s-7.22,49.14-3.87,79.4,5.53,33.14,0,77.22c0,0,7.79,4.41,22.84,3,8.65-.84,20.56-10.26,21.81-14.29,9.12-29.65,24.48-76.08,27.63-103.6C568.56,1029.38,569.56,1011.8,569.56,1011.8Z"
                @click="chooseBodyParts('BACK', 'Right Lower Leg')"
                :fill="isItActive('BACK', 'Right Lower Leg')"
              />
              <path
                d="M358.55,1011.8s20.31,7.31,39.56,8.39c16.28.92,32.15-12.32,32.15-12.32s7.22,49.14,3.86,79.4-5.52,33.14,0,77.22c0,0-7.78,4.41-22.83,3-8.65-.84-20.57-10.26-21.81-14.29-9.12-29.65-24.49-76.08-27.63-103.6C359.55,1029.38,358.55,1011.8,358.55,1011.8Z"
                @click="chooseBodyParts('BACK', 'Left Lower Leg')"
                :fill="isItActive('BACK', 'Left Lower Leg')"
              />
              <path
                d="M493.32,1169.41s-2.69,26.45-6.25,40.66c-2.55,10.18-10.36,22.54,6.38,23.75,14.57,1.06,47.63,2.73,47.63-2.79,0-10.25-10.66-39.74-9.25-51.53a131.89,131.89,0,0,1,2.62-14.45s-12,7.46-21.88,7.66A52.11,52.11,0,0,1,493.32,1169.41Z"
                @click="chooseBodyParts('BACK', 'Right Ankle/Foot')"
                :fill="isItActive('BACK', 'Right Ankle/Foot')"
              />
              <path
                d="M434.79,1169.41s2.69,26.45,6.25,40.66c2.55,10.18,10.36,22.54-6.38,23.75-14.57,1.06-47.64,2.73-47.64-2.79,0-10.25,10.67-39.74,9.26-51.53a134.74,134.74,0,0,0-2.62-14.45s11.95,7.46,21.87,7.66A52.14,52.14,0,0,0,434.79,1169.41Z"
                @click="chooseBodyParts('BACK', 'Left Ankle/Foot')"
                :fill="isItActive('BACK', 'Left Ankle/Foot')"
              />
              <polygon
                points="361.67 461.75 364.98 591.66 563.35 591.66 566.65 461.75 361.67 461.75"
                @click="chooseBodyParts('BACK', 'Lower Back')"
                :fill="isItActive('BACK', 'Lower Back')"
              />
              <path
                d="M566.65,597.41h-205s-25.43,41-22,133.79c0,0,39.15,36.36,62.71,36.51,32.91.2,61.81-17,61.81-17s28.91,17.15,61.82,17c23.56-.15,62.71-36.51,62.71-36.51C592.08,638.38,566.65,597.41,566.65,597.41Z"
                @click="chooseBodyParts('BACK', 'Hip')"
                :fill="isItActive('BACK', 'Hip')"
              />
              <path
                d="M561.28,275.88s-33.39-28.52-44-22.28C501.1,263.07,478.34,273,464.16,273s-36.94-9.91-53.08-19.38c-10.64-6.24-44,22.28-44,22.28-38.49,100-5.38,181.1-5.38,181.1h205S599.77,375.9,561.28,275.88Z"
                @click="chooseBodyParts('BACK', 'Upper Back')"
                :fill="isItActive('BACK', 'Upper Back')"
              />
            </svg>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="24">
          <el-table :data="compcase.injuries" class="injury-table" border highlight-current-row>
            <el-table-column label="Body Area" prop="bodyArea" width="auto"></el-table-column>
            <el-table-column label="Body Part" width="auto" prop="bodyPart"></el-table-column>
            <el-table-column label="Injury" width="250">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`injuries.${scope.$index}.injury`"
                  :rules="{
                    required: true,
                    message: 'Please select an option',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    placeholder="Enter the nature of the injury"
                    v-model="scope.row.injury"
                  >
                    <el-option value="" :disabled="true">
                      What was the nature of the injury?
                    </el-option>
                    <el-option
                      v-for="op in natureOfInjuryOptions"
                      :key="op.value"
                      :label="op.value"
                      :value="op.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-w-full tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8" v-if="headInjuryExists">
          <el-form-item
            prop="elUiFix"
            label="Concussion Symptoms?"
            :rules="{ required: true, message: 'Please select an option', trigger: 'blur' }"
          >
            <el-select
              v-model="compcase.elUiFix"
              placeholder="Is the player displaying symptoms of a concussion?"
              @change="isConcussed"
            >
              <el-option value="undefined" :disabled="true"
                >Is the player displaying symptoms of a concussion?</el-option
              >
              <el-option key="Yes" label="Yes" value="yes"></el-option>
              <el-option key="No" label="No" value="no"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            prop="howPlayerInjured"
            label="How was the player injured?"
            :rules="{
              required: true,
              message: 'Please select an option',
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="compcase.howPlayerInjured"
              placeholder="How was the player injured?"
              @change="otherCheck(compcase.howPlayerInjured, 'howPlayerInjuredOther')"
            >
              <el-option value="undefined" :disabled="true">How was the player injured?</el-option>
              <el-option
                v-for="item in howPlayerInjuredList"
                :key="item.option"
                :value="item.value"
              >
                {{ item.option }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="compcase.howPlayerInjured === 'Other'">
          <el-form-item
            prop="howPlayerInjuredOther"
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="compcase.howPlayerInjuredOther"
              placeholder="How was the player injured?"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-collapse v-if="compcase.symptons && headInjuryExists" class="tw-w-full">
          <el-collapse-item title="Concussion Summary" name="1">
            <div
              v-if="compcase && compcase.symptons"
              class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
            >
              <h4 class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400">
                You have identified that signs/symptoms of concussion are present, you will now be
                guided through a series of questions to help you record the signs and symptoms and
                manage the injury.
              </h4>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.clinicalFeatures"
                  label="Are there clinical features of a potentially serious or structural head and/or neck injury, including prolonged loss of consciousness?"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.clinicalFeatures" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.lossOfConsciousness"
                  label="Loss of consciousness (or prolonged loss of movement >1-2 seconds) or not responding appropriately to trainers, referees or other players"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.lossOfConsciousness" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.protectiveAction"
                  label="No protective action in fall to the ground (not bracing for impact/floppy or stiff)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.protectiveAction" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.impact"
                  label="Impact seizure/convulsion/fit (stiffening or shaking of arms and/or legs on impact)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.impact" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.balanceDisturbance"
                  label="Balance disturbance (unable to stand steadily or walk unassisted) or clumsy or slow to get up following a possible head injury (10-15 seconds)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.balanceDisturbance" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.dazedOrBlank"
                  label="Dazed or blank/vacant stare or not their normal selves/not reacting appropriately to surroundings."
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.dazedOrBlank" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.unusualBehaviour"
                  label="Unusual behaviour for the player"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.unusualBehaviour" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.memoryImpairment"
                  label="Memory impairment (e.g. fails Maddocks questions symptoms - refer to CRT5)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.memoryImpairment" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="concussion.playerReports"
                  label="Player reports or displays any other concussion symptoms (refer to CRT5)"
                  :rules="{
                    required: true,
                    message: 'Concussion Summary Field is required',
                    trigger: 'blur',
                  }"
                >
                  <el-select v-model="compcase.concussion.playerReports" placeholder="">
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Symptom Record" name="2">
            <symptom-record :injury="compcase" @setSymptoms="getSymptomRecords" />
            <el-col :span="12">
              <el-form-item
                prop="symptomRecord.playerSelfState"
                label="If you know the player (or ask parents/friends), how different is the player acting
              compared to their usual self?"
                :rules="{
                  required: true,
                  message: 'Symptom Record Field is required',
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="playerSelfState"
                  placeholder="If you know the player (or ask parents/friends), how different is the player acting
              compared to their usual self?"
                >
                  <el-option value="undefined" :disabled="true"
                    >If you know the player (or ask parents/friends), how different is the player
                    acting compared to their usual self?</el-option
                  >
                  <el-option
                    v-for="item in playerSelfStateList"
                    :key="item.option"
                    :value="item.value"
                  >
                    {{ item.option }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-collapse-item>
        </el-collapse>
      </div>
      <h4
        v-if="compcase.type !== 2"
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
      >
        Notes
      </h4>
      <el-row v-if="compcase.type !== 2">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in compcase.notes"
            :key="index"
            color="#098255"
            :timestamp="item.createdAt.toString()"
            :hide-timestamp="true"
          >
            {{ item.note }}
            <br />
            <!-- element ui built in classes -->
            <span class="el-timeline-item__timestamp is-bottom"
              >{{ formatTimestamp(item.createdAt) }} - {{ item.createdBy.firstName }}
              {{ item.createdBy.lastName || "" }}</span
            >
          </el-timeline-item>
        </el-timeline>
      </el-row>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item prop="playerRemoveBy" label="How was the player removed from the field?">
            <el-select
              v-model="compcase.playerRemoveBy"
              placeholder="How was the player removed from the field?"
            >
              <el-option value="undefined" :disabled="true"
                >How was the player removed from the field?</el-option
              >
              <el-option
                v-for="method in methodRemovedFromField"
                :label="method"
                :key="method"
                :value="method"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="treatPlayer"
            label="Was an ambulance or doctor called to treat the player?"
          >
            <el-select
              v-model="compcase.treatPlayer"
              placeholder="Was an ambulance or doctor called to treat the player?"
            >
              <el-option value="undefined" :disabled="true"
                >Was an ambulance or doctor called to treat the player?</el-option
              >
              <el-option label="Yes" :value="true"></el-option>
              <el-option label="No" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12" v-if="compcase.treatPlayer">
          <el-form-item
            prop="playerReferredTo"
            label="Who was the player referred to?"
            :rules="{
              required: true,
              message: 'Please select an option',
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="compcase.playerReferredTo"
              placeholder="Who was the player referred to?"
              filterable
              @change="otherCheck(compcase.playerReferredTo, 'playerReferredToOther')"
            >
              <el-option value="undefined" :disabled="true"
                >Who was the player referred to?</el-option
              >
              <el-option
                v-for="referral in referredTo"
                :label="referral"
                :key="referral"
                :value="referral"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="compcase.treatPlayer && compcase.playerReferredTo === 'Other'">
          <el-form-item
            prop="playerReferredToOther"
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="compcase.playerReferredToOther"
              placeholder="Who was the player referred to?"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="compcase.type === 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="12">
          <el-form-item
            prop="immediateCare"
            label="What was the immediate care applied to the injury?"
            :rules="{
              required: true,
              message: 'Please select an option',
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="compcase.immediateCare"
              placeholder="What was the immediate care applied to the injury?"
              filterable=""
              @change="otherCheck(compcase.immediateCare, 'immediateCareOther')"
            >
              <el-option value="undefined" :disabled="true"
                >What was the immediate care applied to the injury?</el-option
              >
              <el-option
                v-for="care in immediateCare"
                :label="care"
                :key="care"
                :value="care"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="compcase.immediateCare === 'Other'">
          <el-form-item
            prop="immediateCareOther"
            :rules="{
              required: true,
              message: 'Field is required',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="compcase.immediateCareOther"
              placeholder="What was the immediate care applied to the injury?"
            />
          </el-form-item>
        </el-col>
      </div>
      <el-row>
        <el-col :span="24" v-if="compcase.type !== 2">
          <el-form-item prop="note" label="Notes">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.note"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="compcase.type == 2">
          <el-form-item
            prop="otherSignificants"
            label="Name of First Responder, and any significant observations"
          >
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.otherSignificants"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        v-if="compcase.type == 2 && compcase.symptons"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="availability" label="Availability">
            <el-select
              v-model="compcase.availability"
              placeholder="Is the player displaying symptoms of a concussion?"
            >
              <el-option value="undefined" :disabled="true"
                >Is the player displaying symptoms of a concussion?</el-option
              >
              <el-option
                v-for="availability in injuryAvailability"
                :label="availability.label"
                :key="availability.label"
                :value="availability.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <h4
        v-if="compcase.type == 2"
        class="tw-pt-6 tw-mt-6 tw-font-bold tw-border-t tw-border-gray-400"
      >
        Admin Notes
      </h4>
      <div
        v-if="compcase.type == 2"
        class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-mt-5 tw-border-r tw-border-gray-100"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in compcase.adminNotesList"
            :key="index"
            color="#098255"
            :timestamp="item.createdAt.toString()"
            :hide-timestamp="true"
          >
            {{ item.note }}
            <br />
            <!-- element ui built in classes -->
            <span class="el-timeline-item__timestamp is-bottom"
              >{{ formatTimestamp(item.createdAt) }} - {{ item.createdBy.firstName }}
              {{ item.createdBy.lastName || "" }}</span
            >
          </el-timeline-item>
        </el-timeline>
      </div>
      <el-row v-if="compcase.type == 2">
        <el-col :span="24">
          <el-form-item prop="adminNote">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="compcase.adminNote"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <action-buttons
            cancel
            submit
            @submit="
              if (compcase.symptons && compcase.availability) {
                areYouSure();
              } else {
                updateCompcase();
              }
            "
            @cancel="cancel"
          />
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="Warning" :visible.sync="removeDialog" width="40%">
      <div class="point-adjustment">
        <h4>Do you want to remove this case?</h4>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removeDialog = false">No</el-button>
        <el-button
          type="primary"
          @click="
            compcase.isActive = false;
            updateCompcase();
          "
          >Yes</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import ActionButtons from "../../components/ActionButtons.vue";
import { playerSelfStateList } from "../../utils/constants";
import SymptomRecord from "./SymptomRecord.vue";

import {
  errormsg,
  caseCharges,
  compCasesTypes,
  chargeOutcomes,
  tribunalStatus,
  penaltyTypes,
  compCaseScenes,
  onFieldActions,
  roles,
  natureOfInjuryOptions,
  methodRemovedFromField,
  referredTo,
  immediateCare,
  injuryAvailability,
  howPlayerInjuredList,
} from "../../utils/constants";

export default {
  components: {
    ActionButtons,
    SymptomRecord,
  },
  data() {
    const validateGameTime = (rule, value, callback) => {
      const reg = new RegExp("^[0-9]+$");
      const num = parseInt(value);
      const max = rule.field === "meta.timeOfIncident.mins" ? 199 : 59;
      const min = 0;
      if (!reg.test(value) || num > max || num < min)
        callback(new Error("Please enter a valid time"));
      callback();
    };
    return {
      onFieldActions,
      caseCharges,
      chargeOutcomes,
      penaltyTypes,
      tribunalStatus,
      compCasesTypes,
      sceneType: compCaseScenes,
      chargeGradings: [
        { value: 1, name: 1 },
        { value: 2, name: 2 },
        { value: 3, name: 3 },
        { value: 4, name: 4 },
        { value: 5, name: 5 },
        { value: 6, name: "Referred" },
      ],
      tabPosition: "0",
      natureOfInjuryOptions,
      howPlayerInjuredList,
      methodRemovedFromField,
      referredTo,
      immediateCare,
      injuryAvailability,
      playerSelfStateList,
      concussionAvailabilityCheck: undefined,
      playerSelfState: undefined,
      compcase: {
        _id: undefined,
        type: 1, // const casesType = [ 1 = 'Tribunal', 2 = 'Injury' ]
        scene: 1, // const sceneType = [ 1 = 'Match', 2 = 'Other' ]
        memberId: "",
        charges: [], // [ PositiveInt ] ==> const charges = [ { id, name, description, recommendedGrading, grading, outcome } ]
        note: "",
        status: "Pending",
        concussion: {
          balanceDisturbance: undefined,
          clinicalFeatures: undefined,
          dazedOrBlank: undefined,
          impact: undefined,
          lossOfConsciousness: undefined,
          memoryImpairment: undefined,
          playerReports: undefined,
          protectiveAction: undefined,
          unusualBehaviour: undefined,
        },
        hearing: {
          required: undefined,
          dateTime: undefined,
        },
        meta: {
          penalty: {
            type: undefined,
            units: undefined,
            startDate: undefined,
            expiryDate: undefined,
          },
          suspendedPenalty: {
            type: undefined,
            units: undefined,
            expiryDate: undefined,
          },
          matchId: undefined,
          teamId: undefined,
          carryOverPts: undefined,
          competition: {
            _id: undefined,
            name: undefined,
          },
          appeal: {
            lodged: undefined,
            dateTime: undefined,
            outcome: undefined,
          },
          dateTimeIncident: undefined,
          onFieldAction: undefined,
          timeOfIncident: undefined,
        },
        isActive: undefined,
        symptons: undefined,
        elUiFix: undefined,
        injuries: [],
        natureOfInjury: undefined,
        symptomRecord: {
          playerSelfState: undefined,
          headache: { step: 0, stepName: "None" },
          pressureInHead: { step: 0, stepName: "None" },
          nauseaOrVomit: { step: 0, stepName: "None" },
          dizzy: { step: 0, stepName: "None" },
          blurVision: { step: 0, stepName: "None" },
          balanceProblem: { step: 0, stepName: "None" },
          lightSensitivity: { step: 0, stepName: "None" },
          noiseSensitivity: { step: 0, stepName: "None" },
          slowedDown: { step: 0, stepName: "None" },
          inAFog: { step: 0, stepName: "None" },
          notFeelRight: { step: 0, stepName: "None" },
          difficultyInConcentrating: { step: 0, stepName: "None" },
          difficultyRemembering: { step: 0, stepName: "None" },
          fatigued: { step: 0, stepName: "None" },
          confused: { step: 0, stepName: "None" },
          drowsy: { step: 0, stepName: "None" },
          emotional: { step: 0, stepName: "None" },
          irritable: { step: 0, stepName: "None" },
          sad: { step: 0, stepName: "None" },
          nervousOrAnxious: { step: 0, stepName: "None" },
        },
        howPlayerInjured: undefined,
        howPlayerInjuredOther: undefined,
        treatPlayer: undefined,
        playerReferredTo: undefined,
        playerReferredToOther: undefined,
        immediateCare: undefined,
        immediateCareOther: undefined,
        playerRemoveBy: undefined,
        otherSignificants: undefined,
        availability: undefined,
        adminNote: undefined,
        dateOfInjury: undefined,
        dateOfInjuryStr: "",
        timeOfInjury: undefined,
        timeOfInjuryStr: "",
        venue: {
          _id: undefined,
          name: undefined,
          formatted: undefined,
        },
      },
      showModal: false,
      rules: {
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        status: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        "hearing.required": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.timeOfIncident.mins": {
          required: false,
          validator: validateGameTime,
          trigger: "blur",
        },
        "meta.timeOfIncident.secs": {
          required: false,
          validator: validateGameTime,
          trigger: "blur",
        },
      },
      removeDialog: false,
      roles,
      tempDate: "",
      tempTime: "",
    };
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    headInjuryExists() {
      let exists = _.find(this.compcase.injuries, { bodyPart: "Head/Face/Neck" });
      return exists ? true : false;
    },
  },
  mounted() {
    this.compcase._id = parseInt(this.$route.params.id);
    this.$store.commit("root/loading", true);
    this.$http
      .get(`/nrl/api/v1/admin/competition-cases/${this.compcase._id}`)
      .then((compcaseRes) => {
        this.compcase = _.merge(this.compcase, compcaseRes.data.data);
        if (this.compcase.type === 2) {
          const { symptons } = this.compcase;
          this.compcase.elUiFix = symptons ? "yes" : "no";
          this.concussionAvailabilityCheck = this.compcase.availability || !this.compcase.symptons;
          this.playerSelfState =
            this.compcase &&
            this.compcase.symptomRecord &&
            this.compcase.symptomRecord.playerSelfState
              ? this.compcase.symptomRecord.playerSelfState
              : undefined;
        }
        // init outcome & grading
        if (this.compcase.charges.length > 0)
          this.compcase.charges = this.compcase.charges.map((x) => ({
            ...x,
            outcome: x.outcome ? x.outcome : undefined,
            grading: x.grading ? x.grading : undefined,
          }));
        if (this.compcase.natureOfInjury)
          this.compcase.injuries = [...this.compcase.natureOfInjury];
        this.$store.commit("root/loading", false);
      })
      .catch((e) => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    gameTimeChecks(e, t) {
      const num = parseInt(e);
      const max = t === "mins" ? 200 : 60;
      const min = 0;
      if (num < max && num >= min) return true;
      this.compcase.meta.timeOfIncident[t] = undefined;
    },
    changeTimeForBackend(e) {
      let time;
      if (e) {
        this.tempTime = e;
        time = e;
      }
      const tempDate =
        this.compcase.dateOfInjuryStr ||
        this.moment(this.compcase.dateOfInjury).format("MM-DD-YYYY") ||
        this.moment().now().format("MM-DD-YYYY");
      const dt = new Date(`${tempDate} ${time}`);
      this.compcase.timeOfInjury = Date.parse(dt);
    },
    changeDateForBackend(e) {
      if (e) {
        this.tempDate = e;
        const date = this.moment(e).valueOf();
        this.compcase.dateOfInjury = date;
        // update time timestamp to reflect date
        if (this.compcase.timeOfInjury) {
          const tempDate = e;
          const time =
            this.compcase.timeOfInjuryStr ||
            this.moment(this.compcase.timeOfInjury).format("HH:mm");
          const dt = new Date(`${tempDate} ${time}`);
          this.compcase.timeOfInjury = Date.parse(dt);
        }
      }
    },
    isConcussed() {
      if (this.compcase.symptons) this.compcase.availability = false;
    },
    areYouSure() {
      this.showModal = true;
    },
    otherCheck(field, type) {
      if (field !== "Other") this.compcase[type] = "";
    },
    getSymptomRecords(step, stepName, symptom) {
      const { symptomRecord } = this.compcase;
      symptomRecord[symptom].step = step;
      symptomRecord[symptom].stepName = stepName;
    },
    chooseBodyParts(bodyType, bodyPart) {
      let exists = _.find(this.compcase.injuries, {
        bodyArea: bodyType,
        bodyPart: bodyPart,
      });
      if (!exists) {
        this.compcase.injuries.push({
          bodyArea: bodyType,
          bodyPart: bodyPart,
          injury: undefined,
        });
      } else {
        this.compcase.injuries = this.compcase.injuries.filter((obj) => {
          return obj.bodyArea == bodyType && obj.bodyPart == bodyPart ? false : true;
        });
      }
    },
    isItActive(bodyType, bodyPart) {
      return _.find(this.compcase.injuries, {
        bodyArea: bodyType,
        bodyPart: bodyPart,
      })
        ? "#098255"
        : "#231f20";
    },
    addCharge() {
      this.compcase.charges.push({
        id: undefined,
        name: undefined,
        description: undefined,
        recommendedGrading: undefined,
        grading: undefined,
        outcome: undefined,
        new: true,
      });
    },
    removeCharge(index) {
      this.compcase.charges.splice(index, 1);
    },
    cancel() {
      this.$router.push({
        name: "competition-cases.list",
      });
    },
    async updateCompcase() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.compcase.validate((valid, obj) => {
            if (valid) {
              resolve("valid");
            } else {
              const reqFields = Object.keys(obj);
              const natureOfInjuryFieldCheck = reqFields.filter((f) =>
                f.toLowerCase().includes("injuries")
              );
              const concussionSummaryFieldCheck = reqFields.filter((f) =>
                f.toLowerCase().includes("concussion")
              );
              const symptomRecordFieldCheck = reqFields.filter((f) =>
                f.toLowerCase().includes("symptomrecord")
              );
              if (natureOfInjuryFieldCheck.length && this.compcase.type === 2) {
                this.$customError(
                  "All nature of injury questions are required for this type of competition cases."
                );
                return;
              }
              if (concussionSummaryFieldCheck.length && this.compcase.type === 2) {
                this.$customError(
                  "All concussion summary questions are required for this type of competition cases."
                );
                return;
              }
              if (symptomRecordFieldCheck.length && this.compcase.type === 2) {
                this.$customError(
                  "All symptom record questions are required for this type of competition cases."
                );
                return;
              }

              this.$customError("Check all required fields *");
              reject();
            }
          });
        });

        // add complete status for injuries
        if (this.compcase.type === 2 && this.compcase.status !== "Completed")
          this.compcase.status = "Completed";

        // clean additional charges
        _.forEach(this.compcase.charges, (charge) => {
          const chargeCase = _.find(caseCharges, { type: charge.id });
          // add name to charges
          charge.name = _.get(chargeCase, "name");
          // remove new params in charges
          if (charge.new) delete charge.new;
        });

        this.compcase.natureOfInjury = this.compcase.injuries.length
          ? this.compcase.injuries
          : undefined;
        const availabilityOverride =
          this.compcase.symptons && this.compcase.availability && !this.concussionAvailabilityCheck;

        this.$store.commit("root/loading", true);
        if (this.compcase.type === 2 && availabilityOverride)
          this.compcase.adminNote = this.compcase.adminNote
            ? this.compcase.adminNote + " (Player made available)"
            : "(Player made available)";
        const url =
          this.compcase.type !== 2
            ? `/nrl/api/v1/admin/competition-cases/${this.compcase._id}`
            : `/nrl/api/v1/admin/injury-cases/${this.compcase._id}`;
        const res = await this.$http.put(url, this.compcase);

        if (res.data && res.data.data) {
          this.compcase = { ...this.compcase, ...res.data.data };
          // reset note
          this.compcase.note = undefined;
          this.compcase.adminNote = undefined;
          if (this.removeDialog === true) {
            this.cancel();
            this.removeDialog = false;
          }
          this.showModal = false;
          if (availabilityOverride) this.$router.go(-1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        }
      } catch (e) {
        this.$store.commit("root/loading", false);
        this.removeDialog = false;
        let errMsg = "Check all required fields *";
        this.showModal = false;
        if (e) {
          e.forEach((msg, i) => {
            setTimeout(() => {
              this.$customError(msg);
            }, i * 100);
          });
        } else {
          this.$customError(errMsg);
        }
      }
    },
    formatTimestamp(time) {
      return this.moment(time).format("MMMM Do YYYY, h:mm:ss a");
    },
    formatMemberName() {
      if (this.compcase.member) {
        return `${_.get(this.compcase.member, "profile.firstName")} ${_.get(
          this.compcase.member,
          "profile.lastName"
        )}`;
      }
      return "N/A";
    },
    formatMatchName() {
      if (this.compcase.match) {
        const home =
          (this.compcase.match.homeTeam && this.compcase.match.homeTeam.name) ||
          (this.compcase.match.meta.isBye ? "BYE" : "NA");
        const away =
          (this.compcase.match.awayTeam && this.compcase.match.awayTeam.name) ||
          (this.compcase.match.meta.isBye ? "BYE" : "NA");
        return `${home} vs ${away}`;
      }
      return "N/A";
    },
    formatRoundName() {
      if (this.compcase.match) {
        return _.get(this.compcase.match, "round.displayName");
      }
      return "N/A";
    },
  },
  watch: {
    playerSelfState: function (state) {
      if (this.compcase?.symptomRecord) this.compcase.symptomRecord.playerSelfState = state;
    },
    "compcase.elUiFix": function (bool) {
      this.compcase.symptons = bool === "yes" ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-control {
  text-align: right;
  margin-top: 50px;
}
.injury-table .el-form-item {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
.remove-btn {
  float: right;
  margin-left: 1rem;
}
</style>
