<template>
  <div>
    <el-form
      :rules="ladderTemplateRules"
      label-position="top"
      :model="ladderTemplate"
      id="ladderTemplate"
      ref="ladderTemplate"
    >
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-2 tw-border-r tw-border-gray-100">
        <el-col :span="6">
          <el-form-item
            prop="ladderTemplate"
            label="Ladder Template"
            v-if="allLadderTemplates.length > 0"
          >
            <el-select
              value-key="_id"
              filterable
              v-model="ladderTemplate"
              placeholder="Select a ladder template"
            >
              <el-option
                v-for="item in allLadderTemplates"
                :key="item._id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-2 ">
        <el-col :span="12">
          <h3 class="tw-pt-6 tw-text-lg tw-font-bold">Ladder Settings</h3>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="18">
          Points
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-1 ">
        <el-col :span="6">
          <el-form-item prop="pointsWin" label="Win">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsWin"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsLoss" label="Loss">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsLoss"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsDraw" label="Draw">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsDraw"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-2 ">
        <el-col :span="6">
          <el-form-item prop="pointsBye" label="Bye">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsBye"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsFF" label="Forfeit(Receiving)">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsFF"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pointsWO" label="Wash Out">
            <el-input
              autocomplete="off"
              placeholder="Enter points"
              v-model.number="ladderTemplate.pointsWO"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="18">
          Forfeits
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-1 ">
        <el-col :span="6">
          <el-form-item prop="ffCountAsWin" label="Received Count as Wins">
            <el-select v-model="ladderTemplate.ffCountAsWin" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ffCountAsLoss" label="Given Count as Loss">
            <el-select v-model="ladderTemplate.ffCountAsLoss" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="defaultScoreFFReceived" label="Default Score">
            <el-input
              autocomplete="off"
              placeholder="Enter default score"
              v-model.number="ladderTemplate.defaultScoreFFReceived"
              type="number"
            ></el-input>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="18">
          Count as Played
        </el-col>
      </div>
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-1 ">
        <el-col :span="6">
          <el-form-item prop="woCountAsPlayed" label="Wash Out">
            <el-select v-model="ladderTemplate.woCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="byeCountAsPlayed" label="Bye">
            <el-select v-model="ladderTemplate.byeCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ffCountAsPlayed" label="Forfeits">
            <el-select v-model="ladderTemplate.ffCountAsPlayed" filterable>
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-items-end tw-gap-3 tw-pt-3 "
        v-if="ladderTemplate.sortBy && ladderTemplate.sortBy.length > 0"
      >
        <el-col :span="6">
          Sort By Options, Drag to Change Order
        </el-col>
      </div>

      <el-row :gutter="10">
        <el-col :span="6">
          <draggable :list="ladderTemplate.sortBy">
            <el-card v-for="(item, index) in ladderTemplate.sortBy" :key="index" shadow="hover">
              {{ index + 1 }} {{ item }}
            </el-card>
          </draggable>
        </el-col>
      </el-row>
    </el-form>

    <action-buttons
      submit
      cancel
      submitText="Save"
      @submit="updateLadderTemplate()"
      @cancel="cancelForm()"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";
import { EventBus } from "../../bus";

const _ = require("lodash");

export default {
  data() {
    const IntValidate = (obj, value, callback) => {
      if (!_.isInteger(value)) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      allLadderTemplates: [],
      ladderTemplateRules: {
        name: { required: true, message: errormsg.input_option, trigger: "blur" },
        pointsWin: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        pointsLoss: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        pointsDraw: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        pointsBye: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        pointsFF: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        pointsWO: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        ffCountAsWin: { required: true },
        ffCountAsLoss: { required: true },
        ffCountAsPlayed: { required: true },
        defaultScoreFFReceived: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: IntValidate
        },
        woCountAsPlayed: { required: true },
        byeCountAsPlayed: { required: true }
      }
    };
  },
  components: { ActionButtons, draggable },
  props: {
    competition: { type: Object, required: true }
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/ladder-templates")
      .then(templateRes => {
        this.allLadderTemplates = templateRes.data.data;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: "competitions.list"
      });
    },
    updateLadderTemplate() {
      this.$refs.ladderTemplate.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const update = { laddertemplate: this.ladderTemplate };
          const url = `/nrl/api/v1/admin/competitions/${this.competition._id}`;
          this.$http
            .put(url, update)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    }
  },
  computed: {
    ladderTemplate: {
      get() {
        return this.competition.laddertemplate;
      },
      set(val) {
        this.competition.laddertemplate = val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

#items {
  list-style-type: none;
  padding-left: 0;
}
</style>
