import axios from 'axios';
import store from '../store';
import moment from 'moment';

// GraphQL beta API to get access_token which gives access to the GraphQL API
export const httpnoauth_graphql_beta = axios.create({
  baseURL: store.getters['root/graphqlAuthApiUrl'],
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

// Requires auth, GraphQL API - MongoD AppServices
const httpgraphqlapi = axios.create({
  baseURL: store.getters['root/graphqlApiUrl'],
});

// Add authorization header to all requests
httpgraphqlapi.interceptors.request.use((config) => {

  const originalRequest = config;
  const now = moment().utc().valueOf()/1000;
  const expiry = store.getters["user/graphqlToken"] ? store.getters["user/graphqlToken"].expires_at : undefined;
  let refreshToken = store.getters["user/graphqlToken"] ? store.getters["user/graphqlToken"].refresh_token : undefined;
  const accessToken = store.getters["user/graphqlToken"] ? store.getters["user/graphqlToken"].access_token : undefined;
  // Issue a new token if its expired or about to expire or token is undefined
  if ((expiry - 300 <= now) || !refreshToken || !accessToken || !expiry) {
    store.commit("root/loading", true);
    return httpnoauth_graphql_beta
      .get('/api/v1/issue-token', { 'headers': { 'apitoken': store.getters["user/idTokenNoRoles"] } })
      .then((response) => {
        // add response to store as graphql_token
        store.commit("user/updateGraphqlToken", response.data.graphql);
        store.commit("user/updateRealmToken", response.data.realm);
        originalRequest.headers.Authorization = `Bearer ${response.data.graphql.access_token}`;
        return originalRequest;
      })
      .catch(() => {
        customError();
        store.commit('root/loading', false);
      });
  }

  originalRequest.headers.Authorization = `Bearer ${accessToken}`;
  return originalRequest;
}, err => Promise.reject(err));

export default httpgraphqlapi;
