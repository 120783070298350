<template>
  <div class="tw-flex tw-flex-col tw-items-center">
    <img class="tw-w-48" src="~@/assets/MySideline_PrimaryLogo_black.svg" />
    <h3>Administrator Dashboard</h3>
    <!-- <div class="admin-badge--flex"><span>1</span></div>
    <div class="tw-w-4 tw-h-4 admin-colour-tint"><span>1</span></div>
    <div class="tw-w-4 tw-h-4 admin-colour-tintRM"><span>1</span></div>
    <div class="tw-w-4 tw-h-4 admin-colour-secondary"><span>1</span></div>
    <div class="tw-w-4 tw-h-4 admin-colour-secondary15"><span>1</span></div>
    <div class="tw-w-4 tw-h-4 admin-colour-highlight"><span>1</span></div>
    <div>
      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
      <label for="vehicle1"> I have a bike</label>
    </div> -->
    <!-- <el-card class="card">
      <p class="datetime"><span class="box">UPDATE</span> {{time}}</p>
      <p class="content">
        <ul>
          <li>Gender filter on member lists</li>
          <li>Photo and identity document status displayed on pending list</li>
          <li>Reports - Registration Detail</li>
          <li>Registration history viewable on clearance requests</li>
          <li>Clearance reason displayed on approval form</li>
          <li>Add registration and payment notes to a participant</li>
          <li>Association Admin Permissions - improved access to edit details</li>
          <li>Status filter on entity list</li>
          <li>Member search enhancements</li>
          <li><b>Program Registrations are now live!</b></li>
        </ul>
      </p>
    </el-card> -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Dashboard",
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    time() {
      return moment("19-12-2019, 5:00:00 pm", "DD-MM-YYYY, h:mm a").format("MMMM Do YYYY, h:mm a");
    },
  },
  mounted() {
    // this.$store.commit("app/openDrawer", true);
  },
};
</script>

<style scoped lang="scss">
// .container {
//   min-height: 100%;
//   width: 100%;
//   overflow: hidden;
//   text-align: center;

//   img {
//     width: 20%;
//   }

//   .card {
//     margin: 20px auto;
//     width: 50%;
//     max-width: 100%;
//     overflow: hidden;
//     text-align: center;

//     .datetime {
//       text-align: left;
//       margin-bottom: 2rem;
//     }

//     .content {
//       text-align: left;
//     }

//     .box {
//       background-color: $tint;
//       color: white;
//       border-radius: 10%;
//       padding: 4px;
//       font-family: $fontBold;
//     }
//   }

//   @media only screen and (max-width: $sm) {
//     .card {
//       width: 80%;
//     }
//   }
// }
</style>
