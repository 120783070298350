import Login from "../views/auth/Login.vue";
import Oauth from "../views/auth/Oauth.vue";
import Dashboard from "../views/app/Dashboard.vue";
import Expirations from "../views/competitions/Expirations.vue";
import MyEntity from "../views/myentity/MyEntity.vue";
import NotFound from "../views/app/NotFound.vue";
import ClubsList from "../views/clubs/ClubsList.vue";
import ClubForm from "../views/clubs/ClubForm.vue";
import TeamsList from "../views/teams/TeamsList.vue";
import TeamForm from "../views/teams/TeamForm.vue";
import RegionsList from "../views/regions/RegionsList.vue";
import RegionForm from "../views/regions/RegionForm.vue";
import StatesList from "../views/states/StatesList.vue";
import StateForm from "../views/states/StateForm.vue";
import AssociationsList from "../views/associations/AssociationsList.vue";
import AssociationForm from "../views/associations/AssociationForm.vue";
import ProgramsettingsList from "../views/program-settings/ProgramsettingsList.vue";
import ProgramsettingsForm from "../views/program-settings/ProgramsettingsForm.vue";
import ProgramsList from "../views/programs/ProgramsList.vue";
import ProgramsForm from "../views/programs/ProgramsForm.vue";
import RegistrationProduct from "../views/registration-products/RegistrationProduct.vue";
import CompetitionType from "../views/competitions-settings/CompTypeList.vue";
import CompetitionTypeForm from "../views/competitions-settings/CompTypeForm.vue";
import CompList from "../views/competitions/CompList.vue";
import CompForm from "../views/competitions/CompForm.vue";
import MatchList from "../views/matches/MatchList.vue";
import MatchForm from "../views/matches/MatchForm.vue";
import MatchScoreList from "../views/match-scores/MatchScoreList.vue";
import RedoDraw from "../views/matches/RedoDraw.vue";
import RedoDrawBuilder from "../views/matches/RedoDrawBuilder.vue";
import DrawList from "../views/draws/DrawList.vue";
import DrawBuilder from "../views/draws/DrawBuilder.vue";
import DrawBuilderImport from "../views/draws/DrawBuilderImport.vue";
import DrawEdit from "../views/draws/DrawEdit.vue";
import SquadManagement from "../views/squad-management/SquadManagement.vue";
import RestrictSquadManagement from "../views/squad-management/RestrictSquadManagement.vue";
import SquadManagementDetail from "../views/squad-management/SquadManagementDetail.vue";
import TFASquadManagementDetail from "../views/squad-management/TFASquadManagementDetail.vue";
import UserManagement from "../views/user-management/UserManagement.vue";
import UserForm from "../views/user-management/UserForm.vue";
import VenuesList from "../views/venues/venuesList.vue";
import VenuesForm from "../views/venues/venuesForm.vue";
import AccountDetails from "../views/account-details/AccountDetails.vue";
import myentity from "./myentity";
import PendingVerification from "../views/pending-verification/PendingVerification.vue";
import PendingVerificationForm from "../views/pending-verification/PendingVerificationForm.vue";
import EighteenMRP from "../views/eighteenMRP/EighteenMRP.vue";
import LadderTemplate from "../views/ladder-templates/LadderTemplateForm.vue";
import LadderTemplateList from "../views/ladder-templates/LadderTemplateList.vue";
import MemberList from "../views/member-management/MemberList.vue";
import MemberListGraphql from "../views/member-management/MemberListGraphql.vue";
import MemberDetails from "../views/member-management/MemberDetails.vue";
import Dispensations from "../views/member-management/Dispensations.vue";
import GameDevRegionList from "../views/game-dev-regions/GameDevRegionList.vue";
import GameDevRegionForm from "../views/game-dev-regions/GameDevRegionForm.vue";
import ClearancePermitList from "../views/clearance-permit/ClearancePermitList.vue";
import ClearancePermitRequest from "../views/clearance-permit/ClearancePermitRequest.vue";
import ClearancePermitForm from "../views/clearance-permit/ClearancePermitForm.vue";
import UnderConstruction from "../views/app/UnderConstruction.vue";
import Verify from "../views/verify/Verify.vue";
import PendingRole from "../views/app/PendingRole.vue";
import LadderList from "../views/ladders/LaddersList.vue";
import LadderUpdate from "../views/ladders/LadderUpdate.vue";
import Maintenance from "../views/Maintenance.vue";
import ApiKeysList from "../views/third-party/ApiKeysList.vue";

/* --- Healthcheck -- */
import Healthcheck from "../views/healthcheck/Healthcheck.vue";

/* --- Duplicate Members -- */
import DuplicateMembers from "../views/duplicate-members/DuplicateMembers.vue";
import DuplicateMembersEdit from "../views/duplicate-members/DuplicateMembersEdit.vue";

/* --- Report Imports --- */
// TODO lazy load routes to help webpack code splitting
// import Reports from "../views/reports/Reports.vue";
const Reports = () => import("../views/reports/Reports.vue");
import ExpressionInterest from "../views/reports/registration/ExpressionInterest.vue";
import DownloadReport from "../views/reports/DownloadReport.vue";
import RegoDetailReport from "../views/reports/registration/RegoDetailReport.vue";
import RegoPaymentDetailReport from "../views/reports/registration/RegoPaymentDetailReport.vue";
// import TFARegoPaymentDetailReport from '../views/reports/registration/TFARegoPaymentDetailReport.vue';
import ProgramRegoReport from "../views/reports/registration/ProgramRegoReport.vue";
import ProgramPackFulfilReport from "../views/reports/registration/ProgramPackFulfilReport.vue";
import ProgramRegoPaymentDetail from "../views/reports/registration/ProgramRegoPaymentDetailReport.vue";
import HistoricalRegoReport from "../views/reports/registration/HistoricalRegoReport.vue";
import ContactReport from "../views/reports/registration/ContactReport.vue";
import VoucherReport from "../views/reports/registration/VoucherReport.vue";
import EighteenMonthRegistrationReport from "../views/reports/registration/EighteenMonthRegistrationReport.vue";
import TeamRegoReport from "../views/reports/registration/TeamRegoReport.vue";
import EntityDetailsReport from "../views/reports/competition-management/EntityDetailsReport.vue";
import AdminUsersReport from "../views/reports/competition-management/AdminUsersReport.vue";
import ClearancePermitsReport from "../views/reports/competition-management/ClearancePermitsReport.vue";
import DisbursementsReport from "../views/reports/financial/DisbursementsReport.vue";

// TODO lazy load routes to help webpack code splitting
const TeamSheetsReport = () =>
  import("../views/reports/competition-management/TeamSheetsReport.vue");
//import TeamSheetsReport from "../views/reports/competition-management/TeamSheetsReport.vue";

import AccreditationReport from "../views/reports/competition-management/AccreditationReport.vue";
import SaleablePurchaseReport from "../views/reports/financial/SaleablePurchaseReport.vue";
import MatchesReport from "../views/reports/competition-management/MatchesReport.vue";
import MatchesDetailReport from "../views/reports/competition-management/MatchesDetail.vue";
import MatchesNoParticipants from "../views/reports/competition-management/MatchesNoParticipants.vue";
import PendingRegoReport from "../views/reports/registration/PendingRegoReport.vue";
import DispensationReport from "../views/reports/competition-management/DispensationReport.vue";
import MatchStatsReport from "../views/reports/competition-management/MatchStatsReport.vue";
import CompetitionReport from "../views/reports/competition-management/CompetitionReport.vue";
import SquadReport from "../views/reports/competition-management/SquadReport.vue";
import SquadBetaReport from "../views/reports/competition-management/SquadBetaReport.vue";
import TeamSquadAccreditationReport from "../views/reports/competition-management/TeamSquadAccreditationReport.vue";
import TeamsReport from "../views/reports/competition-management/TeamsReport.vue";
import UnassignedMembers from "../views/reports/competition-management/UnassignedMembers.vue";
import TribunalReport from "../views/reports/competition-cases/TribunalReport.vue";
import TrainingAttendanceReport from "../views/reports/competition-management/TrainingAttendanceReport.vue";
import LaddersReport from "../views/reports/competition-management/LaddersReport.vue";
import CompetitionCasesInjuryReport from "../views/reports/competition-management/CompetitionCasesInjuryReport.vue";
import ProgramFinancialReport from "../views/reports/registration/ProgramFinancialReport.vue";
import PlayerStatsReport from "../views/reports/competition-management/PlayerStatsReport.vue";
import MarketingClubReport from "../views/reports/registration/MarketingClubReport.vue";
import MarketingProgramsReport from "../views/reports/registration/MarketingProgramsReport.vue";
import InjuryReport from "../views/reports/competition-cases/InjuryReport.vue";

// legacy reports
import HistoricMatchStatsReport from "../views/reports/legacy-competition-management/HistoricMatchStatsReport.vue";
import HistoricTeamsReport from "../views/reports/legacy-competition-management/HistoricTeamsReport.vue";
import HistoricMatchesDetailReport from "../views/reports/legacy-competition-management/HistoricMatchesDetailReport.vue";
import HistoricCompetitionReport from "../views/reports/legacy-competition-management/HistoricCompetitionReport.vue";

// member reports
import DuplicateResolution from "../views/reports/members/DuplicateResolution.vue";
import UnavailableMembers from "../views/reports/members/UnavailableMembers.vue";

import AXParticipantsAndVenues from "../views/reports/active-exchange/venuesAndParticipants.vue";

import Communications from "../views/communications/Communications.vue";
import RegradingList from "../views/team-regrading/RegradingList.vue";
import RegradingDetails from "../views/team-regrading/RegradingDetails.vue";
import RegradingSteps from "../views/team-regrading/RegradingSteps.vue";

// Comp Cases
import CompetitionCases from "../views/competition-cases/CompetitionCases.vue";
import CompetitionCaseUpdate from "../views/competition-cases/CompetitionCaseUpdate.vue";
import CompetitionCaseCreate from "../views/competition-cases/CompetitionCaseCreate.vue";

import FailedTransfersReport from "../views/reports/financial/FailedTransfersReport.vue";
import SquadSummaryReport from "../views/reports/competition-management/SquadSummaryReport.vue";
import CommunicationLog from "../views/communications/CommunicationLog.vue";

//import MatchGrid from "../views/match-grid/MatchGrid.vue";
const MatchGrid = () => import("../views/match-grid/MatchGrid.vue");

const MatchGridBeta = () => import("../views/match-grid/MatchGridBeta.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      displayName: "NRL Admin",
      layout: "login",
    },
    component: Login,
  },
  {
    path: "/oauth",
    name: "oauth",
    meta: {
      displayName: "Login",
      layout: "login",
    },
    component: Oauth,
  },
  {
    path: "*",
    name: "404",
    meta: {
      displayName: "Page Not Found",
      layout: "login",
    },
    component: NotFound,
  },
  {
    path: "/",
    name: "dashboard",
    meta: {
      displayName: "Dashboard",
      layout: "app",
      requiresAuth: true,
    },
    component: Dashboard,
  },
  {
    path: "/under-construction",
    name: "under-construction",
    meta: {
      displayName: "Under Construction",
      layout: "app",
      requiresAuth: true,
    },
    component: UnderConstruction,
  },
  {
    path: "/my",
    name: "my-entity",
    meta: {
      layout: "app",
      requiresAuth: true,
    },
    component: MyEntity,
    children: myentity,
  },
  {
    path: "/clubs",
    name: "clubs",
    meta: {
      displayName: "Clubs",
      layout: "app",
      requiresAuth: true,
    },
    component: ClubsList,
  },
  {
    path: "/clubs/:id/",
    name: "clubs.update",
    props: true,
    meta: {
      displayName: "Club Edit",
      layout: "app",
      requiresAuth: true,
    },
    component: ClubForm,
  },
  {
    path: "/clubs/add",
    name: "clubs.add",
    props: true,
    meta: {
      displayName: "Club Add",
      layout: "app",
      requiresAuth: true,
    },
    component: ClubForm,
  },
  {
    path: "/teams",
    name: "teams",
    meta: {
      displayName: "Teams",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamsList,
  },
  {
    path: "/teams/:id",
    name: "teams.update",
    props: true,
    meta: {
      displayName: "Team Edit",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamForm,
  },
  {
    path: "/teams/add",
    name: "teams.add",
    props: true,
    meta: {
      displayName: "Team Add",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamForm,
  },
  {
    path: "/regions",
    name: "regions",
    meta: {
      displayName: "Regions",
      layout: "app",
      requiresAuth: true,
    },
    component: RegionsList,
  },
  {
    path: "/regions/:id",
    name: "regions.update",
    props: true,
    meta: {
      displayName: "Region Edit",
      layout: "app",
      requiresAuth: true,
    },
    component: RegionForm,
  },
  {
    path: "/regions/add",
    name: "regions.add",
    props: true,
    meta: {
      displayName: "Region Add",
      layout: "app",
      requiresAuth: true,
    },
    component: RegionForm,
  },
  {
    path: "/states",
    name: "states",
    meta: {
      displayName: "States",
      layout: "app",
      requiresAuth: true,
    },
    component: StatesList,
  },
  {
    path: "/states/:id",
    name: "states.update",
    props: true,
    meta: {
      displayName: "State Edit",
      layout: "app",
      requiresAuth: true,
    },
    component: StateForm,
  },
  {
    path: "/states/add",
    name: "states.add",
    props: true,
    meta: {
      displayName: "State Add",
      layout: "app",
      requiresAuth: true,
    },
    component: StateForm,
  },
  {
    path: "/associations",
    name: "associations",
    meta: {
      displayName: "Associations",
      layout: "app",
      requiresAuth: true,
    },
    component: AssociationsList,
  },
  {
    path: "/associations/:id",
    name: "associations.update",
    props: true,
    meta: {
      displayName: "Association Edit",
      layout: "app",
      requiresAuth: true,
    },
    component: AssociationForm,
  },
  {
    path: "/associations/add",
    name: "associations.add",
    props: true,
    meta: {
      displayName: "Association Add",
      layout: "app",
      requiresAuth: true,
    },
    component: AssociationForm,
  },
  {
    path: "/program-settings/:id",
    name: "programsetting.update",
    meta: {
      displayName: "Edit Program Setting",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramsettingsForm,
  },
  {
    path: "/program-settings",
    name: "programsettings",
    meta: {
      displayName: "Program Settings",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramsettingsList,
  },
  {
    path: "/programs/:id",
    name: "program.update",
    meta: {
      displayName: "Edit Program",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramsForm,
  },
  {
    path: "/programs/add",
    name: "program.add",
    props: true,
    meta: {
      displayName: "Program Add",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramsForm,
  },
  {
    path: "/programs",
    name: "programs",
    meta: {
      displayName: "Programs",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramsList,
  },
  {
    path: "/registration-products",
    name: "registration-products",
    meta: {
      displayName: "Products",
      layout: "app",
      requiresAuth: true,
    },
    component: RegistrationProduct,
  },
  {
    path: "/expirations",
    name: "expirations",
    meta: {
      displayName: "Expirations",
      layout: "app",
      requiresAuth: true,
    },
    component: Expirations,
  },
  {
    path: "/competitions-types",
    name: "competitions-types.list",
    meta: {
      displayName: "Competition Types",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionType,
  },
  {
    path: "/competitions-types/:id",
    name: "competitions-types.update",
    meta: {
      displayName: "Edit Competition Types",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionTypeForm,
  },
  {
    path: "/competitions-types/:id",
    name: "competitions-types.add",
    meta: {
      displayName: "Add Competition Types",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionTypeForm,
  },
  {
    path: "/competitions",
    name: "competitions.list",
    meta: {
      displayName: "Competitions",
      layout: "app",
      requiresAuth: true,
    },
    component: CompList,
  },
  {
    path: "/competitions/:id",
    name: "competitions.add",
    meta: {
      displayName: "Add Competitions",
      layout: "app",
      requiresAuth: true,
    },
    component: CompForm,
  },
  {
    path: "/competitions/:id",
    name: "competitions.update",
    meta: {
      displayName: "Edit Competitions",
      layout: "app",
      requiresAuth: true,
    },
    component: CompForm,
  },
  {
    path: "/competitions/:id/matches/redo-draw-buider",
    name: "matches.redo-draw-builder",
    meta: {
      displayName: "Redo Draw Builder",
      layout: "app",
      requiresAuth: true,
    },
    component: RedoDrawBuilder,
  },
  {
    path: "/competitions/:id/matches/redo-draw",
    name: "matches.redo-draw",
    meta: {
      displayName: "Redo Draw",
      layout: "app",
      requiresAuth: true,
    },
    component: RedoDraw,
  },
  {
    path: "/competition-cases",
    name: "competition-cases.list",
    meta: {
      displayName: "Competition Cases",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionCases,
  },
  {
    path: "/competition-cases/add",
    name: "competition-cases.add",
    meta: {
      displayName: "Competition Cases",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionCaseCreate,
  },
  {
    path: "/competition-cases/:id",
    name: "competition-cases.update",
    meta: {
      displayName: "Competition Cases",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionCaseUpdate,
  },
  {
    path: "/matches",
    name: "matches.list",
    meta: {
      displayName: "Matches",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchList,
  },
  {
    path: "/matches/:id",
    name: "matches.update",
    props: true,
    meta: {
      displayName: "Edit Matches",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchForm,
  },
  {
    path: "/match-scores",
    name: "matchScores.list",
    meta: {
      displayName: "Match Scores",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchScoreList,
  },
  {
    path: "/user-management",
    name: "userManagement.search",
    meta: {
      displayName: "User Management",
      layout: "app",
      requiresAuth: true,
    },
    component: UserManagement,
  },
  {
    path: "/user-management/:id",
    name: "userManagement.details",
    meta: {
      displayName: "User Details",
      layout: "app",
      requiresAuth: true,
    },
    component: UserForm,
  },
  {
    path: "/draws",
    name: "draws.list",
    meta: {
      displayName: "Draws",
      layout: "app",
      requiresAuth: true,
    },
    component: DrawList,
  },
  {
    path: "/drawbuilder",
    name: "draw.build",
    meta: {
      displayName: "Draw Builder",
      layout: "app",
      requiresAuth: true,
    },
    component: DrawBuilder,
  },
  {
    path: "/drawbuilder-import",
    name: "draw.import",
    meta: {
      displayName: "Draw Builder Import",
      layout: "app",
      requiresAuth: true,
    },
    component: DrawBuilderImport,
  },
  {
    path: "/draws/:id",
    name: "draws.update",
    props: true,
    meta: {
      displayName: "Edit Draw",
      layout: "app",
      requiresAuth: true,
    },
    component: DrawEdit,
  },
  {
    path: "/squad-management",
    name: "squadManagement",
    meta: {
      displayName: "Squad Management",
      layout: "app",
      requiresAuth: true,
    },
    component: SquadManagement,
  },
  {
    path: "/restrict-squad-management",
    name: "restrictSquadManagement",
    meta: {
      displayName: "Restrict Squad Management",
      layout: "app",
      requiresAuth: true,
    },
    component: RestrictSquadManagement,
  },
  {
    path: "/squad-management/:id",
    name: "squadManagement.details",
    meta: {
      displayName: "Squad Management",
      layout: "app",
      requiresAuth: true,
    },
    component: SquadManagementDetail,
  },
  {
    path: "/tfa-squad-management/:id",
    name: "tfaSquadManagement.details",
    meta: {
      displayName: "Squad Management",
      layout: "app",
      requiresAuth: true,
    },
    component: TFASquadManagementDetail,
  },
  {
    path: "/venues",
    name: "venues.list",
    meta: {
      displayName: "Venues",
      layout: "app",
      requiresAuth: true,
    },
    component: VenuesList,
  },
  {
    path: "/venues/:id",
    name: "venues.form",
    meta: {
      displayName: "Edit Venue",
      layout: "app",
      requiresAuth: true,
    },
    component: VenuesForm,
  },
  {
    path: "/account-details",
    name: "account-details",
    meta: {
      displayName: "Account Details",
      layout: "app",
      requiresAuth: true,
    },
    component: AccountDetails,
  },
  {
    path: "/pending-registrations",
    name: "pending-registrations",
    meta: {
      displayName: "Pending Registrations",
      layout: "app",
      requiresAuth: true,
    },
    component: PendingVerification,
  },
  {
    path: "/pending-registrations/:id",
    name: "pending-registrations.details",
    meta: {
      displayName: "Pending Registrations Details",
      layout: "app",
      requiresAuth: true,
    },
    component: PendingVerificationForm,
  },
  {
    path: "/18-month-dispensation",
    name: "18-month-dispensation",
    meta: {
      displayName: "18-month-dispensation",
      layout: "app",
      requiresAuth: true,
    },
    component: EighteenMRP,
  },

  // {
  //   path: '/sitemap',
  //   name: 'site-map',
  //   meta: {
  //     displayName: 'Site Map',
  //     layout: 'app',
  //     requiresAuth: true,
  //   },
  //   component: SiteMap,
  // },
  {
    path: "/ladder-templates",
    name: "laddertemplate.list",
    meta: {
      displayName: "Ladder Template List",
      layout: "app",
      requiresAuth: true,
    },
    component: LadderTemplateList,
  },
  {
    path: "/ladder-template/:id",
    name: "laddertemplate.add",
    meta: {
      displayName: "Add Ladder Template",
      layout: "app",
      requiresAuth: true,
    },
    component: LadderTemplate,
  },
  {
    path: "/ladder-template/:id",
    name: "laddertemplate.update",
    meta: {
      displayName: "Update Ladder Template",
      layout: "app",
      requiresAuth: true,
    },
    component: LadderTemplate,
  },
  {
    path: "/game-dev-regions",
    name: "game-dev-region.list",
    meta: {
      displayName: "Game Dev Region List",
      layout: "app",
      requiresAuth: true,
    },
    component: GameDevRegionList,
  },
  {
    path: "/game-dev-regions/:id",
    name: "game-dev-region.add",
    meta: {
      displayName: "Add Game Dev Region",
      layout: "app",
      requiresAuth: true,
    },
    component: GameDevRegionForm,
  },
  {
    path: "/game-dev-regions/:id",
    name: "game-dev-region.update",
    meta: {
      displayName: "Update Game Dev Region",
      layout: "app",
      requiresAuth: true,
    },
    component: GameDevRegionForm,
  },
  {
    path: "/member-management",
    name: "members.list",
    meta: {
      displayName: "My Members",
      layout: "app",
      requiresAuth: true,
    },
    component: MemberList,
  },
  {
    path: "/member-registrations-beta",
    name: "members.beta.list",
    meta: {
      displayName: "Registrations - beta",
      layout: "app",
      requiresAuth: true,
    },
    component: MemberListGraphql,
  },
  {
    path: "/member-management/:id",
    name: "members.update",
    meta: {
      displayName: "My Members",
      layout: "app",
      requiresAuth: true,
    },
    component: MemberDetails,
  },
  {
    path: "/teams/:teamId/:id",
    name: "team.update",
    meta: {
      displayName: "Team Members",
      layout: "app",
      requiresAuth: true,
    },
    component: MemberDetails,
  },
  {
    path: "/clearance-permit/",
    name: "clearance-permit.list",
    meta: {
      displayName: "Clearance/Permits",
      layout: "app",
      requiresAuth: true,
    },
    component: ClearancePermitList,
  },
  {
    path: "/clearance-permit/request",
    name: "clearance-permit.request",
    meta: {
      displayName: "Clearance/Permit Request",
      layout: "app",
      requiresAuth: true,
    },
    component: ClearancePermitRequest,
  },
  {
    path: "/clearance-permit/:id/",
    name: "clearance-permit.update",
    meta: {
      displayName: "Clearance/Permit Update",
      layout: "app",
      requiresAuth: true,
    },
    component: ClearancePermitForm,
  },
  {
    path: "/verify/",
    name: "verify",
    meta: {
      displayName: "Verification",
      layout: "login",
      requiresAuth: false,
    },
    component: Verify,
  },
  {
    path: "/pending/",
    name: "pending",
    meta: {
      displayName: "Pending",
      layout: "login",
      requiresAuth: false,
    },
    component: PendingRole,
  },
  {
    path: "/discounts/",
    name: "discounts",
    meta: {
      displayName: "Discounts",
      layout: "app",
      requiresAuth: true,
    },
    component: UnderConstruction,
  },
  {
    path: "/duplicate-members/",
    name: "duplicate-members",
    meta: {
      displayName: "Duplicate Members",
      layout: "app",
      requiresAuth: true,
    },
    component: DuplicateMembers,
  },
  {
    path: "/duplicate-members/:id",
    name: "duplicate-members.update",
    meta: {
      displayName: "Duplicate Members",
      layout: "app",
      requiresAuth: true,
    },
    component: DuplicateMembersEdit,
  },

  /* ---------- Reports Start ---------- */
  {
    path: "/reports/",
    name: "reports",
    meta: {
      displayName: "Reports",
      layout: "app",
      requiresAuth: true,
    },
    component: Reports,
  },
  {
    path: "/download-report",
    name: "download-report",
    meta: {
      displayName: "Download Report",
      layout: "login",
    },
    component: DownloadReport,
  },

  // Registration Reports
  {
    path: "/reports/rego-detail",
    name: "rego-detail-report",
    meta: {
      displayName: "Registration Details Report",
      layout: "app",
      requiresAuth: true,
    },
    component: RegoDetailReport,
  },
  {
    path: "/reports/rego-payment-detail",
    name: "rego-payment-detail-report",
    meta: {
      displayName: "Registration Payment Details Report",
      layout: "app",
      requiresAuth: true,
    },
    component: RegoPaymentDetailReport,
  },
  // {
  //   path: "/reports/tfa-rego-payment-detail",
  //   name: "tfa-rego-payment-detail-report",
  //   meta: {
  //     displayName: "TFA Registration Payment Detail Report",
  //     layout: "app",
  //     requiresAuth: true
  //   },
  //   component: TFARegoPaymentDetailReport
  // },
  {
    path: "/reports/program-rego",
    name: "program-rego-report",
    meta: {
      displayName: "Program Registration Report",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramRegoReport,
  },
  {
    path: "/reports/programPack-rego",
    name: "programPack-rego-report",
    meta: {
      displayName: "Program Pack Fulfilment Report",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramPackFulfilReport,
  },
  {
    path: "/reports/program-rego-payment-detail",
    name: "program-rego-payment-detail-report",
    meta: {
      displayName: "Program Registration Payment Detail Report",
      layout: "app",
      requiresAuth: true,
    },
    component: ProgramRegoPaymentDetail,
  },
  {
    path: "/reports/historical-rego",
    name: "historical-rego-report",
    meta: {
      displayName: "Historical Registration Report",
      layout: "app",
      requiresAuth: true,
    },
    component: HistoricalRegoReport,
  },
  {
    path: "/reports/contact",
    name: "contact-report",
    meta: {
      displayName: "Contact Report",
      layout: "app",
      requiresAuth: true,
    },
    component: ContactReport,
  },
  // {
  //   path: "/reports/voucher",
  //   name: "voucher-report",
  //   meta: {
  //     displayName: "Discount Voucher Report",
  //     layout: "app",
  //     requiresAuth: true
  //   },
  //   component: VoucherReport
  // },
  {
    path: "/reports/expression-interest",
    name: "expression-interest-report",
    meta: {
      displayName: "Expression Of Interest",
      layout: "app",
      requiresAuth: true,
    },
    component: ExpressionInterest,
  },
  {
    path: "/reports/eighteen-month-registration",
    name: "eighteen-month-registration-report",
    meta: {
      displayName: "18-Month Registration Report",
      layout: "app",
      requiresAuth: true,
    },
    component: EighteenMonthRegistrationReport,
  },
  {
    path: "/reports/team-rego",
    name: "team-rego-report",
    meta: {
      displayName: "Team Registration Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamRegoReport,
  },
  {
    path: "/reports/pending-rego",
    name: "pending-rego-report",
    meta: {
      displayName: "Pending Registration Report",
      layout: "app",
      requiresAuth: true,
    },
    component: PendingRegoReport,
  },
  // {
  //   path: "/reports/program-financial",
  //   name: "program-financial-report",
  //   meta: {
  //     displayName: "Program Financial Report",
  //     layout: "app",
  //     requiresAuth: true,
  //   },
  //   component: ProgramFinancialReport,
  // },
  {
    path: "/reports/marketing-club",
    name: "marketing-club-report",
    meta: {
      displayName: "Marketing Report (Club Rugby League)",
      layout: "app",
      requiresAuth: true,
    },
    component: MarketingClubReport,
  },
  {
    path: "/reports/marketing-programs",
    name: "marketing-programs-report",
    meta: {
      displayName: "Marketing Report (Programs)",
      layout: "app",
      requiresAuth: true,
    },
    component: MarketingProgramsReport,
  },
  {
    path: "/reports/injury-report",
    name: "injury-report",
    meta: {
      displayName: "Injury Report",
      layout: "app",
      requiresAuth: true,
    },
    component: InjuryReport,
  },

  // Competition Management Reports
  {
    path: "/reports/entity-details",
    name: "entity-details-report",
    meta: {
      displayName: "Entity Details Report",
      layout: "app",
      requiresAuth: true,
    },
    component: EntityDetailsReport,
  },
  {
    path: "/reports/admin-users",
    name: "admin-users-report",
    meta: {
      displayName: "Admin Users Report",
      layout: "app",
      requiresAuth: true,
    },
    component: AdminUsersReport,
  },
  {
    path: "/reports/clearance-permit",
    name: "clearance-permit-report",
    meta: {
      displayName: "Clearance Permit Report",
      layout: "app",
      requiresAuth: true,
    },
    component: ClearancePermitsReport,
  },
  {
    path: "/reports/team-sheets-report",
    name: "team-sheets-report",
    meta: {
      displayName: "Team Sheet Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamSheetsReport,
  },
  {
    path: "/reports/accreditation-report",
    name: "accreditation-report",
    meta: {
      displayName: "Accreditation Report",
      layout: "app",
      requiresAuth: true,
    },
    component: AccreditationReport,
  },
  {
    path: "/reports/matches-report",
    name: "matches-report",
    meta: {
      displayName: "Matches Report",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchesReport,
    props: true,
  },

  {
    path: "/reports/competition-cases",
    name: "competition-cases-report",
    meta: {
      displayName: "Competition Cases - Injury Report Form",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionCasesInjuryReport,
  },
  {
    path: "/reports/matches-detail",
    name: "matches-detail-report",
    meta: {
      displayName: "Matches Detail Report",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchesDetailReport,
  },
  {
    path: "/reports/matches-noparticipants",
    name: "matches-noparticipants-report",
    meta: {
      displayName: "Matches without participants",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchesNoParticipants,
  },
  {
    path: "/reports/dispensation",
    name: "dispensation-report",
    meta: {
      displayName: "Dispensations",
      layout: "app",
      requiresAuth: true,
    },
    component: DispensationReport,
  },
  {
    path: "/reports/match-stats",
    name: "match-stats-report",
    meta: {
      displayName: "Match Stats",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchStatsReport,
  },
  {
    path: "/reports/competition",
    name: "competition-report",
    meta: {
      displayName: "Competition Report",
      layout: "app",
      requiresAuth: true,
    },
    component: CompetitionReport,
  },
  {
    path: "/reports/squad-report",
    name: "squad-report",
    meta: {
      displayName: "Squad Report",
      layout: "app",
      requiresAuth: true,
    },
    component: SquadReport,
  },
  {
    path: "/reports/squad-beta-report",
    name: "squad-beta-report",
    meta: {
      displayName: "Squad Report (Beta)",
      layout: "app",
      requiresAuth: true,
    },
    component: SquadBetaReport,
  },
  {
    path: "/reports/squad-accreditation-report",
    name: "squad-accreditation-report",
    meta: {
      displayName: "Team Squad Accreditation Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamSquadAccreditationReport,
  },
  {
    path: "/reports/teams-report",
    name: "teams-report",
    meta: {
      displayName: "Teams Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TeamsReport,
  },
  {
    path: "/reports/unassigned-members-report",
    name: "unassigned-members-report",
    meta: {
      displayName: "Unassigned Members Report",
      layout: "app",
      requiresAuth: true,
    },
    component: UnassignedMembers,
  },
  {
    path: "/reports/tribunal-report",
    name: "tribunal-report",
    meta: {
      displayName: "Tribunal Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TribunalReport,
  },
  {
    path: "/reports/ladders-report",
    name: "ladders-report",
    meta: {
      displayName: "Ladders Report",
      layout: "app",
      requiresAuth: true,
    },
    component: LaddersReport,
  },

  {
    path: "/reports/training-attendance-report",
    name: "training-attendance-report",

    meta: {
      displayName: "Training Attendance Report",
      layout: "app",
      requiresAuth: true,
    },
    component: TrainingAttendanceReport,
  },
  {
    path: "/reports/player-stats-report",
    name: "player-stats-report",
    meta: {
      displayName: "Player Stats Report",
      layout: "app",
      requiresAuth: true,
    },
    component: PlayerStatsReport,
  },
  // legacy competition mgmt reports
  {
    path: "/reports/historic-match-stats-report",
    name: "historic-match-stats-report",
    meta: {
      displayName: "Historic Match Stats Report",
      layout: "app",
      requiresAuth: true,
    },
    component: HistoricMatchStatsReport,
  },
  {
    path: "/reports/historic-teams-report",
    name: "historic-teams-report",
    meta: {
      displayName: "Historic Teams Report",
      layout: "app",
      requiresAuth: true,
    },
    component: HistoricTeamsReport,
  },
  {
    path: "/reports/historic-matches-detail-report",
    name: "historic-matches-detail-report",
    meta: {
      displayName: "Historic Matches Detail Report",
      layout: "app",
      requiresAuth: true,
    },
    component: HistoricMatchesDetailReport,
  },
  {
    path: "/reports/historic-competition-report",
    name: "historic-competition-report",
    meta: {
      displayName: "Historic Competition Report",
      layout: "app",
      requiresAuth: true,
    },
    component: HistoricCompetitionReport,
  },

  // Financial Reports
  {
    path: "/reports/disbursements",
    name: "disbursements-report",
    meta: {
      displayName: "Disbursements Report",
      layout: "app",
      requiresAuth: true,
    },
    component: DisbursementsReport,
  },
  {
    path: "/reports/saleable-purchase",
    name: "saleable-purchase-report",
    meta: {
      displayName: "Saleable Purchases Report",
      layout: "app",
      requiresAuth: true,
    },
    component: SaleablePurchaseReport,
  },
  {
    path: "/reports/failed-transfers",
    name: "failed-transfers-report",

    meta: {
      displayName: "Failed Transfers Report",
      layout: "app",
      requiresAuth: true,
    },
    component: FailedTransfersReport,
  },
  {
    path: "/reports/squad-summary",
    name: "squad-summary-report",

    meta: {
      displayName: "Squad Summary Report",
      layout: "app",
      requiresAuth: true,
    },
    component: SquadSummaryReport,
  },

  // Member Reports
  {
    path: "/reports/duplicate-resolution",
    name: "duplicate-resolution-report",
    meta: {
      displayName: "Duplicate Resolution Report",
      layout: "app",
      requiresAuth: true,
    },
    component: DuplicateResolution,
  },
  {
    path: "/reports/unavailable-members",
    name: "unavailable-members-report",
    meta: {
      displayName: "Unavailable Members Report",
      layout: "app",
      requiresAuth: true,
    },
    component: UnavailableMembers,
  },

  // active exchange reports
  {
    path: "/reports/ax-participant-venues-report",
    name: "ax-participant-venues-report",
    meta: {
      displayName: "Active exchange Report",
      layout: "app",
      requiresAuth: true,
    },
    component: AXParticipantsAndVenues,
  },

  /* ---------- Reports End ---------- */

  {
    path: "/competition-cases/",
    name: "competition-cases",
    meta: {
      displayName: "Competition Cases",
      layout: "app",
      requiresAuth: true,
    },
    component: UnderConstruction,
  },
  {
    path: "/ladders/",
    name: "ladders.list",
    meta: {
      displayName: "Ladders",
      layout: "app",
      requiresAuth: true,
    },
    component: LadderList,
  },
  {
    path: "/ladders/:id",
    name: "ladders.update",
    meta: {
      displayName: "Update Ladder",
      layout: "app",
      requiresAuth: true,
    },
    component: LadderUpdate,
  },
  {
    path: "/championship-ladders/",
    name: "championship-ladders",
    meta: {
      displayName: "Championship Ladders",
      layout: "app",
      requiresAuth: true,
    },
    component: UnderConstruction,
  },
  // #region Team Regradings
  {
    path: "/team-regrading/",
    name: "regrading.list",
    meta: {
      displayName: "Team Regrading",
      layout: "app",
      requiresAuth: true,
    },
    component: RegradingList,
  },
  {
    path: "/team-regrading/regrade",
    name: "regrading.regrade",
    meta: {
      displayName: "Re-Grade Team",
      layout: "app",
      requiresAuth: true,
    },
    component: RegradingSteps,
  },
  {
    path: "/team-regrading/:id",
    name: "regrading.details",
    meta: {
      displayName: "Team Regrading Details",
      layout: "app",
      requiresAuth: true,
    },
    component: RegradingDetails,
  },
  // #endregion Team Regradings
  // #region Communications
  {
    path: "/communications",
    name: "communications",
    meta: {
      displayName: "Communications",
      layout: "app",
      requiresAuth: true,
    },
    component: Communications,
  },
  {
    path: "/communications/:id",
    name: "communications.details",
    meta: {
      displayName: "Communication Log Details",
      layout: "app",
      requiresAuth: true,
    },
    component: CommunicationLog,
  },
  // #endregion Communication
  {
    path: "/match-grid",
    name: "match-grid",
    meta: {
      displayName: "Match Grid",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchGrid,
  },
  {
    path: "/match-grid-beta",
    name: "match-grid-beta",
    meta: {
      displayName: "Match Grid Beta",
      layout: "app",
      requiresAuth: true,
    },
    component: MatchGridBeta,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: {
      displayName: "Maintenance",
      layout: "login",
      requiresAuth: false,
    },
    component: Maintenance,
  },
  {
    path: "/healthcheck/",
    name: "healthcheck",
    meta: {
      displayName: "healthcheck",
      layout: "app",
      requiresAuth: false,
    },
    component: Healthcheck,
  },
  // Third party
  {
    path: "/api-keys-management",
    name: "apikeys.management",
    meta: {
      displayName: "Api Key Management",
      layout: "app",
      requiresAuth: true,
    },
    component: ApiKeysList,
  },
  // Third party
  {
    path: "/healthcheck",
    name: "healthcheck",
    meta: {
      displayName: "Healthcheck",
      layout: "app",
      requiresAuth: false,
    },
    component: Healthcheck,
  },
];

export default routes;
