<template>
  <el-form label-position="top" :model="program" :rules="rules" ref="program">
    <el-row
      :gutter="10"
      class="tw-py-4"
      v-if="this.$store.getters['user/activeRole'].type === 98 && allregionprogramadmins.length > 0"
    >
      <el-col :span="12">
        <el-form-item prop="owner" label="User">
          <el-select v-model="program.owner" placeholder="Select user for this program" filterable>
            <el-option
              v-for="item in allregionprogramadmins"
              :label="`${item.profile.firstName} ${item.profile.lastName}`"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-tabs type="card">
      <el-tab-pane label="Program Details">
        <!-- <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        > -->
        <div class="tw-flex tw-justify-center tw-w-full">
          <img
            v-if="currentLogo"
            :src="currentLogo"
            @error="displayPlaceholder"
            alt=""
            class="tw-w-24"
          />
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form class="tw-flex-1">
            <div class="tw-max-w-5xl " :span="24">
              <el-form-item label="Program Registration Link">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="programFinderLink"
                  readonly
                  disabled=""
                >
                  <template slot="append">
                    <el-button
                      v-clipboard="programFinderLink"
                      @click="$customSuccess('Successfully copied to clipboard')"
                    >
                      Copy
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div
          class="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100 sm:tw-flex-row"
        >
          <el-form-item class="tw-flex-1" prop="programtype" label="Program Type">
            <el-select v-model="programtype" placeholder="Select program type" filterable>
              <el-option
                v-for="item in allprogramsettings.map((ps) => ({ type: ps.type }))"
                :label="item.type"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="venue" label="Venue">
            <el-select
              v-model="venue"
              :remote="true"
              :remote-method="filter"
              placeholder="Enter venue to start searching"
              filterable
            >
              <el-option
                v-for="item in allvenues"
                :label="item.concatName"
                :key="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="programname" label="Program Name">
            <el-input autocomplete="off" v-model="program.name" placeholder="Enter program name" />
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        >
          <div class="tw-flex-1">
            <el-form-item prop="numberOfSessions" label="No. Of Sessions">
              <el-select v-model="numberOfSessions" placeholder="Select no. of sessions" filterable>
                <el-option-group
                  v-for="group in availableSessionNos"
                  :key="group.duration"
                >
                  <el-option
                    v-for="item in group.combinations"
                    :key="group.combinations.indexOf(item)"
                    :label="group.duration + ' - '+ (group.combinations.indexOf(item)+1)"
                    :value="JSON.stringify(item)"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="minage" label="Minimum Age">
              <el-input-number v-model="minage" :min="selectedProgramSettings.minAge" :max="selectedProgramSettings.maxAge" placeholder="Enter min. age" controls-position="right"></el-input-number>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="maxage" label="Maximum Age">
              <el-input-number v-model="maxage" :min="selectedProgramSettings.minAge" :max="selectedProgramSettings.maxAge" placeholder="Enter max. age" controls-position="right"></el-input-number>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="activeKidsVoucher" label="Active Kids Voucher">
              <el-select
                v-model="selectedProgramSettings.activeKidsVoucher"
                placeholder="Active Kids Voucher"
                disabled="disabled"
              >
                <el-option
                  v-for="item in [
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="price" label="Price">
              <el-input type="text" autocomplete="off" v-model="price" placeholder="$" disabled />
            </el-form-item>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form-item class="tw-flex-1" prop="gender" label="Gender">
            <el-select v-model="program.gender" placeholder="Select gender" filterable>
              <el-option v-for="item in allgenders" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="capacity" label="Capacity">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.capacity"
              placeholder="Enter capacity"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="hours" label="Duration (hours)">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.hours"
              placeholder="Enter no. hours"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="minutes" label="Duration (mins)">
            <el-input
              type="number"
              autocomplete="off"
              v-model.number="program.minutes"
              placeholder="Enter no. minutes"
            />
          </el-form-item>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form-item class="tw-w-48" prop="startTime" label="Start Time">
            <el-time-picker
              v-model="program.startTime"
              format="HH:mm"
              value-format="HH:mm"
              :picker-options="{
                selectableRange: '05:00:00 - 23:59:59',
              }"
              placeholder="Select start time"
            >
            </el-time-picker>
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="startdate" label="Start-End Date">
            <el-date-picker
              value-format="timestamp"
              v-model="daterange"
              type="daterange"
              format="yyyy/MM/dd"
              start-placeholder="Start date"
              end-placeholder="End date"
            />
          </el-form-item>
        </div>
        <div
          class="tw-flex tw-flex-wrap tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
        >
          <el-form-item
            class="tw-flex-1"
            prop="registrationCloseDate"
            label="Registration close date"
          >
            <el-date-picker
              format="dd/MM/yyyy"
              value-format="timestamp"
              v-model="program.registrationCloseDate"
              type="date"
              placeholder="Select an end date"
            />
          </el-form-item>

          <el-form-item class="tw-flex-1" prop="activeInFinder" label="Active In Finder">
            <el-select v-model="program.activeInFinder" placeholder="Select an option" filterable>
              <el-option
                v-for="item in [
                  { option: 'Yes', value: true },
                  { option: 'No', value: false },
                ]"
                :label="item.option"
                :key="item.option"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div>
            <el-form-item prop="activeInFinder" label="Program Status">
              <el-select v-model="program.status" placeholder="Select an option" filterable>
                <el-option v-for="item in programStatuses" :label="item" :key="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="program.status === 'Cancelled - Other'">
            <el-form-item class="tw-flex-1" prop="programcancellationreason" label="Reason">
              <el-input autocomplete="off" v-model="program.cancellationReason" placeholder="Add reason" />
            </el-form-item>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="8">
            <el-form-item prop="gdo.name" label="GDO Contact Name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.name"
                placeholder="Enter name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="gdo.number" label="GDO Contact No.">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.number"
                placeholder="Enter number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="gdo.email" label="GDO Email">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.gdo.email"
                placeholder="Enter email"
              />
            </el-form-item>
          </el-col>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-form-item prop="meta.website" label="Website">
              <el-input
                type="text"
                autocomplete="off"
                v-model="program.meta.website"
                placeholder="Enter a website"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="24">
            <el-form-item prop="notes" label="Notes">
              <el-input
                autocomplete="off"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 20 }"
                v-model="program.notes"
                placeholder="Add program details"
              />
            </el-form-item>
          </el-col>
        </div>
        <action-buttons submit cancel @submit="submitForm('program')" @cancel="cancelForm()" />
      </el-tab-pane>
      <el-tab-pane label="Members" v-if="this.type === 'update'">
        <!-- <el-row class="search-bar">
        <el-col :span="6">
          <el-input
            autocomplete="off"
            v-model="filters[0].value"
            placeholder="Search...">
          </el-input>
        </el-col>
      </el-row> -->
        <data-tables
          :data="tableData"
          :table-props="tableProps"
          @row-click="handleRowClick"
          class="data-table"
        >
          <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
          <el-table-column fixed prop="profile.firstName" label="First Name" width="130">
          </el-table-column>
          <el-table-column fixed prop="profile.lastName" label="Last Name" width="130">
          </el-table-column>
          <el-table-column fixed prop="profile.gender" label="Gender" width="100">
          </el-table-column>
          <el-table-column
            fixed
            :formatter="dobFormatter"
            prop="profile.dob"
            label="Age"
            width="120"
          >
          </el-table-column>
          <el-table-column fixed prop="profile.email" label="Email" width="auto"> </el-table-column>
        </data-tables>
      </el-tab-pane>
      <el-tab-pane
        label="Saleable Items"
        v-if="this.type === 'update' && clearance === roles.superAdmin"
      >
        <saleable-items :program="program" />
      </el-tab-pane>
      <el-tab-pane
        label="Additional Questions"
        v-if="this.type === 'update' && clearance === roles.superAdmin"
      >
        <AdditionalQuestions :program="program" />
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import moment from "moment";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, genders, programStatuses, roles } from "../../utils/constants";
import { getS3URL, calculateAge } from "../../utils/utilities";
import SaleableItems from "./ProgramsSaleableItems.vue";
import AdditionalQuestions from "./ProgramsAdditionalQuestions.vue";

const dobToAge = (member) => {
  if (member) {
    const age = calculateAge(member.profile.dob, true);
    return age;
  }
  return "N/A";
};

export default {
  name: "ProgramsForm",
  components: { ActionButtons, SaleableItems, AdditionalQuestions },
  props: {},
  data() {
    const validateProgramType = (_, value, callback) => {
      if (!this.program.settings.type) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateProgramStatusCancellationReason = (_, value, callback) => {
      if (!this.program.cancellationReason && this.program.status === "Cancelled - Other") {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateDuration = (_, value, callback) => {
      if (!this.program.settings.duration) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateProgramName = (_, value, callback) => {
      if (!this.program.name) {
        return callback(new Error(""));
      }
      return callback();
    };
    const startDateValidator = (_, value, callback) => {
      if (!parseInt(this.program.startDate) || parseInt(this.program.startDate) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const endDateValidator = (_, value, callback) => {
      if (!parseInt(this.program.endDate) || parseInt(this.program.endDate) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateMinAge = (_, value, callback) => {
      if (
        !this.program.settings.minAge ||
        !parseInt(this.program.settings.minAge) ||
        parseInt(this.program.settings.minAge) <= 0 ||
        parseInt(this.program.settings.minAge) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateMaxAge = (_, value, callback) => {
      if (
        !this.program.settings.maxAge ||
        !parseInt(this.program.settings.maxAge) ||
        parseInt(this.program.settings.maxAge) <= 0 ||
        parseInt(this.program.settings.maxAge) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validatePrice = (_, value, callback) => {
      if (this.program.settings.price === -1) {
        return callback(new Error(""));
      }
      return callback();
    };
    const validateCapcity = (rule, value, callback) => {
      if (
        !this.program.capacity ||
        !parseInt(this.program.capacity) ||
        parseInt(this.program.capacity) <= 0 ||
        parseInt(this.program.capacity) % 1 !== 0
      ) {
        return callback(new Error(""));
      }
      return callback();
    };
    const activeKidsValidator = (rule, value, callback) => {
      if (!this.program.settings) {
        return callback(new Error(""));
      }
      return callback();
    };
    return {
      roles,
      type: "insert",
      disableVoucher: true,
      tableData: [],
      tableProps: {
        border: true,
      },
      programFinderLink: "",
      program: {
        _id: null,
        name: null,
        orgtree: {
          national: {
            _id: null,
            name: null,
          },
          gamedevregion: {
            _id: null,
            name: null,
          },
        },
        settings: {
          _id: null,
          type: null,
          duration: null,
          minAge: -1,
          maxAge: -1,
          price: -1,
          activeKidsVoucher: false,
        },
        owner: null,
        gender: null,
        hours: null,
        minutes: null,
        capacity: null,
        startTime: undefined,
        startDate: undefined,
        endDate: undefined,
        registrationCloseDate: null,
        activeInFinder: false,
        notes: null,
        venue: { name: null, _id: null },
        gdo: {
          name: null,
          number: null,
          email: null,
        },
        saleableItems: [],
        additionalQuestions: [],
        meta: {
          website: "",
        },
      },
      programStatuses,
      cancellationReason: "",
      allgenders: genders,
      allvenues: [],
      allprogramsettings: [],
      currentprogramsettings: [],
      selectedProgramSettings: {
        duration: null,
        minAge: -1,
        maxAge: -1,
        price: -1,
        activeKidsVoucher: false,
      },
      allregionprogramadmins: [],
      rules: {
        programtype: {
          required: true,
          validator: validateProgramType,
          message: errormsg.select_option,
          trigger: "blur",
        },
        programcancellationreason: {
          required: true,
          validator: validateProgramStatusCancellationReason,
          message: errormsg.input_option,
          trigger: "blur",
        },
        programname: {
          required: true,
          validator: validateProgramName,
          message: errormsg.input_option,
          trigger: "blur",
        },
        numberOfSessions: {
          required: true,
          validator: validateDuration,
          message: errormsg.select_option,
          trigger: "blur",
        },
        minage: {
          type: "number",
          required: true,
          validator: validateMinAge,
          message: errormsg.input_option,
          trigger: "blur",
        },
        maxage: {
          type: "number",
          required: true,
          validator: validateMaxAge,
          message: errormsg.input_option,
          trigger: "blur",
        },
        price: {
          type: "number",
          required: true,
          validator: validatePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        gender: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        capacity: {
          required: true,
          validator: validateCapcity,
          message: errormsg.input_option,
          trigger: "blur",
        },
        hours: {
          type: "number",
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        minutes: {
          type: "number",
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        startTime: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        startdate: {
          required: true,
          validator: startDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        enddate: {
          required: true,
          validator: endDateValidator,
          message: errormsg.input_option,
          trigger: "blur",
        },
        registrationCloseDate: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        venue: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        activeinfinder: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        activeKidsVoucher: {
          required: true,
          validator: activeKidsValidator,
          message: errormsg.select_option,
          trigger: "blur",
        },
        "gdo.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "gdo.number": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "gdo.email": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        "meta.website": {
          validator: (rule, val, callback) => {
            const expression =
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            const regex = new RegExp(expression);

            if (val.length && !val.match(regex)) {
              return callback(new Error(errormsg.invalidUrl));
            }
            return callback();
          },
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, id, settings } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");

    if (id && id !== "" && id !== "add") {
      const allPromises = [
        this.$http.get(`/nrl/api/v1/admin/members/program-members/${id}`),
        this.$http.get(`/nrl/api/v1/admin/programs/${id}`),
        this.$http.get("/nrl/api/v1/admin/programs-settings"),
      ];
      if (this.$store.getters["user/activeRole"].type === 98) {
        allPromises.push(
          this.$http.get(
            `/nrl/api/v1/admin/gamedevregion/${this.$store.getters["user/activeRole"].entity._id}`
          )
        );
      }
      Promise.all(allPromises)
        .then(([memberRes, programRes, programsettingRes, programadminuserRes]) => {
          this.program = Object.assign({}, this.program, programRes.data.data);
          this.programFinderLink = this.finderLink();

          if (this.program.saleableItems && this.program.saleableItems.length > 0) {
            const items_pricefloat = this.program.saleableItems.map((o) => {
              o.price_int = parseInt(o.price);
              o.price = (parseFloat(o.price) / 100).toFixed(2);
              if (!o.gender) o.gender = "";
              return o;
            });
            this.program.saleableItems = items_pricefloat;
          }

          if (programadminuserRes) {
            this.allregionprogramadmins = programadminuserRes.data.data;
          }
          this.allprogramsettings = programsettingRes.data.data.map((p) => ({
          _id: p._id,
          type: p.type,
          options: p.options
            .map((o) => o.duration)
            .filter((d, index, self) => self.indexOf(d) === index)
            .map((o) => ({
              duration: o,
              combinations: p.options
                .filter((option) => option.duration === o)
                .map((option) => ({
                  minAge: option.minAge,
                  maxAge: option.maxAge,
                  price: option.price,
                  activeKidsVoucher: option.activeKidsVoucher,
                  duration: o
                })),
            })),
        }));
        this.setCurrentProgramSetting();
        this.tableData = memberRes.data.data;
        this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      const allPromises = [
        this.$http.get("/nrl/api/v1/admin/programs-settings"),
      ];

      // Set initial GDO values if no program present
      this.program.gdo.name = this.$store.getters["user/userName"];
      this.program.gdo.email = this.$store.getters["user/email"];

      if (this.$store.getters["user/activeRole"].type === 98 && program) {
        allPromises.push(
          this.$http.get(
            `/nrl/api/v1/admin/gamedevregion/${this.$store.getters["user/activeRole"].entity._id}`
          )
        );
      }
      Promise.all(allPromises)
        .then(([programsettingRes, memberRes, programadminuserRes]) => {
          if (memberRes) {
            this.tableData = memberRes.data.data;
          }
          if (programadminuserRes) {
            this.allregionprogramadmins = programadminuserRes.data.data;
          }
          this.allprogramsettings = programsettingRes.data.data.map((p) => ({
          _id: p._id,
          type: p.type,
          options: p.options
            .map((o) => o.duration)
            .filter((d, index, self) => self.indexOf(d) === index)
            .map((o) => ({
              duration: o,
              combinations: p.options
                .filter((option) => option.duration === o)
                .map((option) => ({
                  minAge: option.minAge,
                  maxAge: option.maxAge,
                  price: option.price,
                  activeKidsVoucher: option.activeKidsVoucher,
                  duration: o
                })),
            })),
        }));
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    setCurrentProgramSetting() {
      this.allprogramsettings.filter((p, i) => {
        if(p._id === this.program.settings._id) {
          this.currentprogramsettings = p.options;
          this.selectedProgramSettings.duration = this.program.settings.duration;
          this.selectedProgramSettings.minAge = this.program.settings.minAge;
          this.selectedProgramSettings.maxAge = this.program.settings.maxAge;
          this.selectedProgramSettings.price = this.program.settings.price;
          this.selectedProgramSettings.activeKidsVoucher = this.program.settings.activeKidsVoucher;
        }
      });
    },
    finderLink() {
      const portalURL =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-profile.mysideline.xyz/"
          : "https://profile.mysideline.com.au/";
      const baseLink = new URL("/register/clubsearch", portalURL);
      baseLink.searchParams.set("criteria", String(this.program._id));
      baseLink.searchParams.set("entity", "true");
      baseLink.searchParams.set("entitytype", "program");
      const source = this.program.orgtree.national._id === 32 ? "rugby-league" : "touch-football";
      baseLink.searchParams.set("source", source);

      return String(baseLink);
    },
    dobFormatter(row) {
      return row && row.profile && row.profile.dob ? dobToAge(row) : "N/A";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.program.orgtree.national._id = this.$store.getters["user/activeRole"].national_id;
          this.program.orgtree.national.name =
            this.$store.getters["user/activeRole"].national_id === 32 ? "NRL" : "TFA";
          this.program.orgtree.gamedevregion._id =
            this.$store.getters["user/activeRole"].entity._id;
          this.program.orgtree.gamedevregion.name =
            this.$store.getters["user/activeRole"].entity.name;
          this.$store.commit("root/loading", true);
          if (this.type === "update") {
            const url = `/nrl/api/v1/admin/programs/${this.program._id}`;
            const update = this.program;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            // this.$store.commit('root/loading', true);
            this.program.owner = this.$store.getters["user/userid"];
            const insert = this.program;
            const url = "/nrl/api/v1/admin/programs";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "programs",
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({ name: "programs" });
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allvenues = response.data.data.map((venue) => ({
                ...venue,
                concatName: `${venue.name} - ${venue.address.suburb}`,
              }));
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allvenues = [];
      }
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    handleRowClick(row) {
      this.$router.push({
        name: "members.update",
        params: {
          id: row._id,
        },
      });
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentLogo() {
      return this.program.settings._id ? getS3URL(this.program.settings._id) : false;
    },
    availableSessionNos() {
      const { program, allprogramsettings } = this;
      const setting =
        program.settings.type && allprogramsettings.length > 0
          ? allprogramsettings.find((ps) => ps.type === program.settings.type)
          : undefined;

      if(setting && setting.options) {
        return setting.options.map((o, i) => {
          if('combinations' in o) {
            return o;
          }
        })
      } else {
        return [];
      }
    },
    programtype: {
      get() {
        return this.program.settings.type;
      },
      set(value) {
        const found = this.allprogramsettings.find((p) => p.type === value);
        if (found) {
          this.program.settings = {
            _id: found._id,
            type: value,
            duration: null,
            minAge: -1,
            maxAge: -1,
            price: -1,
            activeKidsVoucher: false,
          };
          this.setCurrentProgramSetting();
        }
      },
    },
    numberOfSessions: {
      get() {
        return this.program.settings.duration;
      },
      set(value) {
        value = JSON.parse(value);
        this.program.settings.duration = value.duration;
        this.program.settings.minAge = value.minAge;
        this.program.settings.maxAge = value.maxAge;
        this.program.settings.price = value.price;
        this.program.settings.activeKidsVoucher = value.activeKidsVoucher;
        this.selectedProgramSettings.duration = value.duration;
        this.selectedProgramSettings.minAge = value.minAge;
        this.selectedProgramSettings.maxAge = value.maxAge;
        this.selectedProgramSettings.price = value.price;
        this.selectedProgramSettings.activeKidsVoucher = value.activeKidsVoucher;
      },
    },
    minage: {
      get() {
        return this.program.settings.minAge && parseInt(this.program.settings.minAge) > 0
          ? this.program.settings.minAge
          : null;
      },
      set(value) {
        const minAge = parseInt(value);
        const { type, duration, maxAge } = this.program.settings;
        this.program.settings.minAge = minAge > 0 ? minAge : -1;

        if (duration && minAge > 0 && maxAge > 0 && minAge <= maxAge) {
          const programsetting = this.allprogramsettings.find((setting) => setting.type === type);
          if (programsetting && programsetting.options.length > 0) {
            const combination = programsetting.options.find(
              (option) =>
                option.duration === duration && option.minAge <= minAge && maxAge <= option.maxAge
            );
            // let combination = setting || undefined;
            // if (combination)
            //   combination = combination.filter(c => c.minAge <= minAge && maxAge <= c.maxAge);

          } else {
            this.program.settings.price = -1;
          }
        } else {
          this.program.settings.price = -1;
        }
      },
    },
    maxage: {
      get() {
        return this.program.settings.maxAge && parseInt(this.program.settings.maxAge) > 0
          ? this.program.settings.maxAge
          : null;
      },
      set(value) {
        const maxAge = parseInt(value);
        const { type, duration, minAge } = this.program.settings;
        this.program.settings.maxAge = maxAge > 0 ? maxAge : -1;

        if (duration !== "" && minAge > 0 && maxAge > 0 && minAge <= maxAge) {
          const programsetting = this.allprogramsettings.find((setting) => setting.type === type);
          if (programsetting && programsetting.options.length > 0) {
            const combination = programsetting.options.find((o) => {
              return o.duration === duration && o.minAge <= minAge && maxAge <= o.maxAge;
            });
            // .combinations.filter(c => c.minAge <= minAge && maxAge <= c.maxAge);

          } else {
            this.program.settings.price = -1;
          }
        } else {
          this.program.settings.price = -1;
        }
      },
    },
    price: {
      get() {
        return this.selectedProgramSettings.price && parseInt(this.selectedProgramSettings.price) > 0
          ? `$  ${(parseInt(this.selectedProgramSettings.price) / 100).toFixed(2)}`
          : "$ 0";
      },
      set(value) {
        this.program.settings.price = value;
      },
    },
    venue: {
      get() {
        return this.program.venue.name || null;
      },
      set(value) {
        const venue = this.allvenues.find((v) => v._id === value);
        this.program.venue = venue;
        if (this.program.settings.type && venue.address && venue.address.suburb) {
          this.program.name = `${this.program.settings.type} - ${venue.address.suburb}`;
        }
      },
    },
    daterange: {
      get() {
        return [this.program.startDate, this.program.endDate];
      },
      set(values) {
        let [startDate, endDate] = values;

        startDate = moment(startDate)
          .set({
            hour: "12",
            minute: "00",
            second: "00",
          })
          .valueOf();

        endDate = moment(endDate)
          .set({
            hour: "12",
            minute: "00",
            second: "00",
          })
          .valueOf();

        this.program.startDate = startDate;
        this.program.endDate = endDate;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.el-select,
.el-input {
  width: 100%;
}
.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}
.avatar {
  margin: 0 auto;
  height: 12.5rem;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.data-table {
  width: 100%;
}
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
