<template>
  <div>
    <el-form
      label-position="top"
      :model="broadcastForm"
      :rules="broadcastRules"
      ref="broadcastRules"
    >
      <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="8">
          <el-form-item prop="broadcastChannel" label="Broadcast Channel">
            <el-input
              autocomplete="off"
              placeholder="Broadcast Channel"
              type="text"
              v-model="broadcastForm.broadcastChannel"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="broadcastDateTime" label="Broadcast Date & Time">
            <el-date-picker
              value-format="timestamp"
              v-model="broadcastForm.broadcastDateTime"
              type="datetime"
              placeholder="Date and time"
              format="yyyy/MM/dd HH:mm"
              :picker-options="{
                format: 'hh:mm'
              }"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <action-buttons submit cancel @submit="updateBroadcast()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";

export default {
  name: "BroadcastDetails",
  components: {
    ActionButtons
  },
  props: {
    match: Object,
    cancelForm: Function
  },
  data() {
    const broadcastChannelValidator = (_, value, callback) => {
      if (this.broadcastForm.broadcastDateTime && !this.broadcastForm.broadcastChannel) {
        return callback(new Error("Field is required"));
      }
      return callback();
    };

    const broadcastDateTimeValidator = (_, value, callback) => {
      if (this.broadcastForm.broadcastChannel && !this.broadcastForm.broadcastDateTime) {
        return callback(new Error("Field is required"));
      }
      return callback();
    };

    return {
      broadcastForm: {
        broadcastChannel: undefined,
        broadcastDateTime: undefined
      },
      broadcastRules: {
        broadcastChannel: {
          trigger: "blur",
          validator: broadcastChannelValidator
        },
        broadcastDateTime: {
          trigger: "blur",
          validator: broadcastDateTimeValidator
        }
      }
    };
  },
  mounted() {
    if (this.match.meta && this.match.meta.broadcastChannel)
      this.broadcastForm.broadcastChannel = this.match.meta.broadcastChannel;
    if (this.match.meta && this.match.meta.broadcastDateTime)
      this.broadcastForm.broadcastDateTime = this.match.meta.broadcastDateTime;
  },
  methods: {
    updateBroadcast() {
      this.$refs.broadcastRules.validate(valid => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const url = `/nrl/api/v1/admin/matches/${this.match._id}`;
          if (!this.broadcastForm.broadcastChannel || !this.broadcastForm.broadcastChannel.trim())
            this.broadcastForm.broadcastChannel = null;
          if (!this.broadcastForm.broadcastDateTime) this.broadcastForm.broadcastDateTime = null;
          this.$http
            .put(url, { meta: this.broadcastForm })
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
        return true;
      });
    }
  }
};
</script>
