<template>
  <div>
    <el-form ref="form" :model="additionalQuestion" :rules="rules" label-position="top">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="competitionId" label="Competition">
            <el-select
              v-model="additionalQuestion.competitionId"
              placeholder="Competition"
              filterable
            >
              <el-option
                v-for="comp in availableCompetitions"
                :label="comp.name"
                :key="comp._id"
                :value="comp._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="additionalQuestion.type" placeholder="E.g: Input" filterable>
              <el-option
                v-for="e in additionalQTypes"
                :label="e.name"
                :key="e.type"
                :value="e.type"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="fieldName" label="Report Column Name">
            <el-input
              v-model="additionalQuestion.fieldName"
              placeholder="E.g: Application Reason"
            />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item prop="question" label="Question">
            <el-input
              type="textarea"
              v-model="additionalQuestion.question"
              maxlength="40"
              placeholder="E.g: Country / Area of Birth"
              :rows="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="13" v-if="[2, 4].includes(additionalQuestion.type)">
          <el-form-item prop="meta.options" label="Options">
            <div v-for="(option, idx) in additionalQuestion.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="additionalQuestion.meta.options.splice(idx, 1)"
                ></el-button>
              </div>
            </div>
            <div>
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(additionalQuestion.meta.options)"
                >Add Option</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="5">
          <el-form-item prop="required" label="Required">
            <el-select v-model="additionalQuestion.required" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="additionalQuestion.isActive" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="add">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      :data="regiProduct.competitionAdditionalQuestions"
      :table-props="{ border: true }"
      layout="table"
      class="data-table mb-2"
    >
      <el-table-column label="Competition" width="180">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.competitionId"
            placeholder="Competition"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option
              v-for="e in availableCompetitions"
              :label="e.name"
              :key="e._id"
              :value="e._id"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Type" width="180">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option
              v-for="e in additionalQTypes"
              :label="e.name"
              :key="e.type"
              :value="e.type"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Report Column Name" width="200">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.fieldName"
            placeholder="E.g: Application Reason"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Question" width="500">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.question"
            maxlength="40"
            placeholder="E.g: Country / Area of Birth"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Options" width="300">
        <template v-slot="scope">
          <div v-if="scope.row.type === 2 || scope.row.type === 4">
            <div v-for="(option, idx) in scope.row.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                  :disabled="!editingRows[scope.$index]"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="scope.row.meta.options.splice(idx, 1)"
                  v-if="editingRows[scope.$index]"
                />
              </div>
            </div>
            <div v-if="editingRows[scope.$index]">
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(scope.row.meta.options)"
                >Add Option</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Required" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.required"
            placeholder="Required"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Active" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.isActive"
            placeholder="Active"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template v-slot="scope">
          <el-button class="px-1" @click="edit(scope.$index)">
            {{ editingRows[scope.$index] ? "Save" : "Edit" }}
          </el-button>
          <el-button class="px-2" @click="remove(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, genders, additionalQTypes, memberTypes } from "@/utils/constants";

const initAdditionalQuestion = () =>
  _.cloneDeep({
    type: undefined,
    question: undefined,
    fieldName: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    gender: undefined,
    memberType: undefined,
    required: undefined,
    isActive: true,
    meta: {
      options: undefined
    }
  });

const addNewOption = options => {
  options.push({ type: "string", value: "" });
};

export default {
  props: ["regiProduct"],
  data() {
    const defaultRequiredRule = { required: true, trigger: "blur", message: errormsg.input_option };
    const checkOptions = (rule, value, callback) => {
      if (!value || value.length === 0) return callback(new Error("Options is required"));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === "") return callback(new Error("Option value is required."));
      }
      return callback();
    };
    return {
      competitions: [],
      additionalQuestion: initAdditionalQuestion(),
      additionalQTypes,
      genders,
      rules: {
        type: defaultRequiredRule,
        fieldName: [
          defaultRequiredRule,
          {
            min: 0,
            max: 25,
            message: "Length must be less than 25 chars",
            trigger: "blur"
          }
        ],
        competitionId: defaultRequiredRule,
        question: defaultRequiredRule,
        memberType: { required: true, trigger: "blur", message: errormsg.input_option },
        gender: { required: true, trigger: "blur", message: errormsg.input_option },
        ageFrom: defaultRequiredRule,
        ageTo: defaultRequiredRule,
        required: defaultRequiredRule,
        isActive: defaultRequiredRule,
        "meta.options": { trigger: "blur", validator: checkOptions }
      },
      editingRows: []
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then(response => {
        this.competitions = response.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("root/loading", false);
      });
  },
  computed: {
    memberTypes() {
      const { national_id } = this.$store.getters["user/activeRole"];
      return _.filter(memberTypes, t => t.tfa === (national_id === 31));
    },
    availableCompetitions() {
      return this.competitions.filter(c =>
        this.regiProduct.competitionProducts.map(p => p.competitionId).includes(c._id)
      );
    }
  },
  methods: {
    isAgeValid(object) {
      if (object.ageTo < object.ageFrom) {
        this.$customError(errormsg.gte_age);
        return false;
      }
      return true;
    },
    isColumnNameValid(questions, question) {
      if (_.find(questions, q => q.fieldName === question.fieldName)) {
        this.$customError("Report column name already exists");
        return false;
      }
      return true;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (!valid) return;

        // Check if age is valid
        if (!this.isAgeValid(this.additionalQuestion)) return;

        // Check if report column name exists
        if (
          !this.isColumnNameValid(
            this.regiProduct.competitionAdditionalQuestions,
            this.additionalQuestion
          )
        )
          return;

        // Trim
        this.additionalQuestion.fieldName = _.trim(this.additionalQuestion.fieldName);

        // Event Edit
        this.$store.commit("loading", true);
        this.$http
          .post(
            "/nrl/api/v1/admin/registration-products/competition-additional-questions",
            this.additionalQuestion
          )
          .then(res => {
            this.additionalQuestion._id = res.data.data._id;
            this.regiProduct.competitionAdditionalQuestions.push(this.additionalQuestion);
            this.additionalQuestion = initAdditionalQuestion();
            this.$customSuccess("Successful");
            this.$store.commit("loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("loading", false);
          });
      });
    },
    edit(rowIndex) {
      if (this.editingRows[rowIndex]) {
        // Save row
        const additionalQuestion = this.regiProduct.competitionAdditionalQuestions[rowIndex];
        additionalQuestion.fieldName = _.trim(additionalQuestion.fieldName);

        // Check if age is valid
        if (!this.isAgeValid(additionalQuestion)) return false;

        // Check if report column name exists
        const checkQs = _.cloneDeep(this.regiProduct.competitionAdditionalQuestions);
        _.pullAt(checkQs, [rowIndex]);
        if (!this.isColumnNameValid(checkQs, additionalQuestion)) return false;

        this.$store.commit("loading", true);
        this.$http
          .put(
            `/nrl/api/v1/admin/registration-products/competition-additional-questions/${additionalQuestion._id}`,
            additionalQuestion
          )
          .then(() => {
            this.$set(this.editingRows, rowIndex, false);
            this.$customSuccess("Successful");
            this.$store.commit("loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("loading", false);
          });
      } else this.$set(this.editingRows, rowIndex, true);
      return true;
    },
    remove(rowIndex) {
      this.$confirm("Are you sure you want to remove this additional question?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        // Event Edit
        const additionalQuestion = this.regiProduct.competitionAdditionalQuestions[rowIndex];
        this.$store.commit("loading", true);
        this.$http
          .delete(
            `/nrl/api/v1/admin/registration-products/competition-additional-questions/${additionalQuestion._id}`
          )
          .then(() => {
            this.regiProduct.competitionAdditionalQuestions.splice(rowIndex, 1);
            this.$customSuccess("Successful");
            this.$store.commit("loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("loading", false);
          });
      });
    },
    addOption(options) {
      addNewOption(options);
    }
  },
  watch: {
    // eslint-disable-next-line
    "additionalQuestion.type": function(val) {
      if (val === 2 || val === 4) {
        this.additionalQuestion.meta.options = [];
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
      } else this.additionalQuestion.meta.options = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
</style>
