import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import jwtDecode from "jwt-decode";
import matchscores from "./stores/match-scores";
import matchlist from "./stores/match-list";
import members from "./stores/members";
import clearancePermit from "./stores/clearance-permit";
import entities from "./stores/entities";
import competitions from "./stores/competitions";
import ladders from "./stores/ladders";
import users from "./stores/users";
import duplicateMembers from "./stores/duplicate-members";
import eighteenmrp from "./stores/eighteenmrp";
import registrations from "./stores/registrations";
import programs from "./stores/programs";
import matchGrid from "./stores/match-grid";
import { update } from "lodash";
import { roles } from "./utils/constants";

Vue.use(Vuex);

const root = {
  namespaced: true,
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    msUrl: process.env.VUE_APP_MICROSERVICE_URL,
    graphqlAuthApiUrl: process.env.VUE_APP_GRAPHQL_AUTH_API_URL,
    graphqlApiUrl: process.env.VUE_APP_GRAPHQL_API_URL,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    emailNotificationUrl: process.env.VUE_APP_EMAIL_NOTIFICATION_URI,
    rootLoading: false,
    rootLoadingMessage: "",
    maintenanceBegin: parseInt(process.env.VUE_APP_MAINT_BEGIN), //1637233200000,
    maintenanceEnd: parseInt(process.env.VUE_APP_MAINT_END), //1637278200000,
    maintenanceCode: "snapcrackle"
  },
  mutations: {
    loading(state, update) {
      if (typeof update === "boolean") {
        state.rootLoading = update;
        if (update === false) state.rootLoadingMessage = "";
      } else {
        state.rootLoading = true;
        state.rootLoadingMessage = update;
      }
    }
  },
  actions: {},
  getters: {
    rootLoading(state) {
      return state.rootLoading;
    },
    rootLoadingMessage(state) {
      return state.rootLoadingMessage;
    },
    apiUrl(state) {
      return state.apiUrl;
    },
    msUrl(state) {
      return state.msUrl;
    },
    graphqlApiUrl(state) {
      return state.graphqlApiUrl;
    },
    graphqlAuthApiUrl(state) {
      return state.graphqlAuthApiUrl;
    },
    redirectUri(state) {
      return state.redirectUri;
    },
    emailNotificationUrl(state) {
      return state.emailNotificationUrl;
    },

    maintenanceBegin(state) {
      return state.maintenanceBegin;
    },
    maintenanceEnd(state) {
      return state.maintenanceEnd;
    },
    maintenanceCode(state) {
      return state.maintenanceCode;
    }
  }
};

const app = {
  namespaced: true,
  state: {
    appLoading: false,
    previousPath: "/"
  },
  mutations: {
    loading(state, update) {
      state.appLoading = update;
    },
    setPath(state, update) {
      state.previousPath = update;
    }
  },
  getters: {
    appLoading(state) {
      return state.appLoading;
    },
    getPreviousPath(state) {
      return state.previousPath;
    }
  }
};

const user = {
  namespaced: true,
  state: {
    redirectUriPath: "/",
    ignoreResponsiveWarning: false,
    authenticated: false,
    activeRole: false,
    tokens: {},
    profile: {},
    maintenanceCleared: false
  },
  mutations: {
    setRedirectUriPath(state, update) {
      state.redirectUriPath = update;
    },

    DEBUGGING(state, update) {
      state.maintenanceCleared = update;
    },
    ignoreWarning(state, update) {
      state.ignoreResponsiveWarning = update;
    },

    login(state, tokens) {
      const updateuser = jwtDecode(tokens.id_token);
      state.profile = updateuser;
      state.tokens = tokens;
      state.authenticated = true;

      if (updateuser.roles && updateuser.roles.length > 0) {
        // check if current active role is available
        const filteredByCurrent = updateuser.roles.filter(
          role => role._id === state.activeRole._id
        );
        if (filteredByCurrent.length > 0) {
          state.activeRole = filteredByCurrent[0];
        } else {
          state.activeRole = updateuser.roles[0];
        }
      }

      // state.activeRole =
      //   updateuser.roles && updateuser.roles.length > 0 ? updateuser.roles[0] : false;
    },
    logout(state) {
      state.ignoreResponsiveWarning = false;
      state.authenticated = false;
      state.tokens = {};
      state.profile = {};
      state.activeRole = false;
    },
    switchRole(state, role) {
      state.activeRole = role;
    },
    updateRoles(state, roles) {
      state.profile.roles = roles;
    },
    updateVerification(state, status) {
      state.profile.verified = status;
    },
    updateIdToken(state, update) {
      state.tokens.id_token = update;
    },
    updateIdTokenNoRoles(state, update) {
      state.tokens.id_token_no_roles = update;
    },
    updateGraphqlToken(state, update) {
      state.tokens.graphql_token = update;
    },
    updateRealmToken(state, update) {
      state.tokens.realm_token = update;
    }
  },
  actions: {},
  getters: {
    profile(state) {
      return state.profile;
    },
    idToken(state) {
      return state.tokens.id_token;
    },
    idTokenNoRoles(state) {
      return state.tokens.id_token_no_roles;
    },
    graphqlToken(state) {
      return state.tokens.graphql_token;
    },
    realmToken(state) {
      return state.tokens.realm_token;
    },
    redirectUriPath(state) {
      return state.redirectUriPath;
    },
    maintenanceCleared(state) {
      return state.maintenanceCleared;
    },
    ignoreResponsiveWarning(state) {
      return state.ignoreResponsiveWarning;
    },
    isAuthenticated(state) {
      return state.authenticated;
    },
    userid(state) {
      return state.profile._id;
    },
    isVerified(state) {
      return state.profile.verified;
    },
    email(state) {
      return state.profile.email;
    },
    expiry(state) {
      return state.tokens.expires_in;
    },
    accessToken(state) {
      return state.tokens.access_token;
    },
    bearerToken(state) {
      const token = state.tokens.access_token;
      const userId = state.profile._id;
      const encodedString = btoa(`${userId}:${token}`);
      return `Bearer ${encodedString}`;
    },
    refreshToken(state) {
      return state.tokens.refresh_token;
    },
    userName(state) {
      return `${state.profile.firstName} ${state.profile.lastName}`;
    },
    activeRole(state) {
      return state.activeRole;
    },
    activeRoleId(state) {
      return state.activeRole._id;
    },
    roles(state) {
      return state.profile.roles;
    },
    uploadHeaders(state) {
      const { profile, tokens, activeRole } = state;
      const authheader = `${profile._id}:${tokens.access_token}`;
      const encodedString = btoa(authheader);
      const Authorization = `Bearer ${encodedString}`;
      return { Authorization, "active-role": activeRole._id };
    },
    isSuperAdmin(state) {
      return (
        state.activeRole.type === roles.superAdmin &&
        (state.activeRole.entity._id === 32 || state.activeRole.entity._id === 31)
      );
    }
  }
};

const vuexLocal = new VuexPersistence({
  key: "nrl-user",
  storage: window.localStorage,
  modules: ["user"]
});

const store = new Vuex.Store({
  modules: {
    root,
    user,
    app,
    matchscores,
    matchlist,
    members,
    duplicateMembers,
    eighteenmrp,
    clearancePermit,
    entities,
    competitions,
    ladders,
    users,
    registrations,
    programs,
    matchGrid
  },
  plugins: [vuexLocal.plugin]
});

export default store;
