<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="State Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img
              v-if="currentLogo"
              :src="currentLogo"
              @error="displayPlaceholder"
              alt=""
              class="avatar"
            />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component
              v-if="this.$route.params.type !== 'insert'"
              ref="upload"
              :drop="true"
              v-model="files"
              :extensions="extensions"
              :accept="accept"
              :post-action="uploadURL"
              :headers="uploadHeaders"
              :data="entityData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
              class="el-button el-button--default upload"
            >
              Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form
              label-position="top"
              :model="state"
              :rules="rules"
              ref="state"
              class="login-form"
            >
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="national" label="National">
                    <el-select
                      v-model="national"
                      placeholder="Select a national"
                      filterable
                      :disabled="type === 'update'"
                    >
                      <el-option
                        v-for="item in nationals"
                        :label="item.name"
                        :key="item._id"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="stateName"
                      placeholder="Enter a name"
                      :disabled="clearance !== roles.superAdmin"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abbreviation" label="Abbreviation">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.meta.abbreviation"
                      placeholder="Enter an abbreviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.facebook" label="Facebook Link">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.meta.facebook"
                      placeholder=""
                    >
                      <template slot="prepend">https://facebook.com/</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.name" label="Contact Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.contact.name"
                      placeholder="Enter a contact name"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.number" label="Contact Number">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.contact.number"
                      placeholder="Enter a contact number"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.email" label="Contact Email">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.contact.email"
                      placeholder="Enter a contact email"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="type" label="Entity Type">
                    <el-select
                      :disabled="
                        this.$route.params.type !== 'insert' ||
                          this.$store.getters['user/activeRole'].national_id === 31
                      "
                      v-model="state.type"
                      placeholder="Select an entity type"
                      filterable
                    >
                      <el-option
                        v-for="item in entityType"
                        :label="item.name"
                        :key="item.name"
                        :value="item.type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="isActive" label="Active">
                    <el-select v-model="state.isActive">
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.gstRegistered" label="GST Registered">
                    <el-select
                      v-model="state.meta.gstRegistered"
                      :disabled="![roles.stateAdmin, roles.superAdmin].includes(clearance)"
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.website" label="Website">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="state.meta.website"
                      placeholder="Enter a website"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.abn" label="ABN">
                    <el-select
                      v-model="state.meta.abn"
                      :disabled="![roles.stateAdmin, roles.superAdmin].includes(clearance)"
                      filterable
                      remote
                      placeholder="Search by name"
                      :loading="abnLoading"
                      :remote-method="searchAbn"
                    >
                      <el-option
                        v-for="item in abnOptions"
                        :key="item.value"
                        :label="`${item.label} (${item.value})`"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
            </el-form>
            <action-buttons
              submit
              :cancel="cancelable"
              @submit="submitForm('state')"
              @cancel="cancelForm()"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Clearance Auto Approvals"
        v-if="
          clearance === roles.stateAdmin &&
            this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="state" key="clearance" type="clearance" />
      </el-tab-pane>
      <el-tab-pane
        label="Permit Auto Approvals"
        v-if="
          clearance === roles.stateAdmin &&
            this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="state" key="permit" type="permit" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, entityType, roles } from "../../utils/constants";

import { validateEmail, validatePhoneNumber } from "../../utils/utilities";

import AutoApprovals from "../auto-approvals/AutoApprovals.vue";

export default {
  name: "StateForm",
  props: {},
  components: {
    ActionButtons,
    AutoApprovals
  },
  data() {
    const validateNational = (rule, value, callback) => {
      const { state } = this;
      if (!state || !state.orgtree.national._id || state.orgtree.national._id === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateEntityType = (rule, value, callback) => {
      const { type } = this.state;
      if (!type) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateContactEmail = (rule, value, callback) => {
      const { contact } = this.state;
      if (!contact || !contact.email || !validateEmail(contact.email)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };

    const validateContactNumber = (rule, value, callback) => {
      const { contact } = this.state;
      if (!contact || !contact.number || !validatePhoneNumber(contact.number)) {
        return callback(new Error(errormsg.phone));
      }
      return callback();
    };

    return {
      type: "insert",
      nameUpdated: false,
      nationals: [],
      files: [],
      abnOptions: [],
      abnLoading: false,
      state: {
        name: "",
        type: "club-league",
        orgtree: {
          national: {
            _id: "",
            name: ""
          }
        },
        contact: {
          name: "",
          number: "",
          email: ""
        },
        meta: {
          abbreviation: "",
          avatar: "",
          facebook: "",
          gstRegistered: false,
          abn: "",
          website: ""
        }
      },
      rules: {
        national: {
          type: "object",
          required: true,
          validator: validateNational,
          message: errormsg.select_option,
          trigger: "blur"
        },
        name: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        type: {
          required: true,
          message: errormsg.select_option,
          validator: validateEntityType,
          trigger: "blur"
        },
        "meta.abbreviation": {
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.abn": {
          message: errormsg.select_option,
          trigger: "blur"
        },
        "contact.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "contact.number": {
          required: true,
          message: errormsg.phone,
          trigger: "blur",
          validator: validateContactNumber
        },
        "contact.email": {
          required: true,
          message: errormsg.email,
          trigger: "blur",
          validator: validateContactEmail
        },
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.website": {
          validator: (rule, val, callback) => {
            const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            const regex = new RegExp(expression);

            if (val.length && !val.match(regex)) {
              return callback(new Error(errormsg.invalidUrl));
            }
            return callback();
          },
          trigger: "blur"
        }
      },
      uploadURL,
      entityType,
      roles,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png"
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, state, id } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");
    // Load State and Nationals
    if (id && id !== "" && id !== "add" && !state) {
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/states/${id}`),
        this.$http.get("/nrl/api/v1/admin/nationals/")
      ])
        .then(([stateRes, nationalRes]) => {
          this.state = Object.assign({}, this.state, stateRes.data.data);
          if (this.state.meta.gstRegistered !== true)
            this.state.meta = Object.assign({}, this.state.meta, { gstRegistered: false });

          this.nationals = nationalRes.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      // Load Nationals
      this.$http
        .get("/nrl/api/v1/admin/nationals/")
        .then(response => {
          this.nationals = response.data.data;
          // Include State Data if update and passed through props
          if (state) this.state = Object.assign({}, this.state, state);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.state.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.state.meta;
            const { _id } = this.state;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/states/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const currentState = this.state;
          if (this.type === "update") {
            this.$store.commit("root/loading", true);
            const update = {
              ...currentState,
              orgtree: currentState.orgtree
            };

            if (this.nameUpdated) update.name = currentState.name;
            const url = `/nrl/api/v1/admin/states/${currentState._id}`;

            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customSuccess();
                // update state in store
                this.$store.commit("entities/updateDataMember", { entity: "states", data: update });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const insert = currentState;
            const url = "/nrl/api/v1/admin/states/";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "states"
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "states"
      });
    },
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then(response => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map(o => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    }
  },
  computed: {
    cancelable() {
      return this.$route.matched.filter(route => route.path === "/my").length === 0;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    entityData() {
      return {
        entity_type: "state",
        entity_id: this.state._id
      };
    },
    currentLogo() {
      return this.state.meta && this.state.meta.avatar ? this.state.meta.avatar : false;
    },
    stateName: {
      get() {
        return this.state.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.state.name = value;
      }
    },
    national: {
      get() {
        return this.state.orgtree.national._id;
      },
      set(nationalid) {
        const foundNational = this.nationals.find(obj => obj._id === nationalid);
        if (foundNational) {
          const orgtree = {};
          orgtree.national = {
            _id: foundNational._id,
            name: foundNational.name
          };
          this.state.orgtree = orgtree;
        }
      }
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    }
  }
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.entity-card {
  height: 36rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}
</style>
