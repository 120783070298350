<template>
  <div class="tw-flex tw-justify-center tw-mt-24">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-64 tw-p-10 tw-bg-white tw-rounded tw-shadow"
    >
      <img src="~@/assets/shield_black.svg" />
      <h3 class="tw-pt-10 tw-text-lg tw-font-bold tw-text-center">{{ msg }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadReport",
  created() {
    const { url } = this.$route.query;
    if (!url) {
      this.msg = "There has been an error";
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = "download";
      link.click();
    }
  },
  props: {},
  data() {
    return {
      msg: "Downloading..."
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.download-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .download-card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }
  @media only screen and (max-width: $sm) {
    .download-card {
      width: 80%;
    }
  }
}
</style>
