<template>
  <div>
    <el-form ref="form" :model="additionalQuestion" :rules="rules" label-position="top">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="additionalQuestion.type" placeholder="E.g: Input" filterable>
              <el-option
                v-for="e in additionalQTypes"
                :label="e.name"
                :key="e.type"
                :value="e.type"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="fieldName" label="Report Column Name">
            <el-input
              v-model="additionalQuestion.fieldName"
              placeholder="E.g: Application Reason"
            />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item prop="question" label="Question">
            <el-input
              type="textarea"
              v-model="additionalQuestion.question"
              maxlength="40"
              placeholder="E.g: Country / Area of Birth"
              :rows="3"
            />
          </el-form-item>
        </el-col>
        <el-col :span="13" v-if="[2, 4].includes(additionalQuestion.type)">
          <el-form-item prop="meta.options" label="Options">
            <div v-for="(option, idx) in additionalQuestion.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  maxlength="40"
                  :placeholder="'Option ' + (idx + 1)"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="additionalQuestion.meta.options.splice(idx, 1)"
                ></el-button>
              </div>
            </div>
            <div>
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(additionalQuestion.meta.options)"
                >Add Option</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="9">
          <el-form-item prop="memberType" label="Member Type">
            <el-select
              v-model="additionalQuestion.memberType"
              placeholder="E.g: Volunteer"
              filterable
            >
              <el-option v-for="e in memberTypes" :label="e.name" :key="e.type" :value="e.type" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="additionalQuestion.gender" placeholder="E.g: Female">
              <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="ageFrom" label="Age From">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageFrom"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="ageTo" label="Age To">
            <el-input-number
              :min="1"
              :max="99"
              v-model="additionalQuestion.ageTo"
              controls-position="right"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="required" label="Required">
            <el-select v-model="additionalQuestion.required" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="additionalQuestion.isActive" placeholder="Yes / No">
              <el-option :value="true" label="Yes"></el-option>
              <el-option :value="false" label="No"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div
        v-if="clearance == roles.superAdmin"
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="meta.entityType" label="Entity Type">
            <el-select v-model="additionalQuestion.meta.entityType" placeholder="E.g. State">
              <el-option value="state" label="State"></el-option>
              <el-option value="region" label="Region"></el-option>
              <el-option value="association" label="Association"></el-option>
              <el-option value="club" label="Club"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18" v-if="additionalQuestion.meta.entityType">
          <el-form-item prop="meta.entityIds" label="Entities">
            <el-select
              multiple
              v-model="additionalQuestion.meta.entityIds"
              :remote="true"
              :remote-method="filter"
              placeholder="Enter name to start searching"
              filterable
            >
              <el-option
                v-for="item in entities"
                :label="item.name"
                :key="item._id"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="add">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      :data="regiProduct.additionalQuestions"
      :table-props="{ border: true }"
      layout="table"
      class="data-table mb-2"
    >
      <el-table-column label="Type" width="180">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option
              v-for="e in additionalQTypes"
              :label="e.name"
              :key="e.type"
              :value="e.type"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Report Column Name" width="200">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.fieldName"
            placeholder="E.g: Application Reason"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Question" width="500">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.question"
            maxlength="40"
            placeholder="E.g: Country / Area of Birth"
            :disabled="!editingRows[scope.$index]"
          />
        </template>
      </el-table-column>
      <el-table-column label="Options" width="300">
        <template v-slot="scope">
          <div v-if="scope.row.type === 2">
            <div v-for="(option, idx) in scope.row.meta.options" :key="idx">
              <div class="d-flex flex-row">
                <el-input
                  class="option"
                  type="text"
                  v-model="option.value"
                  :placeholder="'Option ' + (idx + 1)"
                  :disabled="!editingRows[scope.$index]"
                />
                <el-button
                  class="option-delete"
                  type="text"
                  icon="el-icon-delete"
                  @click="scope.row.meta.options.splice(idx, 1)"
                  v-if="editingRows[scope.$index]"
                />
              </div>
            </div>
            <div v-if="editingRows[scope.$index]">
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                @click="addOption(scope.row.meta.options)"
                >Add Option</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Member Type" width="300">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.memberType"
            placeholder="Member Type"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option v-for="e in memberTypes" :label="e.name" :key="e.type" :value="e.type" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.gender"
            placeholder="Gender"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option v-for="e in genders" :label="e" :key="e" :value="e" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageFrom"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="110">
        <template v-slot="scope">
          <el-input-number
            :min="1"
            :max="99"
            v-model="scope.row.ageTo"
            :disabled="!editingRows[scope.$index]"
            controls-position="right"
          />
        </template>
      </el-table-column>
      <el-table-column label="Required" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.required"
            placeholder="Required"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Active" width="120">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.isActive"
            placeholder="Active"
            :disabled="!editingRows[scope.$index]"
            filterable
          >
            <el-option :value="true" label="Yes"></el-option>
            <el-option :value="false" label="No"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Entity Type" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <el-select v-model="scope.row.meta.entityType" placeholder="" :disabled="true">
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Entity Ids" width="120" v-if="clearance == roles.superAdmin">
        <template v-slot="scope">
          <p v-if="scope.row.meta && scope.row.meta.entityIds">
            {{ scope.row.meta.entityIds.join(",") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template v-slot="scope">
          <el-button class="px-1" @click="edit(scope.$index)">
            {{ editingRows[scope.$index] ? "Save" : "Edit" }}
          </el-button>
          <el-button class="px-2" @click="remove(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg, genders, additionalQTypes, memberTypes, roles } from "@/utils/constants";

const initAdditionalQuestion = () =>
  _.cloneDeep({
    type: undefined,
    question: undefined,
    fieldName: undefined,
    ageFrom: undefined,
    ageTo: undefined,
    gender: undefined,
    memberType: undefined,
    required: undefined,
    isActive: true,
    meta: {
      options: undefined,
      entityType: undefined,
      entityIds: undefined
    }
  });

const addNewOption = options => {
  options.push({ type: "string", value: "" });
};

export default {
  props: ["regiProduct"],
  data() {
    const defaultRequiredRule = { required: true, trigger: "blur", message: errormsg.input_option };
    const checkOptions = (rule, value, callback) => {
      if (!value || value.length === 0) return callback(new Error("Options is required"));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === "") return callback(new Error("Option value is required."));
      }
      return callback();
    };
    return {
      memberTypes,
      entities: [],
      roles,
      additionalQuestion: initAdditionalQuestion(),
      additionalQTypes,
      genders,
      rules: {
        type: defaultRequiredRule,
        fieldName: [
          defaultRequiredRule,
          {
            min: 0,
            max: 25,
            message: "Length must be less than 25 chars",
            trigger: "blur"
          }
        ],
        question: defaultRequiredRule,
        memberType: { required: true, trigger: "blur", message: errormsg.input_option },
        gender: { required: true, trigger: "blur", message: errormsg.input_option },
        ageFrom: defaultRequiredRule,
        ageTo: defaultRequiredRule,
        required: defaultRequiredRule,
        isActive: defaultRequiredRule,
        "meta.options": { trigger: "blur", validator: checkOptions }
        // 'meta.entityType': { trigger: 'blur', required: false, },
        // 'meta.entityIds': { trigger: 'blur', required: false, }
      },
      editingRows: []
    };
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    }
  },
  methods: {
    filter: _.debounce(function hello(query) {
      if (query !== "") {
        this.$store.commit("root/loading", true);
        this.$http
          .get("/nrl/api/v1/admin/entity-search", {
            params: { entity: this.additionalQuestion.meta.entityType, name: query }
          })
          .then(res => {
            this.entities = res.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      }
    }, 500),
    isAgeValid(object) {
      if (object.ageTo < object.ageFrom) {
        this.$customError(errormsg.gte_age);
        return false;
      }
      return true;
    },
    isColumnNameValid(questions, question) {
      if (_.find(questions, q => q.fieldName === question.fieldName)) {
        this.$customError("Report column name already exists");
        return false;
      }
      return true;
    },
    add() {
      this.$refs.form.validate(valid => {
        if (!valid) return;

        // Check if age is valid
        if (!this.isAgeValid(this.additionalQuestion)) return;

        // Check if report column name exists
        if (!this.isColumnNameValid(this.regiProduct.additionalQuestions, this.additionalQuestion))
          return;

        // Trim
        this.additionalQuestion.fieldName = _.trim(this.additionalQuestion.fieldName);

        // Event Edit
        this.$store.commit("root/loading", true);
        this.$http
          .post(
            "/nrl/api/v1/admin/registration-products/additional-questions",
            this.additionalQuestion
          )
          .then(res => {
            this.additionalQuestion._id = res.data.data._id;
            this.regiProduct.additionalQuestions.push(this.additionalQuestion);
            this.additionalQuestion = initAdditionalQuestion();
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    edit(rowIndex) {
      if (this.editingRows[rowIndex]) {
        // Save row
        const additionalQuestion = this.regiProduct.additionalQuestions[rowIndex];
        additionalQuestion.fieldName = _.trim(additionalQuestion.fieldName);

        // Check if age is valid
        if (!this.isAgeValid(additionalQuestion)) return false;

        // Check if report column name exists
        const checkQs = _.cloneDeep(this.regiProduct.additionalQuestions);
        _.pullAt(checkQs, [rowIndex]);
        if (!this.isColumnNameValid(checkQs, additionalQuestion)) return false;

        this.$store.commit("root/loading", true);
        this.$http
          .put(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`,
            additionalQuestion
          )
          .then(() => {
            this.$set(this.editingRows, rowIndex, false);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      } else this.$set(this.editingRows, rowIndex, true);
      return true;
    },
    remove(rowIndex) {
      this.$confirm("Are you sure you want to remove this additional question?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        // Event Edit
        const additionalQuestion = this.regiProduct.additionalQuestions[rowIndex];
        this.$store.commit("root/loading", true);
        this.$http
          .delete(
            `/nrl/api/v1/admin/registration-products/additional-questions/${additionalQuestion._id}`
          )
          .then(() => {
            this.regiProduct.additionalQuestions.splice(rowIndex, 1);
            this.$customSuccess("Successful");
            this.$store.commit("root/loading", false);
          })
          .catch(error => {
            this.$customError(error.response.data.message);
            this.$store.commit("root/loading", false);
          });
      });
    },
    addOption(options) {
      addNewOption(options);
    }
  },
  watch: {
    // eslint-disable-next-line
    "additionalQuestion.type": function(val) {
      if (val === 2 || val === 4) {
        this.additionalQuestion.meta.options = [];
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
        addNewOption(this.additionalQuestion.meta.options);
      } else this.additionalQuestion.meta.options = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}
</style>
