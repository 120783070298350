<template>
  <div>
    <el-tabs id="match-details" type="card">
      <el-tab-pane label="Match Details">
        <el-form label-position="top" :model="match" :rules="matchRules" ref="matchRules">
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="10">
              <el-form-item prop="competition" label="Competition">
                <el-select
                  v-model="competitionName"
                  placeholder="Select a competition"
                  value-key="_id"
                  filterable
                >
                  <el-option
                    v-for="item in allCompetitions"
                    :key="item._id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="roundType" label="Round Type">
                <el-select v-model="roundType" placeholder="Select a round type">
                  <el-option
                    v-for="item in ['Regular', 'Final']"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="tba">
              <el-form-item prop="tba" class="isTba">
                <el-checkbox-button label="TBA" v-model="tba" @change="changeTba">
                  TBA
                </el-checkbox-button>
                <el-checkbox-button label="BYE" v-model="bye" @change="changeBye">
                  BYE
                </el-checkbox-button>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="8">
              <el-form-item prop="roundDisplayName" label="Round Display Name">
                <el-input
                  autocomplete="off"
                  placeholder="Round display name"
                  v-model="roundDisplayName"
                  :disabled="roundType !== 'Final'"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="roundNumber" label="Round Number">
                <el-input
                  autocomplete="off"
                  placeholder="Round number"
                  type="number"
                  v-model.number="roundNumber"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="venue" label="Venue">
                <el-select
                  v-model="matchVenueName"
                  :remote="true"
                  :remote-method="filter"
                  placeholder="Search venue"
                  filterable
                >
                  <el-option
                    v-for="item in allVenues"
                    :label="`${item.name}${
                      item.address && item.address.suburb ? ' - ' + item.address.suburb : ''
                    }`"
                    :key="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="meta.fieldNo" label="Field No">
                <el-input
                  maxlength="10"
                  autocomplete="off"
                  placeholder="Field No"
                  v-model="match.meta.fieldNo"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="6">
              <el-form-item prop="homeTeam" label="Home Team">
                <el-select
                  :disabled="
                    bye &&
                    awayTeamName !== undefined &&
                    awayTeamName !== null &&
                    awayTeamName !== ''
                  "
                  v-model="homeTeamName"
                  filterable
                  clearable
                  placeholder="Home team"
                >
                  <el-option
                    v-for="item in allTeams"
                    :key="item.name"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="awayTeam" label="Away Team">
                <el-select
                  :disabled="
                    bye &&
                    homeTeamName !== undefined &&
                    homeTeamName !== null &&
                    homeTeamName !== ''
                  "
                  v-model="awayTeamName"
                  filterable
                  clearable
                  placeholder="Away team"
                >
                  <el-option
                    v-for="item in allTeams"
                    :key="item.name"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="dateTime" label="Date and Time">
                <el-date-picker
                  value-format="timestamp"
                  v-model="dateTime"
                  type="datetime"
                  placeholder="Date and time"
                  format="yyyy/MM/dd HH:mm"
                  :picker-options="{
                    format: 'hh:mm',
                  }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="status">
              <el-form-item prop="status" label="Match Status">
                <el-select v-model="matchStatus" placeholder="Status">
                  <el-option
                    v-for="item in statusTypes"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div
            class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
            v-if="matchStatus === 'forfeit'"
          >
            <el-col :span="6">
              <el-form-item prop="forfeitingTeam" label="Forfeiting Team">
                <el-select v-model="forfeitingTeamName" placeholder="Forfeiting Team">
                  <el-option v-for="item in matchTeams" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>

          <div
            class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
            v-if="allowScoring"
          >
            <el-col :span="6">
              <el-form-item label="Home Score">
                <el-input v-model="homeScore" :disabled="!overrideScores" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Away Score">
                <el-input v-model="awayScore" :disabled="!overrideScores" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="***Manually overide scores">
                <el-button @click="overrideScores = true" type="success">OVERRIDE</el-button>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
        <button
          v-if="!isTfa"
          @click="interchangeReport"
          style="
            color: #fff;
            background-color: #f56c6c;
            transition: 0.1s;
            font-weight: 500;
            -moz-user-select: none;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4px;
            border: solid 3px #f56c6c;
          "
        >
          Interchange Report
        </button>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-button type="success" @click="updateMatch()">Save</el-button>
            <el-button type="default" @click="cancelForm()">Cancel</el-button>
          </el-col>
          <el-col :span="12" class="align-text-right">
            <el-button v-if="has(match, '_id')" type="danger" @click="deleteMatch()"
              >Delete</el-button
            >
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="currentMatchId" id="broadcastcastDetailsTab" label="Broadcast Details">
        <broadcast-details v-if="matchLoaded" :match="match" :cancelForm="cancelForm" />
      </el-tab-pane>
      <el-tab-pane
        v-if="currentMatchId && matchLoaded && !bye && !tba && match.elite && awardsEligible"
        id="awardVotingTab"
        label="Award Voting"
        name="12"
      >
        <button
          @click="notifyJudges"
          class="notify-judges"
          style="
            color: #fff;
            background-color: #f56c6c;
            transition: 0.1s;
            font-weight: 500;
            -moz-user-select: none;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4px;
            border: solid 3px #f56c6c;
          "
        >
          Notify Judges
        </button>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form label-position="top" :model="add" :rules="voterRules" ref="voterRules">
            <div class="tw-flex tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100">
              <el-col :span="18">
                <el-form-item prop="memberId" label="Voter Assignment">
                  <el-select v-model="add.memberId" filterable placeholder="Assign a voter">
                    <el-option
                      v-for="voter in availableVoters"
                      :key="voter._id"
                      :label="fullname(voter)"
                      :value="voter._id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </div>
          </el-form>
          <el-col :span="12" class="add-player-col">
            <el-button type="success" @click="handleAddVoter"> Add Voter </el-button>
          </el-col>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <data-tables
            v-if="newVoters.length > 0"
            :data="newVoters"
            :table-props="tableProps"
            :page-size="pageSize"
            @row-click="removeFromVoterList"
            id="voterlist"
            class="data-table"
          >
            <el-table-column label="Name">
              <template slot-scope="scope">
                {{ fullname(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column prop="votingOpen" label="Status">
              <template slot-scope="scope">
                {{ scope.row.votedOn ? "Votes Submitted" : "Pending" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="" label="Actions">
              <template>
                <i class="el-icon-delete remove-icon"></i>
              </template>
            </el-table-column>
          </data-tables>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-select v-model="match.votingOpen" placeholder="Enable Voting">
              <el-option key="open" label="Open" :value="true"> </el-option>
              <el-option key="closed" label="Closed" :value="false"> </el-option>
            </el-select>
          </el-col>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-button type="success" @click="submitVoterAssignment()">Save</el-button>
            <el-button type="default" @click="cancelForm()">Cancel</el-button>
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="currentMatchId && matchLoaded && !bye && !tba && isTfa"
        id="refAppointmentTab"
        label="Referee Appointments"
        name="11"
      >
        <assignments
          key="ref-appointments"
          type="referee"
          :matchid="currentMatchId"
          :competitionid="competitionId"
          :match="match"
          :teams="allTeams"
          :association="assocId"
        />
      </el-tab-pane>
      <el-tab-pane v-if="homeTeamId && currentMatchId" id="homeTeamListTab" label="(H) Team List">
        <team-list
          v-if="homeTeamId"
          :key="`${homeTeamId}-list`"
          :team="match.homeTeam"
          :matchid="currentMatchId"
          :teamid="homeTeamId"
          :isHomeTeam="true"
          :positions="availablePositions"
          :competitionid="competitionId"
        />
      </el-tab-pane>
      <el-tab-pane v-if="awayTeamId && currentMatchId" id="awayTeamListTab" label="(A) Team List">
        <team-list
          v-if="awayTeamId"
          :key="`${awayTeamId}-list`"
          :team="match.awayTeam"
          :matchid="currentMatchId"
          :teamid="awayTeamId"
          :isHomeTeam="false"
          :positions="availablePositions"
          :competitionid="competitionId"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="homeTeamId && currentMatchId && allowScoring"
        id="homeScoreTab"
        :label="homeScoreLabel"
      >
        <scoring
          :key="`${homeTeamId}-score`"
          :team="match.homeTeam"
          :matchid="currentMatchId"
          :teamid="homeTeamId"
          :isHomeTeam="true"
          :competitionId="competitionId"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="awayTeamId && currentMatchId && allowScoring"
        id="awayScoreTab"
        :label="awayScoreLabel"
      >
        <scoring
          :key="`${awayTeamId}-score`"
          :team="match.awayTeam"
          :matchid="currentMatchId"
          :teamid="awayTeamId"
          :isHomeTeam="false"
          :competitionId="competitionId"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="homeTeamId && currentMatchId"
        id="homeInterchangeTab"
        label="(H) Interchanges"
      >
        <movement
          :key="`${homeTeamId}-movement`"
          :team="match.homeTeam"
          :matchid="currentMatchId"
          :teamid="homeTeamId"
          :isHomeTeam="true"
          :competitionId="competitionId"
          @mostRecentMovements="mostRecentMovements"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="awayTeamId && currentMatchId"
        id="awayInterchangeTab"
        label="(A) Interchanges"
      >
        <movement
          :key="`${awayTeamId}-movement`"
          :team="match.awayTeam"
          :matchid="currentMatchId"
          :teamid="awayTeamId"
          :isHomeTeam="false"
          :competitionId="competitionId"
          @mostRecentMovements="mostRecentMovements"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="homeTeamId && currentMatchId && !bye && !tba"
        id="homeReportTab"
        label="Team Sheets"
      >
        <reports :matchid="currentMatchId" :teams="[match.homeTeam, match.awayTeam]" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { has, get } from "lodash";
import { createHelpers } from "vuex-map-fields";
import { errormsg, matchStatus, nationals } from "../../utils/constants";
// eslint-disable-next-line import/no-cycle
import matchStore from "../../stores/match";
import BroadcastDetails from "./BroadcastDetails.vue";
import TeamList from "./TeamList.vue";
import Scoring from "./Scoring.vue";
import Movement from "./Movements.vue";
import Reports from "./Reports.vue";
import Assignments from "./Assignments.vue";
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: "match/getField",
  mutationType: "match/updateField",
});

export default {
  name: "MatchForm",
  props: {},
  components: {
    BroadcastDetails,
    TeamList,
    Scoring,
    Movement,
    Reports,
    Assignments,
  },
  data() {
    const byeAndTbaValidator = (_, value, callback) => {
      if (this.bye && this.tba) {
        return callback(new Error("A match cannot be TBA and BYE"));
      }
      return callback();
    };
    const statusValidator = (_, value, callback) => {
      if (!this.matchStatus) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const dateTimeValidator = (_, value, callback) => {
      if (!parseInt(this.dateTime) || parseInt(this.dateTime) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const roundTypeValidator = (_, value, callback) => {
      if (!this.roundType) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const roundNumberValidator = (_, value, callback) => {
      if (!parseInt(this.roundNumber) || parseInt(this.roundNumber) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const roundDisplayNameValidator = (_, value, callback) => {
      if (this.roundType === "Final" && !this.roundDisplayName) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const venueValidator = (_, value, callback) => {
      if (!this.matchVenueName && !this.tba && !this.bye) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const fieldValidator = (_, value, callback) => {
      if (this.fieldNo && this.fieldNo.length > 10) {
        return callback(new Error(errormsg.char_length));
      }
      return callback();
    };
    const homeTeamValidator = (_, value, callback) => {
      if (!this.homeTeamId && !this.tba) {
        if (!this.bye) {
          return callback(new Error(errormsg.select_option));
        }
        if (this.bye && !this.awayTeamId) {
          return callback(new Error(errormsg.select_option));
        }
      }

      if (this.homeTeamId === this.awayTeamId && !this.tba && !this.bye) {
        return callback(new Error(errormsg.duplicate_team));
      }

      return callback();
    };
    const awayTeamValidator = (_, value, callback) => {
      if (!this.awayTeamId && !this.tba) {
        if (!this.bye) {
          return callback(new Error(errormsg.select_option));
        }
        if (this.bye && !this.homeTeamId) {
          return callback(new Error(errormsg.select_option));
        }
      }

      if (this.homeTeamId === this.awayTeamId && !this.tba && !this.bye) {
        return callback(new Error(errormsg.duplicate_team));
      }

      return callback();
    };

    const validateForfeitingTeam = (_, value, callback) => {
      const { forfeitingTeamName } = this;
      if (!forfeitingTeamName) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    return {
      add: {
        memberId: null,
        votedOn: false,
      },
      has,
      nationals,
      type: "insert",
      nameUpdated: false,
      matchLoaded: false,
      overrideScores: false,
      statusTypes: matchStatus,
      match: {
        competition: {
          _id: null,
          name: null,
        },
        orgtree: {
          association: { _id: null, name: null },
        },
        round: {
          type: null,
          number: null,
          displayName: "",
        },
        venue: {
          _id: null,
          name: null,
        },
        homeTeam: {
          _id: null,
          name: null,
        },
        awayTeam: {
          _id: null,
          name: null,
        },
        meta: {
          isTba: false,
          isBye: false,
          fieldNo: "",
        },
        status: null,
        dateTime: null,
      },
      availablePositions: [],
      allCompetitions: [],
      allTeams: [],
      allVenues: [],
      allMovements: {
        home: [],
        away: [],
      },
      newVoters: [],
      tableProps: {
        border: true,
      },
      pageSize: 100,
      voterRules: {
        memberId: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
      },
      matchRules: {
        competition: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        venue: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: venueValidator,
        },
        fieldNo: {
          required: false,
          trigger: "change",
          validator: fieldValidator,
        },
        roundType: {
          required: true,
          trigger: "change",
          validator: roundTypeValidator,
        },
        roundDisplayName: {
          required: true,
          trigger: "change",
          validator: roundDisplayNameValidator,
        },
        roundNumber: {
          required: true,
          trigger: "change",
          validator: roundNumberValidator,
        },
        homeTeam: {
          required: true,
          trigger: "change",
          validator: homeTeamValidator,
        },
        awayTeam: {
          required: true,
          trigger: "change",
          validator: awayTeamValidator,
        },
        dateTime: {
          required: true,
          message: "Please select a default start time",
          trigger: "change",
          validator: dateTimeValidator,
        },
        bye: {
          required: false,
          trigger: "change",
          validator: byeAndTbaValidator,
        },
        tba: {
          required: false,
          trigger: "change",
          validator: byeAndTbaValidator,
        },
        status: {
          required: false,
          trigger: "change",
          validator: statusValidator,
          message: errormsg.select_option,
        },
        forfeitingTeam: {
          required: true,
          trigger: "blur",
          validator: validateForfeitingTeam,
          message: errormsg.select_option,
        },
      },
    };
  },
  beforeCreate() {
    this.$store.registerModule("match", matchStore);
  },
  beforeDestroy() {
    this.$store.commit("match/resetState");
    this.$store.unregisterModule("match");
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, comp, match, id } = this.$route.params;

    this.type = type || (id !== "add" ? "update" : "insert");
    if (id && id !== "" && id !== "add" && !comp && !match) {
      Promise.all([
        this.$http.get("/nrl/api/v1/admin/competitions/"),
        this.$store.dispatch("match/getVoteStatus", id),
        this.$store.dispatch("match/getMatch", id),
      ])
        .then(([compRes]) => {
          const compResponse = compRes.data.data;
          this.allCompetitions = compResponse;
          this.match = this.$store.getters["match/match"];
          this.$http
            .get(`/nrl/api/v1/admin/competition/${this.competitionId}/award-voters`)
            .then((res) => {
              const { awardVoters } = res.data.data[0];
              this.$store.commit("match/setAwardTeamId", awardVoters._id);
            })
            .catch(() => {});
          this.matchLoaded = true;
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      if (match) {
        this.$store.commit("match/setMatch", match);
        this.match = this.$store.getters["match/match"];
        this.matchLoaded = true;
      }
      if (comp) {
        this.match.competition = comp;
      }
      if (comp) {
        this.$store.commit("match/setCompetition", comp);
      }
      let compid;
      if (comp) {
        compid = comp._id;
      } else if (match) {
        compid = match.competition._id;
      } else {
        compid = "";
      }

      Promise.all([
        this.$http.get("/nrl/api/v1/admin/competitions/"),
        this.$http.get(`/nrl/api/v1/admin/teams/competition/${compid}`),
        this.$http.get(`/nrl/api/v1/admin/competition/${compid}/award-voters`),
      ])
        .then(([compRes, teamsRes, awardRes]) => {
          this.allTeams = teamsRes.data.data;
          this.allCompetitions = compRes.data.data;
          const { awardVoters } = awardRes.data.data[0];
          this.$store.commit("match/setAwardTeamId", awardVoters._id);
        })
        .catch(() => {
          this.$customError();
        });

      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    async notifyJudges() {
      this.$store.commit("root/loading", true);
      const { assignedVoters } = this.match;
      const matchName = `${this.match.homeTeam.name} v ${this.match.awayTeam.name}`;
      try {
        await this.$http.post("/nrl/api/v1/admin/notify-judges", {
          assignedVoters,
          matchName,
          round: this.roundNumber,
        });
        this.$store.commit("root/loading", false);
        this.$customSuccess("Emails sent to assigned judges");
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError();
      }
    },
    async interchangeReport() {
      try {
        this.$store.commit("root/loading", true);
        const { playerMovements, competition, dateTime, awayTeam, homeTeam, _id, round } =
          this.match;
        const comp = {
          _id: competition._id,
          name: competition.name,
        };
        const away = {
          _id: awayTeam._id,
          name: awayTeam.name,
        };
        const home = {
          _id: homeTeam._id,
          name: homeTeam.name,
        };

        await this.$http.post("/nrl/api/v1/admin/interchange-report", {
          playerMovements,
          comp,
          away,
          home,
          matchId: _id,
          round,
          dateTime,
        });
        this.$store.commit("root/loading", false);
        this.$customSuccess("Interchange Report successfully generated");
      } catch (error) {
        this.$store.commit("root/loading", false);
        const errorMsg = error.response.data;
        this.$customError(errorMsg || "Unable to generate Interchange Report");
      }
    },
    fullname(p) {
      return p.profile && p.profile.firstName && p.profile.lastName
        ? `${p.profile.firstName} ${p.profile.lastName}`
        : `Member: ${p.memberId}`;
    },
    changeBye(val) {
      if (val === true) {
        if (this.awayTeamName && this.homeTeamName) {
          this.awayTeamName = { name: null, _id: null };
          this.homeTeamName = { name: null, _id: null };
        } else if (this.awayTeamName) {
          this.homeTeamName = { name: null, _id: null };
        } else if (this.homeTeamName) {
          this.awayTeamName = { name: null, _id: null };
        }
        this.tba = false;
      }
    },
    changeTba(val) {
      if (val === true) {
        this.bye = false;
      }
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allVenues = response.data.data;
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allvenues = [];
      }
    },
    updateMatch() {
      this.$refs.matchRules.validate((valid) => {
        if (valid) {
          if (this.type === "update") {
            // Update match
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/matches/${this.match._id}`;
            const update = this.$store.getters["match/matchForSubmit"];
            if (this.overrideScores) {
              update.scores.homeTeam = this.$store.getters["match/homeScore"];
              update.scores.awayTeam = this.$store.getters["match/awayScore"];
            }
            const { away, home } = this.allMovements;
            const movementCheck = [...away, ...home];
            const sortedMovements = movementCheck.sort((a, b) => {
              const movementAMin = a.min;
              const movementBMin = b.min;
              const movementASec = a.sec;
              const movementBSec = b.sec;
              if (movementAMin > movementBMin) return 1;
              if (movementAMin < movementBMin) return -1;

              if (movementAMin === movementBMin) {
                if (movementASec > movementBSec) return 1;
                if (movementASec < movementBSec) return -1;
              }

              return 0;
            });
            update.playerMovements = sortedMovements;
            this.$http
              .put(url, this.$store.getters["match/matchForSubmit"])
              .then(() => {
                this.$router.go(-1); // either parent component is match list or match-score bulk editing
                // this.$router.push({ name: 'matches.list', params: { currentCompetition: this.competitionId } });
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const url = "/nrl/api/v1/admin/matches/";
            const insert = this.$store.getters["match/matchForSubmit"];
            insert.scores = { homeTeam: 0, awayTeam: 0 };
            insert.scoringEvents = [];
            insert.playerMovements = [];
            insert.gameStates = [];
            this.$http
              .post(url, insert)
              .then(() => {
                this.$router.go(-1); // either parent component is match list or match-score bulk editing
                // this.$router.push({ name: 'matches.list', params: { currentCompetition: this.competitionId } });
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        }
        return true;
      });
    },
    cancelForm() {
      this.$router.go(-1); // either parent component is match list or match-score bulk editing
      // this.$router.push({
      //   name: 'matches.list',
      //   params: (this.competitionId ? { currentCompetition: this.competitionId } : { })
      // });
    },
    deleteMatch() {
      const { _id } = this.match;
      if (!_id) return;
      this.$confirm(
        "Are you sure you want to delete this match? This cannot be undone.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$store.commit("root/loading", true);
          this.$http
            .delete(`nrl/api/v1/admin/matches/${_id}`)
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$router.go(-1); // back to match list
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(get(error, "response.data.message"));
            });
        })
        .catch(() => {});
    },
    async handleAddVoter() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.voterRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { memberId, votedOn } = this.add;
        const { profile } = this.match.awardTeam.find((p) => {
          return p._id === memberId;
        });
        const voter = {
          memberId,
          votedOn,
          profile,
        };
        this.newVoters.push(voter);
        this.$set(this.add, "memberId", null);
        this.$set(this.add, "votedOn", false);
      } catch (error) {
        this.$customError("Could not add Voter");
        throw new Error("Could not add Voter");
      }
    },
    removeFromVoterList(row) {
      this.$confirm(
        "Are you sure you want to remove this player from the team list",
        "Remove player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.newVoters.splice(
            this.newVoters.findIndex((p) => p.memberId === row.memberId),
            1
          );
        })
        .catch(() => {});
    },
    async submitVoterAssignment() {
      this.$confirm(
        "Submitting this team list will override existing one. Do you want to continue?",
        "Submit Team List",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.$store.commit("root/loading", true);
          const { assignedVoters, votingOpen, votesSubmitted } = this.match; // member/s assigned to match prior to viewing match
          const { newVoters } = this; // current member/s selected in form to update

          // no judges assigned or assigning new judge/s
          newVoters.forEach((voters) => {
            voters.votingOpen = votingOpen;
          });

          const newAssignments = newVoters.filter((v) => {
            const { memberId, votingOpen } = v;
            return !assignedVoters.find((av) => {
              return av.memberId === memberId && av.votingOpen === votingOpen;
            });
          });
          const unassignments = assignedVoters.filter((v) => {
            const { memberId } = v;
            return !newVoters.find((nv) => {
              return nv.memberId === memberId;
            });
          });
          const assignmentSubmissions = [];
          newAssignments.forEach((na) => {
            const insert = {
              comp: {
                id: this.competitionId,
                name: this.competitionName,
              },
              match: {
                id: this.match._id,
                home: {
                  id: this.homeTeamId,
                  name: this.homeTeamName,
                },
                away: {
                  id: this.awayTeamId,
                  name: this.awayTeamName,
                },
              },
              createdAt: moment.now(),
            };
            const { memberId, votingOpen } = na;
            insert.memberId = memberId;
            const vs = votesSubmitted.includes(memberId) ? "voted" : "novote";
            insert.status = votingOpen
              ? `voter_assigned_open_${vs}`
              : `voter_assigned_closed_${vs}`;
            assignmentSubmissions.push(insert);
          });
          unassignments.forEach((na) => {
            const insert = {
              comp: {
                id: this.competitionId,
                name: this.competitionName,
              },
              match: {
                id: this.match._id,
                home: {
                  id: this.homeTeamId,
                  name: this.homeTeamName,
                },
                away: {
                  id: this.awayTeamId,
                  name: this.awayTeamName,
                },
              },
              createdAt: moment.now(),
            };
            const { memberId, votingOpen } = na;
            insert.memberId = memberId;
            const vs = votesSubmitted.includes(memberId) ? "voted" : "novote";
            insert.status = votingOpen
              ? `voter_unassigned_open_${vs}`
              : `voter_unassigned_closed_${vs}`;
            assignmentSubmissions.push(insert);
          });
          this.$http
            .post("/nrl/api/v1/admin/match-assignment", { assignmentSubmissions })
            .then(() => {
              this.$store.commit("root/loading", false);
              this.$router.go(-1); // either parent component is match list or match-score bulk editing
              this.$customSuccess("Voters Successfully Assigned");
            })
            .catch((e) => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
          return true;
        })
        .catch(() => {});
    },
    async mostRecentMovements(m) {
      const isHome = m.map((pm) => pm.isHomeTeam);
      const unique = isHome.filter((v, i, s) => s.indexOf(v) === i);
      const team = unique[0] ? "home" : "away";
      this.allMovements[team] = m;
    },
  },
  watch: {
    async awardTeamId(id) {
      await this.$store.dispatch("match/getVoters", id);
      const { assignedVoters, votingOpen } = this.match;
      if (assignedVoters && assignedVoters.length) {
        assignedVoters.forEach((v) => {
          const { memberId } = v;
          const profArr = this.match.awardTeam.find((p) => {
            return p._id === memberId;
          });
          const profile = profArr && profArr.profile ? profArr.profile : null;
          const votedOn = this.match.votesSubmitted.includes(memberId);
          this.newVoters.push({
            memberId,
            votingOpen,
            votedOn,
            profile,
          });
        });
      }
    },
    competitionId() {
      const compid = this.competitionId;
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/teams/competition/${compid}`),
        this.$http.get(`/nrl/api/v1/admin/competitions-settings/competition/${compid}`),
      ])
        .then(([teamRes, settingRes]) => {
          this.allTeams = teamRes.data.data;
          this.availablePositions = settingRes.data.data.positions;
        })
        .catch(() => {});
    },
    roundType(val) {
      if (val === "Regular") {
        this.roundDisplayName = null;
      }
    },
    tba(val) {
      if (val === true) {
        this.$store.commit("match/setVenue", { name: null, _id: null, venueTimezone: null });
        this.$store.commit("match/setDateTime", this.dateTime || null);
        this.$store.commit("match/setTeam", {
          type: "homeTeam",
          data: this.match.homeTeam || { name: null, _id: null },
        });
        this.$store.commit("match/setTeam", {
          type: "awayTeam",
          data: this.match.awayTeam || { name: null, _id: null },
        });
      }
    },
    "match.meta.fieldNo": function (number) {
      this.$store.commit("match/setFieldNo", number);
    },
  },
  computed: {
    currentMatchId() {
      return parseInt(this.$route.params.id);
    },
    ...mapFields({
      matchStatus: "match.status",
      roundDisplayName: "match.round.displayName",
      roundType: "match.round.type",
      roundNumber: "match.round.number",
      tba: "match.meta.isTba",
      bye: "match.meta.isBye",
      dateTime: "match.dateTime",
      competitionName: "match.competition.name",
      homeTeamId: "match.homeTeam._id",
      awayTeamId: "match.awayTeam._id",
      competitionId: "match.competition._id",
      allowScoring: "match.allowScoring",
      awardTeamId: "match.competition.awardVoters",
      awardTeam: "match.awardTeam",
    }),
    availableVoters() {
      return this.awardTeam
        .filter((p) => {
          return !this.newVoters.find(({ memberId }) => {
            return p._id === memberId;
          });
        })
        .sort();
    },
    homeScoreLabel() {
      return `(H) Scores - ${this.homeScore || 0}`;
    },
    awayScoreLabel() {
      return `(A) Scores - ${this.awayScore || 0}`;
    },
    matchTeams() {
      return this.$store.getters["match/matchTeams"];
    },
    homeScore: {
      get() {
        return this.$store.getters["match/homeScore"];
      },
      set(value) {
        this.$store.commit("match/setOverrideHomeScore", value);
      },
    },
    awayScore: {
      get() {
        return this.$store.getters["match/awayScore"];
      },
      set(value) {
        this.$store.commit("match/setOverrideAwayScore", value);
      },
    },
    competitionName: {
      get() {
        return this.$store.getters["match/competitionName"];
      },
      set(value) {
        this.$store.commit("match/setCompetition", value);
      },
    },
    awardsEligible() {
      const currentComp = this.allCompetitions.find((c) => c._id === this.competitionId);
      return currentComp && currentComp.meta && currentComp.meta.enableAwards;
    },
    matchVenueName: {
      get() {
        return this.$store.getters["match/venueName"];
      },
      set(value) {
        const { _id, name, venueTimezone } = value;
        this.$store.commit("match/setVenue", { _id, name, venueTimezone });
      },
    },
    forfeitingTeamName: {
      get() {
        return this.$store.getters["match/forfeitingTeamName"];
      },
      set(value) {
        const team = this.allTeams.find((t) => t.name === value);
        if (value) this.$store.commit("match/setForfeitingTeam", team || { name: "", _id: "" });
      },
    },
    homeTeamName: {
      get() {
        return this.$store.getters["match/homeTeamName"];
      },
      set(value) {
        const { _id, name } = value;
        const awayName = this.$store.getters["match/awayTeamName"];
        if (name !== awayName || name === null) {
          this.$store.commit("match/setTeam", {
            type: "homeTeam",
            data: { _id, name },
          });
        } else {
          this.$customError(errormsg.duplicate_team);
        }
      },
    },
    awayTeamName: {
      get() {
        return this.$store.getters["match/awayTeamName"];
      },
      set(value) {
        const { _id, name } = value;
        const homeName = this.$store.getters["match/homeTeamName"];
        if (name !== homeName || name === null) {
          this.$store.commit("match/setTeam", {
            type: "awayTeam",
            data: { _id, name },
          });
        } else {
          this.$customError(errormsg.duplicate_team);
        }
      },
    },
    isTfa() {
      const { allCompetitions, competitionId } = this;
      if (!allCompetitions.length) return true;
      const c = allCompetitions.find((comp) => comp._id === competitionId);
      const { _id } = c.orgtree.national;
      return _id === 31;
    },
    assocId() {
      const { competitionId, allCompetitions } = this;
      const cc = allCompetitions.find((c) => c._id === competitionId);
      return cc.orgtree && cc.orgtree.association && cc.orgtree.association._id
        ? cc.orgtree.association._id
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.notify-judges:hover {
  border: solid 3px rgb(127 29 29) !important;
}

.tba {
  margin-top: 3rem;
  text-align: right;
}
.align-text-right {
  text-align: right;
}
</style>
