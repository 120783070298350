<template>
  <div class="tw-px-4 sm:tw-px-6 lg:tw-px-8">
    <el-tabs type="card">
      <el-tab-pane label="Competitons">
        <div v-if="isSuper">
          <el-form
            label-position="top"
            :model="competitionExpiration"
            id="competitionExpiration"
            ref="competitionExpiration"
          >
            <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r">
              <el-form-item prop="associations" label="Association" class="tw-w-1/4">
                <el-select
                  v-model="associations"
                  placeholder="Select an association"
                  multiple
                  filterable
                >
                  <el-option
                    v-for="item in allAssociations"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="season" label="Season" class="tw-w-1/4">
                <el-select v-model="season" placeholder="Select a season">
                  <el-option
                    v-for="season in seasonOptions"
                    :key="season"
                    :label="season"
                    :value="season"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="isSuper && isTfa"
                prop="seasonTag"
                label="Season Tag"
                class="tw-w-1/4"
              >
                <el-select v-model="seasonTag" placeholder="Select a season tag">
                  <el-option
                    v-for="st in seasonTagOptions"
                    :key="st.value"
                    :label="st.label"
                    :value="st.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button @click="getComps">get comps</el-button>
            </div>
          </el-form>
        </div>
        <div class="sm:tw-flex sm:tw-items-center">
          <div class="sm:tw-flex-auto">
            <h1 class="tw-text-xl tw-font-semibold tw-text-gray-900">Competitions</h1>
            <p class="tw-mt-2 tw-text-sm tw-text-gray-700">
              Select the appropriate competitions then press expire.
            </p>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-mt-8">
          <div class="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
            <div
              class="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle md:tw-px-6 lg:tw-px-8"
            >
              <div
                class="tw-relative tw-overflow-hidden tw-shadow tw-ring-1 tw-ring-black tw-ring-opacity-5 md:tw-rounded-sm"
              >
                <div
                  v-if="selectedComps.length > 0"
                  class="tw-absolute tw-top-0 tw-flex tw-items-center tw-h-12 tw-space-x-3 tw-left-12 tw-bg-gray-50 sm:tw-left-16"
                >
                  <button
                    @click="expireComps"
                    type="button"
                    class="tw-inline-flex tw-items-center tw-rounded tw-border tw-border-gray-300 tw-bg-white tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-500 focus:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-30"
                  >
                    Expire
                  </button>
                </div>
                <table class="tw-min-w-full tw-divide-y tw-divide-gray-300 tw-table-fixed">
                  <thead class="tw-bg-gray-50">
                    <tr>
                      <th scope="col" class="tw-relative tw-w-12 tw-px-6 sm:tw-w-16 sm:tw-px-8">
                        <input
                          type="checkbox"
                          class="tw-absolute tw-w-4 tw-h-4 tw--mt-2 tw-text-green-600 tw-border-gray-300 tw-rounded tw-left-4 tw-top-1/2 focus:tw-ring-green-500 sm:tw-left-6"
                          :checked="
                            indeterminate || selectedComps.length === allCompetitions.length
                          "
                          :indeterminate="indeterminate"
                          @change="
                            selectedComps = $event.target.checked
                              ? allCompetitions.map((c) => c._id)
                              : []
                          "
                        />
                      </th>
                      <th
                        scope="col"
                        class="tw-min-w-[12rem] tw-py-3.5 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Season
                      </th>
                      <th
                        scope="col"
                        class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Association
                      </th>
                      <th
                        scope="col"
                        class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Season tag
                      </th>
                      <th scope="col" class="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-6">
                        <span class="tw-sr-only">Goto</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                    <tr
                      v-for="comp in allCompetitions"
                      :key="comp._id"
                      :class="[selectedComps.includes(comp._id) && 'tw-bg-gray-50']"
                    >
                      <td class="tw-relative tw-w-12 tw-px-6 sm:tw-w-16 sm:tw-px-8">
                        <div
                          v-if="selectedComps.includes(comp._id)"
                          class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-green-600"
                        ></div>
                        <input
                          type="checkbox"
                          class="tw-absolute tw-w-4 tw-h-4 tw--mt-2 tw-text-green-600 tw-border-gray-300 tw-rounded tw-left-4 tw-top-1/2 focus:tw-ring-green-500 sm:tw-left-6"
                          :value="comp._id"
                          v-model="selectedComps"
                        />
                      </td>
                      <td
                        :class="[
                          'tw-whitespace-nowrap tw-py-4 tw-pr-3 tw-text-sm tw-font-medium',
                          selectedComps.includes(comp._id)
                            ? 'tw-text-green-600'
                            : 'tw-text-gray-900',
                        ]"
                      >
                        {{ comp.name }}
                      </td>
                      <td class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-whitespace-nowrap">
                        {{ comp.season }}
                      </td>
                      <td class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-whitespace-nowrap">
                        {{ comp.orgtree.association.name }}
                      </td>
                      <td class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-whitespace-nowrap">
                        {{ comp.seasonTag }}
                      </td>
                      <td
                        class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-sm tw-font-medium tw-text-right tw-whitespace-nowrap sm:tw-pr-6"
                      >
                        <a
                          :href="`/competitions/${comp._id}`"
                          class="tw-text-green-600 hover:tw-text-green-900"
                          >Goto<span class="tw-sr-only">, {{ comp._id }}</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tw-pt-6 sm:tw-mt-0 sm:tw-ml-8 sm:tw-flex-none">
              <button
                @click="expireComps"
                type="button"
                class="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-green-600 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-500 focus:tw-ring-offset-2 sm:tw-w-auto"
              >
                Expire
              </button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Referees & Volunteers">
        <div v-if="isSuper">
          <el-form
            label-position="top"
            :model="refVolunExpiration"
            id="refVolunExpiration"
            ref="refVolunExpiration"
          >
            <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r">
              <el-form-item prop="states" label="State" class="tw-w-1/4">
                <el-select v-model="states" placeholder="Select a state" multiple filterable>
                  <el-option
                    v-for="item in allStates"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="season" label="Season" class="tw-w-1/4">
                <el-select v-model="season" placeholder="Select a season">
                  <el-option
                    v-for="season in seasonOptions"
                    :key="season"
                    :label="season"
                    :value="season"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="memberType" label="Member Type" class="tw-w-1/4">
                <el-select v-model="memberType" placeholder="Select a member type">
                  <el-option key="Both" label="Both" value="both"> </el-option>
                  <el-option key="Referee" label="Referee" value="referee"> </el-option>
                  <el-option key="Volunteer" label="Volunteer" value="volunteer"> </el-option>
                </el-select>
              </el-form-item>
              <el-button @click="getAssocs">get assocs</el-button>
            </div>
          </el-form>
        </div>
        <div class="sm:tw-flex sm:tw-items-center">
          <div class="sm:tw-flex-auto">
            <h1 class="tw-mt-8 tw-text-xl tw-font-semibold tw-text-gray-900">
              Referees & Volunteers
            </h1>
            <p class="tw-mt-2 tw-text-sm tw-text-gray-700">
              Select the appropriate associations then press expire.
            </p>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-mt-8">
          <div class="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
            <div
              class="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle md:tw-px-6 lg:tw-px-8"
            >
              <div
                class="tw-relative tw-overflow-hidden tw-shadow tw-ring-1 tw-ring-black tw-ring-opacity-5 md:tw-rounded-sm"
              >
                <div
                  v-if="selectedAssocs.length > 0"
                  class="tw-absolute tw-top-0 tw-flex tw-items-center tw-h-12 tw-space-x-3 tw-left-12 tw-bg-gray-50 sm:tw-left-16"
                >
                  <button
                    @click="expireRefsVolun"
                    type="button"
                    class="tw-inline-flex tw-items-center tw-rounded tw-border tw-border-gray-300 tw-bg-white tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-500 focus:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-30"
                  >
                    Expire
                  </button>
                </div>
                <table class="tw-min-w-full tw-divide-y tw-divide-gray-300 tw-table-fixed">
                  <thead class="tw-bg-gray-50">
                    <tr>
                      <th scope="col" class="tw-relative tw-w-12 tw-px-6 sm:tw-w-16 sm:tw-px-8">
                        <input
                          type="checkbox"
                          class="tw-absolute tw-w-4 tw-h-4 tw--mt-2 tw-text-green-600 tw-border-gray-300 tw-rounded tw-left-4 tw-top-1/2 focus:tw-ring-green-500 sm:tw-left-6"
                          :checked="
                            indeterminateAssocs ||
                            selectedAssocs.length === associationsQuery.length
                          "
                          :indeterminate="indeterminateAssocs"
                          @change="
                            selectedAssocs = $event.target.checked
                              ? associationsQuery.map((c) => c._id)
                              : []
                          "
                        />
                      </th>
                      <th
                        scope="col"
                        class="tw-min-w-[12rem] tw-py-3.5 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="tw-min-w-[12rem] tw-py-3.5 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Season
                      </th>
                      <th
                        scope="col"
                        class="tw-min-w-[12rem] tw-py-3.5 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900"
                      >
                        Member Type
                      </th>
                      <th scope="col" class="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-6">
                        <span class="tw-sr-only">Goto</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                    <tr
                      v-for="assoc in associationsQuery"
                      :key="assoc._id"
                      :class="[selectedAssocs.includes(assoc._id) && 'tw-bg-gray-50']"
                    >
                      <td class="tw-relative tw-w-12 tw-px-6 sm:tw-w-16 sm:tw-px-8">
                        <div
                          v-if="selectedAssocs.includes(assoc._id)"
                          class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-green-600"
                        ></div>
                        <input
                          type="checkbox"
                          class="tw-absolute tw-w-4 tw-h-4 tw--mt-2 tw-text-green-600 tw-border-gray-300 tw-rounded tw-left-4 tw-top-1/2 focus:tw-ring-green-500 sm:tw-left-6"
                          :value="assoc._id"
                          v-model="selectedAssocs"
                        />
                      </td>
                      <td
                        :class="[
                          'tw-whitespace-nowrap tw-py-4 tw-pr-3 tw-text-sm tw-font-medium',
                          selectedAssocs.includes(assoc._id)
                            ? 'tw-text-green-600'
                            : 'tw-text-gray-900',
                        ]"
                      >
                        {{ assoc.name }}
                      </td>
                      <td class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-whitespace-nowrap">
                        {{ season || new Date().getFullYear() }}
                      </td>
                      <td class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-whitespace-nowrap">
                        {{ capitalise(memberType) }}
                      </td>
                      <td
                        class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-sm tw-font-medium tw-text-right tw-whitespace-nowrap sm:tw-pr-6"
                      >
                        <a
                          :href="`/associations/${assoc._id}`"
                          class="tw-text-green-600 hover:tw-text-green-900"
                          >Goto<span class="tw-sr-only">, {{ assoc._id }}</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tw-pt-6 sm:tw-mt-0 sm:tw-ml-8 sm:tw-flex-none">
              <button
                @click="expireRefsVolun"
                type="button"
                class="tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-green-600 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-500 focus:tw-ring-offset-2 sm:tw-w-auto"
              >
                Expire
              </button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { mysidelineSeasons } from "../../utils/constants";
import { errormsg } from "../../utils/constants";
const _ = require("lodash");

export default {
  data() {
    return {
      people: [
        {
          name: "Lindsay Walton",
          title: "Front-end Developer",
          email: "lindsay.walton@example.com",
          role: "Member",
        },
        // More people...
      ],
      selectedComps: [],
      selectedAssocs: [],
      checked: false,
      states: [],
      associations: [],
      associationsQuery: [],
      competitions: [],
      season: undefined,
      seasonTag: undefined,
      memberType: "both",
      allStates: [],
      allAssociations: [],
      allCompetitions: [],
      competitionExpiration: {
        associations: [],
        competitions: [],
        season: undefined,
        seasonTag: undefined,
      },
      refVolunExpiration: {
        states: [],
        season: undefined,
        seasonTag: undefined,
      },
      competitionsToExpire: [],
      seasonOptions: mysidelineSeasons,
      seasonTagOptions: [
        {
          value: 1,
          label: "Season 1",
        },
        {
          value: 2,
          label: "Season 2",
        },
      ],
    };
  },
  components: { ActionButtons },
  async beforeCreate() {
    try {
      if (!this.$store.getters["user/isSuperAdmin"]) throw new Error("Unauthorised");
    } catch (error) {
      const msg = error.message;
      this.$customError(msg);
      return this.$router.push("/");
    }
  },
  async mounted() {
    try {
      this.$store.commit("root/loading", true);
      const associations = await this.$http.get("/nrl/api/v1/admin/associations/");
      this.allAssociations = associations?.data?.data;
      // const states = await this.$http.get("/nrl/api/v1/admin/states/");

      // const compsRes = await this.$http.get("/nrl/api/v1/admin/competitions?season=all");
      const associationIds = this.allAssociations.map((assoc) => assoc._id);
      const allStates = this.allAssociations.map((assoc) => assoc.orgtree.state);
      this.allStates = [...new Map(allStates.map((state) => [state["_id"], state])).values()];

      const compsRes = await this.$httpms.post("/getComps", {
        seasons: [2022, 2023],
        seasonTag: 1,
        associations: associationIds,
      });
      this.allCompetitions = compsRes.data.comps;

      const assocRes = await this.$httpms.post("/getAssocs", {
        states: this.allStates.map((s) => s._id),
      });
      this.associationsQuery = assocRes.data.assocs;

      this.competitionExpiration.competitions = this.allCompetitions;
      this.$store.commit("root/loading", false);
    } catch (error) {
      const msg = error.message ? error.message : "System error";
      this.$customError(msg);
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    capitalise(word) {
      const capitalisedWord = word.charAt(0).toUpperCase() + word.slice(1);
      return capitalisedWord;
    },
    cancelForm() {
      this.$router.go(-1);
    },
    async expireComps() {
      try {
        this.$store.commit("root/loading", true);
        const teamIds = [];
        const newComps = [];
        for (const compId of this.selectedComps) {
          for (const comp of this.allCompetitions) {
            if (comp._id === compId) {
              if (!comp.teams) continue;
              for (const team of comp.teams) {
                teamIds.push(team._id);
              }
            } else {
              newComps.push(comp);
            }
          }
        }
        // TODO: send payload to expiration/rollover script
        const payload = { compIds: this.selectedComps, teamIds };

        console.log({ payload });
        await this.$httpms.post("/expireComps/", payload);
        // remove comps from allCompetitions
        this.allCompetitions = newComps;
        this.$store.commit("root/loading", false);
      } catch (error) {
        console.log({ error });
        this.$customError("Could not retrieve competition data");
        this.$store.commit("root/loading", false);
      }
    },
    async expireRefsVolun() {
      try {
        this.$store.commit("root/loading", true);
        const season = this.season ? this.season : new Date().getFullYear();
        const memberType =
          this.memberType === "both" ? ["referee", "volunteer"] : [this.memberType];
        await this.$httpms.post("/expireRefsVolun/", {
          seasons: [season],
          memberType,
          associations: this.selectedAssocs,
        });

        this.$store.commit("root/loading", false);
      } catch (error) {
        this.$customError("Could not retrieve association data");
        this.$store.commit("root/loading", false);
      }
    },
    async getComps() {
      try {
        this.$store.commit("root/loading", true);
        const season = this.season ? this.season : 2023;
        const seasonTag = this.seasonTag ? this.seasonTag : null;
        console.log(this.associations);
        const compsRes = await this.$httpms.post("/getComps", {
          seasons: [season],
          seasonTag: seasonTag,
          associations: this.associations,
        });
        this.allCompetitions = compsRes.data.comps;
        this.competitions = this.allCompetitions;
        // this.competitionExpiration.states = [];
        // this.competitionExpiration.season = undefined;
        // this.competitionExpiration.seasonTag = undefined;
        // this.competitionExpiration.competitions = this.allCompetitions;
        this.$store.commit("root/loading", false);
      } catch (e) {
        const msg = e.message ? e.message : "System error";
        this.$customError(msg);
        this.$store.commit("root/loading", false);
      }
    },
    async getAssocs() {
      try {
        this.$store.commit("root/loading", true);
        const assocsRes = await this.$httpms.post("/getAssocs", {
          states: this.states.length ? this.states : this.allStates.map((s) => s._id),
        });
        this.associationsQuery = assocsRes.data.assocs;
        this.$store.commit("root/loading", false);
      } catch (e) {
        const msg = e.message ? e.message : "System error";
        this.$customError(msg);
        this.$store.commit("root/loading", false);
      }
    },
    resetQuery() {
      this.$store.commit("root/loading", true);
      this.competitionExpiration.associations = undefined;
      this.competitionExpiration.season = undefined;
      this.competitionExpiration.seasonTag = undefined;
      this.competitionExpiration.competitions = this.allCompetitions;
      this.$store.commit("root/loading", false);
    },
  },
  // watch: {
  //   "competitionExpiration.associations": function() {
  //     const { associations, season, seasonTag } = this.competitionExpiration;
  //     console.log({ associations, season, seasonTag });
  //     if (associations) {
  //       this.$store.commit("root/loading", true);
  //       const queryCheck = {};
  //       if (season) queryCheck.season = season;
  //       if (seasonTag) queryCheck.seasonTag = seasonTag;
  //       if (associations) queryCheck.associations = associations;
  //       const activeQueries = Object.keys(queryCheck);

  //       this.competitionExpiration.competitions = this.allCompetitions.filter(comp => {
  //         console.log({ comp });
  //         let filter = associations.includes(comp.orgtree.association._id);
  //         if (filter && activeQueries.includes("season")) filter = filter && comp.season === season;
  //         if (filter && activeQueries.includes("seasonTag"))
  //           filter = filter && comp.seasonTag === seasonTag;
  //         return filter;
  //       });
  //       this.$store.commit("root/loading", false);
  //     }
  //   },
  //   "competitionExpiration.season": function() {
  //     const { associations, season, seasonTag } = this.competitionExpiration;
  //     if (season) {
  //       this.$store.commit("root/loading", true);
  //       const queryCheck = {};
  //       if (associations && associations.length) queryCheck.associations = associations;
  //       if (seasonTag) queryCheck.seasonTag = seasonTag;
  //       const activeQueries = Object.keys(queryCheck);

  //       this.competitionExpiration.competitions = this.allCompetitions.filter(comp => {
  //         let filter = comp.orgtree.national._id === 31 && comp.season === season;
  //         if (filter && activeQueries.includes("associations"))
  //           filter = filter && associations.includes(comp.orgtree.association._id);

  //         // if (filter && activeQueries.includes("state"))
  //         //   filter = filter && states.includes(comp.orgtree.states._id);
  //         if (filter && activeQueries.includes("seasonTag"))
  //           filter = filter && comp.seasonTag === seasonTag;
  //         return filter;
  //       });
  //       this.$store.commit("root/loading", false);
  //     }
  //   },
  //   "competitionExpiration.seasonTag": function() {
  //     const { associations, season, seasonTag } = this.competitionExpiration;
  //     if (seasonTag) {
  //       this.$store.commit("root/loading", true);
  //       const queryCheck = {};
  //       if (associations && associations.length) queryCheck.associations = associations;
  //       if (season) queryCheck.season = season;
  //       const activeQueries = Object.keys(queryCheck);

  //       this.competitionExpiration.competitions = this.allCompetitions.filter(comp => {
  //         let filter = comp.orgtree.national._id === 31 && comp.seasonTag === seasonTag;
  //         if (filter && activeQueries.includes("associations"))
  //           filter = filter && associations.includes(comp.orgtree.association._id);

  //         if (filter && activeQueries.includes("season")) filter = filter && comp.season === season;
  //         return filter;
  //       });
  //       this.$store.commit("root/loading", false);
  //     }
  //   }
  // },
  computed: {
    indeterminate: function () {
      this.selectedComps.length > 0 && this.selectedComps.length < this.allCompetitions.length;
    },
    indeterminateAssocs: function () {
      this.selectedAssocs.length > 0 && this.selectedAssocs.length < this.associationsQuery.length;
    },
    isTfa: function () {
      const userRole = this.$store.getters["user/activeRole"];
      return userRole.national_id === 31;
    },
    isSuper: function () {
      return this.$store.getters["user/isSuperAdmin"];
    },
  },
};
</script>
<style scoped>
.el-select {
  width: 100%;
}
</style>
