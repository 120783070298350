<template>
  <div>
    <el-tabs type="card" @tab-click="updateLogs">
      <el-tab-pane label="Email">
        <el-tabs @tab-click="resetCounters">
          <el-tab-pane :label="labelByRole">
            <!-- By Club -->
            <el-form label-position="top" :model="filter" :rules="filterRules" ref="filterForm">
              <el-row type="flex" align="bottom" :gutter="10">
                <el-col :span="12" v-if="clearance === roles.associationAdmin">
                  <el-form-item prop="clubs" label="Club">
                    <el-select
                      v-model="filter.clubs"
                      placeholder="Select a club"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ _id, name } in clubs"
                        :label="name"
                        :key="_id"
                        :value="_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="clearance === roles.clubAdmin">
                  <el-form-item prop="teams" label="Team Squad">
                    <el-select
                      v-model="filter.teams"
                      placeholder="Select a team"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ _id, name } in teams"
                        :label="name"
                        :key="_id"
                        :value="_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="12"
                  v-if="[roles.gamedevregionAdmin, roles.programAdmin].includes(clearance)"
                >
                  <el-form-item prop="program" label="Program">
                    <el-select v-model="filter.program" placeholder="Select a program" filterable>
                      <el-option
                        v-for="{ _id, name } in programs"
                        :label="name"
                        :key="_id"
                        :value="_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="6"
                  v-if="[roles.clubAdmin, roles.associationAdmin].includes(clearance)"
                >
                  <el-form-item prop="season" label="Season">
                    <el-select
                      v-if="!isTFA"
                      v-model="filter.season"
                      placeholder="Select a season"
                      multiple
                      filterable
                      @change="getTeamBySeason"
                    >
                      <el-option
                        v-for="ss in [currentSeason, currentSeason - 1, currentSeason - 2]"
                        :label="ss"
                        :key="ss"
                        :value="ss"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else
                      v-model="filter.season"
                      placeholder="Select a season"
                      filterable
                      multiple
                      @change="getTeamBySeason"
                    >
                      <el-option
                        v-for="ss in [currentSeason, currentSeason - 1, currentSeason - 2]"
                        :label="ss"
                        :key="ss"
                        :value="ss"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="6"
                  v-if="[roles.clubAdmin, roles.associationAdmin].includes(clearance)"
                >
                  <el-form-item prop="regoStatus" label="Registration Status">
                    <el-select
                      v-model="filter.regoStatus"
                      placeholder="Select a Registration Status"
                      :disabled="filter.season === currentSeason"
                      filterable
                    >
                      <el-option
                        v-for="{ type, name } in registrationStatus"
                        :label="name"
                        :key="type"
                        :value="type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                :gutter="10"
                v-if="[roles.associationAdmin, roles.clubAdmin].includes(clearance)"
              >
                <el-col :span="6">
                  <el-form-item prop="memberType" label="Member Type">
                    <el-select
                      v-model="filter.memberType"
                      placeholder="Member Type"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ name, type } in memberTypes"
                        :label="name"
                        :key="type"
                        :value="type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="gender" label="Gender">
                    <el-select v-model="filter.gender" placeholder="Gender" filterable>
                      <el-option v-for="item in genders" :label="item" :key="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="ageLevel" label="Age Level">
                    <el-select
                      v-model="filter.ageLevel"
                      placeholder="Select an age level"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="item in ageLevels"
                        :label="item.name"
                        :key="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isTFA">
                  <el-form-item prop="" label="Send Reminders To">
                    <el-select
                      v-model="filter.reminder"
                      placeholder="Select to send reminders to"
                      filterable
                    >
                      <el-option
                        v-for="item in reminders"
                        :label="item.name"
                        :key="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item>
                    <el-checkbox
                      label="Include Parent Email"
                      v-model="filter.parentEmail"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="tw-flex tw-items-start tw-gap-3 tw-py-8 tw-border-r tw-border-gray-100">
              <el-col class="btn-container">
                <el-button type="success" @click="createTargetGroup" id="group-count-ByClub">
                  Create Target Group</el-button
                >
              </el-col>
            </div>
            <h3 v-if="countByClub >= 0" id="group-countClub">
              Target Group Count: {{ countByClub }}
            </h3>
          </el-tab-pane>
          <!-- By Competition -->
          <el-tab-pane
            label="By Competition"
            @tab-click="count = -1"
            v-if="[roles.associationAdmin].includes(clearance)"
          >
            <el-form label="By Competition">
              <el-row type="flex" align="bottom" :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Competition">
                    <el-select
                      v-model="filterByComp.competitions"
                      placeholder="Select a competition"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ _id, name } in competitions"
                        :label="name"
                        :key="_id"
                        :value="_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="season" label="Season">
                    <el-select
                      v-model="filterByComp.season"
                      placeholder="Select a season"
                      filterable
                      multiple
                      @change="getCompetitionsBySeason"
                    >
                      <el-option
                        v-for="ss in [currentSeason, currentSeason - 1, currentSeason - 2]"
                        :label="ss"
                        :key="ss"
                        :value="ss"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item prop="memberType" label="Member Type">
                    <el-select
                      v-model="filterByComp.memberType"
                      placeholder="Member Type"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ name, type } in memberTypesForCompTeam"
                        :label="name"
                        :key="type"
                        :value="type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="gender" label="Gender">
                    <el-select v-model="filterByComp.gender" placeholder="Gender" filterable>
                      <el-option v-for="item in genders" :label="item" :key="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="ageLevel" label="Age Level">
                    <el-select
                      v-model="filterByComp.ageLevel"
                      placeholder="Select an age level"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="item in ageLevels"
                        :label="item.name"
                        :key="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item>
                    <el-checkbox
                      label="Include Parent Email"
                      v-model="filterByComp.parentEmail"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="tw-flex tw-items-start tw-gap-3 tw-py-8 tw-border-r tw-border-gray-100">
              <el-col class="btn-container">
                <el-button type="success" @click="createTargetGroupByComp" id="group-count-ByComp">
                  Create Target Group</el-button
                >
              </el-col>
            </div>
            <h3 v-if="countByComp >= 0" id="group-countComp">
              Target Group Count: {{ countByComp }}
            </h3>
          </el-tab-pane>
          <!-- By Teams  -->
          <el-tab-pane
            label="By Team"
            @tab-click="count = -1"
            v-if="[roles.associationAdmin].includes(clearance)"
          >
            <el-form label="By Teams">
              <el-row type="flex" align="bottom" :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Team">
                    <el-select
                      v-model="filterByTeam.teamList"
                      placeholder="Select a team"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ _id, name } in teamList"
                        :label="name"
                        :key="_id"
                        :value="_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="season" label="Season">
                    <el-select
                      v-model="filterByTeam.season"
                      placeholder="Select a season"
                      filterable
                      multiple
                      @change="getTeamBySeason"
                    >
                      <el-option
                        v-for="ss in [currentSeason, currentSeason - 1, currentSeason - 2]"
                        :label="ss"
                        :key="ss"
                        :value="ss"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item prop="memberType" label="Member Type">
                    <el-select
                      v-model="filterByTeam.memberType"
                      placeholder="Member Type"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="{ name, type } in memberTypesForCompTeam"
                        :label="name"
                        :key="type"
                        :value="type"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="gender" label="Gender">
                    <el-select v-model="filterByTeam.gender" placeholder="Gender" filterable>
                      <el-option v-for="item in genders" :label="item" :key="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="ageLevel" label="Age Level">
                    <el-select
                      v-model="filterByTeam.ageLevel"
                      placeholder="Select an age level"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="item in ageLevels"
                        :label="item.name"
                        :key="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item>
                    <el-checkbox
                      label="Include Parent Email"
                      v-model="filterByTeam.parentEmail"
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="tw-flex tw-items-start tw-gap-3 tw-py-8 tw-border-r tw-border-gray-100">
              <el-col class="btn-container">
                <el-button type="success" @click="createTargetGroupByTeam" id="group-count-ByTeam">
                  Create Target Group</el-button
                >
              </el-col>
            </div>
            <h3 v-if="countByTeam >= 0" id="group-countTeam">
              Target Group Count: {{ countByTeam }}
            </h3>
          </el-tab-pane>
        </el-tabs>

        <div class="tw-border-r tw-border-gray-100">
          <!-- <h2 class="tw-text-lg tw-font-bold">Send Email</h2> -->
          <h2 class="tw-w-1/2 tw-text-lg tw-font-bold">Send Email</h2>
          <el-col :span="12">
            <el-form
              id="sendEmailForm"
              label-position="top"
              :model="message"
              :rules="messageRules"
              ref="messageForm"
            >
              <!-- <div class="tw-flex tw-items-start tw-gap-3 tw-pt-6 tw-border-r tw-border-gray-100"> -->
              <!-- <el-form-item>
                <el-checkbox label="Include Parent Email" v-model="parentEmail"></el-checkbox>
              </el-form-item> -->
              <div class="tw-flex tw-items-start tw-gap-3 tw-border-r tw-border-gray-100">
                <el-col :span="24">
                  <el-form-item prop="subject" label="Email Subject">
                    <el-input
                      type="text"
                      v-model="message.subject"
                      placeholder="Title"
                      :disabled="count <= 0"
                    />
                  </el-form-item>
                  <el-form-item class="tw-pt-4" prop="content" label="Email Body">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 15, maxRows: 20 }"
                      v-model="message.content"
                      placeholder="Content"
                      :disabled="count <= 0"
                    />
                  </el-form-item>
                  <el-form-item class="tw-pt-4" prop="exclusions" label="Exclusion emails">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 5 }"
                      v-model="message.exclusions"
                      placeholder="Use comma to separate emails"
                      :disabled="count <= 0"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3 tw-pt-8 tw-border-r tw-border-gray-100">
                <el-form-item>
                  <el-button
                    type="success"
                    form="sendEmailForm"
                    :disabled="count <= 0"
                    @click="sendEmail('messageForm')"
                  >
                    Send Email</el-button
                  >
                </el-form-item>
              </div>
            </el-form>
            <!-- <div class="tw-flex tw-items-start tw-gap-3 tw-pt-8 tw-border-r tw-border-gray-100">
              <el-col class="mb-2 btn-container">
                <el-button type="success" @click="sendEmail('messageForm')" :disabled="count <= 0">
                  Send Email</el-button
                >
              </el-col>
            </div> -->
          </el-col>
          <el-col :span="12">
            <communication-email-template
              :subject="message.subject"
              :content="preview"
              :contact="contact"
              :logo="logo"
              :name="name"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Log Details">
        <!-- <div class="tw-w-full tw-p-0 tw-px-2 tw-py-2 tw-pb-5 tw-m-0 tw-bg-white">
          <div
            class="tw-grid tw-grid-cols-5 tw-gap-5 tw-mb-2.5 tw-h-16 tw-text-base tw-text-gray-500 tw-content-around"
          >
            <div class="tw-flex-1 tw-font-medium tw-border-r-2">Send Date and Time</div>
            <div class="tw-flex-1 tw-font-medium tw-border-r-2">Sender</div>
            <div class="tw-flex-1 tw-font-medium tw-border-r-2">Entity</div>
            <div class="tw-flex-1 tw-font-medium tw-border-r-2">Subject</div>
            <div class="tw-flex-1 tw-font-medium">Recipients</div>
          </div>
          <div class="tw-grid tw-grid-cols-5 tw-gap-5 tw-mb-2.5 tw-h-16">
            <div class="tw-flex-1 tw-text-xs tw-font-medium tw-text-black">
              Send Date and Time
            </div>
            <div class="tw-flex-1 tw-text-xs tw-font-medium tw-text-black">Sender</div>
            <div class="tw-flex-1 tw-text-xs tw-font-medium tw-text-black">Entity</div>
            <div class="tw-flex-1 tw-text-xs tw-font-medium tw-text-black">Subject</div>
            <div class="tw-flex-1 tw-text-xs tw-font-medium tw-text-black">
              Recipients
            </div>
          </div>
        </div> -->
        <!-- <data-tables
          :filters="filters"
          :data="injuryData"
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
          class="data-table"
        > -->
        <data-tables
          :data="emailsTable"
          :table-props="tableProps"
          :pagination-props="paginationProps"
          :page-size="pageSize"
          @row-click="handleRowClick"
        >
          <el-table-column
            prop="createdAt"
            :formatter="dateFormatter"
            label="Send Date"
            width="200"
          ></el-table-column>
          <el-table-column prop="sender" label="Sender" width="150"> </el-table-column>
          <el-table-column prop="entity" label="Entity" width="200"></el-table-column>
          <el-table-column prop="subject" label="Email Subject" width="250"></el-table-column>
          <el-table-column prop="recipients" label="Recipents" width="auto"></el-table-column>
        </data-tables>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import _ from "lodash";
import CommunicationEmailTemplate from "./CommunicationEmailTemplate.vue";
import {
  genders,
  currentSeason,
  memberTypes,
  roles,
  registrationStatus,
  ageLevels,
  errormsg,
  nationals,
} from "../../utils/constants";
import { getS3URL, validateEmail } from "../../utils/utilities";
import competitions from "../../stores/competitions";
// import func from 'vue-editor-bridge';

export default {
  components: {
    CommunicationEmailTemplate,
  },
  data() {
    return {
      count: -1,
      countByClub: -1,
      countByComp: -1,
      countByTeam: -1,
      currentSeason,
      showLogDetails: false,
      emailsTable: [],
      filter: {
        memberType: "",
        regoStatus: "",
        season: [], //currentSeason,
        ageLevel: "",
        teams: [],
        clubs: [],
        competitions: [],
        teamList: [],
        reminder: "",
        program: null,
        parentEmail: false,
      },
      filterByComp: {
        memberType: "",
        season: [], //currentSeason,
        ageLevel: "",
        competitions: [],
        gender: "",
        parentEmail: false,
      },
      filterByTeam: {
        memberType: "",
        season: [], //currentSeason,
        ageLevel: "",
        teamList: [],
        gender: "",
        parentEmail: false,
      },
      filterRules: {
        program: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      preview: "",
      message: {
        subject: "",
        content: "",
        exclusions: "",
      },
      messageRules: {
        subject: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        content: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        exclusions: {
          required: false,
          message: errormsg.emails,
          trigger: "blur",
          validator: (rule, val, cb) => {
            if (val) {
              const emails = val.split(",");
              if (emails.length && emails.some((x) => !validateEmail(x.trim()))) {
                return cb(new Error(errormsg.emails));
              }
            }
            return cb();
          },
        },
        filterBy: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
      },
      genders,
      registrationStatus,
      ageLevels,
      clubs: [],
      teams: [],
      competitions: [],
      teamList: [],
      programs: [],
      roles,
      contact: null,
      logo: "",
      name: "",
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      reminders: [
        { name: "All Registrations" },
        { name: "Pending Registrations" },
        { name: "Non-Financial" },
      ],
      filterBy: "", // it can be 'By Club' or 'By Competition' or 'By Team',
      labelByRole: "",
      parentEmail: false,
    };
  },
  mounted() {
    if (this.clearance === this.roles.associationAdmin) {
      this.$store.commit("root/loading", true);
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/associations/${this.entity._id}`),
        this.$http.get("/nrl/api/v1/admin/clubs"),
        this.$http.get(`/nrl/api/v1/admin/competitions`),
        this.$http.get(`/nrl/api/v1/admin/teams`),
      ])
        .then(([association, clubs, competitions, teams]) => {
          this.contact = association.data.data && association.data.data.contact;
          this.logo = association.data.data && association.data.data.meta.avatar;
          this.name = association.data.data.name;
          this.clubs = clubs.data.data;
          this.competitions = competitions.data.data;
          this.teamList = teams.data.data;

          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });

      this.labelByRole = "By Club";
    }
    if (this.clearance === this.roles.clubAdmin) {
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/clubs/${this.entity._id}`),
        this.$http.get("/nrl/api/v1/admin/club/teams"),
      ])
        .then(([club, teams]) => {
          this.contact = club.data.data && club.data.data.contact;
          this.logo = club.data.data && club.data.data.meta.avatar;
          this.name = club.data.data.name;
          this.teams = teams.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });

      this.labelByRole = "By Team";
    }

    if ([this.roles.gamedevregionAdmin, this.roles.programAdmin].includes(this.clearance)) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/programs")
        .then((response) => {
          this.programs = response.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
      this.labelByRole = "By Program";
    }

    this.$http
      .get("/nrl/api/v1/admin/email-comms")
      .then((response) => {
        this.emailsTable = response.data.data;
        this.emailsTable = _.sortBy(this.emailsTable, "createdAt").reverse();
      })
      .catch((error) => {
        this.$customError(_.get(error, "response.data.message"));
        this.$store.commit("root/loading", false);
      });
  },
  methods: {
    updateLogs() {
      this.$http
        .get("/nrl/api/v1/admin/email-comms")
        .then((response) => {
          this.emailsTable = response.data.data;
          this.emailsTable = _.sortBy(this.emailsTable, "createdAt").reverse();
        })
        .catch((error) => {
          this.$customError(_.get(error, "response.data.message"));
          this.$store.commit("root/loading", false);
        });
    },
    createTargetGroup() {
      this.$refs.filterForm.validate((val) => {
        if (val) {
          this.$store.commit("root/loading", true);
          // this.$http
          // .get("/nrl/api/v1/admin/communications/members/registration/count", {
          //   params: this.filter,
          // })

          // Only in case of TFA  - if no clubs are selected in tab "By club"
          // ONHOLD
          // if (this.$store.getters["user/activeRole"].national_id === nationals.TFA) {
          //   if (this.filter.clubs && this.filter.clubs.length <= 0) {
          //     const allTFAClubs = this.clubs.map((c) => c._id);
          //     this.filter.clubs = allTFAClubs;
          //   }
          // }

          this.$http
            .post("/nrl/api/v1/admin/communications/members/registrations-comms/count", this.filter)
            .then((response) => {
              this.countByClub = response.data.data;
              this.count = this.countByClub;
              this.filterBy = this.labelByRole;
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
    createTargetGroupByComp() {
      this.$store.commit("root/loading", true);
      // , {
      //   //" /nrl/api/v1/admin/communications/members/competition/count", {
      //   params: this.filterByComp,
      // })
      this.$http
        .post(
          "/nrl/api/v1/admin/communications/members/registrations-comms/count",
          this.filterByComp
        )
        .then((response) => {
          this.countByComp = response.data.data;
          this.count = this.countByComp;
          this.filterBy = "By Competition";
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    createTargetGroupByTeam() {
      this.$store.commit("root/loading", true);

      this.$http
        .post(
          "/nrl/api/v1/admin/communications/members/registrations-comms/count",
          this.filterByTeam
        )
        .then((response) => {
          this.countByTeam = response.data.data;
          this.count = this.countByTeam;
          this.filterBy = "By Team";
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    getTeamBySeason() {
      this.$store.commit("root/loading", true);

      const season =
        this.clearance === this.roles.clubAdmin ? this.filter.season : this.filterByTeam.season;
      Promise.all([this.$http.get(`/nrl/api/v1/admin/teams?season=${season}`)])
        .then(([teams]) => {
          if (this.clearance === this.roles.clubAdmin) {
            this.teams = teams.data.data;
            this.filter.teams = [];
          } else {
            this.teamList = teams.data.data;
            this.filterByTeam.teamList = "";
          }
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    getCompetitionsBySeason() {
      this.$store.commit("root/loading", true);
      console.log({ season: this.filterByComp.season });
      let url = "/nrl/api/v1/admin/competitions?";
      if (this.filterByComp.season.length === 1) {
        url += "season=" + this.filterByComp.season[0];
      } else {
        this.filterByComp.season.forEach((szn, i) => {
          url += "season=" + szn;
          if (this.filterByComp.season.length - 1 !== i) url += "&";
        });
      }

      //`/nrl/api/v1/admin/competitions?season=${this.filterByComp.season}`
      Promise.all([this.$http.get(url)])
        .then(([competitions]) => {
          this.competitions = competitions.data.data;
          this.filterByComp.competitions = "";
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    resetCounters() {
      //On tab change
      this.count = -1;
      this.countByClub = -1;
      this.countByComp = -1;
      this.countByTeam = -1;
      // send email body reset to initial stage
      this.message = {
        subject: "",
        content: "",
        exclusions: "",
      };
    },
    // async sendEmail(formName) {
    //   try {
    //     // this.$refs[formName].validate(valid => {

    //     if (this.message.subject && this.message.content) {
    //       // if (valid) {
    //       const exclusions = _.chain(this.message.exclusions.split(","))
    //         .map(_.trim)
    //         .map(_.toLower)
    //         .filter(_.length)
    //         .uniq()
    //         .value();

    //       let criteria = {};
    //       // let filterBy = ""; // it can be 'By Club' or 'By Competition' or 'By Team'
    //       if (
    //         this.count === this.countByClub &&
    //         ((this.filterBy === "By Club" && this.clearance === roles.associationAdmin) ||
    //           (this.filterBy === "By Team" && this.clearance === roles.clubAdmin))
    //       ) {
    //         criteria = this.filter;
    //       }
    //       if (this.count === this.countByComp && this.filterBy === "By Competition") {
    //         criteria = this.filterByComp;
    //       }
    //       if (this.count === this.countByTeam && this.filterBy === "By Team") {
    //         criteria = this.filterByTeam;
    //       }

    //       if (this.clearance === roles.programAdmin || this.clearance === roles.gamedevregionAdmin)
    //         criteria = this.filter;

    //       const body = {
    //         subject: this.message.subject,
    //         content: this.message.content,
    //         criteria: criteria,
    //         exclusions,
    //         filterBy: this.filterBy,
    //         parentEmail: this.parentEmail,
    //       };
    //       this.$store.commit("root/loading", true);

    //       const response = await this.$http.post(
    //         "/nrl/api/v1/admin/communications/send-email",
    //         body
    //       );

    //       this.resetMessageForm();
    //       this.$customSuccess();
    //       this.$store.commit("root/loading", false);
    //     } else {
    //       this.$customError(errormsg.input_option);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     this.$customError();
    //     this.$store.commit("root/loading", false);
    //   }
    //   // });
    // },
    async sendEmail(formName) {
      try {
        if (this.message.subject && this.message.content) {
          const exclusions = _.chain(this.message.exclusions.split(","))
            .map(_.trim)
            .map(_.toLower)
            .filter(_.length)
            .uniq()
            .value();

          let criteria = {};
          // let filterBy = ""; // it can be 'By Club' or 'By Competition' or 'By Team'
          if (
            this.count === this.countByClub &&
            ((this.filterBy === "By Club" && this.clearance === roles.associationAdmin) ||
              (this.filterBy === "By Team" && this.clearance === roles.clubAdmin))
          ) {
            criteria = this.filter;
            this.parentEmail = this.filter.parentEmail;
          }
          if (this.count === this.countByComp && this.filterBy === "By Competition") {
            criteria = this.filterByComp;
            this.parentEmail = this.filterByComp.parentEmail;
          }
          if (this.count === this.countByTeam && this.filterBy === "By Team") {
            criteria = this.filterByTeam;
            this.parentEmail = this.filterByTeam.parentEmail;
          }

          if (
            this.clearance === roles.programAdmin ||
            this.clearance === roles.gamedevregionAdmin
          ) {
            criteria = this.filter;
            this.parentEmail = this.filter.parentEmail;
          }

          const body = {
            subject: this.message.subject,
            content: this.message.content,
            memberType: criteria.memberType,
            regoStatus: criteria.regoStatus,
            season: criteria.season,
            ageLevel: criteria.ageLevel,
            teams: criteria.teams,
            clubs: criteria.clubs,
            competitions: criteria.competitions,
            teamList: criteria.teamList,
            reminder: criteria.reminder,
            program: criteria.program,
            gender: criteria.gender,
            exclusions,
            filterBy: this.filterBy,
            parentEmail: this.parentEmail,
            count: false,
          };

          this.$store.commit("root/loading", true);

          const response = await this.$http.post(
            "/nrl/api/v1/admin/communications/members/registrations-comms/count",
            body
          );
          // const response = await this.$http.post(
          //   "/nrl/api/v1/admin/communications/send-email",
          //   body
          // );

          this.resetMessageForm();
          this.$customSuccess();
          this.$store.commit("root/loading", false);
        } else {
          this.$customError(errormsg.input_option);
        }
      } catch (e) {
        this.$customError(e.response.data.message);
        this.$store.commit("root/loading", false);
      }
      // });
    },
    resetMessageForm() {
      this.$set(this.message, "subject", "");
      this.$set(this.message, "content", "");
      this.$set(this, "preview", "");
    },
    formatHtml(htmlString) {
      htmlString = _.escape(htmlString);
      htmlString = htmlString.replace(/(?:\r\n|\r|\n)/g, "<br/>");
      return htmlString;
    },
    dateFormatter(row) {
      const timeGiven =
        typeof row.createdAt === "number"
          ? this.moment(row.createdAt).format("HH:mm")
          : row.createdAt;
      const time = timeGiven ? timeGiven + ", " : "";
      return row.createdAt ? time + this.moment(row.createdAt).format("MMMM Do YYYY") : "NA";
      // return row.sendDate ? this.moment(row.sendDate).format("MMMM Do YYYY") : "NA";
    },
    handleRowClick(row) {
      this.$router.push({
        name: "communications.details",
        params: {
          id: row._id,
        },
      });
    },
  },
  computed: {
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === nationals.TFA;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    entity() {
      return this.$store.getters["user/activeRole"].entity;
    },
    user() {
      return this.$store.getters["user/profile"];
    },
    memberTypes() {
      if (this.$store.getters["user/activeRole"].national_id === nationals.TFA) {
        return memberTypes.filter((x) => x.tfa);
      }
      return memberTypes;
    },
    memberTypesForCompTeam() {
      if (this.$store.getters["user/activeRole"].national_id === nationals.TFA) {
        // For TFA , we don't show Membertype - Referee and Volunteer for Competition and Team
        return memberTypes.filter((x) => x.tfa && x.name !== "Referee" && x.name !== "Volunteer");
      }
      return memberTypes;
    },
  },
  watch: {
    "message.content": function (val) {
      this.preview = this.formatHtml(val);
    },
    "filter.season": function (val) {
      if (val === currentSeason) {
        this.filter.regoStatus = "";
      }
    },
    "filter.program": function (val) {
      const prog = this.programs.find((x) => x._id === val);
      if (prog) {
        this.name = prog.name;
        this.contact = prog.gdo;
        this.logo = prog.settings._id ? getS3URL(prog.settings._id) : "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
