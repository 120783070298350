<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Club Details">
        <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="6" class="avatar-box entity-card">
            <img
              v-if="currentLogo"
              :src="currentLogo"
              @error="displayPlaceholder"
              alt=""
              class="avatar"
            />
            <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar" />
            <vue-upload-component
              v-if="this.$route.params.type !== 'insert'"
              ref="upload"
              :drop="true"
              v-model="files"
              :extensions="extensions"
              :accept="accept"
              :post-action="uploadURL"
              :headers="uploadHeaders"
              :data="entityData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
              class="el-button el-button--default upload"
            >
              Upload
            </vue-upload-component>
          </el-col>
          <el-col :span="18">
            <el-form
              label-position="top"
              :model="club"
              :rules="clubRules"
              ref="club"
              class="login-form"
            >
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="association" label="Association">
                    <el-select
                      v-model="association"
                      placeholder="Select an association"
                      filterable
                      :disabled="type === 'update'"
                    >
                      <el-option
                        v-for="item in associations"
                        :label="item.name"
                        :key="item._id"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="name" label="Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="clubName"
                      placeholder="Enter a name"
                      :disabled="clearance !== roles.superAdmin"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.abbreviation" label="Abbreviation">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.meta.abbreviation"
                      placeholder="Enter an abbreviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.facebook" label="Facebook Link">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.meta.facebook"
                      placeholder=""
                    >
                      <template slot="prepend">https://facebook.com/</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.name" label="Contact Name">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.contact.name"
                      placeholder="Enter a contact name"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.number" label="Contact Number">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.contact.number"
                      placeholder="Enter a contact number"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="contact.email" label="Contact Email">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.contact.email"
                      placeholder="Enter a contact email"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contact.address" label="Address">
                    <div class="el-input">
                      <google-auto-complete
                        class="auto-complete"
                        id="map"
                        @placechanged="getAddressData"
                        v-model="currentFormattedAddress"
                        placeholder="Enter an address"
                      >
                      </google-auto-complete>
                    </div>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="venue" label="Venue">
                    <el-select
                      v-model="venue"
                      :remote="true"
                      :remote-method="filter"
                      placeholder="Enter venue to start searching"
                      filterable
                    >
                      <el-option
                        v-for="item in allvenues"
                        :label="item.name + ' - ' + item.address.suburb"
                        :key="item.name + ' - ' + item.address.suburb"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.posid" label="POSID (Optional)">
                    <el-input
                      :disabled="clearance !== roles.superAdmin"
                      type="text"
                      autocomplete="off"
                      v-model="club.meta.posid"
                      placeholder="Enter club's POSID"
                    />
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="isActive" label="Active">
                    <el-select
                      v-model="club.isActive"
                      :disabled="
                        ![
                          roles.associationAdmin,
                          roles.regionAdmin,
                          roles.stateAdmin,
                          roles.superAdmin
                        ].includes(clearance)
                      "
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.gstRegistered" label="GST Registered">
                    <el-select
                      v-model="club.meta.gstRegistered"
                      :disabled="
                        ![
                          roles.clubAdmin,
                          roles.associationAdmin,
                          roles.regionAdmin,
                          roles.stateAdmin,
                          roles.superAdmin
                        ].includes(clearance)
                      "
                    >
                      <el-option label="Yes" :value="true" />
                      <el-option label="No" :value="false" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
              <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
                <el-col :span="12">
                  <el-form-item prop="meta.website" label="Website">
                    <el-input
                      type="text"
                      autocomplete="off"
                      v-model="club.meta.website"
                      placeholder="Enter a website"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="meta.abn" label="ABN">
                    <el-select
                      v-model="club.meta.abn"
                      :disabled="
                        ![
                          roles.clubAdmin,
                          roles.associationAdmin,
                          roles.regionAdmin,
                          roles.stateAdmin,
                          roles.superAdmin
                        ].includes(clearance)
                      "
                      filterable
                      remote
                      placeholder="Search by name"
                      :loading="abnLoading"
                      :remote-method="searchAbn"
                    >
                      <el-option
                        v-for="item in abnOptions"
                        :key="item.value"
                        :label="`${item.label} (${item.value})`"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </div>
            </el-form>
            <action-buttons
              submit
              :cancel="!isMyEntity"
              @submit="submitForm('club')"
              @cancel="cancelForm()"
            />
          </el-col>
        </div>
      </el-tab-pane>
      <el-tab-pane
        class=""
        label="Finder Details"
        v-if="this.$store.getters['user/activeRole'].national_id === 32"
      >
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form class="tw-flex-1">
            <div class="tw-max-w-5xl " :span="24">
              <el-form-item label="Club Registration Link">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="clubFinderLink"
                  readonly
                  disabled=""
                >
                  <template slot="append">
                    <el-button
                      v-clipboard="clubFinderLink"
                      @click="$customSuccess('Successfully copied to clipboard')"
                    >
                      Copy
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <el-form class="" label-position="top" id="finderDetails" ref="finderDetails">
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="12">
              <el-form-item label="Active in Finder?">
                <el-select v-model="club.activeInFinder">
                  <el-option label="Yes" :value="true" />
                  <el-option label="No" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="What we offer?">
                <el-select v-model="club.finderDetails.contactType">
                  <el-option value="Contact"></el-option>
                  <el-option value="Non-Contact"></el-option>
                  <el-option value="Both"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div
            class="tw-flex tw-flex-wrap tw-flex-1 tw-w-full tw-gap-3 tw-py-3 tw-border tw-border-gray-100"
          >
            <div class="tw-flex-1">
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2 tw-text-gray-600 tw-uppercase">Ages offered</span>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2 ">Under 5</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U5">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2">U6-U9</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U6toU9">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2">U10-U12</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U10toU12">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2">U13-U16</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U13toU16">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2">U17-U18</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U17toU18">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="tw-flex tw-py-3 tw-text-sm">
                <span class="tw-w-1/2">19+</span>
                <el-checkbox-group class="tw-w-1/2" v-model="club.finderDetails.ages.U19">
                  <el-checkbox label="Male"></el-checkbox>
                  <el-checkbox label="Female"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="tw-flex-1">
              <el-form-item label="Description">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="Please input a description to display in Finder"
                  v-model="club.finderDetails.description"
                >
                </el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>

        <action-buttons
          submit
          :cancel="!isMyEntity"
          @submit="submitForm('club')"
          @cancel="cancelForm()"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Member Types"
        v-if="this.$store.getters['user/activeRole'].national_id === 32"
      >
        <el-row>
          <el-form label-position="top" id="memberTypes" ref="memberTypes">
            <el-checkbox-group
              v-model="club.memberTypes"
              :disabled="clearance !== roles.superAdmin"
            >
              <div class="d-flex">
                <div class="d-flex-column">
                  <el-checkbox
                    v-for="type in fpfilter(o => o.type.includes('player'))(memberTypes)"
                    :key="type.name"
                    :label="type.type"
                    :disabled="type.stateType !== stateType && type.stateType !== 'both'"
                  >
                    {{ type.name }}
                  </el-checkbox>
                </div>
                <div class="d-flex-column">
                  <el-checkbox
                    v-for="type in fpfilter(o => !o.type.includes('player'))(memberTypes)"
                    :key="type.name"
                    :label="type.type"
                    :disabled="type.stateType !== stateType && type.stateType !== 'both'"
                  >
                    {{ type.name }}
                  </el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </el-form>
          <action-buttons
            submit
            :cancel="!isMyEntity"
            @submit="submitForm('club')"
            @cancel="cancelForm()"
          />
        </el-row>
      </el-tab-pane>
      <el-tab-pane
        label="Clearance Auto Approvals"
        v-if="
          clearance === roles.clubAdmin && this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="club" key="clearance" type="clearance" />
      </el-tab-pane>
      <el-tab-pane
        label="Permit Auto Approvals"
        v-if="
          clearance === roles.clubAdmin && this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <auto-approvals :entity="club" key="permit" type="permit" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { isNaN } from "lodash";
import fpfilter from "lodash/fp/filter";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, memberTypes, roles } from "../../utils/constants";
import { parseGoogleAddress, validateEmail, validatePhoneNumber } from "../../utils/utilities";
import AutoApprovals from "../auto-approvals/AutoApprovals.vue";

export default {
  name: "ClubForm",
  props: {},
  components: {
    ActionButtons,
    AutoApprovals
  },
  data() {
    const validateAssociation = (rule, value, callback) => {
      const { club } = this;
      if (!club || !club.orgtree.association._id || club.orgtree.association._id === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateAddress = (rule, value, callback) => {
      const { club } = this;
      if (
        !club ||
        !club.contact.address ||
        !club.contact.address.formatted ||
        club.contact.address.formatted === ""
      ) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };

    const validateVenue = (rule, value, callback) => {
      if (!this.club.venue._id || this.club.venue.name === "") {
        return callback(new Error(""));
      }
      return callback();
    };

    const validateContactEmail = (rule, value, callback) => {
      const { contact } = this.club;
      if (!contact || !contact.email || !validateEmail(contact.email)) {
        return callback(new Error(errormsg.email));
      }
      return callback();
    };

    const validateContactNumber = (rule, value, callback) => {
      const { contact } = this.club;
      if (!contact || !contact.number || !validatePhoneNumber(contact.number)) {
        return callback(new Error(errormsg.phone));
      }
      return callback();
    };

    return {
      type: "insert",
      nameUpdated: false,
      associations: [],
      allvenues: [],
      files: [],
      legalNames: [],
      abnOptions: [],
      abnLoading: false,
      clubFinderLink: "",
      club: {
        name: "",
        activeInFinder: false,
        orgtree: {
          association: {
            _id: "",
            name: ""
          }
        },
        venue: {
          _id: "",
          name: ""
        },
        meta: {
          abbreviation: "",
          avatar: "",
          social: "",
          posid: "",
          gstRegistered: false,
          abn: "",
          autoApprovals: [],
          website: ""
        },
        contact: {
          name: "",
          email: "",
          number: "",
          address: ""
        },
        memberTypes: [],
        finderDetails: {
          contactType: "",
          ages: {
            U5: [],
            U6toU9: [],
            U10toU12: [],
            U13toU16: [],
            U17toU18: [],
            U19: []
          },
          description: ""
        }
      },
      clubRules: {
        association: {
          type: "object",
          required: true,
          validator: validateAssociation,
          message: errormsg.select_option,
          trigger: "blur"
        },
        name: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        venue: {
          type: "text",
          required: true,
          validator: validateVenue,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.posid": {
          required: false,
          trigger: "blur",
          message: errormsg.posid_number,
          validator: (rule, val, cb) => {
            if (val && isNaN(+val)) {
              return cb(new Error(errormsg.posid_number));
            }
            return cb();
          }
        },
        "meta.abbreviation": {
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.abn": {
          message: errormsg.select_option,
          trigger: "blur"
        },
        "contact.name": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "contact.number": {
          required: true,
          message: errormsg.phone,
          trigger: "blur",
          validator: validateContactNumber
        },
        "contact.email": {
          required: true,
          message: errormsg.email,
          trigger: "blur",
          validator: validateContactEmail
        },
        "contact.address": {
          required: true,
          message: errormsg.input_option,
          validator: validateAddress,
          trigger: "blur"
        },
        isActive: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.website": {
          validator: (rule, val, callback) => {
            const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            const regex = new RegExp(expression);

            if (val && val.length && !val.match(regex)) {
              return callback(new Error(errormsg.invalidUrl));
            }
            return callback();
          },
          trigger: "blur"
        }
      },
      roles,
      memberTypes,
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png"
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, club, id } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");
    // Load Club and Associations
    if (id && id !== "" && id !== "add" && !club) {
      this.$http
        .get(`/nrl/api/v1/admin/clubs/${id}`)
        .then(clubRes => {
          const clubres = clubRes.data.data;
          if ("venue" in clubres === false) {
            clubres.venue = { name: null, _id: null };
          }
          this.club = Object.assign({}, this.club, clubres);
          if (this.club.meta.gstRegistered !== true)
            this.club.meta = Object.assign({}, this.club.meta, { gstRegistered: false });

          /* construct club finder link */
          this.clubFinderLink = this.finderLink();

          if (!this.isMyEntity) {
            this.$http
              .get("/nrl/api/v1/admin/associations/")
              .then(assocRes => {
                this.associations = assocRes.data.data;
                this.$store.commit("root/loading", false);
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.associations = [this.club.orgtree.association];
            this.$store.commit("root/loading", false);
          }
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else {
      // Load Associations
      this.$http
        .get("/nrl/api/v1/admin/associations/")
        .then(response => {
          this.associations = response.data.data;
          // Include Club Data if update and passed through props
          if (club) {
            const clubres = club;
            if ("venue" in clubres === false) {
              clubres.venue = { name: null, _id: null };
            }
            this.club = Object.assign({}, this.club, clubres);
            this.clubFinderLink = this.finderLink();
          }
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
  methods: {
    finderLink() {
      const portalURL =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-profile.mysideline.xyz/"
          : "https://profile.mysideline.com.au/";
      const baseLink = new URL("/register/clubsearch", portalURL);
      baseLink.searchParams.set("criteria", String(this.club.name));
      baseLink.searchParams.set("club", String(this.club._id));
      const source = this.club.orgtree.national._id === 32 ? "rugby-league" : "touch-football";
      baseLink.searchParams.set("source", source);
      return String(baseLink);
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    searchLegalNames(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResults: 50 })
            .then(response => {
              this.legalNames = response.data.data;
            })
            .catch(() => {});
        }, 200);
      } else {
        this.legalNames = [];
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.club.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.club.meta;
            const { _id } = this.club;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/clubs/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const currentClub = this.club;
          if (this.type === "update") {
            this.$store.commit("root/loading", true);
            const update = {
              ...currentClub,
              orgtree: currentClub.orgtree,
              venue: currentClub.venue
            };
            const url = `/nrl/api/v1/admin/clubs/${currentClub._id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customSuccess();

                // update data member in store
                this.$store.commit("entities/updateDataMember", { entity: "clubs", data: update });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.nameUpdated = false;
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const insert = currentClub;
            const url = "/nrl/api/v1/admin/clubs/";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
                this.$router.push({
                  name: "clubs"
                });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
          return true;
        }
        return false;
      });
    },
    cancelForm() {
      this.$router.push({
        name: "clubs"
      });
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then(response => {
              this.allvenues = response.data.data;
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allvenues = [];
      }
    },
    getAddressData(addressData, place) {
      this.club.contact.address = parseGoogleAddress(addressData, place);
    },
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then(response => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map(o => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    },
    fpfilter
  },
  computed: {
    currentFormattedAddress() {
      return this.club.contact.address ? this.club.contact.address.formatted : null;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    entityData() {
      return {
        entity_type: "club",
        entity_id: this.club._id
      };
    },
    uploadURL() {
      return uploadURL;
    },
    currentLogo() {
      return this.club.meta && this.club.meta.avatar ? this.club.meta.avatar : false;
    },
    clubName: {
      get() {
        return this.club.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.club.name = value;
      }
    },
    association: {
      get() {
        return this.club.orgtree.association._id;
      },
      set(associd) {
        const foundAssoc = this.associations.find(obj => obj._id === associd);
        if (foundAssoc) {
          const { orgtree, _id, name } = foundAssoc;
          orgtree.association = { _id, name };
          this.club.orgtree = orgtree;
        }
      }
    },
    venue: {
      get() {
        return this.club.venue && this.club.venue.name && this.club.venue.name !== ""
          ? this.club.venue.name
          : null;
      },
      set(value) {
        const venue = this.allvenues.find(v => v._id === value);
        this.club.venue.name = venue.name;
        this.club.venue._id = venue._id;
        this.allvenues = [];
      }
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    stateType() {
      return this.club.orgtree.state ? this.club.orgtree.state.type : "";
    },
    isMyEntity() {
      return this.$route.path.split("/").includes("my");
    }
  },
  watch: {
    stateType(newStateType) {
      if (this.$store.getters["user/activeRole"].national_id === 32) {
        // NRL
        if (this.type === "insert" && newStateType === "club-league") {
          this.club = Object.assign({}, this.club, {
            memberTypes: ["player", "player-league-tag", "coach", "trainer", "volunteer"]
          });
        } else if (this.type === "insert" && newStateType === "referee") {
          this.club = Object.assign({}, this.club, {
            memberTypes: ["referee", "coach", "volunteer"]
          });
        }
      } else {
        // TFA
        this.club = Object.assign({}, this.club, {
          memberTypes: ["player-touch", "coach", "volunteer"]
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.entity-card {
  height: 46rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.update-payment {
  width: 50%;
  margin: 0 auto;
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}

.age {
  span {
    flex: 1 0 auto;
  }

  .el-checkbox-group {
    margin-right: 30%;
  }
}
</style>
