<template>
  <div>
    <data-tables
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column prop="type" label="Type" width="auto"> </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        :formatter="statusFormatter"
        prop="isActive"
        label="Active Status"
        width="auto"
      >
      </el-table-column>
      <el-table-column
        :formatter="contactFormatter"
        prop="isContact"
        label="Contact Status"
        width="auto"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
export default {
  name: "ProgramsettingsList",
  props: {},
  data() {
    return {
      loading: true,
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true
      },
      paginationProps: {
        pageSizes: [20, 50, 100]
      }
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/programs-settings")
      .then(response => {
        this.tableData = response.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    handleRowClick(row) {
      this.$router.push({
        name: "programsetting.update",
        params: {
          type: "update",
          program: row,
          id: row._id
        }
      });
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    statusFormatter(row) {
      return row.isActive === true || row.isActive == undefined ? "Active" : "Inactive";
    },
    contactFormatter(row) {
      return row.isContact === false || row.isContact == undefined ? "Non-contact" : "Contact";
    }
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}
.el-pagination {
  margin-top: 1rem !important;
}
</style>
