<template>
  <el-row class="team-profile">
    <el-row class="go-back">
      <el-button
        @click="$router.push('/squad-management')"
        class="go-back-button"
        icon="el-icon-arrow-left"
      >
        Go Back
      </el-button>
    </el-row>
    <div class="box-card" id="squad-management">
      <div class="team-detail">Name: {{ (currentTeam && currentTeam.name) || "" }}</div>
      <div class="team-detail">Age Level: {{ (currentTeam && currentTeam.ageLvl) || "" }}</div>
      <div class="team-detail">Member Type: {{ teamMemberType }}</div>
      <br />
      <el-tabs type="card">
        <el-tab-pane label="Players" v-if="currentTeam.memberType !== 'referee'">
          <div
            class="tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
          >
            <label class="tw-w-32"> Search by: </label>
            <el-input
              class="tw-w-48"
              autocomplete="off"
              clearable
              v-model="playerFilters.firstName"
              placeholder="First Name"
            >
            </el-input>
            <el-input
              class="tw-w-48"
              autocomplete="off"
              clearable
              v-model="playerFilters.lastName"
              placeholder="Last Name"
            >
            </el-input>
            <el-select
              class="tw-w-32"
              clearable
              v-model="playerFilters.gender"
              placeholder="Gender"
            >
              <el-option v-for="item in ['Male', 'Female']" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-button type="success" @click="updateListPlayers">Search</el-button>
          </div>

          <el-row :gutter="10">
            <el-transfer
              @change="clearFilters"
              class="player-squad"
              v-model="squadAllocation.players"
              :props="{
                key: '_id',
                label: 'name',
              }"
              :button-texts="['Remove From Squad', 'Add To Squad']"
              :titles="['Members', 'Current Squad']"
              :data="squadAllocation.availablePlayers"
            >
            </el-transfer>
          </el-row>
          <el-row :gutter="10" class="update-squad-box">
            <el-button type="success" class="update-squad" @click="updateSquad">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Non-Players">
          <div
            class="tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100"
          >
            <label class="tw-w-32"> Search by: </label>
            <el-input
              class="tw-w-48"
              autocomplete="off"
              v-model="nonPlayerFilters.firstName"
              placeholder="First Name"
              clearable
            >
            </el-input>
            <el-input
              class="tw-w-48"
              autocomplete="off"
              v-model="nonPlayerFilters.lastName"
              placeholder="Last Name"
              clearable
            >
            </el-input>
            <el-select
              clearable
              v-model="nonPlayerFilters.nonPlayerType"
              placeholder="Role"
              :disabled="currentTeam.memberType === 'referee'"
            >
              <el-option
                v-for="{ name, type } in nonPlayerRoles"
                :key="type"
                :label="name"
                :value="type"
              >
              </el-option>
            </el-select>
            <el-button type="success" @click="updateListNonPlayers">Search</el-button>
          </div>
          <el-row :gutter="10">
            <el-transfer
              @change="clearFilters"
              class="player-squad"
              v-model="squadAllocation.nonplayers"
              :props="{
                key: '_id',
                label: 'name',
              }"
              :button-texts="['Remove From Squad', 'Add To Squad']"
              :titles="['Members', 'Current Squad']"
              :data="squadAllocation.availableNonPlayers"
            >
            </el-transfer>
          </el-row>
          <el-row :gutter="10" class="update-squad-box">
            <el-button type="success" class="update-squad" @click="updateSquad">
              Update Squad
            </el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          label="Player Points"
          v-if="clearance === roles.associationAdmin && currentTeam.memberType !== 'referee'"
        >
          <player-points :team="currentTeam" :squadPlayers="squadPlayers" />
        </el-tab-pane>
        <el-tab-pane label="Default Squad" v-if="competitionId && competitionId.length > 0">
          <default-squad
            :team="currentTeam"
            :teamid="currentTeam._id"
            :positions="availablePositions"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-row>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import PlayerPoints from "./SquadManagementPlayerPoints.vue";
import DefaultSquad from "./DefaultSquad.vue";
import squadManagementStore from "../../stores/squad-management";
import { createHelpers } from "vuex-map-fields";
import { orgtree, errormsg, ageLevels, memberTypes, roles } from "../../utils/constants";

export default {
  name: "TFASquadManagementDetail",
  components: {
    PlayerPoints,
    DefaultSquad,
  },
  props: {},
  data() {
    return {
      roles,
      orgtree,
      errormsg,
      ageLevels,
      currentTeam: {},
      loading: true,
      playerFilters: {
        firstName: "",
        lastName: "",
        gender: "",
      },
      nonPlayerFilters: {
        firstName: "",
        lastName: "",
        nonPlayerType: "",
      },
      teamAgeLvl: "",
      squadAllocation: {
        availablePlayers: [],
        availableNonPlayers: [],
        players: [],
        nonplayers: [],
      },
      memberTypes,
      availablePositions: [],
      competitionId: null,
    };
  },
  beforeCreate() {
    this.$store.registerModule("squad-management", squadManagementStore);
  },
  beforeDestroy() {
    this.$store.commit("squad-management/resetState");
    this.$store.unregisterModule("squad-management");
  },
  mounted() {
    const { id } = this.$route.params;
    this.$store.commit("app/loading", true);
    Promise.all([
      this.$http.get(`/nrl/api/v1/admin/teams/${id}`),
      this.$http.get(`/nrl/api/v1/admin/squad/players/${id}`),
      this.$http.get(`/nrl/api/v1/admin/squad/nonplayers/${id}`),
      this.$http.get(`/nrl/api/v1/admin/teams/${id}/competition`),
    ])
      .then(([team, playerRes, nonPlayerRes, compId]) => {
        this.currentTeam = team.data.data;
        this.teamAgeLvl = this.currentTeam && this.currentTeam.ageLvl;
        const { players } = playerRes.data.data;
        const { nonplayers } = nonPlayerRes.data.data;
        this.competitionId = compId.data.data;

        this.squadAllocation.availablePlayers = _.map(players, this.formatWithName).sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.squadAllocation.availableNonPlayers = _.map(nonplayers, this.formatWithName).sort(
          (a, b) => a.name.localeCompare(b.name)
        );

        this.squadAllocation.players = _.map(players, "_id");
        this.squadAllocation.nonplayers = _.map(nonplayers, "_id");

        if (this.currentTeam.memberType === "referee")
          this.nonPlayerFilters.nonPlayerType = "referee";

        this.$store.commit("app/loading", false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit("app/loading", false);
      });
  },
  methods: {
    clearFilters() {
      this.nameFilter = "";
      this.genderFilter = "";
      this.roleFilter = "";
    },
    // formatters
    modifiedFormatter(row) {
      return row.modifiedAt ? moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    formatWithName(pl) {
      const formatDOB = pl.profile.dob.split("-");
      return {
        ...pl,
        name: `${pl.profile.firstName} ${pl.profile.lastName} ${formatDOB[2]}/${formatDOB[1]}/${formatDOB[0]}`,
      };
    },
    // update squad
    updateSquad() {
      this.$store.commit("app/loading", true);
      const { players, nonplayers } = this.squadAllocation;
      const { _id: teamid } = this.currentTeam;
      Promise.all([
        this.$http.put(`/nrl/api/v1/admin/squad/${teamid}`, { players, type: "player" }),
        this.$http.put(`/nrl/api/v1/admin/squad/${teamid}`, { nonplayers, type: "nonplayer" }),
      ])
        .then(([playerResponse, nonPlayerRes]) => {
          if (playerResponse.data.message) {
            this.$customError(playerResponse.data.message);
          }
          if (nonPlayerRes.data.message) {
            this.$customError(nonPlayerRes.data.message);
          }
          if (!playerResponse.data.message && !nonPlayerRes.data.message) {
            this.$customSuccess();
          }

          // Remove player assigned by super admin
          this.squadAllocation.availablePlayers = _.filter(
            this.squadAllocation.availablePlayers,
            (ap) =>
              !(ap.assignedBySuper === true && !_.includes(this.squadAllocation.players, ap._id))
          );

          this.$store.commit("app/loading", false);
        })
        .then(() => {
          setTimeout(() => {
            location.reload();
          }, 1000);
        })
        .catch(() => {
          this.$store.commit("app/loading", false);
          this.$customError();
        });
    },
    updateListPlayers() {
      this.$store.commit("app/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/squad/tfa/players/search/${this.currentTeam._id}`, {
          params: this.playerFilters,
        })
        .then((res) => {
          const { players, entityPlayers } = res.data.data;
          this.squadAllocation.availablePlayers = _.map(
            [...players, ...entityPlayers],
            this.formatWithName
          ).sort((a, b) => a.name.localeCompare(b.name));
          this.$store.commit("app/loading", false);
        })
        .catch(() => {
          this.$store.commit("app/loading", false);
        });
    },
    updateListNonPlayers() {
      this.$store.commit("app/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/squad/tfa/nonplayers/search/${this.currentTeam._id}`, {
          params: this.nonPlayerFilters,
        })
        .then((res) => {
          const { nonplayers, entityNonPlayers } = res.data.data;
          this.squadAllocation.availableNonPlayers = _.map(
            [...nonplayers, ...entityNonPlayers],
            this.formatWithName
          ).sort((a, b) => a.name.localeCompare(b.name));
          this.$store.commit("app/loading", false);
        })
        .catch(() => {
          this.$store.commit("app/loading", false);
        });
    },
  },
  computed: {
    teamMemberType() {
      const type = _.find(memberTypes, (m) => m.type === _.get(this.currentTeam, "memberType"));
      return type ? type.name : null;
    },
    nonPlayerRoles() {
      if (this.clearance === roles.clubAdmin)
        return this.memberTypes.filter((p) => p.type === "coach");
      if (this.currentTeam.memberType === "referee")
        return this.memberTypes.filter((p) => p.name === "Referee");
      return this.memberTypes.filter((p) => p.playing === false && p.tfa);
    },
    squadPlayers() {
      return this.squadAllocation.availablePlayers.filter((x) =>
        this.squadAllocation.players.includes(x._id)
      );
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
  watch: {
    competitionId() {
      const compid =
        this.competitionId && this.competitionId.length > 0 ? this.competitionId[0]._id : null;
      if (!compid) return;
      this.$http
        .get(`/nrl/api/v1/admin/competitions-settings/competition/${compid}`)
        .then((settingRes) => {
          this.availablePositions = settingRes.data.data.positions;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}

.go-back-button {
  float: left;
}

.team-detail {
  padding: 0.4rem;
}

.box-card {
  margin-top: 1rem;
  text-align: left;
}

.player-squad {
  margin-top: 2rem;
  text-align: center;
}

.update-squad-box {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.filter-box {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  span {
    margin-right: 2rem;
  }
  .el-input {
    width: 195px;
    height: 40px;
  }
  .el-input,
  .el-select {
    margin-right: 1rem;
  }
}
</style>
