<template>
  <div>
    <el-row>
      <el-col :span="24" class="mb-2 btn-container">
        <el-row class="mb-1">
          <el-button @click="goBack()"> Back</el-button>
          <el-button type="primary" @click="downloadMatchReport" style="float: right">
            Download Matches Report</el-button
          >
        </el-row>
        <el-row>
          <el-button type="primary" @click="downloadTemplate" style="float: right">
            Download Template</el-button
          >
        </el-row>
      </el-col>
    </el-row>
    <el-form label-position="top" :model="competition" id="drawbuilder" ref="drawbuilder">
      <el-row :gutter="10">
        <!-- select competition -->
        <el-col :span="12">
          <el-form-item prop="competition" label="Competition">
            <el-select value-key="_id" v-model="competition" disabled>
              <el-option v-for="item in allComps" :key="item._id" :label="item.name" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <div v-if="isCompValid">
          <el-col :span="4">
            <el-form-item prop="regularRounds" label="Regular Season Rounds">
              <el-input type="text" v-model="regularRounds" disabled />
            </el-form-item>
          </el-col>
          <!-- display final rounds number  -->
          <el-col :span="4">
            <el-form-item prop="finalRounds" label="Final Season Rounds">
              <el-input type="text" v-model="finalRounds" disabled />
            </el-form-item>
          </el-col>
          <!-- display start time  -->
          <el-col :span="4">
            <el-form-item prop="startTime" label="Default Start Time">
              <el-input
                type="text"
                autocomplete="off"
                v-model="startTime"
                placeholder=""
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item prop="" label="Current Round">
            <el-input type="text" v-model="redoDrawRegular.currentRound" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="competition && isCompValid" class="mb-2">
        <!-- table teams -->
        <el-col :span="12">
          <data-tables :data="competition.teams" layout="table" class="data-table">
            <el-table-column width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-clipboard="scope.row.name"
                  icon="el-icon-copy-document"
                  @click="$customSuccess('Successfully copied to clipboard')"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="_id" label="Team ID" width="auto"> </el-table-column>
            <el-table-column prop="name" label="Team Name" width="auto"> </el-table-column>
          </data-tables>
        </el-col>
        <!-- table venues -->
        <el-col :span="12">
          <el-select
            class="mt-1"
            v-model="selectedVenue"
            :remote="true"
            :remote-method="filterVenue"
            @change="selectedVenueChange"
            placeholder="Search venue"
            filterable
          >
            <el-option
              v-for="item in foundVenues"
              :label="item.name"
              :key="item._id"
              :value="item._id"
            >
            </el-option>
          </el-select>
          <data-tables :data="venues" layout="table" class="data-table">
            <el-table-column width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-clipboard="scope.row._id"
                  icon="el-icon-copy-document"
                  @click="$customSuccess('Successfully copied to clipboard')"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="_id" label="Venue ID" width="auto"> </el-table-column>
            <el-table-column prop="name" label="Venue Name" width="auto"> </el-table-column>
            <el-table-column width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-clipboard="scope.row._id"
                  icon="el-icon-close"
                  @click="removeVenue(scope.row._id)"
                ></el-button>
              </template>
            </el-table-column>
          </data-tables>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <!-- upload button && uploading errors -->
    <el-form :rules="redoDrawRegularRules" :model="redoDrawRegular">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="redoFromRound" label="Redo Draw From Round">
            <el-input-number
              controls-position="right"
              v-model="redoDrawRegular.redoFromRound"
              :min="redoDrawRegular.currentRound"
              :max="regularRounds"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-if="isCompValid">
      <!-- upload button -->
      <h4>Upload Draw</h4>
      <el-col :span="4" class="mb-2">
        <vue-upload-component
          ref="upload"
          @input="handleUpload"
          :drop="true"
          :extensions="extensions"
          :accept="accept"
          :multiple="false"
          class="el-button el-button--success upload"
        >
          Upload
        </vue-upload-component>
      </el-col>
      <!-- errors display -->
      <el-col :span="20">
        <el-alert
          v-for="(error, i) in this.errors"
          :key="i"
          type="error"
          :description="error"
          show-icon
        >
        </el-alert>
      </el-col>
    </el-row>
    <el-row v-if="isCompValid && uploaded && errors.length === 0">
      <el-col :span="24">
        <h1 class="align-text-center">Imported Draw Data</h1>
        <template v-for="{ type, number } in types">
          <h2 class="mt-1" :key="type" v-if="number > 0">{{ type }} Season</h2>
          <template v-for="round in number">
            <h3 :key="'head_' + round + type">Round {{ round || "n/a" }}</h3>
            <data-tables
              :key="'table_' + round + type"
              :data="uploaded[type][round]"
              layout="table"
              class="data-table"
            >
              <el-table-column prop="roundType" label="Round Type" width="auto"> </el-table-column>
              <el-table-column prop="homeTeam" label="Home Team" width="auto"> </el-table-column>
              <el-table-column prop="awayTeam" label="Away Team" width="auto"> </el-table-column>
              <el-table-column prop="venue" label="Venue" width="auto"> </el-table-column>
              <el-table-column prop="fieldNo" label="Field No" width="auto"> </el-table-column>
              <el-table-column
                :formatter="dateTimeFormatter"
                prop="dateTime"
                label="Date & Time"
                width="auto"
              >
              </el-table-column>
              <el-table-column prop="bye" label="BYE" width="auto"> </el-table-column>
              <el-table-column prop="tba" label="TBA" width="auto"> </el-table-column>
            </data-tables>
          </template>
        </template>
      </el-col>
    </el-row>
    <el-row v-if="errors.length === 0 && matches.length">
      <el-col :span="20" class="mb-2 btn-container">
        <el-button type="success" @click="saveMatches"> Save Matches</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { isInteger, uniqBy, map, find, sortBy, inRange, flatten } from "lodash";
import { errormsg } from "../../utils/constants";
import drawImportMixin from "../../mixins/draw-import";

export default {
  mixins: [drawImportMixin],
  data() {
    const redoDrawRegularRoundValidator = (obj, value, callback) => {
      if (
        !inRange(
          this.redoDrawRegular.redoFromRound,
          this.redoDrawRegular.currentRound,
          this.regularRounds + 1
        )
      ) {
        return callback(
          `Invalid redo draw round number. It should be between ${this.redoDrawRegular.currentRound} - ${this.regularRounds}`
        );
      }
      return callback();
    };
    return {
      redoDrawRegular: {
        currentRound: 0,
        roundType: "Regular",
        redoFromRound: 0,
      },
      redoDrawRegularRules: {
        redoFromRound: {
          required: true,
          trigger: "blur",
          message: "Invalid redo draw round number",
          validator: redoDrawRegularRoundValidator,
        },
      },
      matches: [],
      uploaded: null,
      errors: [],
      competition: null,
      compMatches: [],
      isCompValid: false,
      files: [],
      allComps: [],
      templateUrl: `${process.env.BASE_URL}Draw-Import-Template.xlsx`,
    };
  },
  methods: {
    // validate uploaded doc
    validate(doc, i) {
      const cols = {
        roundNumber: "A",
        roundType: "B",
        homeTeam: "C",
        awayTeam: "D",
        venueID: "E",
        fieldNo: "F",
        date: "G",
        time: "H",
        bye: "I",
        tba: "J",
      };
      // display only: skip 0 & header
      const index = i + 2;
      const errors = [];

      if (!doc.roundType) {
        errors.push(
          `Missing round type at cell ${cols.roundType}${index}, please check your spreadsheet`
        );
      }

      if (!["Regular", "Final"].includes(doc.roundType)) {
        errors.push(
          `Invalid round type '${doc.roundType}' at cell ${cols.roundType}${index}, round type must be 'Regular'`
        );
      }

      if (!doc.roundNumber || !isInteger(+doc.roundNumber)) {
        errors.push(
          `Missing round number at cell ${cols.roundNumber}${index}, please check your spreadsheet`
        );
      }

      if (
        doc.roundType === "Regular" &&
        !inRange(+doc.roundNumber, this.redoDrawRegular.redoFromRound, this.regularRounds + 1)
      ) {
        errors.push(
          `Invalid round number '${doc.roundNumber}' at cell ${cols.roundNumber}${index}, The round number should be valid in between ${this.redoDrawRegular.redoFromRound} - ${this.regularRounds}`
        );
      }

      if (doc.roundType === "Final" && !inRange(+doc.roundNumber, 1, this.finalRounds + 1)) {
        errors.push(
          `Invalid round number '${doc.roundNumber}' at cell ${cols.roundNumber}${index}, valid round number for this competition: 1 - ${this.finalRounds}`
        );
      }

      if (doc.homeTeam && !find(this.competition.teams, { name: doc.homeTeam })) {
        errors.push(
          `Invalid home team '${doc.homeTeam}' at cell ${cols.homeTeam}${index}, home team name does not exist in this competition`
        );
      }

      if (doc.awayTeam && !find(this.competition.teams, { name: doc.awayTeam })) {
        errors.push(
          `Invalid away team '${doc.awayTeam}' at cell ${cols.awayTeam}${index}, away team name does not exist in this competition`
        );
      }

      if (doc.venueID && !find(this.venues, { _id: +doc.venueID })) {
        errors.push(
          `Invalid venue id '${doc.venueID}' at cell ${cols.venueID}${index}, venue ID does not exist`
        );
      }

      if (doc.fieldNo && doc.fieldNo.length > 10) {
        errors.push(
          `Invalid field no '${doc.fieldNo}' at cell ${cols.fieldNo}${index}, field No is limited to 10 characters`
        );
      }

      if (doc.date && !this.moment(doc.date, "DD/MM/YYYY", true).isValid()) {
        errors.push(
          `Invalid date '${doc.date}' at cell ${cols.date}${index}, valid date format must be dd/mm/yyyy, e.g. 20/11/2022`
        );
      }

      if (doc.time && !this.moment(doc.time, "h:m A", true).isValid()) {
        errors.push(
          `Invalid time '${doc.time}' at cell ${cols.time}${index}, valid time format must be hh:mm A, e.g. 01:10 pm`
        );
      }

      if (doc.bye && doc.bye.toLowerCase() !== "yes" && doc.bye.toLowerCase() !== "no") {
        errors.push(
          `Invalid BYE value '${doc.bye}' at cell ${cols.bye}${index}, bye can only be empty, 'yes' or 'no'`
        );
      }

      if (doc.tba && doc.tba.toLowerCase() !== "yes" && doc.tba.toLowerCase() !== "no") {
        errors.push(
          `Invalid TBA value '${doc.tba}' at cell ${cols.tba}${index}, tba can only be empty, 'yes' or 'no'`
        );
      }
      doc.bye = doc.bye && doc.bye.toLowerCase() === "yes";
      doc.tba = doc.tba && doc.tba.toLowerCase() === "yes";

      // Validate team based on bye and tba
      if (!doc.bye && !doc.tba) {
        // Neither bye nor tba
        if (!doc.homeTeam) {
          errors.push(
            `Missing home team at cell ${cols.homeTeam}${index}, please check your spreadsheet`
          );
        }
        if (!doc.awayTeam) {
          errors.push(
            `Missing away team at cell ${cols.awayTeam}${index}, please check your spreadsheet`
          );
        }

        if (doc.awayTeam === doc.homeTeam) {
          errors.push(`Invalid at line ${index}, Home & Away team cannot be the same`);
        }

        if (!doc.venueID) {
          errors.push(
            `Missing venue id at cell ${cols.venueID}${index}, please check your spreadsheet`
          );
        }

        if (!doc.date) {
          errors.push(`Missing date at cell ${cols.date}${index}, please check your spreadsheet`);
        }

        if (!doc.time) {
          errors.push(`Missing time at cell ${cols.time}${index}, please check your spreadsheet`);
        }
      } else if (doc.bye && !doc.tba) {
        // Only bye
        if (!doc.homeTeam && !doc.awayTeam) {
          errors.push(
            `Missing home or away team at cell ${cols.homeTeam}${index} and cell ${cols.awayTeam}${index} for bye, bye must have one team, please check your spreadsheet`
          );
        }
        if (doc.homeTeam && doc.awayTeam) {
          errors.push(
            `Invalid home or away team at cell ${cols.homeTeam}${index} and cell ${cols.awayTeam}${index} for bye, bye can only have one team`
          );
        }

        if (!doc.date || !this.moment(doc.date, "DD/MM/YYYY", true).isValid()) {
          errors.push(
            `Invalid date ${cols.date}${index} for bye, valid date format must be dd/mm/yyyy, e.g. 20/11/2022`
          );
        }

        if (!doc.time || !this.moment(doc.time, "h:m A", true).isValid()) {
          errors.push(
            `Invalid time at cell ${cols.date}${index} for bye, valid time format must be hh:mm A, e.g. 01:10 pm`
          );
        }
      } else if (!doc.bye && doc.tba) {
        // Only tba
        if (doc.homeTeam && doc.awayTeam) {
          errors.push(
            `Invalid home or away team at cell ${cols.homeTeam}${index} and cell ${cols.awayTeam}${index} for tba, tba can only have one team or no team`
          );
        }
      } else {
        // Both bye and tba
        errors.push(
          `Invalid bye or tba at cell ${cols.bye}${index} and cell ${cols.tba}${index}, bye and tba cannot be both yes`
        );
      }
      return errors;
    },
    // process spreedsheet
    handleUpload(file) {
      this.resetUploadParts();

      if (
        !this.redoDrawRegular.redoFromRound ||
        !inRange(
          this.redoDrawRegular.redoFromRound,
          this.redoDrawRegular.currentRound,
          this.regularRounds + 1
        )
      ) {
        this.errors.push(
          `Invalid redo draw round number. It should be between ${this.redoDrawRegular.currentRound} - ${this.regularRounds}`
        );
      }

      // check regular whether matches had no status pre-game
      const hasNoneOfPregame = this.compMatches.some(
        (x) =>
          x.round.number >= this.redoDrawRegular.redoFromRound &&
          x.round.type === "Regular" &&
          x.status !== "pre-game"
      );
      if (hasNoneOfPregame) {
        this.errors.push(
          `Cannot redo draw, because some matches have a status of 'Final', 'Forfeit', 'Washed Out/Postponed' or 'Disputed' in Redo Draw From Round (Round ${this.redoDrawRegular.redoFromRound}) or later rounds.`
        );
      }

      this.processExcel(file).then((objects) => {
        if (!objects) {
          return;
        }

        if (objects.length === 0) {
          this.errors.push("Invalid draw builder import file");
          return;
        }
        // validation
        const validateErrors = objects.map(this.validate);
        this.errors = flatten(this.errors.concat(validateErrors));
        if (this.errors.length) {
          return;
        }

        // order by roundNumber and roundType
        objects = sortBy(objects, ["roundNumber", "roundType"]);

        // generate matches from uploaded docs
        this.matches = objects.map(this.reformSavingObject);

        this.uploaded = this.reformImportedResult(objects);
      });
    },
    // saving
    saveMatches() {
      this.$store.commit("root/loading", true);
      this.$confirm(
        "Are you sure you want to replace existing matches? This cannot be undone.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http
            .post("/nrl/api/v1/admin/matches/redo-draw", {
              matches: this.matches,
              ...this.redoDrawRegular,
              competitionId: this.competition._id,
            })
            .then(() => {
              this.resetUploadParts();
              this.$customSuccess();
              this.$store.commit("root/loading", false);
              this.$router.push({
                name: "matches.list",
                params: {
                  currentCompetition: this.competition._id,
                },
              });
            })
            .catch((err) => {
              this.$store.commit("root/loading", false);
              this.$customError(err.response.data.message);
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
    downloadTemplate() {
      fetch(this.templateUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Redo-Draw-Import-Template.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    downloadMatchReport() {
      this.$router.push({
        name: "matches-report",
        params: { competitionId: this.competition._id },
      });
    },
    goBack() {
      this.$router.push({
        name: "matches.list",
        params: {
          currentCompetition: this.competition._id,
        },
      });
    },
    resetUploadParts() {
      this.uploaded = null;
      this.matches = [];
      this.errors = [];
    },
    resetAll() {
      this.uploaded = null;
      this.matches = [];
      this.errors = [];
      this.isCompValid = false;
      this.venues = [];
    },
  },
  mounted() {
    const { id: compId } = this.$route.params;
    if (compId) {
      this.$store.commit("root/loading", true);
      this.$http
        .get(`/nrl/api/v1/admin/competitions/${compId}`)
        .then((response) => {
          this.competition = response.data.data;
          this.allComps = [this.competition];
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$customError();
          this.$store.commit("root/loading", false);
        });
    } else {
      this.$customError();
    }
  },
  computed: {
    // showing result table in loop
    types: {
      get() {
        return [
          {
            type: "Regular",
            number: Array(this.regularRounds - this.redoDrawRegular.redoFromRound + 1)
              .fill()
              .map((e, i) => i + this.redoDrawRegular.redoFromRound),
          },
          {
            type: "Final",
            number: Array(this.finalRounds)
              .fill()
              .map((e, i) => i + 1),
          },
        ];
      },
    },
  },
  watch: {
    competition(comp) {
      // validate competition
      if (comp.teams.length <= 2) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      if (comp.hasPools && comp.pools && comp.pools.some((pool) => pool.teams.length <= 2)) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      this.$store.commit("root/loading", true);
      this.$http.get(`/nrl/api/v1/admin/matches/competition/${comp._id}`).then((response) => {
        this.$store.commit("root/loading", false);
        if (!response.data.data.length) {
          this.$customError(errormsg.draw_matches);
          return false;
        }

        this.compMatches = response.data.data;

        // all passed then mark comp as valided
        this.isCompValid = true;

        // calculate current round
        // eslint-disable-next-line no-multi-assign
        this.redoDrawRegular.currentRound = this.redoDrawRegular.redoFromRound =
          this.getMinRedoRound(this.competition, this.compMatches);

        // get venues
        this.$store.commit("root/loading", true);
        this.$http
          .post(`/nrl/api/v1/admin/venues/competition/${comp._id}`)
          .then((res) => {
            const allVenues = map(res.data.data, "teamvenue");
            const uniqueVenues = uniqBy(allVenues, "_id");
            this.venues = this.venues.concat(uniqueVenues);
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$customError();
            this.$store.commit("root/loading", false);
          });
        return true;
      });
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}
h2 {
  margin: 0;
}
li {
  list-style: none;
  margin-bottom: 1rem !important;
}
ul {
  padding-inline-start: 10px !important;
  padding-top: 20px;
}
.btn-container {
  margin-top: 2rem;
  text-align: left;
}
.btn-container-right {
  margin-top: 2rem;
  text-align: right;
}
.el-select {
  width: 100%;
}
</style>
