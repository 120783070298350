/* eslint-disable no-return-assign */
import _ from 'lodash';

const initState = () => ({
    filters: {
        status: ['In Progress', '_blanks'],
        matchDateRange: undefined
    },
    data: []
});

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    reset: state => _.assign(state, initState()),
    updateStatusFilter: (state, payload) => state.filters.status = payload,
    updateDateRangeFilter: (state, payload) => state.filters.matchDateRange = payload,
    updateData: (state, payload) => state.data = payload
  }
};
