<template>
  <div>
    <el-dialog :visible.sync="redoDrawDialog" title="Redo Draw" width="22%" center>
      <span style="word-break: keep-all">How do you want to create your draw ?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="redraw" type="success" class="bulk-edit"> Draw Import </el-button>
        <el-button @click="redodrawBuilder" type="success" class="bulk-edit">
          Draw Builder</el-button
        >
        <el-button @click="redoDrawDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
    <div
      class="tw-grid tw-w-full tw-max-w-5xl tw-grid-cols-1 tw-gap-2 tw-pb-8 tw-border-b tw-border-gray-200 sm:tw-grid-cols-3"
    >
      <el-select
        class="select-competition"
        :disabled="bulkEditing === true"
        v-model="currentCompetition"
        @change="fetchMatchesByCompetition"
        placeholder="Select a competition"
        filterable
      >
        <el-option
          v-for="item in availableCompetitions"
          :label="item.name"
          :key="item._id"
          :value="item._id"
        >
        </el-option>
      </el-select>

      <div
        id="buttons"
        class="tw-flex tw-flex-col tw-flex-wrap tw-justify-end tw-col-span-2 tw-gap-3 sm:tw-flex-row sm:tw-pr-3"
      >
        <el-button @click="addMatch" type="success" icon="el-icon-plus" class="tw-w-48 tw-h-10">
          Add Match
        </el-button>

        <el-button
          v-if="currentCompetition && tableData.length && !bulkEditing"
          @click="redoDrawDialog = true"
          type="success"
          icon="el-icon-refresh-left"
          class="tw-w-48 tw-h-10"
        >
          Redo Draw
        </el-button>
        <el-button
          v-if="currentCompetition && tableData.length > 0 && bulkEditing === false"
          @click="beginBulkEdit"
          type="success"
          icon="el-icon-setting"
          class="tw-w-48 tw-h-10"
        >
          Bulk Edit
        </el-button>
        <!-- TODO GRABU-1273 final fixtures to be enabled once tested -->
        <el-button
          v-if="currentCompetition && tableData.length && !bulkEditing"
          @click="automateFinalFixtures"
          type="success"
          class="tw-h-10"
        >
          Automate Final Fixtures
        </el-button>
        <el-button
          v-if="currentCompetition && bulkEditing === true"
          @click="saveBulkEdit"
          type="success"
          icon="el-icon-setting"
          class="tw-w-48 tw-h-10 bulk-edit"
        >
          Save Edits
        </el-button>
        <el-button
          v-if="currentCompetition && bulkEditing === true"
          @click="cancelBulkEdit"
          class="tw-w-48 tw-h-10 bulk-edit"
        >
          Cancel
        </el-button>
      </div>
    </div>
    <data-tables
      v-loading="loading"
      :data="bulkEditing === true ? editData : tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column fixed align="left" prop="round.type" label="Round Type" width="100">
      </el-table-column>
      <el-table-column fixed align="left" prop="round.number" label="Round" width="100">
        <template slot-scope="scope">
          <el-input
            :id="`round-input-${scope.$index}`"
            class="round-edit-input"
            v-if="bulkEditing"
            autocomplete="off"
            placeholder="Round number"
            type="number"
            v-model="scope.row.round.number"
            @change="markAsEdited(scope)"
            min="1"
          ></el-input>
          <span v-else class="round-edit-span">{{ scope.row.round.number }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Home Team" width="300">
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.meta.isBye && !scope.row.homeTeam"
            v-if="bulkEditing"
            v-model="scope.row.homeTeam"
            value-key="_id"
            name="name"
            @change="markAsEdited(scope)"
            filterable
            :placeholder="
              scope.row.meta.isBye &&
              (!scope.row.homeTeam || (scope.row.homeTeam && !scope.row.homeTeam.name))
                ? 'BYE'
                : 'Select home team'
            "
          >
            <el-option v-for="item in allTeams" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.homeTeam && scope.row.homeTeam.name
                ? scope.row.homeTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Away Team" width="300">
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.meta.isBye && !scope.row.awayTeam"
            v-if="bulkEditing"
            v-model="scope.row.awayTeam"
            value-key="_id"
            name="name"
            @change="markAsEdited(scope)"
            filterable
            :placeholder="
              scope.row.meta.isBye &&
              (!scope.row.awayTeam || (scope.row.awayTeam && !scope.row.awayTeam.name))
                ? 'BYE'
                : 'Select away team'
            "
          >
            <el-option v-for="item in allTeams" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.awayTeam && scope.row.awayTeam.name
                ? scope.row.awayTeam.name
                : scope.row.meta.isBye
                ? "BYE"
                : "NA"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :formatter="dateTimeFormatter" align="left" label="Date & Time" width="250">
        <template slot-scope="scope">
          <el-date-picker
            format="yyyy/MM/dd HH:mm"
            v-if="bulkEditing"
            value-format="timestamp"
            v-model="scope.row.dateTime"
            @change="markAsEdited(scope)"
            type="datetime"
            placeholder="Select date and time"
            default-time="12:00:00"
          >
          </el-date-picker>
          <span v-else> {{ dateTimeFormatter(scope.row) }} </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Venue" width="250">
        <template slot-scope="scope">
          <el-select
            v-if="bulkEditing"
            :remote="true"
            :remote-method="filterVenues"
            @change="markAsEdited(scope)"
            v-model="scope.row.venue"
            value-key="_id"
            name="name"
            placeholder="Please select a venue"
            filterable
          >
            <el-option v-for="item in allVenues" :key="item._id" :label="item.name" :value="item">
            </el-option>
          </el-select>
          <span v-else>
            {{
              scope.row.venue && scope.row.venue.suburb
                ? scope.row.venue.name + " - " + scope.row.venue.suburb
                : scope.row.venue.name
                ? scope.row.venue.name
                : "NA"
            }}
          </span>
          <span
            v-if="
              scope.row.homeTeam.name &&
              scope.row.awayTeam.name &&
              scope.row.venue &&
              !scope.row.venue.name &&
              submited
            "
            style="color: #f56c6c"
          >
            Please select an option
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="Field No" width="210">
        <template slot-scope="scope">
          <el-input
            maxlength="10"
            v-if="bulkEditing"
            @change="markAsEdited(scope)"
            v-model="scope.row.meta.fieldNo"
            placeholder="Field No"
          >
          </el-input>
          <span v-else>
            {{ scope.row.meta && scope.row.meta.fieldNo ? scope.row.meta.fieldNo : "NA" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :formatter="matchStatusFormatter"
        prop="status"
        label="Match Status"
        width="250"
      >
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        align="left"
        prop="modifiedAt"
        label="Last Modified"
        width="250"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { errormsg, matchStatus, nationals } from "../../utils/constants";

const _ = require("lodash");

const processEmpty = (obj) => obj || { _id: null, name: null, venueTimezone: null };

// Check if any regular round match has same home and away team
const checkSameTeam = (matches) => {
  const sameTeamRD = _.find(
    matches,
    (match) =>
      match.homeTeam._id &&
      match.awayTeam._id &&
      match.homeTeam._id !== "TBA" &&
      match.homeTeam._id === match.awayTeam._id
  );
  return !sameTeamRD;
};

const checkTbaByeMatch = (matches) =>
  matches.some(
    (match) =>
      (match.homeTeam._id === "TBA" && match.awayTeam._id === "BYE") ||
      (match.homeTeam._id === "BYE" && match.awayTeam._id === "TBA")
  );

const validRoundNum = (matches) => {
  const reg = new RegExp("^[0-9]+$");
  const valid = matches.every((match) => reg.test(match.round.number));
  return valid;
};

export default {
  name: "MatchesList",
  props: {},
  data() {
    return {
      redoDrawDialog: false,
      nationals,
      availableCompetitions: [],
      bulkEditing: false,
      currentCompetition: null,
      currentCompetitionData: null,
      currentCompetitionLadder: null,
      allTeams: [],
      allVenues: [],
      loading: true,
      tableData: [],
      editData: [],
      pageSize: 15,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [15, 20, 25],
      },
      matchStatus,
      submited: false,
    };
  },
  mounted() {
    let { currentCompetition } = this.$route.params;
    // eslint-disable-next-line prefer-destructuring
    if (!currentCompetition) currentCompetition = this.$store.state.matchlist.currentCompetition;
    // load previous competition after an update
    if (currentCompetition) {
      this.fetchMatchesByCompetition(currentCompetition);
      this.currentCompetition = currentCompetition;
    }
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.availableCompetitions =
          response.data && response.data.data ? response.data.data.filter((c) => c.isActive) : [];
        this.updateAndGetCompetitionLadder();
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    updateAndGetCompetitionLadder() {
      const competitionId = this.currentCompetition;
      this.$http
        .post(`/nrl/api/v1/admin/ladders/${competitionId}/refresh`)
        .then((response) => {
          this.currentCompetitionLadder = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.$customError();
        });
    },
    updateMatches(matches) {
      this.$store.commit("root/loading", true);
      matches.forEach((match) => {
        const url = `/nrl/api/v1/admin/matches/${match._id}`;
        this.$http
          .put(url, match)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch(() => {
            this.$store.commit("root/loading", false);
            this.$customError();
          });
      });
    },
    updateMatch(match, homeTeam, awayTeam) {
      match.homeTeam._id = homeTeam._id;
      match.homeTeam.name = homeTeam.name;
      match.awayTeam._id = awayTeam._id;
      match.awayTeam.name = awayTeam.name;
      match.meta.isTba = false;

      const url = `/nrl/api/v1/admin/matches/${match._id}`;
      this.$http
        .put(url, match)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    /**
     * Returns a team based on isWinner if status is final and forfeitingTeam if status is forfeit
     * @param {*} match
     * @param {*} isWinner take winner or loser default is winner
     */
    goingAheadTeam(match, isWinner = true) {
      if (match.status === "final") {
        if (isWinner) {
          return match.scores.awayTeam > match.scores.homeTeam ? match.awayTeam : match.homeTeam;
        } else {
          return match.scores.awayTeam > match.scores.homeTeam ? match.homeTeam : match.awayTeam;
        }
      }
      if (match.status === "forfeit") {
        if (isWinner) {
          return match.meta.forfeitingTeam;
        } else {
          // non forfeitingTeam is returned
          return match.meta.forfeitingTeam !== match.homeTeam._id ? match.awayTeam : match.homeTeam;
        }
      }
      this.$message.error("Unknown error occurred");
    },
    automateFinalFixtures() {
      /**
       * 1. Check if all matches are completed in all rounds
       * 2. Fill in final and round 1
       * 3. check if all rounds in final 1 are completed then move to final 2 and so on
       * DOCS: https://nationalrugbyleague.atlassian.net/wiki/spaces/GAM/pages/4580049224/Final+fixtures+flow+charts+with+types
       */
      const matches = this.tableData;
      this.updateAndGetCompetitionLadder();

      let finalsType;

      // Uncompleted Regular matches
      const notCompletedMatches = matches.filter(
        (match) => match.status === "pre-game" && match.round.type === "Regular"
      );
      if (notCompletedMatches.length > 0) {
        this.$message.error(
          "Please complete all regular rounds matches before generating final matches"
        );
        return;
      }

      // Check if any regular completed match has status dispute
      const disputeMatches = matches.filter(
        (match) => match.status === "disputed" && match.round.type === "Regular"
      );

      if (disputeMatches.length > 0) {
        this.$message.error(
          "Please resolve all regular rounds matches disputes before generating final matches"
        );
        return;
      }

      // Check if any regular completed match has status washout
      const washoutMatches = matches.filter(
        (match) => match.status === "washout" && match.round.type === "Regular"
      );

      if (washoutMatches.length > 0) {
        this.$message.error(
          "One of more of regular matches is Washed Out/Postponed. Cannot generate final matches, please update them manually"
        );
        return;
      }

      const teamsCount = this.currentCompetitionLadder.teams.length;
      const allFinalMatchesCount = matches.filter((match) => match.round.type === "Final").length;
      const eliminationFinalMatches = matches.filter((match) =>
        match.round.displayName.includes("Elimination Finals")
      );
      const completedEliminationFinalMatches = matches.filter(
        (match) =>
          ["final", "forfeit"].includes(match.status) &&
          match.round.displayName.includes("Elimination Finals")
      );
      const qualifyingFinalMatches = matches.filter((match) =>
        match.round.displayName.includes("Qualifying Finals")
      );
      const completedQualifyingFinalMatches = matches.filter(
        (match) =>
          ["final", "forfeit"].includes(match.status) &&
          match.round.displayName.includes("Qualifying Finals")
      );
      const semiFinalMatches = matches.filter((match) =>
        match.round.displayName.includes("Semi Final")
      );
      const completedSemifinalMatches = matches.filter(
        (match) =>
          ["final", "forfeit"].includes(match.status) &&
          match.round.displayName.includes("Semi Final")
      );
      const preliminaryFinalMatches = matches.filter((match) =>
        match.round.displayName.includes("Preliminary Final")
      );
      const completedPreliminaryFinalMatches = matches.filter(
        (match) =>
          ["final", "forfeit"].includes(match.status) &&
          match.round.displayName.includes("Preliminary Final")
      );
      const grandFinalMatch = matches.filter((match) =>
        match.round.displayName.includes("Grand Final")
      );

      if (this.currentCompetitionData.finalsType)
        finalsType = this.currentCompetitionData.finalsType.toLowerCase();
      else {
        /**
         * TYPE: 2 team 1 week
         * Grand Final - GF
         */
        if (allFinalMatchesCount === 1) {
          finalsType = "2 team 1 week";
        }
        /**
         * TYPE: 4 team 2 weeks
         * 2 Semi finals - SF1, SF2
         * Grand Final - GF
         */
        if (allFinalMatchesCount === 3) {
          finalsType = "4 team 2 week";
        }
        /**
         * TYPE: 4 team 3 weeks or 4 team 4 weeks
         * 2 Semi finals - SF1, SF2
         * 1 Preliminary final - PF
         * Grand final - GF
         */
        if (allFinalMatchesCount === 4) {
          finalsType = "4 team 3 week";
        }
        /**
         * TYPE: 6 team 3 weeks
         * 2 Elimination finals - EF1, EF2
         * 2 Semi finals - SF1, SF2
         * Grand final - GF
         */
        if (allFinalMatchesCount === 5) {
          finalsType = "6 team 3 week";
        }
        /**
         * TYPE: 5 team 4 weeks - exceptional case
         * 1 Elimination final - EF
         * 1 Qualifying final - QF
         * 2 Semi finals - SF1, SF2
         * 1 Preliminary final - PF
         * Grand Final - GF
         */
        if (allFinalMatchesCount === 6 && teamsCount < 6) {
          finalsType = "5 team 4 week";
        }
        /**
         * 6 team 4 weeks - exceptional case
         * 2 Elimination finals - EF1, EF2
         * 2 Semi finals - SF1, SF2
         * 1 Preliminary final - PF
         * Grand Final - GF
         */
        if (allFinalMatchesCount === 6 && teamsCount >= 6) {
          finalsType = "6 team 4 week";
        }
        /**
         * TYPE: 8 team 4 weeks
         * 2 Elimination finals - EF1, EF2
         * 2 Qualifying finals - QF1, QF2
         * 2 Semi finals - SF1, SF2
         * 2 Preliminary finals - PF1, PF2
         * Grand Final - GF
         */
        if (allFinalMatchesCount === 9) {
          finalsType = "8 team 4 week";
        }
      }

      /**
       * TYPE: 2 team 1 week
       * Grand Final - GF
       */
      if (finalsType === "2 team 1 week") {
        // GF
        this.updateMatch(
          grandFinalMatch[0],
          this.currentCompetitionLadder.teams[0],
          this.currentCompetitionLadder.teams[1]
        );
      }

      /**
       * TYPE: 4 team 2 weeks
       * 2 Semi finals - SF1, SF2
       * Grand Final - GF
       */
      if (finalsType === "4 team 2 week") {
        if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          // SF1
          this.updateMatch(
            semiFinalMatches[0],
            this.currentCompetitionLadder.teams[0],
            this.currentCompetitionLadder.teams[3]
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[1],
            this.currentCompetitionLadder.teams[1],
            this.currentCompetitionLadder.teams[2]
          );
        } else {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating grand final"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedSemifinalMatches[0]),
            this.goingAheadTeam(completedSemifinalMatches[1])
          );
        }
      }

      /**
       * TYPE: 4 team 3 weeks or 4 team 4 weeks
       * 2 Semi finals - SF1, SF2
       * 1 Preliminary final - PF
       * Grand final - GF
       */
      if (finalsType === "4 team 3 week" || finalsType === "4 team 4 week") {
        if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          // SF1
          this.updateMatch(
            semiFinalMatches[1],
            this.currentCompetitionLadder.teams[0],
            this.currentCompetitionLadder.teams[1]
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[0],
            this.currentCompetitionLadder.teams[2],
            this.currentCompetitionLadder.teams[3]
          );
        } else if (
          preliminaryFinalMatches.length === 1 &&
          completedPreliminaryFinalMatches.length !== preliminaryFinalMatches.length
        ) {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating preliminary final matches"
            );
            return;
          }
          // PF1
          this.updateMatch(
            preliminaryFinalMatches[0],
            this.goingAheadTeam(completedSemifinalMatches[1], false), // loser
            this.goingAheadTeam(completedSemifinalMatches[0])
          );
        } else {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating grand final"
            );
            return;
          }
          if (completedPreliminaryFinalMatches.length !== 1) {
            this.$message.error(
              "Please complete all preliminary final matches before generating grand final"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedSemifinalMatches[1]),
            this.goingAheadTeam(completedPreliminaryFinalMatches[0])
          );
        }
      }

      /**
       * TYPE: 6 team 3 weeks
       * 2 Elimination finals - EF1, EF2
       * 2 Semi finals - SF1, SF2
       * Grand final - GF
       */
      if (finalsType === "6 team 3 week") {
        if (
          eliminationFinalMatches.length === 2 &&
          completedEliminationFinalMatches.length !== eliminationFinalMatches.length
        ) {
          // EF1
          this.updateMatch(
            eliminationFinalMatches[0],
            this.currentCompetitionLadder.teams[3],
            this.currentCompetitionLadder.teams[4]
          );
          // EF2
          this.updateMatch(
            eliminationFinalMatches[1],
            this.currentCompetitionLadder.teams[2],
            this.currentCompetitionLadder.teams[5]
          );
        } else if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          if (completedEliminationFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all elimination final matches before generating semi final matches"
            );
            return;
          }
          // SF1
          this.updateMatch(
            semiFinalMatches[0],
            this.currentCompetitionLadder.teams[0],
            this.goingAheadTeam(completedEliminationFinalMatches[0])
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[1],
            this.currentCompetitionLadder.teams[1],
            this.goingAheadTeam(completedEliminationFinalMatches[1])
          );
        } else {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating grand final"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedSemifinalMatches[0]),
            this.goingAheadTeam(completedSemifinalMatches[1]),
          );
        }
      }

      /**
       * TYPE: 5 team 4 weeks - exceptional case
       * 1 Elimination final - EF
       * 1 Qualifying final - QF
       * 2 Semi finals - SF1, SF2
       * 1 Preliminary final - PF
       * Grand Final - GF
       */
      if (finalsType === "5 team 4 week") {
        if (
          eliminationFinalMatches.length === 1 &&
          qualifyingFinalMatches.length === 1 &&
          completedEliminationFinalMatches.length !== eliminationFinalMatches.length &&
          completedQualifyingFinalMatches.length !== qualifyingFinalMatches.length
        ) {
          // EF
          this.updateMatch(
            eliminationFinalMatches[0],
            this.currentCompetitionLadder.teams[3],
            this.currentCompetitionLadder.teams[4]
          );
          // QF
          this.updateMatch(
            qualifyingFinalMatches[0],
            this.currentCompetitionLadder.teams[1],
            this.currentCompetitionLadder.teams[2]
          );
        } else if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          if (completedEliminationFinalMatches.length !== 1) {
            this.$message.error(
              "Please complete all elimination final matches before generating semi final matches"
            );
            return;
          }
          if (completedQualifyingFinalMatches.length !== 1) {
            this.$message.error(
              "Please complete all elimination final matches before generating semi final matches"
            );
            return;
          }
          // SF1
          this.updateMatch(
            semiFinalMatches[0],
            this.goingAheadTeam(completedQualifyingFinalMatches[0], false), // loser
            this.goingAheadTeam(completedEliminationFinalMatches[0]),
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[1],
            this.currentCompetitionLadder.teams[0],
            this.goingAheadTeam(completedQualifyingFinalMatches[0])
          );
        } else if (
          preliminaryFinalMatches.length === 1 &&
          completedPreliminaryFinalMatches.length !== preliminaryFinalMatches.length
        ) {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating preliminary final"
            );
            return;
          }
          // PF
          this.updateMatch(
            preliminaryFinalMatches[0],
            this.goingAheadTeam(completedSemifinalMatches[1], false), // loser
            this.goingAheadTeam(completedSemifinalMatches[0]),
          );
        } else {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating grand final"
            );
            return;
          }
          if (completedPreliminaryFinalMatches.length !== 1) {
            this.$message.error(
              "Please complete all preliminary final matches before generating grand final"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedSemifinalMatches[1]),
            this.goingAheadTeam(completedPreliminaryFinalMatches[0]),
          );
        }
      }

      /**
       * 6 team 4 weeks - exceptional case
       * 2 Elimination finals - EF1, EF2
       * 2 Semi finals - SF1, SF2
       * 1 Preliminary final - PF
       * Grand Final - GF
       */
      if (finalsType === "6 team 4 week") {
        if (
          eliminationFinalMatches.length === 2 &&
          completedEliminationFinalMatches.length !== eliminationFinalMatches.length
        ) {
          // EF1
          this.updateMatch(
            eliminationFinalMatches[0],
            this.currentCompetitionLadder.teams[3],
            this.currentCompetitionLadder.teams[4]
          );
          // EF2
          this.updateMatch(
            eliminationFinalMatches[1],
            this.currentCompetitionLadder.teams[2],
            this.currentCompetitionLadder.teams[5]
          );
        } else if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          if (completedEliminationFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all elimination final matches before generating semi final matches"
            );
            return;
          }
          // SF1
          this.updateMatch(
            semiFinalMatches[0],
            this.currentCompetitionLadder.teams[0],
            this.currentCompetitionLadder.teams[1]
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[1],
            this.goingAheadTeam(completedEliminationFinalMatches[0]),
            this.goingAheadTeam(completedEliminationFinalMatches[1])
          );
        } else if (
          preliminaryFinalMatches.length === 1 &&
          completedPreliminaryFinalMatches.length < 1
        ) {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating preliminary final match"
            );
            return;
          }
          // PF
          this.updateMatch(
            preliminaryFinalMatches[0],
            this.goingAheadTeam(completedSemifinalMatches[0], false), // loser
            this.goingAheadTeam(completedSemifinalMatches[1])
          );
        } else {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating grand final"
            );
            return;
          }
          if (completedPreliminaryFinalMatches.length !== 1) {
            this.$message.error(
              "Please complete all preliminary final matches before generating grand final"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedSemifinalMatches[0]),
            this.goingAheadTeam(completedPreliminaryFinalMatches[0])
          );
        }
      }

      /**
       * TYPE: 8 team 4 weeks
       * 2 Elimination finals - EF1, EF2
       * 2 Qualifying finals - QF1, QF2
       * 2 Semi finals - SF1, SF2
       * 2 Preliminary finals - PF1, PF2
       * Grand Final - GF
       */
      if (finalsType === "8 team 4 week") {
        if (
          eliminationFinalMatches.length === 2 &&
          qualifyingFinalMatches.length === 2 &&
          completedEliminationFinalMatches.length !== eliminationFinalMatches.length &&
          completedQualifyingFinalMatches.length !== qualifyingFinalMatches.length
        ) {
          // Elimination finals
          // EF1
          this.updateMatch(
            eliminationFinalMatches[0],
            this.currentCompetitionLadder.teams[4],
            this.currentCompetitionLadder.teams[7]
          );
          // EF2
          this.updateMatch(
            eliminationFinalMatches[1],
            this.currentCompetitionLadder.teams[5],
            this.currentCompetitionLadder.teams[6]
          );

          // Qualifying finals
          // QF1
          this.updateMatch(
            qualifyingFinalMatches[0],
            this.currentCompetitionLadder.teams[0],
            this.currentCompetitionLadder.teams[3]
          );
          // QF2
          this.updateMatch(
            qualifyingFinalMatches[1],
            this.currentCompetitionLadder.teams[1],
            this.currentCompetitionLadder.teams[2]
          );
        } else if (
          semiFinalMatches.length === 2 &&
          completedSemifinalMatches.length !== semiFinalMatches.length
        ) {
          if (completedEliminationFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all elimination final matches before generating semi final matches"
            );
            return;
          }
          if (completedQualifyingFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all qualifying final matches before generating semi final matches"
            );
            return;
          }
          // SF1
          this.updateMatch(
            semiFinalMatches[0],
            this.goingAheadTeam(completedQualifyingFinalMatches[0], false), // loser
            this.goingAheadTeam(completedEliminationFinalMatches[0]),
          );
          // SF2
          this.updateMatch(
            semiFinalMatches[1],
            this.goingAheadTeam( completedQualifyingFinalMatches[1], false), // loser
            this.goingAheadTeam(completedEliminationFinalMatches[1]),
          );
        } else if (
          preliminaryFinalMatches.length === 2 &&
          completedPreliminaryFinalMatches.length !== preliminaryFinalMatches.length
        ) {
          if (completedSemifinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all semi final matches before generating preliminary final matches"
            );
            return;
          }
          if (completedQualifyingFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all qualifying final matches before generating preliminary final matches"
            );
            return;
          }
          // PF1
          this.updateMatch(
            preliminaryFinalMatches[1],
            this.goingAheadTeam(completedQualifyingFinalMatches[0]),
            this.goingAheadTeam(completedSemifinalMatches[1]),
          );
          // PF2
          this.updateMatch(
            preliminaryFinalMatches[0],
            this.goingAheadTeam(completedQualifyingFinalMatches[1]),
            this.goingAheadTeam(completedSemifinalMatches[0]),
          );
        } else {
          if (completedQualifyingFinalMatches.length !== 2) {
            this.$message.error(
              "Please complete all preliminary final matches before generating grand final match"
            );
            return;
          }
          // GF
          this.updateMatch(
            grandFinalMatch[0],
            this.goingAheadTeam(completedPreliminaryFinalMatches[0]),
            this.goingAheadTeam(completedPreliminaryFinalMatches[1])
          );
        }
      }
    },
    markAsEdited(scope) {
      scope.row.edited = true;
      if (scope.row.homeTeam._id === scope.row.awayTeam._id) {
        this.$customError(errormsg.duplicate_team);
      }
    },
    redodrawBuilder() {
      if (this.currentCompetitionData.teams.length <= 2) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      if (
        this.currentCompetitionData.hasPools &&
        this.currentCompetitionData.pools &&
        this.currentCompetitionData.pools.some((pool) => pool.teams.length <= 2)
      ) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      this.$router.push({
        name: "matches.redo-draw-builder",
        params: {
          id: this.currentCompetition,
        },
      });
      return true;
    },
    redraw() {
      if (this.currentCompetitionData.teams.length <= 2) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      if (
        this.currentCompetitionData.hasPools &&
        this.currentCompetitionData.pools &&
        this.currentCompetitionData.pools.some((pool) => pool.teams.length <= 2)
      ) {
        this.$customError(errormsg.draw_notenoughteam);
        return false;
      }

      this.$router.push({
        name: "matches.redo-draw",
        params: {
          id: this.currentCompetition,
        },
      });
      return true;
    },
    beginBulkEdit() {
      this.editData = _.cloneDeep(this.tableData).filter((item) => {
        if (item.homeTeam == null) {
          item.homeTeam = {
            name: null,
            venueTimezone: null,
            _id: null,
          };
        } else if (item.awayTeam == null) {
          item.awayTeam = {
            name: null,
            venueTimezone: null,
            _id: null,
          };
        }
        return item;
      });
      this.bulkEditing = true;
    },
    cancelBulkEdit() {
      this.editData = [];
      this.bulkEditing = false;
    },
    saveBulkEdit() {
      // this.$store.commit("root/loading", true);
      this.submited = true;

      // do not allow TBA and BYE in a match
      if (checkTbaByeMatch(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError(errormsg.draw_tbabyematch);
        return false;
      }

      // Check if any regular round match has same home and away team
      if (!checkSameTeam(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError(errormsg.draw_sameteam);
        return false;
      }

      if (!validRoundNum(this.editData)) {
        this.$store.commit("root/loading", false);
        this.$customError("Round value must only use integers 0-9");
        return false;
      }

      const editPromises = [];
      const now = moment().valueOf();
      let validateVenue = this.editData.find(
        (match) => match.homeTeam.name && match.awayTeam.name && match.venue && !match.venue.name
      );
      if (validateVenue) {
        return false;
      }
      this.editData.forEach((match) => {
        if (match.homeTeam.name && match.awayTeam.name) {
          match.meta.isBye = false;
          match.meta.isTba = false;
          // match
        }
        if (match.homeTeam._id === 0 || match.awayTeam._id === 0) {
          match.meta.isBye = true;
          if (match.homeTeam._id === 0) {
            match.homeTeam = null;
          } else {
            match.awayTeam = null;
          }
        }
        if (match.edited && match.round.number && typeof match.round.number === "string") {
          const roundNum = parseInt(match.round.number);
          if (match.round.type === "Regular") match.round.displayName = `Round ${roundNum}`;
          match.round.number = roundNum;
        }
        if (match.edited) {
          match.modifiedAt = now;
          editPromises.push(this.$http.put(`/nrl/api/v1/admin/matches/${match._id}`, match));
        }
      });
      this.tableData = _.cloneDeep(this.editData).sort(
        (a, b) =>
          b.round.type.localeCompare(a.round.type) ||
          a.round.number - b.round.number ||
          a.dateTime - b.dateTime
      );
      this.editData = [];
      Promise.all(editPromises)
        .then(() => {
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.submited = false;
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.bulkEditing = false;
          this.$customError();
        });
      return true;
    },
    filterVenues(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allVenues = response.data.data.map((venue) => ({
                _id: venue._id,
                name: venue.name,
              }));
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allVenues = [];
      }
    },
    fetchMatchesByCompetition(previous) {
      const { currentCompetition } = this;
      this.loading = true;
      Promise.all([
        this.$http.get(`/nrl/api/v1/admin/matches/competition/${previous || currentCompetition}`),
        this.$http.get(`/nrl/api/v1/admin/competitions/${previous || currentCompetition}`),
        this.updateAndGetCompetitionLadder(),
      ])
        .then(([matchesRes, compRes]) => {
          this.tableData = matchesRes.data.data
            .map((match) => ({
              ...match,
              homeTeam: processEmpty(match.homeTeam),
              awayTeam: processEmpty(match.awayTeam),
              venue: processEmpty(match.venue),
              dateTime: match.dateTime || null,
            }))
            .sort(
              (a, b) =>
                b.round.type.localeCompare(a.round.type) ||
                a.round.number - b.round.number ||
                a.dateTime - b.dateTime
            );

          this.allVenues = matchesRes.data.data
            .map((match) => match.venue)
            .filter(
              (venue, index, self) =>
                venue &&
                index ===
                  self.findIndex((t) => venue && t && t._id === venue._id && t.name === venue.name)
            );

          this.currentCompetitionData = compRes.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.$customError();
          this.loading = false;
        });
    },
    addMatch() {
      this.$router.push({
        name: "matches.update",
        params: {
          type: "add",
          id: "add",
          comp: this.currentCompetitionData || null,
          association_id: this.currentCompetitionData
            ? this.currentCompetitionData.orgtree.association._id
            : null,
        },
      });
    },
    handleRowClick(row) {
      if (!this.bulkEditing) {
        this.$router.push({
          name: "matches.update",
          params: {
            type: "update",
            id: row._id,
          },
        });
      }
    },
    matchStatusFormatter(row) {
      if (row.status) {
        return matchStatus.find((status) => status.type === row.status).name;
      }
      return "NA";
    },
    modifiedFormatter(row) {
      if (row.modifiedAt) {
        return this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
    dateTimeFormatter(row) {
      if (row.dateTime) {
        return this.moment(row.dateTime).format("MMMM Do YYYY, h:mm a");
      }
      return "NA";
    },
  },
  watch: {
    // When competition changes, fetch all the team of new comp and omit unnecessary info
    currentCompetitionData() {
      const { _id: compid } = this.currentCompetitionData;
      this.$http
        .get(`/nrl/api/v1/admin/teams/competition/${compid}`)
        .then((teamRes) => {
          this.allTeams = teamRes.data.data.map((team) => ({ _id: team._id, name: team.name }));
          this.allTeams.push({ _id: 0, name: "BYE" });
        })
        .catch(() => {
          this.$customError();
        });
    },
    currentCompetition(val) {
      this.$store.commit("matchlist/update", { currentCompetition: val });
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
}

.data-table-cell {
  text-align: center;
}

.el-pagination {
  margin-top: 1rem !important;
}

.add-match {
  float: left;
  margin-bottom: 2rem;
}

.comp-action {
  margin-bottom: 1.5rem;
  .bulk-edit {
    float: right;
    margin-left: 1rem;
  }

  .select-competition {
    padding-top: 0.12rem;
    float: left;
    width: calc(100% - 2rem);
    height: 3rem;
  }
}
.dialog-footer {
  text-align: center;
  .el-button {
    margin: 10px;
    width: 200px;
  }
}
</style>
