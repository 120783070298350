<template>
  <div>
    <el-form label-position="top" :model="regiProduct" :rules="rules" ref="regiproduct">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="competitionId" label="Competition">
            <el-select v-model="competitionId" placeholder="Competition" filterable>
              <el-option
                v-for="comp in competitions"
                :label="comp.name"
                :key="comp._id"
                :value="comp._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="type" label="Type">
            <el-select v-model="type" placeholder="Type">
              <el-option
                v-for="item in compFeeTypes"
                :label="item.name"
                :key="item.type"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="price" label="Price">
            <el-input type="text" autocomplete="off" v-model="price" placeholder="Price" />
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="status" label="Status">
            <el-select v-model="status" placeholder="Status">
              <el-option
                v-for="item in allStatus"
                :label="item.status"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="payment" label="Online payment required">
            <el-select v-model="payment" placeholder="Online payment required">
              <el-option
                v-for="item in allPayments"
                :label="item.payment"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm('regiproduct')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>

    <data-tables
      v-if="regiProduct.competitionProducts.length > 0"
      :data="regiProduct.competitionProducts"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Competition" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.competitionId"
            placeholder="Competition"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="comp in competitions"
              :label="comp.name"
              :key="comp._id"
              :value="comp._id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Type" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in compFeeTypes"
              :label="item.name"
              :key="item.type"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="120">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.price"
            :disabled="!optionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="120">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.status"
            placeholder="Status"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in allStatus"
              :label="item.status"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Online Payment Required" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.payment"
            placeholder="Payment Required"
            :disabled="!optionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="item in allPayments"
              :label="item.payment"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template v-if="!optionsdisabled[scope.$index]"> Edit </template>
            <template v-else> Save </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg, compFeeTypes, genders, roles } from "../../utils/constants";

const _ = require("lodash");

export default {
  name: "CompetitionProducts",
  props: ["regiProduct"],
  data() {
    const validateCompetitionId = (rule, value, callback) => {
      if (this.currentOptions.competitionId === undefined) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateType = (rule, value, callback) => {
      if (this.currentOptions.type === undefined) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validatePrice = (rule, value, callback) => {
      if (!this.currentOptions.price || parseFloat(this.currentOptions.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateStatus = (rule, value, callback) => {
      if (this.currentOptions.status == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatePayment = (rule, value, callback) => {
      if (this.currentOptions.payment == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      competitions: [],
      currentOptions: {
        competitionId: undefined,
        type: undefined,
        price: -1,
        status: false,
        payment: false,
      },
      optionsdisabled: [],
      compFeeTypes,
      availableRoles: [],
      allStatus: [
        { status: "Open", value: true },
        { status: "Closed", value: false },
      ],
      allPayments: [
        { payment: "Yes", value: true },
        { payment: "No", value: false },
      ],
      allGenders: genders,
      rules: {
        competitionId: {
          required: true,
          validator: validateCompetitionId,
          message: errormsg.select_option,
          trigger: "change",
        },
        type: {
          required: true,
          validator: validateType,
          message: errormsg.select_option,
          trigger: "change",
        },
        price: {
          required: true,
          validator: validatePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        status: {
          required: true,
          validator: validateStatus,
          trigger: "change",
        },
        payment: {
          required: true,
          validator: validatePayment,
          trigger: "change",
        },
      },
      tableProps: {
        border: true,
      },
      roles,
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then((response) => {
        this.competitions =
          response.data && response.data.data ? response.data.data.filter((c) => c.isActive) : [];
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { competitionId, type, payment, status } = this.currentOptions;

          const overlapping = _.find(
            this.regiProduct.competitionProducts,
            (cp) => cp.competitionId === competitionId
          );
          if (overlapping) {
            this.$customError(errormsg.comp_overlapp);
            return false;
          }
          // Update registration products
          this.$store.commit("root/loading", true);
          this.regiProduct.entity_type = Object.keys(roles)
            .find((key) => roles[key] === this.$store.getters["user/activeRole"].type)
            .replace("Admin", "");
          this.regiProduct.entity_id = this.$store.getters["user/activeRole"].entity._id;
          const compProduct = {
            competitionId,
            type,
            price: "$ ".concat(parseFloat(this.currentOptions.price).toFixed(2).toString()),
            price_int: parseInt(parseFloat(this.currentOptions.price).toFixed(2) * 100),
            payment,
            status,
          };

          const insert = {
            competitionId: compProduct.competitionId,
            type: compProduct.type,
            price: compProduct.price_int,
            isRegoOpen: compProduct.status != null ? compProduct.status : false,
            onlinePaymentRequired: compProduct.payment != null ? compProduct.payment : false,
          };

          this.currentOptions = {
            competitionId: undefined,
            type: undefined,
            price: -1,
            status: false,
            payment: false,
          };

          const url = "/nrl/api/v1/admin/registration-products/competition-products";
          this.$http
            .post(url, insert)
            .then((response) => {
              compProduct._id = response.data.data._id;
              this.regiProduct.competitionProducts.push(compProduct);
              this.$store.commit("root/loading", false);
              this.optionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });
          return true;
        }
        return false;
      });
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.regiProduct.competitionProducts[rowindex];

        if (row.price.indexOf("$") === 0) {
          const processedprice = row.price
            .substring(row.price.indexOf("$") + 1, row.price.length)
            .trim();
          if (Number.isNaN(parseFloat(processedprice)) || parseFloat(processedprice) < 0) {
            this.$customError(errormsg.price_invalid);
            return false;
          }
        } else if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          return false;
        }

        const current_compId = row.competitionId;
        const overlapping = this.regiProduct.competitionProducts.find(
          (o) => o._id !== row._id && o.competitionId === current_compId
        );
        if (overlapping) {
          this.$customError(errormsg.comp_overlapp);
          return false;
        }
        // Update registration products
        this.$store.commit("root/loading", true);
        if (row.price.indexOf("$") === 0) {
          this.regiProduct.competitionProducts[rowindex].price_int = parseInt(
            parseFloat(
              row.price.substring(row.price.indexOf("$") + 1, row.price.length).trim()
            ).toFixed(2) * 100
          );
          this.regiProduct.competitionProducts[rowindex].price = "$ ".concat(
            parseFloat(row.price.substring(row.price.indexOf("$") + 1, row.price.length).trim())
              .toFixed(2)
              .toString()
          );
        } else {
          this.regiProduct.competitionProducts[rowindex].price_int = parseInt(
            parseFloat(row.price).toFixed(2) * 100
          );
          this.regiProduct.competitionProducts[rowindex].price = "$ ".concat(
            parseFloat(row.price).toFixed(2).toString()
          );
        }
        const product = this.regiProduct.competitionProducts[rowindex];
        const update = {
          competitionId: product.competitionId,
          type: product.type,
          price: product.price_int,
          isRegoOpen: product.status,
          onlinePaymentRequired: product.payment,
        };

        const url = `/nrl/api/v1/admin/registration-products/competition-products/${product._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch((error) => {
            this.$store.commit("root/loading", false);
            this.$customError(error.response.data.message);
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(index) {
      this.$store.commit("root/loading", true);
      this.$confirm("Are you sure you want to remove this registration fee?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const product = this.regiProduct.competitionProducts[index];
          this.$http
            .delete(`/nrl/api/v1/admin/registration-products/competition-products/${product._id}`)
            .then(() => {
              this.regiProduct.competitionProducts.splice(index, 1);
              this.optionsdisabled.splice(index, 1);
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    competitionId: {
      get() {
        return this.currentOptions.competitionId;
      },
      set(value) {
        this.currentOptions.competitionId = value || undefined;
      },
    },
    type: {
      get() {
        return this.currentOptions.type;
      },
      set(value) {
        this.currentOptions.type = value || undefined;
      },
    },
    price: {
      get() {
        if (parseFloat(this.currentOptions.price) >= 0) {
          if (this.currentOptions.price.indexOf(".") !== -1) {
            return "$ "
              .concat(
                this.currentOptions.price.substring(0, this.currentOptions.price.indexOf("."))
              )
              .concat(".")
              .concat(
                this.currentOptions.price
                  .substring(
                    this.currentOptions.price.indexOf(".") + 1,
                    this.currentOptions.price.length
                  )
                  .substring(0, 2)
              );
          }
          return "$ ".concat(this.currentOptions.price);
        }
        return null;
      },
      set(value) {
        if (value.indexOf("$") === 0) {
          const processedvalue = value.substring(value.indexOf("$") + 1, value.length).trim();
          this.currentOptions.price =
            !Number.isNaN(parseFloat(processedvalue)) && parseFloat(processedvalue) >= 0
              ? processedvalue
              : -1;
        } else {
          this.currentOptions.price =
            !Number.isNaN(parseFloat(value.trim())) && parseFloat(value.trim()) >= 0
              ? value.trim()
              : -1;
        }
      },
    },
    status: {
      get() {
        return this.currentOptions.status ? "Open" : "Closed";
      },
      set(value) {
        this.currentOptions.status = value;
      },
    },
    payment: {
      get() {
        return this.currentOptions.payment ? "Yes" : "No";
      },
      set(value) {
        this.currentOptions.payment = value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
