<template>
  <div>
    <el-form label-position="top" :model="venue" :rules="rules" :disabled="!isSuperAdmin" ref="venueForm">
      <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
        <el-col :span="8">
          <el-form-item class="tw-pt-4" prop="name" label="Venue Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="venue.name"
              placeholder="Enter a name"
            />
          </el-form-item>
          <div v-if="isSuperAdmin">
            <el-form-item class="tw-pt-4" prop="address.formatted" label="Address">
              <div class="el-input">
                <google-auto-complete
                  class="auto-complete"
                  id="map"
                  :key="mapInput"
                  @placechanged="getAddressData"
                  v-model="currentFormattedAddress"
                  placeholder="Enter an Address"
                >
                </google-auto-complete>
              </div>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item class="tw-pt-4" prop="address.formatted" label="Address">
              <el-input
                type="text"
                autocomplete="off"
                v-model="currentFormattedAddress"
                placeholder="Enter an Address"
              />
            </el-form-item>
          </div>
          <el-form-item class="tw-pt-4" prop="venueTimezone" label="Timezone">
            <el-select filterable v-model="venue.venueTimezone" placeholder="Select a timezone">
              <el-option v-for="item in availableZones" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <action-buttons submit cancel @submit="updateVenue()" @cancel="cancelForm()" />
        </el-col>
        <el-col :span="16">
          <GmapMap class="google-map" :center="markerPosition" :zoom="11" map-type-id="roadmap">
            <GmapMarker
              :position="markerPosition"
              :clickable="isSuperAdmin"
              :draggable="isSuperAdmin"
              @click="center = markerPosition"
            />
          </GmapMap>
        </el-col>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";
import { parseGoogleAddress } from "../../utils/utilities";

export default {
  name: "VenueForm",
  props: {},
  components: {
    ActionButtons
  },
  data() {
    return {
      type: "insert",
      nameUpdated: false,
      mapInput: 1,
      venue: {
        name: "",
        address: {
          formatted: "",
          lat: "",
          lng: "",
          addressLine1: "",
          addressLine2: "",
          suburb: "",
          state: "",
          postcode: "",
          country: ""
        },
        venueTimezone: ""
      },
      rules: {
        name: { required: true, message: errormsg.input_option },
        "address.formatted": { required: true, message: errormsg.input_option },
        venueTimezone: { required: true, message: errormsg.select_option }
      },
      isSuperAdmin: this.$store.getters['user/isSuperAdmin']
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, id, venue } = this.$route.params;
    this.type = type || (id !== "add" ? "update" : "insert");
    // Load Teams and Clubs
    if (id && id !== "" && id !== "add" && !venue) {
      this.$http
        .get(`/nrl/api/v1/admin/venues/${id}`)
        .then(venueRes => {
          this.venue = Object.assign({}, this.venue, venueRes.data.data);
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    } else if (venue) {
      this.venue = Object.assign({}, this.venue, venue);
      this.$store.commit("root/loading", false);
    } else {
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    // Not using drag in map yet (Structure changed, update this before use!!!)
    // handleDrag(e) {
    //   const location = { lat: e.lat(), lng: e.lng() };
    //   const { lat, lng } = location;
    //   this.venue.address.lat = lat;
    //   this.venue.address.lng = lng;
    //   this.$gmapApiPromiseLazy()
    //     .then(() => {
    //       const geocoder = new google.maps.Geocoder();
    //       geocoder.geocode({ location }, (results, status) => {
    //         if (status === 'OK' && results.length > 0) {
    //           const result = results[0];
    //           this.venue.address.formatted = result.formatted_address;
    //           const info = result.address_components.map(c => c.short_name);
    //           const [number, street, suburb, region, state, country, postcode] = info;
    //           this.venue.address = Object.assign({}, this.venue.address,
    //             {
    //               street,
    //               suburb,
    //               region,
    //               state,
    //               country,
    //               number,
    //               postcode,
    //             });
    //           this.mapInput += 1;
    //         }
    //       });
    //     })
    //     .catch(() => {});
    // },
    getAddressData(addressData, place) {
      this.venue.address = parseGoogleAddress(addressData, place);
    },
    updateVenue() {
      new Promise((resolve, reject) => {
        this.$refs.venueForm.validate(valid => (valid ? resolve() : reject()));
      })
        .then(() => {
          const { id } = this.$route.params;
          const { venue } = this;
          if (this.type === "update" && id) {
            this.$http
              .put(`/nrl/api/v1/admin/venues/${id}`, venue)
              .then(() => {
                this.$customSuccess();
              })
              .catch(() => {
                this.$customError();
              });
          } else if (this.type === "add") {
            this.$http
              .post("/nrl/api/v1/admin/venues", venue)
              .then(() => {
                this.$router.push({
                  name: "venues.list"
                });
                this.$customSuccess();
              })
              .catch(() => {
                this.$customError();
              });
          }
        })
        .catch(() => {});
    },
    cancelForm() {
      this.$router.push({
        name: "venues.list"
      });
    }
  },
  computed: {
    currentFormattedAddress() {
      return this.venue.address.formatted;
    },
    availableZones() {
      return moment.tz
        .names()
        .filter(
          n =>
            n.includes("Australia") ||
            n.includes("NZ") ||
            n.includes("Fiji") ||
            n.includes("Tonga") ||
            n.includes("Pacific/Samoa") ||
            n.includes("GMT+10")
        );
    },
    markerPosition() {
      return {
        lat: this.venue.address.lat ? parseFloat(this.venue.address.lat) : -33.8688,
        lng: this.venue.address.lng ? parseFloat(this.venue.address.lng) : 151.2093
      };
    }
  }
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}

.google-map {
  width: 100%;
  height: 350px;
  margin: 0 auto;
}
</style>
