<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane label="Fees">
        <products :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane
        label="Additional Questions"
        v-if="nationalId === 31 || clearance === roles.superAdmin"
      >
        <AdditionalQuestions :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Saleable Items">
        <saleable-items :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Date Range Discounts">
        <DiscountsDateRange :regiProduct="regiProduct" />
      </el-tab-pane>

      <el-tab-pane
        label="Discount Codes"
        v-if="
          (clearance === roles.clubAdmin && nationalId === 32) ||
          (clearance === roles.associationAdmin && nationalId === 31)
        "
      >
        <DiscountsCode />
      </el-tab-pane>
      <el-tab-pane label="Family Discounts" v-if="clearance === roles.clubAdmin">
        <DiscountsVolume :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Competition Fees" v-if="showCompTabs">
        <competitionProducts :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Competition Additional Questions" v-if="showCompTabs">
        <CompetitionAdditionalQuestions :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Competition Saleable Items" v-if="showCompTabs">
        <competitionSaleableItems :regiProduct="regiProduct" />
      </el-tab-pane>
      <el-tab-pane label="Competition Date Range Discounts" v-if="showCompTabs">
        <competitionDiscountsDateRange :regiProduct="regiProduct" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import Products from "./Products.vue";
import SaleableItems from "./SaleableItems.vue";
import DiscountsVolume from "./DiscountsVolume.vue";
import DiscountsCode from "./DiscountsCode.vue";
import DiscountsDateRange from "./DiscountsDateRange.vue";
import CompetitionProducts from "./CompetitionProducts.vue";
import CompetitionSaleableItems from "./CompetitionSaleableItems.vue";
import CompetitionDiscountsDateRange from "./CompetitionDiscountsDateRange.vue";
import AdditionalQuestions from "./AdditionalQuestions.vue";
import CompetitionAdditionalQuestions from "./CompetitionAdditionalQuestions.vue";
import { roles } from "../../utils/constants";

export default {
  name: "RegistrationProduct",
  props: {},
  components: {
    Products,
    DiscountsDateRange,
    DiscountsCode,
    DiscountsVolume,
    SaleableItems,
    CompetitionProducts,
    CompetitionSaleableItems,
    CompetitionDiscountsDateRange,
    AdditionalQuestions,
    CompetitionAdditionalQuestions,
  },
  data() {
    return {
      regiProduct: {
        _id: "",
        entity_type: "",
        entity_id: -1,
        products: [],
        discounts: [],
        familyDiscounts: [],
        saleableitems: [],
        competitionProducts: [],
        competitionSaleableItems: [],
        competitionDiscounts: [],
        additionalQuestions: [],
        competitionAdditionalQuestions: [],
      },
      roles,
    };
  },
  computed: {
    nationalId() {
      return this.$store.getters["user/activeRole"].national_id;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    showCompTabs() {
      // Show comp product, saleable items and discounts tabs
      const activeRole = this.$store.getters["user/activeRole"];
      return activeRole.national_id === 31 && activeRole.type === roles.associationAdmin;
    },
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http.get("/nrl/api/v1/admin/registration-products").then((response) => {
      const product =
        response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;

      if (product) {
        const {
          _id,
          entityType: entity_type,
          entityId: entity_id,
          products,
          discounts,
          familyDiscounts,
          saleableItems,
          additionalQuestions,
          competitionProducts,
          competitionSaleableItems,
          competitionDiscounts,
          competitionAdditionalQuestions,
        } = product;

        this.regiProduct = {
          _id,
          entity_type,
          entity_id,
          products: products.map((p) => ({
            _id: p._id,
            role: p.memberType,
            gender: p.gender,
            agefrom: p.ageFrom,
            ageto: p.ageTo,
            price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
            price_int: p.price,
            status: p.isRegoOpen,
            payment: p.onlinePaymentRequired,
          })),
          additionalQuestions: additionalQuestions
            ? additionalQuestions.map((p) => ({
                _id: p._id,
                type: p.type,
                fieldName: p.fieldName,
                question: p.question,
                ageFrom: p.ageFrom,
                ageTo: p.ageTo,
                memberType: p.memberType,
                gender: p.gender,
                required: p.required,
                isActive: p.isActive,
                meta: {
                  options: _.get(p, "meta.options", []),
                  entityIds: _.get(p, "meta.entityIds", []),
                  entityType: _.get(p, "meta.entityType", undefined),
                },
              }))
            : [],
          discounts: discounts
            ? discounts.map((p) => ({
                _id: p._id,
                type: p.type === "dollar" ? "$" : "%",
                discountName: p.name,
                discountFrom: p.fromDate,
                discountTo: p.toDate,
                discountAmount:
                  p.type === "dollar"
                    ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                    : p.amount.toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
          familyDiscounts: familyDiscounts
            ? familyDiscounts.map((p) => ({
                _id: p._id,
                familyFrom: p.familyFrom,
                familyTo: p.familyTo,
                discountAmount: (parseFloat(p.amount) / 100).toFixed(2).toString(),
                discountAmount_int: p.amount,
                discountMemberType: p.memberType,
              }))
            : [],
          saleableitems: saleableItems
            ? saleableItems.map((p) => ({
                _id: p._id,
                name: p.name,
                description: p.description,
                types: p.memberTypes,
                price_int: p.price,
                price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                requireSizes: p.requireSizes || false,
                mandatory: p.mandatory || false,
                seasonPass: p.seasonPass || false,
                seasonPassFrom: p.seasonPassFrom,
                seasonPassTo: p.seasonPassTo,
                gender: p.gender || "",
                ageFrom: p.ageFrom,
                ageTo: p.ageTo,
                url: p.url,
                meta: {
                  options: _.get(p, "meta.options", []),
                  entityIds: _.get(p, "meta.entityIds", []),
                  entityType: _.get(p, "meta.entityType", undefined),
                },
              }))
            : [],
          competitionProducts: competitionProducts
            ? competitionProducts.map((p) => ({
                _id: p._id,
                competitionId: p.competitionId,
                type: p.type,
                price: `$ ${(parseFloat(p.price) / 100).toFixed(2).toString()}`,
                price_int: p.price,
                status: p.isRegoOpen,
                payment: p.onlinePaymentRequired,
              }))
            : [],
          competitionAdditionalQuestions: competitionAdditionalQuestions
            ? competitionAdditionalQuestions.map((p) => ({
                _id: p._id,
                type: p.type,
                fieldName: p.fieldName,
                question: p.question,
                competitionId: p.competitionId,
                required: p.required,
                isActive: p.isActive,
                meta: {
                  options: _.get(p, "meta.options", []),
                },
              }))
            : [],
          competitionSaleableItems: competitionSaleableItems
            ? competitionSaleableItems.map((p) => ({
                _id: p._id,
                competitionId: p.competitionId,
                name: p.name,
                description: p.description,
                price_int: p.price,
                price: (parseFloat(p.price) / 100).toFixed(2).toString(),
                requireSizes: p.requireSizes || false,
                mandatory: p.mandatory || false,
                seasonPass: p.seasonPass || false,
              }))
            : [],
          competitionDiscounts: competitionDiscounts
            ? competitionDiscounts.map((p) => ({
                _id: p._id,
                competitionId: p.competitionId,
                type: p.type === "dollar" ? "$" : "%",
                discountName: p.name,
                discountFrom: p.fromDate,
                discountTo: p.toDate,
                discountAmount:
                  p.type === "dollar"
                    ? (parseFloat(p.amount) / 100).toFixed(2).toString()
                    : p.amount.toString(),
                discountAmount_int: p.amount,
              }))
            : [],
        };

        this.optionsdisabled = new Array(this.regiProduct.products.length).fill(false);
        this.discountoptionsdisabled = new Array(this.regiProduct.discounts.length).fill(false);
        this.familydiscountoptionsdisabled = new Array(
          this.regiProduct.familyDiscounts.length
        ).fill(false);
        this.saleableoptionsdisabled = new Array(this.regiProduct.saleableitems.length).fill(false);
      }
      this.$store.commit("root/loading", false);
    });
  },
};
</script>

<style scoped lang="scss"></style>
