<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="statusTypes"
      :min="0"
      name="Status"
      v-model="inputVal.status"
    />
    <el-row>
      <el-col style="margin:5px 15px 0px;">
        <div style="margin-bottom: 15px;">Program Date Range</div>
        <el-date-picker
          v-model="inputVal.matchDateRange"
          type="daterange"
          range-separator="|"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          align="right"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <div class="mt-2 d-flex-column">
      <div class="mt-1">
        <el-button
            @click="clear"
            class="full-width button-invisible">Clear</el-button>
        <el-button
            @click='submit'
            type="primary"
            class="full-width">Apply Filters</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from '@/components/CheckboxGroup.vue';
import { EventBus } from '../../bus';
import { programStatuses } from '../../utils/constants';

export default {
  name: 'FilterPage',
  components: { CheckBoxGroup },
  props: ['value'],
  methods: {
    submit() {
      this.$emit('submit', this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, 'status', []);
      EventBus.$emit('clear');
      this.$emit('clear', this.inputVal);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  data() {
    const { status } = this.$store.state.programs.filters;
    // for each in programStatuses map as {name: programStatus, type: programStatus}
    const statusTypes = programStatuses.map(programStatus => ({
      name: programStatus,
      type: programStatus,
    }));
    statusTypes.push({
      name: 'No Status',
      type: '_blanks',
    })
    return {
      inputVal: {
        status,
        matchDateRange: undefined
      },
      statusTypes: statusTypes,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
