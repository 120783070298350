<template>
  <div>
    <el-form
      label-position="top"
      :model="comp.meta"
      :rules="metaRules"
      id="metaRules"
      ref="metaRules"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="elite" label="Elite">
            <el-select v-model="comp.meta.elite">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="enableAwards" label="Awards">
            <el-select v-model="comp.meta.enableAwards">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="12">
          <el-form-item prop="teamOfYearVoting" label="Team Of Year Voting">
            <el-select v-model="comp.meta.awardsSettings.teamOfYear">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="allowDemerits" label="Allow Demerit Points">
            <el-select v-model="comp.meta.awardsSettings.demerits">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="8">
          <el-form-item prop="periodVoting" label="Period Voting">
            <el-select v-model="allowPeriodVoting">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="allowPeriodVoting">
          <el-form-item prop="periodNo" label="Number of Periods">
            <el-select v-model="comp.meta.awardsSettings.periodVoting">
              <el-option label="1" :value="1" />
              <el-option label="2" :value="2" />
              <el-option label="3" :value="3" />
              <el-option label="4" :value="4" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        v-if="comp.meta.enableAwards && allowPeriodVoting && comp.meta.awardsSettings.periodVoting"
      >
        <el-col :span="5" v-for="p in comp.meta.awardsSettings.periodVoting" :key="p">
          <el-form-item
            prop="roundRanges"
            v-if="p === 1 || (periodRangeObj[p - 1] && periodRangeObj[p - 1].length)"
            :label="`Add Round Ranges for Period ${p}`"
          >
            <el-slider
              range
              show-stops
              :min="checkMin(p)"
              :max="checkMax(p)"
              @change="(e) => capture(e, p)"
            >
            </el-slider>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="comp.meta.enableAwards">
        <el-col :span="12">
          <el-form-item prop="allowEOS" label="End of Season Categories">
            <el-checkbox-group v-model="comp.meta.awardsSettings.endOfSeasonVoting">
              <el-checkbox label="Captain"></el-checkbox>
              <el-checkbox label="Coach"></el-checkbox>
              <el-checkbox label="Rookie"></el-checkbox> </el-checkbox-group
          ></el-form-item> </el-col
      ></el-row>
    </el-form>

    <el-collapse v-model="activeNames">
      <el-collapse-item title="Team List Mailing List" name="1">
        <el-form
          @submit.prevent.native="addEmailToList('tl')"
          label-position="top"
          :model="notification"
          :rules="notificationRules"
          id="notificationRules"
          ref="notificationRules"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="newEmail">
                <el-input
                  class="add-email"
                  type="text"
                  autocomplete="off"
                  v-model="notification.newEmail"
                  placeholder="Add a contact email"
                >
                  <el-button
                    type="warning"
                    size="tiny"
                    slot="append"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addEmailToList('tl')"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <ul>
          <li shadow="hover" v-for="email in emailList" :key="email" class="small-card">
            <span> {{ email }} </span>
            <i class="el-icon-delete" @click="removeEmail(email, 'tl')"></i>
          </li>
        </ul>
      </el-collapse-item>
      <el-collapse-item title="Report Mailing List" name="2">
        <el-form
          @submit.prevent.native="addEmailToList('report')"
          label-position="top"
          :model="notification"
          :rules="reportListRules"
          id="reportListRules"
          ref="reportListRules"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item prop="newReportEmail">
                <el-input
                  class="add-email"
                  type="text"
                  autocomplete="off"
                  v-model="notification.newReportEmail"
                  placeholder="Add a contact email"
                >
                  <el-button
                    type="warning"
                    size="tiny"
                    slot="append"
                    icon="el-icon-circle-plus"
                    @click.prevent.native="addEmailToList('report')"
                  >
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <ul>
          <li shadow="hover" v-for="email in reportList" :key="email" class="small-card">
            <span> {{ email }} </span>
            <i class="el-icon-delete" @click="removeEmail(email, 'report')"></i>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>

    <action-buttons submit cancel @submit="updateMeta()" @cancel="cancelForm()" />
  </div>
</template>

<script>
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg } from "../../utils/constants";
import { validateEmail } from "../../utils/utilities";
import { EventBus } from "../../bus";

const validateContactEmail = (rule, value, callback) => {
  if (!value || !validateEmail(value)) {
    return callback(new Error(errormsg.email));
  }
  return callback();
};

export default {
  data() {
    const validateRoundRanges = (rule, value, callback) => {
      const { periodRanges, periodVoting } = this.comp.meta.awardsSettings;
      if (
        this.allowPeriodVoting &&
        periodVoting &&
        (!periodRanges || periodRanges.filter((p) => p).length !== periodVoting)
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatePeriodNo = (rule, value, callback) => {
      const { allowPeriodVoting } = this;
      if (allowPeriodVoting && !this.comp.meta.awardsSettings.periodVoting) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    return {
      activeNames: [],
      emailList:
        this.comp && this.comp.meta && this.comp.meta.notificationList
          ? this.comp.meta.notificationList
          : [],
      reportList:
        this.comp && this.comp.meta && this.comp.meta.reportList ? this.comp.meta.reportList : [],
      notification: { newEmail: "", newReportEmail: "" },
      notificationRules: {
        newEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      reportListRules: {
        newReportEmail: {
          message: errormsg.input_option,
          trigger: "submit",
          validator: validateContactEmail,
        },
      },
      metaRules: {
        elite: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        enableAwards: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
        },
        periodVoting: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        periodNo: {
          required: this.allowPeriodVoting,
          validator: validatePeriodNo,
          trigger: "blur",
        },
        teamOfYearVoting: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        allowDemerits: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        allowEOS: {
          required: false,
          message: errormsg.input_option,
          trigger: "blur",
        },
        roundRanges: {
          required: false,
          validator: validateRoundRanges,
          trigger: "change",
        },
      },
      allowPeriodVoting: false,
      periodRanges: [],
      periodRangeObj: {},
    };
  },
  components: { ActionButtons },
  props: {
    competition: { type: Object, required: true },
  },
  updated() {
    if (this.comp && this.comp.meta) {
      if (this.comp.meta.notificationList) this.emailList = this.comp.meta.notificationList;
      if (this.comp.meta.reportList) this.reportList = this.comp.meta.reportList;
    }
  },
  methods: {
    capture(e, p) {
      this.periodRangeObj[p] = e;
      this.comp.meta.awardsSettings.periodRanges[p - 1] = e.join("-");
    },
    checkMin(p) {
      if (this.periodRangeObj[p - 1] && this.periodRangeObj[p - 1][1])
        return this.periodRangeObj[p - 1][1] + 1;
      return 1;
    },
    checkMax(p) {
      if (this.periodRangeObj[p + 1] && this.periodRangeObj[p + 1][0])
        return this.periodRangeObj[p + 1][0] - 1;
      return this.comp.regularSeasonRounds || 25;
    },
    removeEmail(email, type) {
      const emailList = this[type === "tl" ? "emailList" : "reportList"];
      const filtered = emailList.filter((mail) => mail !== email);
      this.$store.commit("root/loading", true);
      const update =
        type === "tl"
          ? { meta: { ...this.comp.meta, notificationList: filtered } }
          : { meta: { ...this.comp.meta, reportList: filtered } };
      const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
      this.$http
        .put(url, update)
        .then((response) => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
          EventBus.$emit("updateCompetition", response.data.data);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    addEmailToList(type) {
      let divRef = "notificationRules";
      if (type === "report") divRef = "reportListRules";
      this.$refs[divRef].validate((valid) => {
        if (valid) {
          const newEmail = this.notification[type === "tl" ? "newEmail" : "newReportEmail"];
          this[type === "tl" ? "emailList" : "reportList"].push(newEmail);
          this.$store.commit("root/loading", true);
          const update =
            type === "tl"
              ? { meta: { ...this.comp.meta, notificationList: this.emailList } }
              : { meta: { ...this.comp.meta, reportList: this.reportList } };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
              this.notification.newEmail = "";
              this.notification.newReportEmail = "";
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
          this.$set(this.notification, "newEmail", "");
        }
      });
    },
    updateMeta() {
      this.$refs.metaRules.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          if (!this.allowPeriodVoting) {
            this.comp.meta.awardsSettings.periodRanges = [];
            this.comp.meta.awardsSettings.periodVoting = 0;
          }
          const update = { meta: { ...this.comp.meta, notificationList: this.emailList } };
          const url = `/nrl/api/v1/admin/competitions/${this.comp._id}`;
          this.$http
            .put(url, update)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess();
              EventBus.$emit("updateCompetition", response.data.data);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        }
      });
    },
  },
  computed: {
    comp() {
      return { ...this.competition, meta: this.competition.meta || {} };
    },
    roundRanges() {
      const values = Object.values(this.periodRangeObj);
      const finalRanges = values.map((r) => r.join("-"));
      if (finalRanges.filter((r) => r).length === this.comp.meta.awardsSettings.periodVoting)
        this.comp.meta.awardsSettings.periodRanges = finalRanges;
      return finalRanges;
    },
  },
  watch: {
    "comp.meta.awardsSettings.periodVoting": function (v) {
      if (v && v > 0) this.allowPeriodVoting = true;
      const obj = {};
      for (let i = 1; i <= v; i++) {
        obj[i] = [];
      }
      this.periodRangeObj = obj;
    },
    "comp.meta.awardsSettings.periodRanges": function (v) {
      const test = v.map((r) => r.split("-").map((r) => parseInt(r)));
      this.periodRangeObj = test;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.small-card {
  margin: 0.5rem;
}

.add-email {
  text-align: center;
}
</style>
