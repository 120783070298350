<template>
  <div>
    <el-form v-if="program.type">
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3 ">
        <el-col :span="6">
          <el-form-item label="Program Type">
            <el-input
              autocomplete="off"
              v-model="program.type"
              placeholder="Select a program type"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isActive" label="Active">
            <el-select v-model="program.isActive">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isContact" label="Contact">
            <el-select v-model="program.isContact">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="isVisibleToAllRegions" label="Visible to all regions">
            <el-select v-model="program.isVisibleToAllRegions">
              <el-option label="Yes" :value="true" />
              <el-option label="No" :value="false" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="submit-action" :span="12">
          <el-button @click="updateSettingsStatus" type="success">Submit</el-button>
        </el-col>
      </div>
    </el-form>
    <el-tabs type="card">
      <el-tab-pane label="Fees">
        <products @update-program="updateProgram" />
      </el-tab-pane>
      <el-tab-pane label="Saleable Items">
        <saleable-items :program="program" />
      </el-tab-pane>
      <el-tab-pane label="Additional Questions">
        <AdditionalQuestions :program="program" />
      </el-tab-pane>
      <el-tab-pane
        label="Discount Codes"
        v-if="isSuperAdmin"
      >
        <DiscountsCode :program="program" />
      </el-tab-pane>
      <el-tab-pane
        label="Visibility"
        v-if="isSuperAdmin && (program.isVisibleToAllRegions === false || program.isVisibleToAllRegions === undefined)"
      >
        <GameDevRegionList :program="program" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import Products from "./ProgramsettingsProducts.vue";
import SaleableItems from "./ProgramsettingsSaleableItems.vue";
import AdditionalQuestions from "./ProgramsettingsAdditionalQuestions.vue";
import DiscountsCode from './DiscountsCode.vue';
import GameDevRegionList from './GameDevRegionsList.vue'

export default {
  name: "ProgramsettingsForm",
  components: {
    Products,
    SaleableItems,
    AdditionalQuestions,
    DiscountsCode,
    GameDevRegionList
  },
  data() {
    return {
      program: {
        type: undefined,
        options: [],
        saleableItems: [],
        additionalQuestions: [],
        isActive: null,
        isContact: null,
        isVisibleToAllRegions: null,
      },
      isSuperAdmin: this.$store.getters['user/isSuperAdmin']
    };
  },
  methods: {
    updateProgram(val) {
      _.assign(this.program, val);
    },
    updateSettingsStatus() {
      const { isActive, _id, isContact, isVisibleToAllRegions } = this.program;
      this.$store.commit("root/loading", true);
      const url = `/nrl/api/v1/admin/programs-settings/${_id}`;
      this.$http
        .put(url, { isActive, isContact, isVisibleToAllRegions })
        .then(() => {
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  }
};
</script>

<style scoped lang="scss">
.submit-action {
  float: right;
  width: auto;
}

.el-select {
  width: 100%;
}
</style>
