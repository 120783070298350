import axios from 'axios';
import store from '../store';
import moment from 'moment';
import { httpnoauth_graphql_beta } from './httpgraphqlapi';

// GraphQL beta API to get access_token which gives access to the GraphQL API
export const httpauth_apikeys_beta = axios.create({
  baseURL: store.getters['root/graphqlAuthApiUrl'],
  headers: {
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
  }
});

// Add authorization header to all requests
httpauth_apikeys_beta.interceptors.request.use((config) => {

  const originalRequest = config;
  const now = moment().utc().valueOf();
  const expiry = store.getters["user/graphqlToken"] ? store.getters["user/graphqlToken"].expires_at : undefined;
  const accessToken = store.getters["user/realmToken"] ? store.getters["user/realmToken"] : undefined;
  
  // Issue a new token if its expired or about to expire or token is undefined
  if ((expiry - 300 <= now) || !accessToken || !expiry) {
    store.commit("root/loading", true);
    return httpnoauth_graphql_beta
      .get('/api/v1/issue-token', { 'headers': { 'apitoken': store.getters["user/idTokenNoRoles"] } })
      .then((response) => {
        // add response to store as graphql_token
        store.commit("user/updateGraphqlToken", response.data.graphql);
        store.commit("user/updateRealmToken", response.data.realm);
        originalRequest.headers.Authorization = `Bearer ${response.data.realm}`;
        return originalRequest;
      })
      .catch(() => {
        customError();
        store.commit('root/loading', false);
      });
  }

  originalRequest.headers.Authorization = `Bearer ${accessToken}`;
  return originalRequest;
}, err => Promise.reject(err));

export default httpauth_apikeys_beta;
