<template>
  <div>
    <el-form>
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="name" label="Game dev regions">
            <el-select
              v-model="name"
              placeholder="Select a game dev region"
              filterable
            >
              <el-option
                v-for="row in gameDevRegionsList"
                :label="row.name"
                :key="row._id"
                :value="row._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="submit-action" :span="12">
          <el-button @click="addGameDevRegion" type="success">Add</el-button>
        </el-col>
      </el-row>
    </el-form>
    <data-tables
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      class="data-table">
      <el-table-column
        prop="_id"
        label="ID"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto">
      </el-table-column>
      <el-table-column label="Action" width="200">
        <template slot-scope="scope">
          <el-button @click="handleRemoveRowRegion(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
export default {
  name: 'GameDevRegionList',
  props: ["program"],
  data() {
    return {
      gameDevRegionsList: [],
      tableData: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      name: '',
      programSettings: {},
    };
  },
  mounted() {
    this.$store.commit('root/loading', true);
    this.$http
      .get('/nrl/api/v1/admin/game-dev-regions')
      .then((response) => {
        this.gameDevRegionsList = response.data.data;
        this.programSettings = this.program;
        this.filterGameDevRegionData(this.gameDevRegionsList);
        this.$store.commit('root/loading', false);
      })
      .catch(() => {
        this.$customError();
        this.$store.commit('root/loading', false);
      });
  },
  methods: {
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    filterGameDevRegionData(data) {
      this.tableData = data.filter((row) => {
        return this.programSettings.visibleToRegions && this.programSettings.visibleToRegions.includes(row._id);
      });
    },
    addGameDevRegion() {
      let visibleToRegions;
      const { _id } = this.programSettings;
      if(this.programSettings.visibleToRegions) {
        visibleToRegions = this.programSettings.visibleToRegions;
        visibleToRegions.push(this.name);
      } else {
        visibleToRegions = [this.name];
      }
      visibleToRegions = [...new Set([...visibleToRegions])]
      this.updateGameDevRegions(visibleToRegions, _id);
    },
    handleRemoveRowRegion(indexToDelete) {
      let visibleToRegions = this.programSettings.visibleToRegions;
      const { _id } = this.programSettings;
      visibleToRegions = visibleToRegions.filter(function (element, index) {
        return index !== indexToDelete;
      });
      this.updateGameDevRegions(visibleToRegions, _id);
    },
    updateGameDevRegions(visibleToRegions, _id) {
      this.$store.commit("root/loading", true);
      const url = `/nrl/api/v1/admin/programs-settings/${_id}`;
      this.$http
        .put(url, { visibleToRegions })
        .then((response) => {
          this.programSettings = response.data.data;
          this.filterGameDevRegionData(this.gameDevRegionsList);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    }
  },
};
</script>

<style scoped lang="scss">
    .data-table {
      width: 100%;
      margin-top: 2.5rem !important;
    }
</style>
