<template>
  <div class="d-flex-column">
    <!-- <el-row>
      <el-col>
        <CheckBoxGroup
          :choices="[{type: true, name: 'Yes'}, {type: false, name: 'No'}]"
          name="Active Status"
          v-model="inputVal.active"
        />
      </el-col>
    </el-row> -->
    <el-row>
      <el-col>
        <CheckBoxGroup :choices="tribunalStatus" name="Status" v-model="inputVal.status" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CheckBoxGroup
          :choices="onFieldActions"
          name="On Field Action"
          v-model="inputVal.onFieldAction"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col style="margin: 5px 15px 0px">
        <div style="margin-bottom: 15px">Match Date Range</div>
        <el-date-picker
          v-model="inputVal.matchDateRange"
          type="daterange"
          range-separator="|"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          align="right"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="margin: 5px 15px 0px">
        <div style="margin-bottom: 15px">Round</div>
        <el-select v-model="inputVal.rounds" placeholder="Select a round" multiple filterable>
          <el-option
            v-for="round in roundOptions"
            :label="round.name"
            :key="round.type"
            :value="round.type"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <div class="mt-2 d-flex-column">
      <div class="mt-1">
        <el-button @click="clear" class="full-width button-invisible">Clear</el-button>
        <el-button @click="submit" type="primary" class="full-width">Apply Filters</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { tribunalStatus, onFieldActions } from "@/utils/constants";
import CheckBoxGroup from "@/components/CheckboxGroup.vue";
import { EventBus } from "../../bus";

export default {
  name: "FilterPage",
  components: { CheckBoxGroup },
  props: ["value", "rounds"],
  methods: {
    submit() {
      this.$emit("submit", this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, "active", [true]);
      this.$set(this.inputVal, "status", []);
      this.$set(this.inputVal, "onFieldAction", []);
      this.$set(this.inputVal, "matchDateRange", undefined);
      this.$set(this.inputVal, "rounds", []);
      EventBus.$emit("clear");
      this.$emit("clear", this.inputVal);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  data() {
    return {
      inputVal: {
        active: [true],
        status: [],
        onFieldAction: [],
        matchDateRange: undefined,
        rounds: [],
      },
      tribunalStatus: tribunalStatus.map((name) => ({ type: name, name })),
      onFieldActions: onFieldActions.map((name) => ({ type: name, name })),
      roundOptions: this.rounds
        .map((r) => ({ type: r.value, name: r.displayName }))
        .sort((a, b) => a.type - b.type),
    };
  },
};
</script>

<style lang="scss" scoped></style>
