<template>
  <div>
    <p style="border-bottom: solid; border-color: lightgrey" class="tw-py-4">
      KYC report
      <br />
    </p>
    <div v-if="!rawHtml">
      <p>
        For a failed transfers report that do not have kyc press kyc below.
        <br />
        The report will display on your screen
      </p>
      <p class="tw-py-4">
        <button class="el-button el-button--success" @click="sendReport(0)">KYC</button>
      </p>
    </div>

    <div ref="report" v-html="rawHtml"></div>
    <p style="border-bottom: solid; border-color: lightgrey" class="tw-py-4">
      Wip report
      <br />
    </p>
    <div v-if="!rawHtmlWip">
      <p>
        For a status report on wip pin press wip below.
        <br />
        The report will display on your screen
      </p>
      <p class="tw-py-4">
        <button class="el-button el-button--success" @click="sendReportWip(0)">Wip</button>
      </p>
    </div>

    <div ref="reportWip" v-html="rawHtmlWip"></div>

    <p style="border-bottom: solid; border-color: lightgrey" class="tw-py-4">Email report</p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="dateRange" label="Date Range">
            <el-date-picker
              v-model="filters.dateRange"
              type="daterange"
              range-separator="|"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReportLegacy()" />
    </el-form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, mysidelineSeasons } from "@/utils/constants";

export default {
  name: "FailedTransfersReport",
  components: {
    ActionButtons,
  },
  mounted() {
    const { load, skip, pages } = this.$route.query;
    if (load === "auto") {
      this.sendReport(skip, pages);
    }
  },
  data() {
    return {
      rawHtml: null,
      rawHtmlWip: null,
      filters: {
        dateRange: null,
        fileType: "csv",
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
        dateRange: {
          required: true,
          trigger: "blur",
          validator: (obj, val, callback) => {
            if (!val) {
              return callback(new Error(errormsg.select_option));
            }
            return callback();
          },
        },
      },
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
    };
  },
  methods: {
    sendReportLegacy() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "failed-transfers",

            fileType: this.filters.fileType,
            filters: {
              dateRange: this.filters.dateRange,
            },
            meta: {
              timezone: moment.tz.guess(),
            },
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    },
    sendReport(skip, pages) {
      console.log("report");
      if (!skip) skip = 0;
      if (!pages) pages = 1;
      this.$store.commit("root/loading", true);
      this.$httpms
        .get(`/getReport?skip=${skip}&pages=${pages}`) // getReport
        .then((response) => {
          if (response.data) this.rawHtml = response.data;
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
    sendReportWip(skip, pages) {
      console.log("report");
      if (!skip) skip = 0;
      if (!pages) pages = 1;
      this.$store.commit("root/loading", true);
      this.$httpms
        .get(`/getPinWipReport?skip=${skip}&pages=${pages}`) // getReport
        .then((response) => {
          if (response.data) this.rawHtmlWip = response.data;
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
  },
  computed: {
    seasons() {
      return mysidelineSeasons.map((v) => ({ name: v.toString(), value: v }));
    },
  },
};
</script>
