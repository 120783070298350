import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';

import httpauth from '../axios/httpauth';
import { reject } from 'core-js/fn/promise';

const formatWithName = pl => ({ ...pl, name: `${pl.profile.firstName} ${pl.profile.lastName}` });
const getDefaultState = () => ({
    team: {
        defaultSquad: {
            players: [],
            nonPlayers: [],
            modifiedAt: null,
        },
        availablePlayers: [],
        availableNonPlayers: [],
        compid: null
    }
});

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        updateField,
        resetState(state) {
          Object.assign(state, getDefaultState());
        },
        setAvailablePlayers(state, { data, type }) {
            const field = type === 'player' ? 'availablePlayers' : 'availableNonPlayers';
            state.team[field] = data || [];
        },
        setDefaultSquad(state, { data, type }) {
            const field = type === 'player' ? 'players' : 'nonPlayers';
            state.team.defaultSquad[field] = data || [];
        },
        setModifiedSquadTime(state, data ) {
            state.team.defaultSquad.modifiedAt = data.modifiedAt;
        },
        setTeamComp(state, data){
            state.team.compid = data || null;
        }
    },
    actions: {
        getPlayers({ commit }, teamid) {
          return new Promise((resolve, reject) => {
            Promise.all([
              httpauth.get(`/nrl/api/v1/admin/teamlists/players/${teamid}`),
              httpauth.get(`/nrl/api/v1/admin/teamlists/nonplayers/${teamid}`),
            ]).then(([playerRes, nonPlayerRes]) => {
              commit('setAvailablePlayers', {
                data: playerRes.data.data.map(p => formatWithName(p)),
                type: 'player'
              });
              commit('setAvailablePlayers', {
                data: nonPlayerRes.data.data.map(p => formatWithName(p)),
                type: 'nonplayer'
              });
              resolve();
            }).catch((err) => {
              reject(err);
            });
          });
        },
        getDefaultSquad({commit}, teamid){
            return new Promise((resolve, reject) =>{
                Promise.all([
                    httpauth.get(`/nrl/api/v1/admin/teams/${teamid}/defaultsquad`),
                    httpauth.get(`/nrl/api/v1/admin/teamlists/players/${teamid}`),
                    httpauth.get(`/nrl/api/v1/admin/teamlists/nonplayers/${teamid}`),
                ])
                .then(([defSquad, playerRes, npRes]) => {
                    const defaultSquad = defSquad.data.data.defaultSquad;
                    const playerSquad = playerRes.data.data;
                    const npSquad = npRes.data.data;
                    const filteredSquad = defaultSquad && defaultSquad.players ? defaultSquad.players.filter( p => playerSquad.find(pl=> p._id === pl._id) ): [];
                    const filteredNpSquad = defaultSquad && defaultSquad.nonPlayers ? defaultSquad.nonPlayers.filter( p => npSquad.find(pl=> p._id === pl._id) ) : [];
                    
                    if(defaultSquad && defaultSquad.modifiedAt){
                        const {modifiedAt} = defaultSquad;                   
                        commit('setModifiedSquadTime', {modifiedAt});
                    }
                    
                    if(filteredSquad.length > 0)
                        commit('setDefaultSquad', { data: filteredSquad, type: 'player' });
                    if(filteredNpSquad.length > 0)
                        commit('setDefaultSquad', { data: filteredNpSquad, type: 'nonplayer' });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        getTeamComp({commit}, teamid){
            return new Promise ((resolve, reject)=>{
                httpauth.get(`/nrl/api/v1/admin/teams/${teamid}/competition`)
                .then((res)=>{
                    const comps = res.data.data;
                    commit('setTeamComp', comps);
                    resolve();
                })
                .catch((err)=>{
                    reject(err);
                })
            })
        }
    },
    getters: {
        getField,
        availablePlayers: state => state.team.availablePlayers,
        availableNonPlayers: state => state.team.availableNonPlayers,
        defaultSquad: state => state.team.defaultSquad.players,
        npDefaultSquad: state => state.team.defaultSquad.nonPlayers,
        squadLastModified: state => state.team.defaultSquad.modifiedAt,
        compid: state => state.team.compid
    }
}