<template>
  <div>
    <el-form label-position="top" :model="regiProduct" :rules="discountrules" ref="regidiscounts">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="8">
          <el-form-item prop="competitionId" label="Competition">
            <el-select v-model="competitionId" placeholder="Competition" filterable>
              <el-option
                v-for="comp in availableCompetitions"
                :label="comp.name"
                :key="comp._id"
                :value="comp._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discounttype" label="Discount type">
            <el-select v-model="discounttype" placeholder="Discount type" filterable>
              <el-option v-for="item in allDiscountTypes" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountname" label="Discount name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="discountname"
              placeholder="Discount name"
            />
          </el-form-item>
        </el-col>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="discountdatefrom" label="Discount from">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="discountdatefrom"
              placeholder="Discount from date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountdateto" label="Discount to">
            <el-date-picker
              format="dd/MM/yyyy"
              type="date"
              v-model="discountdateto"
              placeholder="Discount to date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="discountamount" label="Discount amount">
            <el-input
              type="text"
              autocomplete="off"
              v-model="discountamount"
              placeholder="Discount amount"
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 ">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitDiscount('regidiscounts')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      v-if="regiProduct.competitionDiscounts.length > 0"
      :data="regiProduct.competitionDiscounts"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Competition" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.competitionId"
            placeholder="Competition"
            :disabled="!discountoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option
              v-for="comp in availableCompetitions"
              :label="comp.name"
              :key="comp._id"
              :value="comp._id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Type" width="90">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.type"
            placeholder="Type"
            :disabled="!discountoptionsdisabled[scope.$index]"
            filterable
          >
            <el-option v-for="item in allDiscountTypes" :label="item" :key="item" :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Discount Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.discountName"
            :disabled="!discountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Discount Amount" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.discountAmount"
            :disabled="!discountoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Discount From" width="170">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.discountFrom"
            placeholder="Discount from date"
            value-format="timestamp"
            :disabled="!discountoptionsdisabled[scope.$index]"
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Discount To" width="170">
        <template slot-scope="scope">
          <el-date-picker
            format="dd/MM/yyyy"
            type="date"
            v-model="scope.row.discountTo"
            placeholder="Discount to date"
            value-format="timestamp"
            :disabled="!discountoptionsdisabled[scope.$index]"
            :clearable="false"
          />
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRowDiscount(scope.$index)">
            <template v-if="!discountoptionsdisabled[scope.$index]">
              Edit
            </template>
            <template v-else>
              Save
            </template>
          </el-button>
          <el-button @click="handleRemoveRowDiscount(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { errormsg } from "../../utils/constants";

export default {
  name: "CompetitionDiscountsDateRange",
  props: ["regiProduct"],
  data() {
    const validateDiscountType = (rule, value, callback) => {
      if (this.currentDiscount.type == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountName = (rule, value, callback) => {
      if (!this.currentDiscount.discountName || this.currentDiscount.discountName === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountFrom = (rule, value, callback) => {
      if (!this.currentDiscount.discountFrom) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountTo = (rule, value, callback) => {
      if (!this.currentDiscount.discountTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDiscountAmount = (rule, value, callback) => {
      if (
        !this.currentDiscount.discountAmount ||
        !parseFloat(this.currentDiscount.discountAmount) ||
        parseFloat(this.currentDiscount.discountAmount) <= 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      competitions: [],
      currentDiscount: {
        competitionId: undefined,
        type: undefined,
        discountName: undefined,
        discountFrom: undefined,
        discountTo: undefined,
        discountAmount: undefined
      },
      discountoptionsdisabled: [],
      allDiscountTypes: ["$", "%"],
      discountrules: {
        discounttype: {
          required: true,
          validator: validateDiscountType,
          message: errormsg.select_option,
          trigger: "change"
        },
        discountname: {
          required: true,
          validator: validateDiscountName,
          message: errormsg.input_option,
          trigger: "blur"
        },
        discountdatefrom: {
          required: true,
          validator: validateDiscountFrom,
          message: errormsg.input_option,
          trigger: "blur"
        },
        discountdateto: {
          required: true,
          validator: validateDiscountTo,
          message: errormsg.input_option,
          trigger: "blur"
        },
        discountamount: {
          required: true,
          validator: validateDiscountAmount,
          message: errormsg.input_option,
          trigger: "blur"
        }
      },
      tableProps: {
        border: true
      }
    };
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/admin/competitions")
      .then(response => {
        this.competitions = response.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    submitDiscount(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (
            this.currentDiscount.type === "%" &&
            parseFloat(this.currentDiscount.discountAmount) >= 100
          ) {
            this.$customError(errormsg.regiproduct_invalidpercetageamount);
            return false;
          }
          const cd = this.currentDiscount;
          const today = this.moment()
            .startOf("day")
            .valueOf();
          const cddiscountFrom = this.moment(cd.discountFrom, "YYYY-MM-DD").valueOf();
          const cddiscountTo = this.moment(cd.discountTo, "YYYY-MM-DD").valueOf();
          if (cddiscountFrom < today) {
            this.$customError(errormsg.regiproduct_invaliddiscountfrom);
            return false;
          }
          if (cddiscountFrom > cddiscountTo) {
            this.$customError(errormsg.regiproduct_invaliddiscountfrom);
            return false;
          }
          const discountSameRange = this.regiProduct.competitionDiscounts.find(
            d =>
              d.competitionId === cd.competitionId &&
              !(d.discountFrom > cddiscountTo || d.discountTo < cddiscountFrom)
          );

          if (discountSameRange) {
            this.$customError(errormsg.regiproduct_overlap);
            return false;
          }
          this.$store.commit("root/loading", true);
          const compDiscount = {
            competitionId: this.currentDiscount.competitionId,
            type: this.currentDiscount.type,
            discountName: this.currentDiscount.discountName,
            discountFrom: cddiscountFrom,
            discountTo: cddiscountTo,
            discountAmount_int: parseInt(
              parseFloat(this.currentDiscount.discountAmount).toFixed(2) * 100
            ),
            discountAmount: Math.floor(this.currentDiscount.discountAmount)
          };

          const insert = {
            competitionId: compDiscount.competitionId,
            type: compDiscount.type === "$" ? "dollar" : "percentage",
            name: compDiscount.discountName,
            fromDate: cddiscountFrom,
            toDate: cddiscountTo,
            amount:
              compDiscount.type === "$"
                ? compDiscount.discountAmount_int
                : compDiscount.discountAmount
          };
          this.currentDiscount = {
            competitionId: undefined,
            type: undefined,
            discountName: undefined,
            discountFrom: undefined,
            discountTo: undefined,
            discountAmount: undefined
          };
          const url = "/nrl/api/v1/admin/registration-products/competition-discounts";
          this.$http
            .post(url, insert)
            .then(response => {
              compDiscount._id = response.data.data._id;
              this.regiProduct.competitionDiscounts.push(compDiscount);
              this.$store.commit("root/loading", false);
              this.discountoptionsdisabled.push(false);
              this.$customSuccess();
            })
            .catch(error => {
              this.$store.commit("root/loading", false);
              this.$customError(error.response.data.message);
            });
          return true;
        }
        return false;
      });
    },
    handleEditRowDiscount(rowindex) {
      if (this.discountoptionsdisabled[rowindex]) {
        const row = this.regiProduct.competitionDiscounts[rowindex];
        if (!row.discountName || row.discountName === "") {
          this.$customError(errormsg.regiproduct_invaliddiscountname);
          return false;
        }
        if (
          !row.discountAmount ||
          !parseFloat(row.discountAmount) ||
          parseFloat(row.discountAmount) <= 0
        ) {
          this.$customError(errormsg.regiproduct_invaliddiscountamount);
          return false;
        }
        if (row.type === "%" && parseFloat(row.discountAmount) >= 100) {
          this.$customError(errormsg.regiproduct_invalidpercetageamount);
          return false;
        }

        const cddiscountFromValue = row.discountFrom;
        const cddiscountToValue = row.discountTo;
        const today = this.moment()
          .startOf("day")
          .valueOf();
        if (cddiscountFromValue < today) {
          this.$customError(errormsg.regiproduct_invaliddiscountfrom);
          return false;
        }
        if (cddiscountFromValue > cddiscountToValue) {
          this.$customError(errormsg.regiproduct_invaliddiscountfrom);
          return false;
        }
        const discountSameRange = this.regiProduct.competitionDiscounts.find(
          d =>
            row._id !== d._id &&
            d.competitionId === row.competitionId &&
            !(d.discountFrom > cddiscountToValue || d.discountTo < cddiscountFromValue)
        );
        if (discountSameRange) {
          this.$customError(errormsg.regiproduct_overlap);
          return false;
        }
        this.$store.commit("root/loading", true);
        this.regiProduct.competitionDiscounts[rowindex].discountFrom = cddiscountFromValue;
        this.regiProduct.competitionDiscounts[rowindex].discountTo = cddiscountToValue;
        this.regiProduct.competitionDiscounts[rowindex].discountAmount =
          row.type === "$"
            ? parseFloat(row.discountAmount).toFixed(2)
            : Math.floor(row.discountAmount);
        this.regiProduct.competitionDiscounts[rowindex].discountAmount_int = parseInt(
          parseFloat(row.discountAmount).toFixed(2) * 100
        );
        this.$set(this.discountoptionsdisabled, rowindex, !this.discountoptionsdisabled[rowindex]);

        const compDiscount = this.regiProduct.competitionDiscounts[rowindex];
        const update = {
          competitionId: compDiscount.competitionId,
          type: compDiscount.type === "$" ? "dollar" : "percentage",
          name: compDiscount.discountName,
          fromDate: cddiscountFromValue,
          toDate: cddiscountToValue,
          amount:
            compDiscount.type === "$"
              ? compDiscount.discountAmount_int
              : Math.floor(compDiscount.discountAmount)
        };
        const url = `/nrl/api/v1/admin/registration-products/competition-discounts/${compDiscount._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
            this.$set(
              this.discountoptionsdisabled,
              rowindex,
              !this.discountoptionsdisabled[rowindex]
            );
          })
          .catch(error => {
            this.$store.commit("root/loading", false);
            this.$customError(error.response.data.message);
          });
      }
      this.$set(this.discountoptionsdisabled, rowindex, !this.discountoptionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRowDiscount(index) {
      this.$store.commit("root/loading", true);
      const compDiscount = this.regiProduct.competitionDiscounts[index];
      this.$http
        .delete(`/nrl/api/v1/admin/registration-products/competition-discounts/${compDiscount._id}`)
        .then(() => {
          this.regiProduct.competitionDiscounts.splice(index, 1);
          this.discountoptionsdisabled.splice(index, 1);
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        })
        .catch(error => {
          this.$store.commit("root/loading", false);
          this.$customError(error.response.data.message);
        });
    }
  },
  computed: {
    availableCompetitions() {
      return this.competitions.filter(c =>
        this.regiProduct.competitionProducts.map(p => p.competitionId).includes(c._id)
      );
    },
    competitionId: {
      get() {
        return this.currentDiscount.competitionId;
      },
      set(value) {
        this.currentDiscount.competitionId = value || undefined;
      }
    },
    discounttype: {
      get() {
        return this.currentDiscount.type != null ? this.currentDiscount.type : null;
      },
      set(value) {
        this.currentDiscount.type = value;
      }
    },
    discountname: {
      get() {
        return this.currentDiscount.discountName != null ? this.currentDiscount.discountName : null;
      },
      set(value) {
        this.currentDiscount.discountName = value;
      }
    },
    discountdatefrom: {
      get() {
        return this.currentDiscount.discountFrom;
      },
      set(value) {
        this.currentDiscount.discountFrom =
          value && value !== ""
            ? this.moment(
                value
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" "),
                "ddd MMM DD YYYY"
              ).format("YYYY-MM-DD")
            : null;
      }
    },
    discountdateto: {
      get() {
        return this.currentDiscount.discountTo;
      },
      set(value) {
        this.currentDiscount.discountTo =
          value && value !== ""
            ? this.moment(
                value
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" "),
                "ddd MMM DD YYYY"
              ).format("YYYY-MM-DD")
            : null;
      }
    },
    discountamount: {
      get() {
        return this.currentDiscount.discountAmount != null
          ? this.currentDiscount.discountAmount
          : null;
      },
      set(value) {
        this.currentDiscount.discountAmount = Number(value) && parseFloat(value) > 0 ? value : null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
