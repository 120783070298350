<template>
  <div>
    <el-row>
      <el-button @click="addComp" type="success" icon="el-icon-plus" class="add-comp"> Add Competition </el-button>
    </el-row>
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input
          autocomplete="off"
          v-model="filters.search"
          placeholder="Search...">
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible=true"> Filters </el-button>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>
    <data-tables-server
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :total="total"
      @query-change="queryChange"
      @row-click="handleRowClick"
      class="data-table">
      <el-table-column
        prop="name"
        label="Name"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="orgtree.association.name"
        label="Association"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="season"
        label="Season"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="ageLvl"
        label="Competition Age Group"
        width="auto">
      </el-table-column>
      <el-table-column
        :formatter="compStatus"
        prop="isActive" 
        label="Competition Status"
        width="auto">
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterPage from './FilterPage.vue';

export default {
  name: 'CompetitionsList',
  components: { FilterPage },
  props: {
  },
  data() {
    const { filters, pagination: { total, pageSize, currentPage } } = this.$store.state.competitions;
    return {
      queryInfo: null,
      loading: false,
      tableData: [],
      pageSize,
      currentPage,
      total,
      filterVisible: false,
      filters,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
    };
  },
  methods: {
    queryChange(queryInfo) {
      this.queryInfo = queryInfo;
      this.fetchData();
    },
    fetchData() {
      const { pageSize, page } = this.queryInfo;
      this.loading = true;
      this.$http
        .get(`/nrl/api/v1/admin/competitions/data-table/${pageSize}/${page}`, { params: this.filters })
        .then((response) => {
          // eslint-disable-next-line no-multi-assign
          this.tableData = response.data.data.pageData;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
        });
    },
    sendFilter(e) {
      const { isActive } = e;
      this.filters.isActive = isActive;
      this.filterVisible = false;
      this.$store.commit('competitions/updateFilterIsActive', isActive);
      this.fetchData();
    },
    addComp() {
      this.$router.push({ name: 'competitions.update', params: { type: 'add', id: 'add' }, });
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'competitions.update',
        params: {
          type: 'update',
          comp: row,
          id: row._id,
        },
      });
    },
    modifiedFormatter(row) {
      return (row.modifiedAt ? this.moment(row.modifiedAt).format('MMMM Do YYYY, h:mm:ss a') : 'NA');
    },
    compStatus(row){
      return (row.isActive ? "Active" : "Inactive");
    },
  },
  watch: {
    tableData(data) {
      this.$store.commit('competitions/updateData', data);
      this.$store.commit('competitions/updatePagination', { data: _.pick(this, ['pageSize', 'currentPage', 'total']) });
    },
    // eslint-disable-next-line func-names
    'filters.search': _.debounce(function (name) {
      this.$store.commit('competitions/updateSearch', name);
      this.fetchData();
    }, 500)
  }
};
</script>

<style scoped lang="scss">
    .search-bar {
      margin-bottom: 1rem;
    }

    .data-table {
        width: 100%;
    }
    .el-pagination {
        margin-top: 1rem !important;
    }

    .add-comp {
      float: left;
      margin-bottom: 2rem;
    }

</style>
