<template>
  <div>
    <el-button icon='el-icon-plus'
      v-if="this.$store.getters['user/activeRole'].type !== 98"
      type="success"
      class="add-program-button"
      @click="handleAddProgram" >
      Add Program
    </el-button>
    <el-row>
      <el-row class="search-bar" :gutter="10">
        <el-col :span="6">
          <el-input
            id="filterSearch"
            autocomplete="off"
            v-model="filters[0].value"
            placeholder="Filter search..."
          >
          </el-input>
        </el-col>
        <el-col :span="18">
          <el-button @click="filterVisible = true">Filters</el-button>
          <el-button :disabled="showAll === false" :type="showAll === false ? 'error': 'success'" style="float: right;" @click="applyFilters" icon="el-icon-refresh-left">Undo</el-button>
          <el-button :disabled="showAll === true" :type="showAll === true ? 'error': 'success'" style="float: right;" @click="clearFilters">Show All Programs</el-button>
        </el-col>
      </el-row>
    </el-row>
    
    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page
        @cancel="filterVisible = false"
        @submit="sendFilter"
        @clear="sendFilter"
      />
    </el-dialog>
    
    <data-tables
      :filters="filters"
      v-loading="loading"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table">
       <el-table-column
        fixed
        prop="settings.type"
        label="Program Type"
        width="auto">
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="Program Name"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="settings.duration"
        label="No. of Sessions"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="venue.name"
        label="Venue"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="gdo.name"
        label="GDO Contact Name"
        width="auto">
      </el-table-column>
      <el-table-column
        label="Start Date"
        width="150">
        <template slot-scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Program Status"
        width="auto">
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import moment from 'moment';
import FilterPage from "./FiltersPage.vue";

export default {
  name: 'ProgramsList',
  components: { FilterPage },
  props: {
  },
  data() {
    return {
      loading: true,
      tableData: [
      ],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      filterVisible: false,
      showAll: false,
      filters: [
        {
          value: "",
          filterFn: (row, filter) =>
            Object.keys(row).some(prop => {
              if (!filter.value || filter.value === "") return false;
              if (prop) {
                const {name, } = row;
                const venueName = row.venue.name;
                const programType = row.settings.type;
                const nameFilter = name || "";
                const venueFilter = venueName || "";
                const programTypeFilter = programType || "";
                const filterRegex = new RegExp(_.escapeRegExp(filter.value), "i");
                return (
                  nameFilter.match(filterRegex) ||
                  venueFilter.match(filterRegex) ||
                  programTypeFilter.match(filterRegex)
                );
              }
              return false;
            })
        },
        {
          value: []
        }
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.$http
      .get('/nrl/api/v1/admin/programs')
      .then((response) => {
        this.$store.commit("programs/updateData", _.orderBy(response.data.data, 'startDate', 'desc'));
        this.applyFilters();
        this.$store.commit('root/loading', false);
        this.loading = false;
      })
      .catch(() => {
        this.$customError();
      });
  },
  methods: {
    clearFilters() {
      this.tableData = this.$store.state.programs.data;
      this.showAll = true;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'program.update',
        params: {
          type: 'update',
          program: row,
          settings: row.settings,
          id: row._id,
        },
      });
    },
    handleAddProgram() {
      this.$router.push({
        name: 'program.add',
        params: {
          type: 'insert',
        },
      });
    },
    applyFilters() {
    this.showAll = false;
    const { status, matchDateRange } = this.$store.state.programs.filters;
    let filteredData;
      if(status && status.length > 0) {
        // filter this.tableDate where status is in status array
        filteredData = _.filter(this.$store.state.programs.data, (row) => {
          if(row.status) {
            const matchedStatus = status.find(element => {
              if (row.status.includes(element)) {
                return true;
              }
            })
            if (matchedStatus !== undefined) {
              return true;
            }
          } else {
            if(status.includes('_blanks')) {
              return true;
            }
          }
        });
      } else {
        filteredData = this.$store.state.programs.data;
      }
      if (matchDateRange) {
        const [start, end] = matchDateRange;
        filteredData = _.filter(filteredData, (row) =>
            start <= _.get(row, "startDate") &&
            _.get(row, "startDate") <= +this.moment(end).add(1, "day")
        );
      }
      this.tableData = filteredData;
    },
    sendFilter(event) {
      console.log('event', event);
      this.$store.commit("programs/updateStatusFilter", event.status);
      this.$store.commit("programs/updateDateRangeFilter", event.matchDateRange);

      this.applyFilters();
      this.filterVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
    .data-table {
        width: 100%;
        margin-top: 1rem !important;
    }
    .el-pagination {
        margin-top: 1rem !important;
    }
    .add-program-button {
        margin-bottom: 1rem !important;
    }
</style>
