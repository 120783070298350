<template>
  <div>
    <el-dialog
      title="Does Interchange Count?"
      :visible.sync="interchangeDialogVisible"
      width="30%"
      :before-close="handleDialogClose"
    >
      <span>Please confirm whether this interchange counted or not</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            interchangeDialogVisible = false;
            interchangeCounts = false;
          "
          >No</el-button
        >
        <el-button
          type="primary"
          @click="
            interchangeDialogVisible = false;
            interchangeCounts = true;
          "
          >Yes</el-button
        >
      </span>
    </el-dialog>
    <h4 class="tw-py-4 tw-font-bold">Team Name - {{ team.name }}</h4>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="24" class="add-movements">
        <el-form label-position="top" :model="add" :rules="movementRules" ref="movementRules">
          <el-form-item prop="playeroff">
            <el-select v-model="add.playeroff" placeholder="Player off" clearable filterable>
              <el-option
                v-for="item in availablePlayersToAdd"
                :key="item.name"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="playeron">
            <el-select v-model="add.playeron" placeholder="Player on" clearable filterable>
              <el-option
                v-for="item in availablePlayersToAdd"
                :key="item.name"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="event">
            <el-select
              v-model="add.event"
              placeholder="Movement"
              clearable
              filterable
              @change="showInterchangeDialog"
            >
              <el-option
                v-for="item in playerInterchanges"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="min">
            <el-input
              autocomplete="off"
              type="number"
              v-model.number="add.min"
              placeholder="Min"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sec">
            <el-input
              autocomplete="off"
              type="number"
              v-model.number="add.sec"
              placeholder="Sec"
            ></el-input>
          </el-form-item>
          <el-form-item prop="period" v-if="competition && competition.meta.elite">
            <el-select v-model="add.period" placeholder="Period" clearable filterable>
              <el-option
                v-for="item in gamePeriods"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="handleAddMovement"> Add </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="allMovements.length > 0"
        :data="allMovements"
        :table-props="tableProps"
        layout="table"
        :page-size="pageSize"
        @row-click="handleRemoveMovement"
        id="movements-table"
        class="data-table"
      >
        <el-table-column prop="name" label="Movement"> </el-table-column>
        <el-table-column label="Player Off">
          <template slot-scope="scope" v-if="scope.row.meta.off">
            <span> {{ getName(scope.row.meta.off) }} </span>
          </template>
        </el-table-column>
        <el-table-column style="background: red" label="Player On">
          <template slot-scope="scope" v-if="scope.row.meta.on">
            <span> {{ getName(scope.row.meta.on) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="min" label="Minute"> </el-table-column>
        <el-table-column prop="sec" label="Second"> </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <!-- <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-button type="success" @click="() => this.$router.go(-1)">Save</el-button>
      </el-col>
    </div> -->
  </div>
</template>

<script>
import { errormsg, interchanges, gamePeriods } from "../../utils/constants";

const formatWithName = (pl) => ({ ...pl, name: `${pl.firstName} ${pl.lastName}` });
const getName = (pl) => `${pl.firstName || ""} ${pl.lastName || ""}`;
const positiveIntValidate = (_, value, callback) => {
  if (parseInt(value) && parseInt(value) < 0) {
    return callback(new Error("Please enter a valid duration"));
  }
  return callback();
};

export default {
  name: "Movements",
  props: {
    team: Object,
    teamid: Number,
    matchid: Number,
    isHomeTeam: Boolean,
    competitionId: Number,
  },
  data() {
    return {
      add: {
        playeroff: null,
        playeron: null,
        event: null,
        min: null,
        sec: null,
        period: undefined,
      },
      movementRules: {
        playeroff: { required: false, message: errormsg.select_option, trigger: "blur" },
        playeron: { required: false, message: errormsg.select_option, trigger: "blur" },
        event: { required: true, message: errormsg.select_option, trigger: "blur" },
        min: { validator: positiveIntValidate, trigger: "blur" },
        sec: { validator: positiveIntValidate, trigger: "blur" },
        period: {
          required: true,
          message: errormsg.select_option,
          trigger: "blur",
        },
      },
      tableProps: {
        border: true,
      },
      pageSize: 100,
      interchanges,
      getName,
      gamePeriods,
      competition: null,
      interchangeDialogVisible: false,
      interchangeCounts: false,
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    this.$http
      .get(`/nrl/api/v1/admin/competitions/${this.competitionId}`)
      .then((res) => {
        this.competition = res.data.data;
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  created() {
    const { matchid, isHomeTeam } = this;
    this.$store.commit("root/loading", true);
    this.$store
      .dispatch("match/getMovements", { isHomeTeam, matchid })
      .then(() => {
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    handleAddMovement() {
      new Promise((resolve, reject) => {
        this.$refs.movementRules.validate((valid) => (valid ? resolve() : reject()));
      })
        .then(() => {
          const { playeroff, playeron, event: type, min, sec, period } = this.add;

          if (playeron && playeroff && playeroff === playeron) {
            this.$customError(errormsg.same_player);
            return;
          }

          if (type === 10 && this.competition.meta.elite) {
            this.interchangeCounts = true;
          } else if (!this.competition.meta.elite) {
            this.interchangeCounts = true;
          }

          this.$store.commit("root/loading", true);
          const { team, matchid, isHomeTeam } = this;
          const { name } = this.interchanges.find((t) => t.type === type);
          const off = this.availablePlayers.find((p) => p._id === playeroff);
          const on = this.availablePlayers.find((p) => p._id === playeron);
          const movementEvent = {
            type,
            name,
            teamId: team._id,
            isHomeTeam,
            min: min || 0,
            sec: sec || 0,
            meta: {
              period,
            },
            interchangeCounts: this.interchangeCounts,
          };
          if (off) movementEvent.meta.off = off;
          if (on) movementEvent.meta.on = on;
          this.$http
            .post(`/nrl/api/v1/admin/matches/movements/${matchid}`, movementEvent)
            .then((response) => {
              if (!response) {
                this.$customError(errormsg.bad_movement);
              } else if (response.data.message) {
                this.$customError(response.data.message);
              } else {
                this.$customSuccess();
                this.allMovements.push({ ...response.data.data });
                this.add = {
                  playeroff: null,
                  playeron: null,
                  event: null,
                  min: null,
                  sec: null,
                  period: undefined,
                };
              }
              this.$store.commit("root/loading", false);
            })
            .catch((err) => {
              this.$customError(err);
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
    handleRemoveMovement(row) {
      const { matchid } = this;
      this.$confirm(
        "Are you sure you want to remove this movement event?",
        "Remove Movement Event",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.$store.commit("root/loading", true);
          this.$http
            .delete(`/nrl/api/v1/admin/matches/movements/${matchid}`, {
              data: { eventid: row._id },
            })
            .then(() => {
              this.allMovements.splice(
                this.allMovements.findIndex((e) => e._id === row._id),
                1
              );
              this.$customSuccess();
              this.$store.commit("root/loading", false);
            })
            .catch(() => {
              this.$customError();
              this.$store.commit("root/loading", false);
            });
        })
        .catch(() => {});
    },
    handleDialogClose(done) {
      this.$confirm("If you close this dialog and submit interchange, it won't count.")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    showInterchangeDialog(newValue) {
      if (this.competition.meta.elite && (newValue === 11 || newValue === 12)) {
        this.interchangeDialogVisible = true;
      } else if (this.competition.meta.elite) {
        this.interchangeCounts = false;
      }
    },
  },
  computed: {
    allMovements: {
      get() {
        return this.$store.getters["match/movements"](this.isHomeTeam);
      },
      set(data) {
        const { isHomeTeam } = this;
        this.$commit("match/setMovement", { isHomeTeam, data });
      },
    },
    availablePlayers() {
      return this.$store.getters["match/teamlist"](this.isHomeTeam);
    },
    availablePlayersToAdd() {
      const { playeroff } = this.add;
      return this.availablePlayers
        .map((p) => formatWithName(p))
        .filter((t) => !playeroff || t._id !== playeroff);
    },
    playerInterchanges() {
      return this.$store.getters["user/activeRole"].national_id === 32
        ? this.interchanges
        : this.interchanges.filter((o) => o.type === 16); // Send Off/Dismissal
    },
  },
  watch: {
    allMovements(v) {
      this.$emit("mostRecentMovements", v);
    },
  },
};
</script>

<style scoped lang="scss">
.add-movements {
  .el-form {
    .el-form-item {
      max-width: 15%;
      float: left;
      padding-right: 1rem;
    }
    .el-button {
      width: 12.5rem;
    }
  }
  margin-bottom: 2rem;
}

.data-table {
  margin-bottom: 2rem !important;
}

.remove-icon {
  color: red;
  font-size: 2rem;
}
</style>
