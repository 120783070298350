import { render, staticRenderFns } from "./CompetitionCaseCreate.vue?vue&type=template&id=33998ed0&scoped=true&"
import script from "./CompetitionCaseCreate.vue?vue&type=script&lang=js&"
export * from "./CompetitionCaseCreate.vue?vue&type=script&lang=js&"
import style0 from "./CompetitionCaseCreate.vue?vue&type=style&index=0&id=33998ed0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33998ed0",
  null
  
)

export default component.exports