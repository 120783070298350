<template>
  <div>
    <el-row>
      <el-col :span="6">
        <el-form :model="setting" label-position="top" :rules="settingRules" ref="settingRules">
          <el-form-item prop="name" label="Competition Type">
            <el-input autocomplete="off" placeholder="Enter competition type" v-model="setting.name"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-form
      label-position="top"
      :model="currentPosition"
      :rules="rules"
      ref="setting">
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="6">
          <el-form-item prop="name" label="Position Name">
            <el-input autocomplete="off" placeholder="Enter position name" v-model="currentPosition.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="abbreviation" label="Abbreviation">
            <el-input autocomplete="off" placeholder="Enter abbreviation" v-model="currentPosition.abbreviation"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
         <el-form-item prop="number" label="Position No.">
           <el-input autocomplete="off" placeholder="Enter position no." type="number" v-model.number="currentPosition.number"></el-input>
         </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item prop="order" label="Order">
            <el-input autocomplete="off" placeholder="Enter order" type="number" v-model.number="currentPosition.order"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button type="success" @click="submitForm('setting')">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      cancel
      @submit="updateCompetitionSettings()"
      @cancel="cancelForm()"/>
    <data-tables
    v-if="setting.positions.length > 0"
    :data="setting.positions"
    :table-props="tableProps"
    layout="table"
    id="compTypePositions"
    class="data-table">
      <el-table-column
        label="Name"
        width="200">
        <template slot-scope="scope">
          <el-input autocomplete="off" v-model="scope.row.name" :disabled="!optionsdisabled[scope.$index]"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Abbreviation"
        width="150">
        <template slot-scope="scope">
          <el-input autocomplete="off" v-model="scope.row.abbreviation" :disabled="!optionsdisabled[scope.$index]"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Number"
        width="150">
        <template slot-scope="scope">
          <el-input autocomplete="off" v-model="scope.row.number" :disabled="!optionsdisabled[scope.$index]"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Order"
        prop="order"
        width="150">
        <template slot-scope="scope">
          <el-input autocomplete="off" v-model="scope.row.order" :disabled="!optionsdisabled[scope.$index]"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Edit/Remove"
        width="auto">
        <template slot-scope="scope">
          <el-button @click="handleEditRow(scope.$index)">
            <template>
                {{ !optionsdisabled[scope.$index] ? 'Edit' : 'Save' }}
            </template>
          </el-button>
          <el-button @click="handleRemoveRow(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue';
import { errormsg } from '../../utils/constants';

export default {
  name: 'CompetitionsForm',
  components: { ActionButtons },
  props: {
  },
  data() {
    const numberValidator = (_, value, callback) => {
      const existingNumbers = this.setting.positions.map(p => p.number);
      if (!parseInt(value) || parseInt(value) < 0 || value % 1 !== 0) {
        return callback(new Error('Please enter a valid number'));
      }
      if (existingNumbers.includes(value)) {
        return callback(new Error('That position number has already been used!'));
      }
      return callback();
    };

    const orderValidator = (_, value, callback) => {
      const existingNumbers = this.setting.positions.map(p => p.order);
      if (!parseInt(value) || parseInt(value) < 0 || value % 1 !== 0) {
        return callback(new Error('Please enter a valid number'));
      }
      if (existingNumbers.includes(value)) {
        return callback(new Error('That order number has already been used!'));
      }
      return callback();
    };

    return {
      type: 'insert',
      setting: {
        name: '',
        positions: [
        ]
      },
      optionsdisabled: [],
      currentPosition: {
        name: null,
        abbreviation: null,
        number: null,
        order: null,
      },
      settingRules: {
        name: {
          required: true,
          min: 3,
          message: errormsg.input_option,
        }
      },
      rules: {
        name: {
          required: true,
          message: errormsg.input_option,
          trigger: 'blur'
        },
        abbreviation: {
          required: true,
          message: errormsg.input_option,
          trigger: 'blur'
        },
        number: {
          type: 'number',
          required: true,
          trigger: 'blur',
          validator: numberValidator,
        },
        order: {
          type: 'number',
          required: true,
          trigger: 'blur',
          validator: orderValidator,
        },
      },
      tableProps: {
        border: true,
      },
    };
  },
  mounted() {
    this.$store.commit('root/loading', true);
    const { type, compSetting, id } = this.$route.params;
    this.type = (type || (id !== 'add' ? 'update' : 'insert'));
    if (id && id !== '' && id !== 'add' && !compSetting) {
      this.$http
        .get(`/nrl/api/v1/admin/competitions-settings/${id}`)
        .then((response) => {
          this.setting = response.data.data;
          this.$store.commit('root/loading', false);
        })
        .catch(() => {
          this.$store.commit('root/loading', false);
          this.$customError();
        });
    } else {
      if (compSetting) { this.setting = compSetting; }
      if (this.setting.positions.length > 0) {
        this.optionsdisabled = new Array(this.setting.positions.length).fill(false);
      }
      this.$store.commit('root/loading', false);
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const toAdd = Object.assign({}, this.currentPosition);
          this.setting.positions.push(toAdd);
          this.currentPosition = {
            name: null,
            abbreviation: null,
            number: null,
            order: null,
          };
          return true;
        }
        return false;
      });
    },
    updateCompetitionSettings() {
      new Promise((resolve, reject) => {
        this.$refs.settingRules
          .validate(valid => (valid ? resolve() : reject()));
      }).then(() => {
        if (this.type === 'update') {
          // Update setting
          this.$store.commit('root/loading', true);
          const update = {
            positions: this.setting.positions,
            name: this.setting.name
          };

          const url = `/nrl/api/v1/admin/competitions-settings/${this.setting._id}`;
          this.$http
            .put(url, update)
            .then(() => {
              this.$router.push({ name: 'competitions-types.list' });
              this.$store.commit('root/loading', false);
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          this.$store.commit('root/loading', true);
          const insert = {
            name: this.setting.name,
            positions: this.setting.positions,
            orgtree: {
              national: {
                _id: this.$store.getters['user/activeRole'].national_id,
                name: this.$store.getters['user/activeRole'].entity.name,
              }
            }
          };

          const url = '/nrl/api/v1/admin/competitions-settings/';
          this.$http
            .post(url, insert)
            .then(() => {
              this.$router.push({ name: 'competitions-types.list' });
              this.$customSuccess();
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        }
      });
    },
    cancelForm() {
      this.$router.push({
        name: 'competitions-types.list',
      });
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.setting.positions[rowindex];
        if (!parseInt(row.number) || parseInt(row.number) < 0) {
          this.$customError(errormsg.pos_number);
          return false;
        }
        row.number = parseInt(row.number);
        if (!parseInt(row.order) || parseInt(row.order) < 0) {
          this.$customError(errormsg.order_number);
          return false;
        }
        row.order = parseInt(row.order);
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(rowindex) {
      this.setting.positions.splice(rowindex, 1);
      this.optionsdisabled.splice(rowindex, 1);
    },
  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
  .el-input, .el-select {
    width: 100%;
  }

  .btn-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  .btn-container-add {
    float: right;
  }

  .data-table {
    margin-top: 2.5rem !important;
  }
</style>
