<template>
  <div>
    <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="24">
        <el-form
          label-position="top"
          :model="gamedevregion"
          :rules="rules"
          ref="gamedevregion"
          class="login-form"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="12">
              <el-form-item prop="national" label="National">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="gamedevregion.orgtree.national.name"
                  placeholder="National Name"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="name" label="Name">
                <el-input
                  autocomplete="off"
                  placeholder="Enter name"
                  v-model="gamedevregion.name"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="12">
              <el-form-item prop="contactName" label="Contact Name">
                <el-input
                  autocomplete="off"
                  placeholder="Enter contact name"
                  v-model="gamedevregion.contactName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="contactNumber" label="Contact Number">
                <el-input
                  autocomplete="off"
                  placeholder="Enter contact name"
                  v-model="gamedevregion.contactNumber"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="12">
              <el-form-item prop="contactEmail" label="Contact Email">
                <el-input
                  autocomplete="off"
                  placeholder="Enter contact email"
                  v-model="gamedevregion.contactEmail"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="meta.posid" label="POSID">
                <el-input
                  autocomplete="off"
                  placeholder="Enter POSID"
                  v-model="gamedevregion.meta.posid"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-start tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
            <el-col :span="12">
              <el-form-item prop="meta.abn" label="ABN">
                <el-select
                  v-model="gamedevregion.meta.abn"
                  :disabled="![roles.superAdmin].includes(clearance)"
                  filterable
                  remote
                  placeholder="Search by name"
                  :loading="abnLoading"
                  :remote-method="searchAbn"
                >
                  <el-option
                    v-for="item in abnOptions"
                    :key="item.value"
                    :label="`${item.label} (${item.value})`"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="meta.gstRegistered" label="GST Registered">
                <el-select
                  v-model="gamedevregion.meta.gstRegistered"
                  :disabled="![roles.superAdmin].includes(clearance)"
                >
                  <el-option label="Yes" :value="true" />
                  <el-option label="No" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
        <action-buttons
          submit
          :cancel="!isMyEntity"
          @submit="submitForm('gamedevregion')"
          @cancel="cancelForm()"
        />
      </el-col>
    </div>
  </div>
</template>

<script>
import { isNaN } from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import { errormsg, uploadURL, roles } from "../../utils/constants";

export default {
  name: "GameDevRegionForm",
  props: {},
  components: {
    ActionButtons
  },
  data() {
    return {
      type: null,
      roles,
      abnLoading: false,
      abnOptions: [],
      gamedevregion: {
        orgtree: {
          national: {
            _id: null,
            name: null
          }
        },
        name: null,
        contactName: null,
        contactNumber: null,
        contactEmail: null,
        meta: {
          posid: "",
          abn: "",
          gstRegistered: false
        }
      },
      files: [],
      rules: {
        name: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactName: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactNumber: { required: true, message: errormsg.input_option, trigger: "blur" },
        contactEmail: { required: true, message: errormsg.input_option, trigger: "blur" },
        "meta.gstRegistered": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur"
        },
        "meta.abn": {
          required: true,
          message: errormsg.select_option,
          trigger: "blur"
        },
        "meta.posid": {
          required: false,
          trigger: "blur",
          message: errormsg.posid_number,
          validator: (rule, val, cb) => {
            if (val && isNaN(+val)) {
              return cb(new Error(errormsg.posid_number));
            }
            return cb();
          }
        }
      },
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png"
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { type, gamedevregion, id } = this.$route.params;
    this.type = type || (id === "add" ? "insert" : "update");
    if (id && id !== "" && id !== "add") {
      if (gamedevregion) {
        this.gamedevregion = gamedevregion;
        this.$store.commit("root/loading", false);
      } else {
        this.$http
          .get(`/nrl/api/v1/admin/game-dev-regions/${id}`)
          .then(response => {
            this.gamedevregion = response.data.data;
            this.$store.commit("root/loading", false);
          })
          .catch(() => {
            this.$customError();
            this.$store.commit("root/loading", false);
          });
      }
      this.$store.commit("root/loading", false);
    } else {
      const { entity } = this.$store.getters["user/activeRole"];
      this.gamedevregion.orgtree.national._id = entity._id;
      this.gamedevregion.orgtree.national.name = entity.name;
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    searchAbn(query) {
      if (query !== "") {
        this.abnLoading = true;
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/abr-proxy/name", { name: query, maxResult: 10 })
            .then(response => {
              this.abnLoading = false;
              if (response.data.data.length > 0) {
                this.abnOptions = response.data.data.map(o => ({ label: o.text, value: o.abn }));
              }
            })
            .catch(() => {
              this.$customError();
            });
        }, 200);
      }
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    submitForm(formname) {
      this.$refs[formname].validate(valid => {
        if (valid) {
          if (this.type === "insert") {
            this.$store.commit("root/loading", true);
            const insert = this.gamedevregion;
            const url = "/nrl/api/v1/admin/game-dev-regions";
            this.$http
              .post(url, insert)
              .then(() => {
                this.$customSuccess();
                this.$router.push({ name: "game-dev-region.list" });
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          } else {
            this.$store.commit("root/loading", true);
            const url = `/nrl/api/v1/admin/game-dev-regions/${this.gamedevregion._id}`;
            this.$http
              .put(url, this.gamedevregion)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        }
      });
    },
    cancelForm() {
      this.$router.push({ name: "game-dev-region.list" });
    }
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentLogo() {
      return this.gamedevregion.meta && this.gamedevregion.meta.avatar
        ? this.gamedevregion.meta.avatar
        : false;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    uploadURL() {
      return uploadURL;
    },
    entityData() {
      return {
        entity_type: "gamedevregion",
        entity_id: this.gamedevregion._id
      };
    },
    isMyEntity() {
      return this.$route.path.split("/").includes("my");
    }
  }
};
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}

.entity-card {
  height: 40rem;
}

.avatar-box {
  overflow: hidden;
  .avatar {
    margin: 0 auto;
    height: 12.5rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 70%;
  }
}

.operation {
  el-button {
    width: 100%;
    height: 100%;
  }
}
</style>
