<template>
  <div>
    <el-form
      label-position="top"
      :model="program"
      :rules="saleableitemsrules"
      ref="regisaleableitems"
    >
      <el-row type="flex" align="bottom" :gutter="10">
        <el-col :span="4">
          <el-form-item prop="saleableitemname" label="Name">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemname"
              placeholder="Name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="saleableitemdesc" label="Description">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemdesc"
              placeholder="Description"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="saleableitemprice" label="Price">
            <el-input
              type="text"
              autocomplete="off"
              v-model="saleableitemprice"
              placeholder="Price"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="requireSizes" label="Require Sizes">
            <el-select v-model="currentSalebleItem.requireSizes" placeholder="Member types">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="mandatory" label="Mandatory">
            <el-select v-model="currentSalebleItem.mandatory" placeholder="Member types">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="seasonPass" label="Single season purchase">
            <el-select v-model="currentSalebleItem.seasonPass" placeholder="Single season purchase">
              <el-option
                v-for="item in [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="gender" label="Gender">
            <el-select v-model="currentSalebleItem.gender" placeholder="Gender">
              <el-option
                v-for="item in [
                  { label: 'All', value: '' },
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' }
                ]"
                :label="item.label"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item prop="ageFrom" label="Age from">
            <el-input
              type="number"
              autocomplete="off"
              v-model="currentSalebleItem.ageFrom"
              placeholder="Age from"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="ageTo" label="Age to">
            <el-input
              type="number"
              autocomplete="off"
              v-model="currentSalebleItem.ageTo"
              placeholder="Age to"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="tw-py-3 tw-h-14">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitSaleableitem('regisaleableitems')"
              >Add</el-button
            >
          </el-form-item>
        </el-col>
      </div>
    </el-form>
    <data-tables
      v-if="program.saleableItems.length > 0"
      :data="program.saleableItems"
      :table-props="tableProps"
      layout="table"
      class="data-table"
    >
      <el-table-column label="Name" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.name"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Description" width="300">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.description"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Price" width="150">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="text"
            v-model="scope.row.price"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Require Sizes" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.requireSizes"
            placeholder="Require sizes"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Mandatory" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.mandatory"
            placeholder="Mandatory"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Season" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.seasonPass"
            placeholder="Season"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Gender" width="200">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.gender"
            placeholder="Gender"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          >
            <el-option
              v-for="item in [
                { label: 'All', value: '' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' }
              ]"
              :label="item.label"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Age From" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageFrom"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Age To" width="100">
        <template slot-scope="scope">
          <el-input
            autocomplete="off"
            type="number"
            v-model="scope.row.ageTo"
            :disabled="!saleableoptionsdisabled[scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Edit/Remove" width="300">
        <template slot-scope="scope">
          <el-button @click="handleEditRowSaleble(scope.$index)">
            <template v-if="!saleableoptionsdisabled[scope.$index]">
              Edit
            </template>
            <template v-else>
              Save
            </template>
          </el-button>
          <el-button @click="handleRemoveRowSaleble(scope.$index)">Remove</el-button>
        </template>
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import _ from "lodash";
import { errormsg } from "../../utils/constants";

const updateProgram = (self, saleableItems, onSuccess) => {
  const update = {
    saleableItems: _.reduce(
      saleableItems,
      (items, item) => {
        item.price = item.price_int;
        delete item.price_int;

        if (!item.gender || item.gender === "") delete item.gender;

        if (item.ageFrom && item.ageFrom !== "") item.ageFrom = parseInt(item.ageFrom);
        else delete item.ageFrom;

        if (item.ageTo && item.ageTo !== "") item.ageTo = parseInt(item.ageTo);
        else delete item.ageTo;

        items.push(item);
        return items;
      },
      []
    )
  };
  const url = `/nrl/api/v1/admin/programs-settings/${self.program._id}`;
  self.$http
    .put(url, update)
    .then(response => {
      const program = response.data.data;
      const parsedItems = program.saleableItems.map(o => {
        o.price_int = parseInt(o.price);
        o.price = (parseFloat(o.price) / 100).toFixed(2);
        if (!o.gender) o.gender = "";
        return o;
      });
      onSuccess(parsedItems);
      self.$store.commit("root/loading", false);
      self.$customSuccess();
    })
    .catch(() => {
      self.$store.commit("root/loading", false);
      self.$customError();
    });
};

export default {
  name: "ProgramsettingsSaleableItems",
  props: ["program"],
  data() {
    const validateSalebleName = (rule, value, callback) => {
      if (!this.currentSalebleItem.name || this.currentSalebleItem.name === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleDesc = (rule, value, callback) => {
      if (!this.currentSalebleItem.description || this.currentSalebleItem.description === "") {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSaleblePrice = (rule, value, callback) => {
      if (!this.currentSalebleItem.price || parseFloat(this.currentSalebleItem.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleAgeFrom = (rule, value, callback) => {
      if (!this.currentSalebleItem.ageFrom && this.currentSalebleItem.ageTo) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateSalebleAgeTo = (rule, value, callback) => {
      if (!this.currentSalebleItem.ageTo && this.currentSalebleItem.ageFrom) {
        return callback(new Error(errormsg.input_option));
      }
      if (parseInt(this.currentSalebleItem.ageTo) < parseInt(this.currentSalebleItem.ageFrom)) {
        return callback(new Error(errormsg.gte_age));
      }
      return callback();
    };

    return {
      currentSalebleItem: {
        name: null,
        description: null,
        price: null,
        types: [],
        requireSizes: false,
        mandatory: false,
        seasonPass: false,
        gender: "",
        ageFrom: null,
        ageTo: null
      },
      saleableoptionsdisabled: [],
      saleableitemsrules: {
        saleableitemname: {
          required: true,
          validator: validateSalebleName,
          message: errormsg.input_option,
          trigger: "blur"
        },
        saleableitemdesc: {
          required: true,
          validator: validateSalebleDesc,
          message: errormsg.input_option,
          trigger: "blur"
        },
        saleableitemprice: {
          required: true,
          validator: validateSaleblePrice,
          message: errormsg.input_option,
          trigger: "blur"
        },
        ageTo: {
          validator: validateSalebleAgeTo
        },
        ageFrom: {
          validator: validateSalebleAgeFrom,
          message: errormsg.input_option
        }
      },
      tableProps: {
        border: true
      }
    };
  },
  methods: {
    submitSaleableitem(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const saleableitemsamename = this.program.saleableItems.filter(
            d => d.name === this.currentSalebleItem.name
          );
          if (saleableitemsamename && saleableitemsamename.length > 0) {
            this.$customError(errormsg.regiproduct_saleableitemsamename);
            return false;
          }
          this.$store.commit("root/loading", true);

          const insert = {
            name: this.currentSalebleItem.name,
            description: this.currentSalebleItem.description,
            price_int: parseInt(parseFloat(this.currentSalebleItem.price).toFixed(2) * 100),
            price: parseFloat(this.currentSalebleItem.price).toFixed(2),
            requireSizes: this.currentSalebleItem.requireSizes,
            mandatory: this.currentSalebleItem.mandatory,
            seasonPass: this.currentSalebleItem.seasonPass
          };

          if (this.currentSalebleItem.gender) insert.gender = this.currentSalebleItem.gender;
          if (this.currentSalebleItem.ageFrom)
            insert.ageFrom = parseInt(this.currentSalebleItem.ageFrom);
          if (this.currentSalebleItem.ageTo) insert.ageTo = parseInt(this.currentSalebleItem.ageTo);

          const saleableItems = _.cloneDeep(this.program.saleableItems);
          saleableItems.push(insert);

          updateProgram(this, saleableItems, parsedItems => {
            this.program.saleableItems = parsedItems;
            this.currentSalebleItem = {
              name: null,
              description: null,
              price: null,
              types: [],
              requireSizes: false,
              mandatory: false,
              seasonPass: false,
              gender: "",
              ageFrom: null,
              ageTo: null
            };
          });

          return true;
        }
        return false;
      });
    },
    handleEditRowSaleble(rowindex) {
      if (this.saleableoptionsdisabled[rowindex]) {
        const row = this.program.saleableItems[rowindex];
        if (!row.name || row.name === "") {
          this.$customError(errormsg.input_option);
          return false;
        }
        if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          return false;
        }
        const saleableitemsamename = this.program.saleableItems.filter(
          (d, index) => d.name === row.name && index !== rowindex
        );
        if (saleableitemsamename && saleableitemsamename.length > 0) {
          this.$customError(errormsg.regiproduct_saleableitemsamename);
          return false;
        }
        this.$store.commit("root/loading", true);
        // this.program.saleableItems[rowindex].price = parseFloat(row.price).toFixed(2);
        this.program.saleableItems[rowindex].price_int = parseInt(
          parseFloat(row.price).toFixed(2) * 100
        );

        updateProgram(this, _.cloneDeep(this.program.saleableItems), parsedItems => {
          this.program.saleableItems = parsedItems;
          this.$set(
            this.saleableoptionsdisabled,
            rowindex,
            !this.saleableoptionsdisabled[rowindex]
          );
        });

        return true;
      }
      this.$set(this.saleableoptionsdisabled, rowindex, !this.saleableoptionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRowSaleble(index) {
      this.$store.commit("root/loading", true);
      const saleableItems = _.cloneDeep(this.program.saleableItems);
      saleableItems.splice(index, 1);
      updateProgram(this, saleableItems, () => {
        this.program.saleableItems.splice(index, 1);
        this.saleableoptionsdisabled.splice(index, 1);
      });
    }
  },
  computed: {
    saleableitemname: {
      get() {
        return this.currentSalebleItem.name;
      },
      set(value) {
        this.currentSalebleItem.name = value;
      }
    },
    saleableitemdesc: {
      get() {
        return this.currentSalebleItem.description;
      },
      set(value) {
        this.currentSalebleItem.description = value;
      }
    },
    saleableitemprice: {
      get() {
        return this.currentSalebleItem.price;
      },
      set(value) {
        this.currentSalebleItem.price =
          !Number.isNaN(parseFloat(value)) && parseFloat(value) >= 0 ? value : null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
