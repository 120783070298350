<template>
  <div>
    <el-button
      icon="el-icon-plus"
      v-if="clearance === roles.clubAdmin"
      type="success"
      @click="handleRequest"
    >
      Request Clearance/Permit
    </el-button>
    <el-row class="search-bar" :gutter="10">
      <el-col :span="6">
        <el-input autocomplete="off" v-model="filters[0].value" placeholder="Search..."> </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="filterVisible = true"> Filters </el-button>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
      <filter-page @cancel="filterVisible = false" @submit="sendFilter" @clear="sendFilter" />
    </el-dialog>

    <data-tables
      v-loading="loading"
      :filters="filters"
      :data="tableData"
      :table-props="tableProps"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      @row-click="handleRowClick"
      class="data-table"
    >
      <el-table-column fixed prop="_id" label="Id" width="50"> </el-table-column>
      <el-table-column fixed prop="member._id" label="NRL ID" width="100">
        <template slot-scope="scope">
          <span :id="scope.row.member._id">{{ scope.row.member._id }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed :formatter="typeFormatter" prop="type" label="Type" width="100">
      </el-table-column>
      <el-table-column fixed prop="member.profile.firstName" label="First Name" width="250">
      </el-table-column>
      <el-table-column prop="member.profile.lastName" label="Last Name" width="250">
      </el-table-column>
      <el-table-column :formatter="ageFormatter" prop="profile.dob" label="Age" width="120">
      </el-table-column>
      <el-table-column :formatter="dobFormatter" prop="member.profile.dob" label="DOB" width="100">
      </el-table-column>
      <el-table-column prop="member.profile.gender" label="Gender" width="100"> </el-table-column>
      <el-table-column prop="fromClub.name" label="From Club" width="150"> </el-table-column>
      <el-table-column prop="toClub.name" label="To Club" width="150"> </el-table-column>
      <el-table-column prop="approval.status" label="Status" width="100">
        <template slot-scope="scope">
          {{ capitalize(scope.row.approval.status) }}
        </template>
      </el-table-column>
      <el-table-column
        :formatter="modifiedFormatter"
        prop="modifiedAt"
        label="Last Modified"
        width="250"
      >
      </el-table-column>
      <el-table-column
        :formatter="requiresActionFormatter"
        prop="approval.status"
        label="Requires Action"
        width="150"
      >
      </el-table-column>
    </data-tables>
  </div>
</template>

<script>
import { escapeRegExp, capitalize, debounce } from "lodash";
import FilterPage from "@/views/clearance-permit/FilterPage.vue";
import { roles } from "@/utils/constants";
import { calculateAge } from "../../utils/utilities";

const dobToAge = member => {
  if (member) {
    const age = calculateAge(member.profile.dob, true);
    return age;
  }
  return "N/A";
};

export default {
  name: "ClearancePermitsList",
  components: { FilterPage },
  props: {},
  data() {
    return {
      filterVisible: false,
      memberTypeNames: [
        {
          value: 2,
          text: "Clearance",
        },
        {
          value: 1,
          text: "Permit",
        },
      ],
      statusNames: [
        {
          value: "approved",
          text: "Approved",
        },
        {
          value: "declined",
          text: "Declined",
        },
        {
          value: "pending",
          text: "Pending",
        },
      ],
      requiresAction: ["Yes", "No"].map((x) => ({
        text: x,
        value: x,
      })),
      capitalize,
      loading: true,
      tableData: [],
      filters: [
        {
          value: this.$store.state.clearancePermit.search,
          statusValue: "",
          filterFn: (row, filter) =>
            Object.keys(row).some((prop) => {
              if (filter.statusValue && filter.statusValue !== "") {
                return false;
              }
              if (prop === "_id") {
                const id = row[prop];
                const filterRegex = new RegExp(escapeRegExp(filter.value), "i");
                return id.toString().match(filterRegex);
              }
              if (prop === "member") {
                const { firstName, lastName } = row[prop].profile;
                const { _id } = row[prop];
                const filterRegex = new RegExp(escapeRegExp(filter.value), "i");
                return (
                  _id.toString().match(filterRegex) ||
                  firstName.match(filterRegex) ||
                  lastName.match(filterRegex)
                );
              }

              if (prop === "fromClub" || prop === "toClub") {
                const { name } = row[prop];
                const filterRegex = new RegExp(escapeRegExp(filter.value), "i");
                return name.match(filterRegex);
              }
              return false;
            }),
        },
        {
          value: [],
        },
      ],
      remoteFilter: {
        type: null,
        status: null,
        requiresAction: null,
      },
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      roles,
    };
  },
  mounted() {
    this.sendFilter(this.$store.state.clearancePermit.filters);
  },
  methods: {
    fetchStore() {
      const { data } = this.$store.state.clearancePermit;
      if (data.length) {
        this.tableData = data;
        return true;
      }
      return false;
    },
    ageFormatter(row) {
      return row && row.member && row.member.profile && row.member.profile.dob ? dobToAge(row.member) : "N/A";
    },
    sendFilter(event) {
      // store params
      this.$store.commit("clearancePermit/updateFilters", event);

      // eslint-disable-next-line no-unused-vars
      const {
        type: typeArray,
        requiresAction: requiresActionArray,
        status: statusArray,
        gender: genderArray,
        ageLvl: ageArray,
        fromClub,
        toClub,
      } = event;
      const type = typeArray.length > 0 ? typeArray[0] : null;
      const ageLevel = ageArray;
      // TODO: Req Action depends on the approval object of the clerance or permit -- isRequiredAction
      const requiresAction = requiresActionArray.length > 0 ? requiresActionArray[0] : null;
      const status = statusArray.length > 0 ? statusArray[0] : null;
      const gender = genderArray.length > 0 ? genderArray[0] : null;
      const params = {
        type,
        requiresAction,
        status,
        gender,
        ageLevel,
        fromClub,
        toClub,
      };

      this.loading = true;
      this.$http
        .get("/nrl/api/v1/admin/clearance-permits/", { params })
        .then((response) => {
          this.tableData = response.data.data;
          this.loading = false;
          this.filterVisible = false;
        })
        .catch(() => {
          this.loading = false;
          this.$customError();
          this.filterVisible = false;
        });
    },
    handleRowClick(row) {
      this.$router.push({
        name: "clearance-permit.update",
        params: {
          type: "update",
          clearancePermit: row,
          id: row._id,
        },
      });
    },
    handleRequest() {
      this.$router.push({
        name: "clearance-permit.request",
      });
    },
    modifiedFormatter(row) {
      return row.modifiedAt ? this.moment(row.modifiedAt).format("MMMM Do YYYY, h:mm:ss a") : "NA";
    },
    typeFormatter(row) {
      return row.type === 2 ? "Clearance" : "Permit";
    },
    dobFormatter(row) {
      return row.member && row.member.profile.dob
        ? this.moment(row.member.profile.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        : "NA";
    },
    requiresActionFormatter(row) {
      return this.isRequiredAction(row) ? "Yes" : "No";
    },
    requiresActionFilter(value, row) {
      switch (value) {
        case "No":
          return !this.isRequiredAction(row);
        case "Yes":
          return this.isRequiredAction(row);
        default:
          return true;
      }
    },
    isRequiredAction(row) {
      // overall status is pending => being true
      let result = row.approval && row.approval.status && ["pending"].includes(row.approval.status);

      if (result) {
        // check at the current active role level
        let levelStatus = "";
        let entityName;
        if (!this.$store.getters["user/activeRole"].entity) return false;

        const activeRoleId = this.$store.getters["user/activeRole"].entity._id;
        switch (this.$store.getters["user/activeRole"].type) {
          case roles.clubAdmin:
            entityName = "clubs";
            break;
          case roles.associationAdmin:
            entityName = "associations";
            break;
          case roles.regionAdmin:
            entityName = "regions";
            break;
          case roles.stateAdmin:
            entityName = "states";
            break;
          default:
            entityName = "";
            break;
        }
        const entity = entityName && row.approval[entityName].find((x) => x._id === activeRoleId);
        levelStatus = (entity && entity.status) || "";

        // at the current level, check if need an action
        result = ["pending"].includes(levelStatus);
      }
      return result;
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
  watch: {
    tableData(val) {
      this.$store.commit("clearancePermit/updateData", val);
    },
    filters: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function (val) {
        this.$store.commit("clearancePermit/updateSearch", val[0].value);
      }, 500),
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-table {
  width: 100%;
}

.el-pagination {
  margin-top: 1rem !important;
}

.filter-select {
  width: 100%;
}
</style>
